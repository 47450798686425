import { classnames } from '@/helpers';
import { HTMLAttributes } from 'react';
import { BUTTON_PADDING } from '../Button/Button';
import { ButtonSize, ButtonVariant } from '../types';
import css from './Fab.module.scss';
type Icon = JSX.Element;
type GenericFabProps = {
  variant?: ButtonVariant;
  size: ButtonSize;
  floating?: boolean;
  icon?: Icon;
  rightIcon?: Icon;
  label?: string;
  disabled?: boolean;
  to?: string;
};

export type FabProps = HTMLAttributes<HTMLButtonElement> & GenericFabProps;

const getVariant = (variant: ButtonVariant, size: ButtonSize, floating: boolean, disabled: boolean) => {
  const variants = {
    primary: ` text-${size}  ${
      disabled
        ? 'bg-bg_secondary_bold text-fg_disabled border border-border_bold'
        : 'hover:bg-bg_primary_hover text-fg_primary_inverse bg-bg_primary'
    } ${!disabled && floating ? 'shadow-lg' : ''}`,
    secondary: `bg-bg_secondary_bold text-${size} text-fg_primary ${
      !disabled ? 'hover:bg-bg_secondary_hover' : 'pointer-events-none'
    } ${!disabled && floating ? 'shadow-lg' : ''} ${disabled ? 'border border-border_bold' : ''}`,
    tertiary: `bg-brand text-${size} text-white ${disabled ? 'opacity-20' : 'hover:bg-brand-200'} ${
      !disabled && floating ? 'shadow-lg' : ''
    }`,
    link: `text-${size} ${disabled ? 'opacity-20' : ''}`,
  };

  return variants[variant];
};

const base = `flex items-center justify-center rounded-full`;
const btnClasses = (
  variant: ButtonVariant,
  size: ButtonSize,
  floating: boolean,
  disabled?: boolean,
  className?: string,
) => classnames(base, getVariant(variant, size, floating, disabled), className);

const Fab = ({
  variant = 'primary',
  label,

  size,
  floating,
  icon,
  disabled,
  children,
  className = '',
  rightIcon,
  to,
  ...props
}: FabProps) => {
  const commonClassNames = `${css.button} ${css[size]} ${css[variant]} ${
    label || children ? `!h-auto !w-auto ${BUTTON_PADDING[size]} gap-x-md` : ''
  } ${btnClasses(variant, size, floating, disabled, className)}`;

  const content = (
    <>
      {icon && <span className={`${css.icon} ${css[size]}`}>{icon}</span>}
      {label ?? children}
      {rightIcon && <span className={`${css.icon} ${css[size]}`}>{rightIcon}</span>}
    </>
  );

  if (to) {
    return (
      <a href={to} className={commonClassNames}>
        {content}
      </a>
    );
  }
  return (
    <button className={commonClassNames} {...props}>
      {content}
    </button>
  );
};

export default Fab;
