import breakpoints from '@/breakpoints';
import { isMobile, isServer, setMobile, unsetMobile } from '@/helpers';
import { useCallback, useEffect, useState } from 'react';

const getScreenSize = () => {
  if (isServer) return { width: isMobile() ? breakpoints.sm : breakpoints.md };
  const { innerWidth: width } = window;
  return { width };
};

const useMobileView = () => {
  const [windowSize, setWindowSize] = useState<{ width: number }>(getScreenSize);
  const [isMobileView, setIsMobileView] = useState<boolean>(isMobile);

  // Debounced resize handler
  const handleResize = useCallback(() => {
    const newSize = getScreenSize();
    setWindowSize((prev) => (prev.width !== newSize.width ? newSize : prev));
  }, []);

  useEffect(() => {
    if (windowSize.width < breakpoints.md) {
      setMobile();
      setIsMobileView(true);
      return;
    }
    unsetMobile();
    setIsMobileView(false);
  }, [windowSize]);

  useEffect(() => {
    const debounce = (func, delay) => {
      let timeout: NodeJS.Timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), delay);
      };
    };

    const debouncedResize = debounce(handleResize, 50);

    window.addEventListener('resize', debouncedResize);
    return () => window.removeEventListener('resize', debouncedResize);
  }, [handleResize]);

  return { isMobileView, windowSize };
};

export default useMobileView;
