import { SEARCH_RESULT_PER_PAGE_LIMIT } from '@/features/searchV2/constants';
import { searchContextSchema } from '@/features/searchV2/types/tracking';
import { searchPlaceSchema } from '@/types/api/services/search';
import { z } from 'zod';

const searchState = z.object({
  orderBy: z.number(),
  extend: z.boolean(),
  fetching: z.boolean(),
  fetchingExtendedPlaces: z.boolean(),
  places: z.record(z.number(), z.array(searchPlaceSchema)),
  profilePages: z.array(searchPlaceSchema),
  topSearch: z.object({ places: z.array(searchPlaceSchema) }),
  results: z.number().optional(),
  version: z.number().optional(),
  startingScoreVersion: z.number(),
  showInjectionNotice: z.boolean().optional(),
  extendSearchDisabled: z.boolean().optional(),
  extendSearchIteration: z.number().optional(),
  isSSRFetched: z.boolean(),
  isSistaminutenSearch: z.boolean().optional(),
  bounds: z.string().optional(),
  searchContext: searchContextSchema.optional(),
});

export type SearchState = z.infer<typeof searchState>;

export const SEARCH_ACTION = {
  SET_FETCHING: 'search/SET_FETCHING',
  SET_BOUNDS: 'search/SET_BOUNDS',
  SET_RESULTS: 'search/SET_RESULTS',
  SET_RESET_RESULTS: 'search/SET_RESET_RESULTS',
  SET_EXTENDED_RESULTS: 'search/SET_EXTENDED_RESULTS',
  SET_EXTEND_FETCHING: 'search/SET_EXTEND_FETCHING',
} as const;

type SearchAction =
  | {
      type: typeof SEARCH_ACTION.SET_RESULTS;
      payload: Partial<SearchState>;
    }
  | {
      type: typeof SEARCH_ACTION.SET_FETCHING;
      payload: boolean;
    }
  | {
      type: typeof SEARCH_ACTION.SET_RESET_RESULTS;
    }
  | {
      type: typeof SEARCH_ACTION.SET_EXTENDED_RESULTS;
      payload: { page: number; extendedPlaces: SearchState['places'][number]; results: number };
    }
  | {
      type: typeof SEARCH_ACTION.SET_EXTEND_FETCHING;
      payload: boolean;
    }
  | {
      type: typeof SEARCH_ACTION.SET_BOUNDS;
      payload: string;
    };

const initialState: SearchState = {
  orderBy: 1,
  places: { 0: [] },
  profilePages: [],
  fetching: false,
  fetchingExtendedPlaces: false,
  results: undefined,
  topSearch: { places: [] },
  version: 2,
  startingScoreVersion: 1,
  extendSearchDisabled: undefined,
  showInjectionNotice: false,
  isSSRFetched: false,
  extendSearchIteration: 0,
  bounds: undefined,
};

export function searchV2(state: SearchState = initialState, action: SearchAction): SearchState {
  switch (action.type) {
    case SEARCH_ACTION.SET_RESULTS: {
      return {
        ...state,
        ...action.payload,
        fetching: false,
        isSSRFetched: false,
      };
    }
    case SEARCH_ACTION.SET_EXTENDED_RESULTS: {
      const { page = 0, extendedPlaces, results } = action.payload;

      const extendedPlacesCount = extendedPlaces.length;
      let remainingPlaces = extendedPlacesCount;
      let currentPage = Number(page);

      const places = { ...state.places };

      /**
       * Paginate the extended places and add them to the correct page
       */
      while (remainingPlaces > 0) {
        // calculate how many places can fit on the current page
        const countOnCurrentPage = state.places?.[currentPage]?.length || 0;
        const populateOnCurrentPage = Math.min(remainingPlaces, SEARCH_RESULT_PER_PAGE_LIMIT - countOnCurrentPage);

        // add the places to the current page
        places[currentPage] = [
          ...(places[currentPage] || []),
          ...extendedPlaces.slice(
            extendedPlacesCount - remainingPlaces,
            extendedPlacesCount - remainingPlaces + populateOnCurrentPage,
          ),
        ];

        remainingPlaces -= populateOnCurrentPage;
        currentPage += 1;
      }

      return {
        ...state,
        places,
        extendSearchIteration: state.extendSearchIteration + 1,
        results,
        fetchingExtendedPlaces: false,
      };
    }
    case SEARCH_ACTION.SET_FETCHING: {
      return {
        ...state,
        fetching: action.payload,
      };
    }
    case SEARCH_ACTION.SET_BOUNDS: {
      return {
        ...state,
        bounds: action.payload,
      };
    }
    case SEARCH_ACTION.SET_EXTEND_FETCHING: {
      return {
        ...state,
        fetchingExtendedPlaces: action.payload,
      };
    }

    case SEARCH_ACTION.SET_RESET_RESULTS: {
      return {
        ...state,
        fetching: false,
        places: { 0: [] },
        profilePages: [],
        topSearch: { places: [] },
        results: 0,
        isSSRFetched: false,
      };
    }
    default:
      return state;
  }
}
