import { _s } from '@/locale';
import { RefObject, useRef } from 'react';
import Autosuggest from 'react-autosuggest';
import { AutoSuggestInputTheme, AutoSuggestionSection } from '../shared/types';

import { KeywordAutoSuggestManagerOutput, KeywordSuggestion, KeywordSuggestionOption } from './KeywordAutoSuggest.type';

type KeywordAutoSuggestProps = KeywordAutoSuggestManagerOutput & {
  inputRef: RefObject<HTMLDivElement>;
  renderInputComponent: (inputProps: any) => React.ReactNode;
  renderSuggestion: (suggestion: KeywordSuggestion, options: KeywordSuggestionOption) => React.ReactNode;
  renderSectionTitle: (section: AutoSuggestionSection<KeywordSuggestion>) => React.ReactNode;
  theme?: AutoSuggestInputTheme;
};

const KeywordAutoSuggest = ({
  inputRef,
  keyword,
  onSuggestionsFetchRequested,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  onSuggestionsClearRequested,
  suggestions,
  showSuggestions,
  onSuggestionSelected,
  renderInputComponent,
  renderSuggestion,
  renderSectionTitle,
  theme = {
    container: 'react-autosuggest__container',
    containerOpen: 'react-autosuggest__container--open',
    input: 'react-autosuggest__input',
    inputOpen: 'react-autosuggest__input--open',
    inputFocused: 'react-autosuggest__input--focused',
    suggestionsContainer: 'react-autosuggest__suggestions-container',
    suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open ',
  },
}: KeywordAutoSuggestProps) => {
  const autosuggestRef = useRef();

  return (
    <Autosuggest
      ref={autosuggestRef}
      id="keyword-autosuggest"
      suggestions={suggestions.filter((section) => section && section.suggestions && section.suggestions.length > 0)}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={(suggestion) => suggestion.text}
      inputProps={{
        placeholder: _s('Enter service or salon'),
        value: keyword ?? '',
        type: 'search',
        onChange,
        onFocus,
        onBlur,
        onKeyDown,
        ref: inputRef,
      }}
      focusInputOnSuggestionClick={false}
      alwaysRenderSuggestions={showSuggestions}
      getSectionSuggestions={(section) => section.suggestions}
      renderSuggestion={renderSuggestion}
      renderSectionTitle={renderSectionTitle}
      renderInputComponent={renderInputComponent}
      multiSection
      theme={theme}
    />
  );
};

export default KeywordAutoSuggest;
