import Card from '@/components/elements/Card/Card';
import { Button, Fab } from '@/components/elements/forms/buttons';
import Icon from '@/components/icons/Icon';
import EmptyState from '@/features/ProfilePages/components/EmptyState';
import ServiceCard from '@/features/ProfilePages/components/ServiceCard/ServiceCard';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { useState } from 'react';

const baseTranslationKey = 'features.ProfilePages.components.Services';

const Services = ({
  services,
  categoryName,
  placeId,
}: {
  placeId: number;
  services?: {
    name: string;
    description?: string;
    length?: number;
    leftSlot?: React.ReactNode;
    price?: number;
    externalUrl?: string;
    discount?: number;
  }[];
  categoryName: string;
}) => {
  const { isMobileView } = useMobileView();
  const [expanded, setExpanded] = useState<boolean>(true);
  return (
    <>
      {!services?.length && (
        <Card size={isMobileView ? 'lg' : 'xl'}>
          <div className="gap-2xl flex flex-col">
            <div className="flex items-center justify-between">
              <h2 className="text-h-s">{_s(`${baseTranslationKey}.title`)}</h2>
              {!isMobileView && (
                <Button
                  size="md"
                  label={_s(`${baseTranslationKey}.cta`)}
                  iconNoFilter
                  rightIcon={<Icon variant="plus" color="fg_primary_inverse" />}
                />
              )}
            </div>
            <EmptyState
              body={_s(`${baseTranslationKey}.emptyState.body`)}
              title={_s(`${baseTranslationKey}.emptyState.title`)}
              icon={<img src="/images/illustrations/empty.svg" alt="" />}
            />
            {isMobileView && (
              <Button
                size="md"
                label={_s(`${baseTranslationKey}.cta`)}
                iconNoFilter
                block
                rightIcon={<Icon variant="plus" color="fg_primary_inverse" />}
              />
            )}
          </div>
        </Card>
      )}
      {services?.length && (
        <div className={`gap-sm ${expanded ? 'pb-xl' : ''} flex flex-col`}>
          <div className="p-md border-border_regular flex items-center justify-between border-t">
            <h2 className="text-h-s">{categoryName}</h2>
            <div className="gap-sm text-fg_secondary flex items-center">
              <span>
                <span>{services?.length}</span> <span>{_s(`${baseTranslationKey}.label`)}</span>
              </span>
              <Fab
                size="sm"
                variant="secondary"
                onClick={() => setExpanded((prev) => !prev)}
                rightIcon={<Icon variant={expanded ? 'chevron-up' : 'chevron-down'} color="fg_primary" />}
              />
            </div>
          </div>
          {expanded && services.map((service, index) => <ServiceCard placeId={placeId} key={index} {...service} />)}
        </div>
      )}
    </>
  );
};

export default Services;
