import { bookActions } from '@/actions';
import Avatar from '@/components/elements/Avatar';
import { Button } from '@/components/elements/forms/buttons';
import { EmployeeBadges, EmployeeReviews } from '@/components/modules/employee';
import { ServicePicker } from '@/components/modules/pages/bookService/ServicePicker';
import { StarRating } from '@/components/modules/rating';
import { ReadMore, ReadMorePreview } from '@/components/modules/readmore';
import {
  addTimezone,
  isMobile,
  isSistaminuten as isSistaminutenInstance,
  restoreBodyScroll,
  saveBodyScroll,
  trackMpEvent,
  trackPageCio,
  url,
} from '@/helpers';
import { __ } from '@/locale';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { EmployeeGallery, EmployeeInstagramFeed } from './';

function Employee(props) {
  const { dispatch, employee, place, rating, inModal, history, isMpReviews } = props;
  const { isBookable, settings = {}, inactive, info } = employee.about || {};
  const [showServices, setShowServices] = useState(false);
  const [showEmployeeReviews, setShowEmployeeReviews] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const isSistaminuten = isSistaminutenInstance() || Boolean(place.lastMinuteDiscount);

  useEffect(() => {
    if (!inModal && !showServices && !showEmployeeReviews && !showMoreInfo) {
      restoreBodyScroll();
    }
  }, [showServices, showEmployeeReviews, showMoreInfo, inModal]);

  useEffect(() => {
    const {
      id: employeeId,
      about: { externalId },
    } = employee;
    const selectedEmployeeExternalId = url.returnGetParameter('sr');
    if (!inModal && selectedEmployeeExternalId === externalId) {
      pickService();
    }
    const selectedReviewEmployee = parseInt(url.returnGetParameter('recensionerFor'));
    if (!inModal && selectedReviewEmployee === employeeId) {
      handleShowEmployeeReviewsModal();
    }

    history.listen((location, action) => {
      const selectedReviewEmployee = parseInt(url.returnGetParameter('recensionerFor'));
      if (!inModal && selectedReviewEmployee === employeeId) {
        saveBodyScroll();
        setShowEmployeeReviews(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (inactive) {
    return null;
  }
  const close = (e) => {
    setShowServices(false);
    dispatch(bookActions.removeService(1));
    props.history.replace({ search: url.changeGetParameter('sr') });
  };

  const handleCloseModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowServices(false);
    setShowEmployeeReviews(false);
    setShowMoreInfo(false);
    props.history.replace({ search: url.changeGetParameter('recensionerFor') });
  };

  const handleShowEmployeeReviewsModal = (e) => {
    e && e.preventDefault();
    e && e.stopPropagation();
    saveBodyScroll();
    setShowEmployeeReviews(true);
  };

  const handleShowMoreInfo = (e) => {
    e && e.preventDefault();
    e && e.stopPropagation();
    saveBodyScroll();
    setShowMoreInfo(true);
  };

  const pickService = () => {
    if (!place || !hasActiveServices()) {
      return null;
    }

    dispatch(bookActions.pickEmployee(employee.id, employee.about.priceListId));
    dispatch(bookActions.keepEmployee());

    saveBodyScroll();

    const externalId = (employee && employee.about && employee.about.externalId) || null;
    if (externalId) {
      props.history.replace({ search: url.changeGetParameter('sr', externalId) });
    }

    setTimeout(() => {
      setShowServices(true);
      setShowEmployeeReviews(false);
      setShowMoreInfo(false);
    }, 50);

    trackPageCio('booking_choose_service_prompt', {
      merchant_id: place?.id,
      merchant_name: place?.about?.name,
    });
  };

  const findOneService = (employeeServices, placeServices) => {
    for (let i = 0; i < placeServices.length; i++) {
      if (placeServices[i].services) {
        for (let j = 0; j < placeServices[i].services.length; j++) {
          if (employeeServices.indexOf(placeServices[i].services[j].id) !== -1) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const hasActiveServices = () => {
    if (employee.emptyProgram) {
      return false;
    }

    if (employee.services && employee.services.length) {
      if (!place.services || !place.services.length) {
        return false;
      }
      return findOneService(employee.services, place.services);
    } else {
      return false;
    }
  };

  const currentTime = addTimezone(parseInt(new Date().getTime() / 1000, 10)) * 1000;
  const showBookButton = !(settings.lastDay && settings.lastDay < currentTime) && isBookable && hasActiveServices();

  const showTooltip = Boolean(info);

  const onClick = !inModal
    ? rating
      ? handleShowEmployeeReviewsModal
      : showBookButton
      ? pickService
      : info
      ? handleShowMoreInfo
      : undefined
    : undefined;
  const readMoreButton = !showMoreInfo && (
    <div className="absolute bottom-0 right-12 block w-6 bg-gradient-to-l from-white pl-12">
      <span className="text-primary cursor-pointer bg-white text-sm" onClick={handleShowMoreInfo}>
        {__('more')}
      </span>
    </div>
  );

  const className = `${inModal && !rating ? 'sm:px-8 sm:pt-4' : ''} ${
    inModal ? 'pb-8' : 'border-b-[1px] border-black-100'
  } `;

  return (
    <div className={className}>
      <div
        className="flex cursor-pointer items-start py-3"
        style={!inModal && (showBookButton || rating || showTooltip) ? { cursor: 'pointer' } : undefined}>
        <Avatar
          src={
            employee.about && employee.about.settings && employee.about.settings.showPhoto === false
              ? null
              : employee.about.avatar
          }
          size={inModal ? 'md' : '56'}
          onClick={onClick}
        />
        <div data-cy="employee" className="my-auto flex w-full items-start justify-between" onClick={onClick}>
          <div className="ml-3 mr-2 flex flex-1 flex-col">
            <span className={`${inModal ? 'text-lg' : ''} block font-semibold`}>
              {rating && (
                <StarRating
                  count={rating.count}
                  rating={rating.score}
                  justCount={isMobile()}
                  link={!inModal}
                  showValue={true}
                  hideSchema
                />
              )}
              {(employee.about.name || '').split('.').join('. ')}
            </span>
            <span className="text-black-600 text-sm">{(employee.about.jobTitle || '').split('.').join('. ')}</span>
          </div>
          {!inModal && showBookButton && (
            <Button
              data-cy="chooseEmployeeButton"
              className="min-w-[75px]"
              variant="primary"
              onClick={pickService}
              size="sm">
              {__('select')}
            </Button>
          )}
        </div>
      </div>
      <div className="pb-3">
        <EmployeeGallery place={place} employee={employee} />
        <div className="flex flex-row gap-1">
          <EmployeeBadges employee={employee} isSistaminuten={isSistaminuten} inModal={inModal} />
        </div>
        {inModal && showTooltip && (
          <div className="mt-2">
            <ReadMore expanded={props.showMoreInfo} maxLines={3} text={info} />
          </div>
        )}
        {!inModal && showTooltip && (
          <div className="mt-2">
            <ReadMorePreview
              htmlId={'employee-more-' + employee.id}
              expanded={showMoreInfo}
              text={info}
              maxLines={3}
              lineHeight={20}
              button={readMoreButton}
            />
          </div>
        )}
      </div>

      {inModal && place?.about?.settings?.subscriptionType === 'growth' && employee?.contact?.instagram && (
        <div className="mb-2">
          <EmployeeInstagramFeed employee={employee} inModal={inModal} />
        </div>
      )}

      {!inModal && (
        <ServicePicker
          source="booking_choose_service_prompt"
          top={
            <div className="font-semibold" style={{ textAlign: 'center' }}>
              {__('pickServices')}
            </div>
          }
          hidePopup={close}
          employeeData={employee}
          placeData={place}
          showServices={showServices}
          fromEmployee={true}
        />
      )}
      {!inModal && rating?.score && (showEmployeeReviews || showMoreInfo) && (
        <EmployeeReviews
          isOpen={showEmployeeReviews || showMoreInfo}
          showMoreInfo={showMoreInfo}
          close={handleCloseModal}
          employee={employee}
          place={place}
          rating={rating}
          pickService={showBookButton ? pickService : null}
          isMpReviews={isMpReviews}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

const EmployeeWithRouter = withRouter(connect(mapStateToProps)(Employee));
export default EmployeeWithRouter;
