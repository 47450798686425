import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import CardWrapper from '@/components/modules/CardWrapper';
import QliroCheckout from '@/components/modules/checkout/QliroCheckout/QliroCheckout';
import GoBack from '@/components/modules/pages/bokningar/GoBack';
import CheckoutDetails from '@/components/templates/giftCards/CheckoutDetails/CheckoutDetails';
import { APP_ROUTES } from '@/constants/pages';
import withPlaceDetailsFallback from '@/hoc/withPlaceDetailsFallback';
import { formDataSchema, GiftcardFormData } from '@/hooks/useGiftcardCheckoutFormData';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { InitQliroResponse } from '@/types/api/services/booking';
import { createPaymentResponseSchema } from '@/types/api/services/qliro';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { getGiftcardCheckoutProductType } from '../GiftcardCheckout.helpers';
import { GiftcardCheckoutState, giftcardCheckoutStateSchema } from '../GiftcardCheckout.hooks';

const baseTranslationKey = 'pages.booking.checkout.QliroBookingCheckout';
const pageTitle = _s(`seo.activateOnlinePaymentQliroTitle`);
const pageDescription = _s(`seo.activateOnlinePaymentQliroDescription`);

const QliroGiftcardCheckout = ({
  checkoutState,
  formdata,
  qliroOrder,
}: {
  checkoutState: GiftcardCheckoutState;
  formdata: GiftcardFormData;
  qliroOrder: InitQliroResponse;
}) => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const productType = getGiftcardCheckoutProductType(match.params, location.pathname);

  const { summary } = checkoutState;
  const discount = formdata.discountCode ? { code: formdata.discountCode, amount: summary.discountAmount } : undefined;

  const { isMobileView } = useMobileView();

  const handleOnBackButtonClick = () => {
    history.replace({
      pathname: location.state.from ?? APP_ROUTES.GIFTCARD_CHECKOUT,
      state: {
        savedCheckoutState: location.state?.savedCheckoutState,
        valueCard: location.state?.valueCard,
        employeeId: location.state?.employeeId,
        guestEmail: location.state?.guestEmail,
      },
    });
  };

  return (
    <PageViewLayout
      type="subView"
      title={_s(`${baseTranslationKey}.page-title`)}
      back
      onBackButtonClick={handleOnBackButtonClick}
      wrapperClass={isMobileView ? 'bg-brown-50' : 'bg-gradient'}>
      <div className="bg-brown-50 lg:bg-transparent">
        <div className="lg:py-xxl lg:container">
          <div className="gap-xxl flex items-start">
            <div className="gap-lg flex grow-[9999] basis-[600px] flex-col">
              <CardWrapper className="w-full lg:hidden">
                <CheckoutDetails
                  selectedAmount={formdata.amount ?? formdata.customAmount}
                  selectedQuantity={formdata.quantity}
                  productType={productType}
                  deliveryType={formdata.type}
                  discount={discount}
                />
              </CardWrapper>
              <CardWrapper>
                <div className="p-lg">
                  <QliroCheckout snippet={qliroOrder.snippet} />
                </div>
              </CardWrapper>
            </div>
            <div className="sticky top-20 hidden lg:flex lg:grow-[100] lg:basis-[390px]">
              <CardWrapper className="w-full">
                <CheckoutDetails
                  key={2}
                  selectedAmount={formdata.amount ?? formdata.customAmount}
                  selectedQuantity={formdata.quantity}
                  productType={productType}
                  deliveryType={formdata.type}
                  discount={discount}
                />
              </CardWrapper>
            </div>
          </div>
        </div>
      </div>

      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="robots" content="noindex" />
      </Helmet>
    </PageViewLayout>
  );
};

export default withPlaceDetailsFallback(() => {
  const location = useLocation();
  const parsedFormdataState = formDataSchema.safeParse(location?.state?.savedFormDataState);
  const parsedCheckoutState = giftcardCheckoutStateSchema.safeParse(location?.state?.savedCheckoutState);
  const parsedQliroOrder = createPaymentResponseSchema.safeParse(location?.state?.qliroOrder);

  if (!parsedCheckoutState.success || !parsedQliroOrder.success || !parsedFormdataState.success) return <GoBack />;

  return (
    <QliroGiftcardCheckout
      checkoutState={parsedCheckoutState.data}
      formdata={parsedFormdataState.data}
      qliroOrder={parsedQliroOrder.data}
    />
  );
});
