import Icon from '@/components/icons/Icon';
import { themed } from '@/helpers/theme';

interface BreadcrumbsProps {
  className?: string;
  items: { title: string; url: string }[];
}

const Breadcrumbs = ({ items, className = '' }: BreadcrumbsProps) => {
  return (
    <div className={className}>
      <ol className="gap-2xs flex list-none">
        {items.map((item, index) => {
          const isCurrentLink = items.length === index + 1;
          return (
            <div key={`${index + 1}`} className="gap-2xs flex items-center">
              <li key={`item-${index + 1}`} className="before:text-black-600 text-l inline-block">
                <a
                  className={`cursor-pointer ${themed(
                    isCurrentLink ? 'text-fg_primary' : 'text-fg_link_bold underline',
                    isCurrentLink ? 'text-black-800' : 'text-information-700 underline hover:underline',
                  )} `}
                  href={item.url}>
                  <span>{item.title}</span>
                </a>
              </li>
              {!isCurrentLink && (
                <Icon key={`separator-${index + 1}`} variant="arrow-right" size="xs" color="black-600" />
              )}
            </div>
          );
        })}
      </ol>
    </div>
  );
};

export default Breadcrumbs;
