import { Card } from '@/components/elements/cards';
import { LinkButton } from '@/components/elements/forms/buttons';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';

import { __ } from '@/locale';
import React from 'react';
import { Link } from 'react-router-dom';

class GoBack extends React.Component {
  render() {
    return (
      <PageViewLayout type="mainView">
        <div className="container">
          <Card className="mx-auto max-w-[640px]">
            <div className="flex justify-center pb-4">
              <img src="/images/booking-already-done.png" alt="" />
            </div>
            <h1 className="mb-4 text-center">
              {__('bookingFlowErrorPage')}
              <Link to="/">{__('backToHomePage')}</Link>
            </h1>
            <div className="flex flex-col items-center justify-center">
              <LinkButton variant="primary" to="/vad/var" className="mb-2">
                {__('makeNewBooking')}
              </LinkButton>
              <LinkButton to="/bokningar">{__('gotoMyBookings')}</LinkButton>
            </div>
          </Card>
        </div>
      </PageViewLayout>
    );
  }
}

export default GoBack;
