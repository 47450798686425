import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { _s } from '@/locale';
import { qliroServices } from '@/services';
import { SelectedPaymentMethod } from '@/types/checkout';
import * as Sentry from '@sentry/react';
import { Dispatch, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { productTypeSchema } from '../presentkort/GiftcardCheckout.types';
import { baseTranslationKey } from './ValidateQliroPayment';

const searchParamsSchema = z.object({
  type: z.union([z.literal('bundle'), productTypeSchema]),
  paymentRef: z.string(),
  customerId: z.string(),
  successPath: z.string().optional(),
});

const qliroNotificationSchema = z.object({
  success: z.boolean(),
  paymentId: z.string(),
  id: z.number().optional(),
});

type QliroRedirect = {
  pathname: string;
  search?: string;
  state?: { selectedPaymentMethod: SelectedPaymentMethod };
};

function handleErrorRedirect(failireRedirect: QliroRedirect, setRedirect: Dispatch<QliroRedirect>) {
  toast(({ closeToast }) => (
    <Snackbar label={_s(`${baseTranslationKey}.failedPaymentError`)} type="danger" onClose={closeToast} />
  ));
  setRedirect(failireRedirect);
}

const useValidateQliroPayment = () => {
  const [redirect, setRedirect] = useState<QliroRedirect | null>(null);
  const location = useLocation();
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));
  const validateSearchParams = searchParamsSchema.safeParse(searchParams);
  const failireRedirect: QliroRedirect = { pathname: sessionStorage.getItem('paymentCheckoutReturnUrl') || '/' };

  useEffect(() => {
    if (validateSearchParams.success === false) {
      // 1. set redirect, broken url
      setRedirect(failireRedirect);
      Sentry.captureMessage('Error validating Qliro payment, broken url params.');
      // 2. show toast
      toast(({ closeToast }) => (
        <Snackbar label={_s(`${baseTranslationKey}.failedPaymentError`)} type="danger" onClose={closeToast} /> // <- fix translation
      ));
      return;
    }

    const { type, paymentRef: merchantReference, customerId, successPath } = validateSearchParams.data;

    const eventSource = qliroServices.completePayment(merchantReference, customerId);

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);

      const validNotification = qliroNotificationSchema.safeParse(data);

      if (validNotification.success === false) {
        handleErrorRedirect(failireRedirect, setRedirect);
        eventSource.close();
        return;
      }

      const { success, id } = validNotification.data;

      if (success) {
        switch (type) {
          case 'bundle':
            setRedirect({
              pathname: `/bundles/confirmation`,
              search: `?bundleId=${id}`,
              state: { selectedPaymentMethod: { type: CHECKOUT_PAYMENT_METHOD.QLIRO } },
            });
            break;
          case 'giftcard':
          case 'wellnesscard':
          case 'placecard':
          case 'valuecard':
            setRedirect({
              pathname: successPath + `/${id}`,
              state: { selectedPaymentMethod: { type: CHECKOUT_PAYMENT_METHOD.QLIRO } },
            });
            break;
          default:
            handleErrorRedirect(failireRedirect, setRedirect);
        }
      } else {
        handleErrorRedirect(failireRedirect, setRedirect);
      }
      eventSource.close();
    };

    eventSource.onerror = (error) => {
      handleErrorRedirect(failireRedirect, setRedirect);
      eventSource.close();
    };

    return () => {
      eventSource?.close?.();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { redirect };
};

export default useValidateQliroPayment;
