import Card from '@/components/elements/Card/Card';
import { LinkButton } from '@/components/elements/forms/buttons';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';

const baseTranslationKey = 'features.ProfilePages.components.VerifyCompany';

const VerifyCompany = () => {
  const { isMobileView } = useMobileView();

  return (
    <Card size={isMobileView ? 'lg' : 'xl'}>
      <div className={`${isMobileView ? 'pt-[150px]' : ''}`}>
        <div className="gap-xl relative z-10 flex flex-col">
          <h2 className={`font-bold ${isMobileView ? 'text-h-l' : 'text-h-xl'}`}>
            {_s(`${baseTranslationKey}.title`)}
          </h2>

          <ul className={`gap-xs flex flex-col`}>
            <ListItem leftPadding={false} className="pb-0 pt-0" leftSlot={<Icon color="fg_positive" variant="check" />}>
              <ListItemContent
                {...(isMobileView
                  ? { content: _s(`${baseTranslationKey}.item1`) }
                  : { subTitle: _s(`${baseTranslationKey}.item1`) })}
              />
            </ListItem>
            <ListItem className="pb-0 pt-0" leftPadding={false} leftSlot={<Icon color="fg_positive" variant="check" />}>
              <ListItemContent
                {...(isMobileView
                  ? { content: _s(`${baseTranslationKey}.item2`) }
                  : { subTitle: _s(`${baseTranslationKey}.item2`) })}
              />
            </ListItem>
            <ListItem className="pb-0 pt-0" leftPadding={false} leftSlot={<Icon color="fg_positive" variant="check" />}>
              <ListItemContent
                {...(isMobileView
                  ? { content: _s(`${baseTranslationKey}.item3`) }
                  : { subTitle: _s(`${baseTranslationKey}.item3`) })}
              />
            </ListItem>
          </ul>

          <div>
            <LinkButton
              block={isMobileView}
              size="lg"
              iconNoFilter
              href={undefined}
              variant="primary"
              label={_s(`${baseTranslationKey}.cta`)}
              rightIcon={<Icon variant="payment-method-bank-id" ext="svg" color="fg_primary_inverse" />}
            />
          </div>
        </div>
        <div className={`absolute right-0 top-0 ${isMobileView ? 'z-0 h-3/4 w-full overflow-hidden' : ''}`}>
          <picture>
            <source srcSet={'/images/profile-page-background.png'} media="(min-width: 768px)" />
            <img src={'/images/profile-page-background-mobile.png'} alt="" />
          </picture>
        </div>
        {isMobileView && (
          <div className="absolute bottom-0 left-0 h-full w-full bg-gradient-to-t from-white to-transparent"></div>
        )}
      </div>
    </Card>
  );
};

export default VerifyCompany;
