import { Card } from '@/components/elements/cards';
import { LinkButton } from '@/components/elements/forms/buttons';
import { SupportIcon } from '@/components/icons';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import SEO from '@/components/modules/SEO';
import Breadcrumbs from '@/components/modules/breadcrumbs/Breadcrumbs';
import Navigation from '@/components/modules/pages/support/Navigation';
import { RandomSupportFaq, SupportFaqItem } from '@/components/modules/pages/support/RandomSupportFaq';
import { supportFaq } from '@/constants/faq';
import { getHomeDescription, isMobile, isServer, isSistaminuten, trackPage, url } from '@/helpers';
import { __, _s } from '@/locale';
import { useEffect, useState } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

const SupportSection = () => {
  const location = useLocation();
  const selectedSection = supportFaq.find((section) => section.path === location.pathname);
  const { path } = useRouteMatch();
  const [active, setActive] = useState<number>((location.state && location.state.active) || 0);

  const getScreenName = () => {
    return 'support_' + (selectedSection.title || '').toLowerCase().replace(/\s/g, '_');
  };

  useEffect(() => {
    if (isServer) return;
    window.scrollTo(0, 0);
    try {
      trackPage();
      // trackMpEvent('screen_shown', { screen_name: getScreenName() });
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (isServer) return;
    window.scrollTo(0, 0);
  }, [location]);

  const goToSupportFormClicked = () => {};

  const goToSupportForm = (
    <Card className="tracking-content text-black-900 flex flex-col bg-white text-base lg:text-lg">
      <h3 className="splitter lg:text-h-m flex items-start justify-start gap-x-4 text-base font-bold">
        <SupportIcon className="h-5 lg:h-7" />
        {_s('supportPage.getInTouch')}
      </h3>
      <p>{_s('supportPage.contactByForm')}</p>
      <LinkButton
        to={{ pathname: '/support/form', state: { selectedSection: selectedSection.path, active } }}
        onClick={goToSupportFormClicked}
        className="mt-8 h-[40px] lg:h-[48px]"
        variant="primary">
        {_s('supportPage.gotoForm')}
      </LinkButton>
    </Card>
  );

  return (
    <PageViewLayout title={_s('Support')} back backSrc="/support" type="subView" wrapperClass="bg-gradient">
      <div className="container-fixed">
        <div className="flex flex-wrap justify-between gap-8 lg:flex-nowrap">
          <div className="flex-1">
            <Switch exact path={path}>
              <Route exact path={`${path}`}>
                <Breadcrumbs
                  className="mb-7 mt-2 text-sm"
                  items={[
                    { title: __('Start'), url: '/' },
                    { title: __('support'), url: '/support' },
                    { title: _s(selectedSection.title), url: selectedSection.path },
                  ]}
                />
                <div
                  className="h-[178px] w-auto bg-cover bg-center bg-no-repeat lg:max-w-[730px]"
                  style={{ backgroundImage: `url(${selectedSection.bgImage})` }}>
                  <div className="container mx-auto flex h-full items-center justify-center">
                    <h1 className="text-lg font-bold text-white lg:text-4xl">{_s(selectedSection.title)}</h1>
                  </div>
                </div>
                <Card
                  style={{ margin: 0 }}
                  className="tracking-content text-black-900 flex flex-col rounded-b-lg rounded-t-none bg-white">
                  <h4 className="text-black-600 flex items-center justify-start gap-x-4 pb-10 text-lg tracking-normal">
                    <selectedSection.icon className="h-4" />
                    {_s(selectedSection.title)}
                  </h4>
                  {selectedSection.questions.map((question, i) => {
                    const isActive = question.id === active;
                    return (
                      <SupportFaqItem
                        className={`${i < selectedSection.questions.length - 1 ? 'splitter' : 'mb-8'}`}
                        qClassName="font-bold lg:underline"
                        btnClassName="hover:text-primary"
                        question={question}
                        active={isActive}
                        setActive={setActive}
                        closeInactive={isMobile()}
                        section={selectedSection.path}
                        key={i}
                      />
                    );
                  })}
                </Card>
                <div className="lg:hidden">{goToSupportForm}</div>
                <RandomSupportFaq />
              </Route>
            </Switch>
          </div>
          <div className="mt-14 hidden w-full lg:block lg:max-w-[300px]  xl:max-w-[400px]">
            <Navigation title={_s('supportPage.categories')} />
            {goToSupportForm}
          </div>
        </div>
      </div>
      <SEO
        title={__('seo.helpTitle')}
        description={getHomeDescription()}
        url={`${url.getBaseUrl()}support`}
        image={url.getBaseImageUrl()}>
        {isSistaminuten() && <meta name="robots" content="noindex" />}
      </SEO>
    </PageViewLayout>
  );
};

export default SupportSection;
