import { SISTAMINUTEN_V2_FEATURE_FLAG } from '@/constants/experimentConstants';
import { getSERPBreadcrumbs, isSistaminuten, url } from '@/helpers';
import { getBreadcrumbsSchema, getSearchResultsPageSchema } from '@/helpers/jsonld';
import { SearchManagerOutput } from '@/hooks/search/useSearch';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { getSeoProps } from '../pages/index.helpers';

const SEO = (search: SearchManagerOutput) => {
  const location = useLocation();
  const seo = getSeoProps(search, location);
  /**
   * @TODO: Remove this when sistaminuten is rolled out
   * Until rolled out we want to noindex sistaminuten pages to avoid duplicated content
   */
  let feature = SISTAMINUTEN_V2_FEATURE_FLAG;
  const isNoIndex = search.type === 'sistaminuten';

  const breadcrumbs = getSERPBreadcrumbs(search.q, search.location).map((item) => ({
    ...item,
    url: `${url.getBaseUrl()}${(item.url ?? '').slice(1)}`,
  }));

  return (
    <Helmet encodeSpecialCharacters={true}>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
      <link rel="canonical" href={seo.url} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:locale" content="sv_SE" />
      <meta property="og:image" content={seo.image} />
      <meta property="og:description" content={seo.description} />
      {!isSistaminuten() && <meta property="og:site_name" content="Bokadirekt" />}
      {!isSistaminuten() && <meta name="twitter:card" content="summary" />}
      {!isSistaminuten() && <meta name="twitter:site" content="@bokadirekt" />}
      ...{isNoIndex && <meta name="robots" content="noindex, follow" />}
      {seo.prev}
      {seo.next}
      <script type="application/ld+json">{getSearchResultsPageSchema(search.places[search.page] || [])}</script>
      <script type="application/ld+json">{getBreadcrumbsSchema(breadcrumbs)}</script>
    </Helmet>
  );
};

export default SEO;
