import { useContext } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import defaults from '../theme';
import deepMerge from '../utils/deepMerge';
import { ThemeContext } from '../Lightbox'; 

function Container({ ...props }) {
  const theme = useContext(ThemeContext);
  const classes = StyleSheet.create(deepMerge(defaultStyles, theme));

  return <div id="lightboxBackdrop" className={css(classes.container)} {...props} />;
}

const defaultStyles = {
  container: {
    alignItems: 'center',
    backgroundColor: defaults.container.background,
    boxSizing: 'border-box',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    paddingBottom: defaults.containerGutter.vertical,
    paddingLeft: defaults.containerGutter.horizontal,
    paddingRight: defaults.containerGutter.horizontal,
    paddingTop: defaults.containerGutter.vertical,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: defaults.container.zIndex,
  },
};

export default Container;
