import Card from '@/components/elements/Card/Card';
import { Button } from '@/components/elements/forms/buttons';
import { DropdownButton } from '@/components/elements/forms/DropdownButton';
import Icon from '@/components/icons/Icon';
import { Dropdown } from '@/components/modules/dropdown';
import { classnames, isSistaminuten } from '@/helpers';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { Fragment } from 'react/jsx-runtime';
import { FILTER_OPTIONS, getSelectedFilters } from './Filter.helpers';
import { FilterManagerOutput, FilterOption } from './Filter.types';

type FilterProps = FilterManagerOutput & {};

const Filter = (props: FilterProps) => {
  const { isMobileView } = useMobileView();
  const selectedFilters = getSelectedFilters(props.prefs);
  const appliedFilters = (props.urlPrefs || '')
    .split(',')
    .filter(Boolean)
    .map((pref) => pref && +pref);

  return (
    <>
      <DropdownButton
        toggleOpen={() => props.onShowOptions(true)}
        isActive={appliedFilters.length > 0}
        icon={<Icon className="pointer-events-none" variant="settings" size="xs" />}
        isToggleOpen={props.showOptions && !isMobileView}
        newDesign
        className={classnames('relative !min-w-[109px] pl-2')}
        activeBadge={appliedFilters.length}>
        <>
          <span className="text-m whitespace-nowrap">{_s('filters')}</span>
          {props.showOptions && !isMobileView && (
            <Dropdown
              allowClick
              className="left-[-0.05rem] top-7 m-0 min-w-[20rem] rounded-b-2xl rounded-t-none p-0"
              onClickOutside={() => props.onShowOptions(false)}>
              <div className="px-sm pb-sm">
                <span className="text-capitalize text-xxs text-black-600 pb-md block">{_s('filterBy')}</span>
                <ul className="space-y-sm">
                  <FilterOptions
                    selectedFilters={selectedFilters}
                    onChange={(filterOption) => props.onToggleFilterOption(filterOption)}
                  />
                </ul>
              </div>
            </Dropdown>
          )}
        </>
      </DropdownButton>
      {props.showOptions && isMobileView && (
        <Fragment>
          <div className="bg-scrim-40 fixed inset-0 z-10"></div>
          <Card
            bottom
            closable={false}
            onClickOutside={() => props.onShowOptions(false)}
            className="fixed bottom-0 left-0 right-0 z-20 text-left">
            <span className="text-capitalize text-xxs text-black-600 pb-md block">{_s('filterBy')}</span>
            <ul className="space-y-sm pb-2xl">
              <FilterOptions
                selectedFilters={selectedFilters}
                onChange={(filterOption) => props.onToggleFilterOption(filterOption)}
              />
            </ul>
            <Button onClick={props.onApplyFilters}>Visa resultat</Button>
          </Card>
        </Fragment>
      )}
    </>
  );
};

const FilterOptions = ({
  selectedFilters,
  onChange,
}: {
  selectedFilters: FilterOption[];
  onChange: (filterBy: FilterOption) => void;
}) => {
  return (
    <>
      {FILTER_OPTIONS.filter(
        (option) => (isSistaminuten() && option.id === 10 ? false : true) /** hide bundle filter on sistaminuten */,
      ).map((item, index) => {
        const isActive = selectedFilters.some((filter) => filter.id === item.id);
        return (
          <li
            key={index}
            className="gap-sm flex cursor-pointer flex-row-reverse items-center"
            role="checkbox"
            aria-checked={isActive}
            onClick={() => onChange(item)}>
            <span className="flex-1">{_s(item.label)}</span>
            {isActive ? (
              <Icon variant="checkbox-checked-filled" className="flex-shrink-0" />
            ) : (
              <Icon variant="square" className="flex-shrink-0" />
            )}
          </li>
        );
      })}
    </>
  );
};

export default Filter;
