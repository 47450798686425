import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { usePinInput } from '@/components/elements/forms/PinInput/PinInput';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import CardWrapper from '@/components/modules/CardWrapper';

import Header from '@/components/elements/Header/Header';
import Button, { ButtonProps } from '@/components/elements/forms/buttons/Button/Button';
import ListInput from '@/components/elements/forms/listInput/ListInput/ListInput';
import Icon from '@/components/icons/Icon';
import BundleNoticeBanner from '@/components/modules/BundleNoticeBanner';
import SEO, { seoPropsFromBaseString } from '@/components/modules/SEO';
import { TooltipPortal } from '@/components/modules/Tooltip';
import UpdateUserInfo from '@/components/modules/UpdateUserInfo/UpdateUserInfo';
import useUpdateUserInfo from '@/components/modules/UpdateUserInfo/UpdateUserInfo.hooks';
import { ThreeDSComponent } from '@/components/modules/adyen';
import CheckoutDetails from '@/components/modules/checkout/CheckoutDetails/CheckoutDetails';
import CheckoutModal from '@/components/modules/checkout/CheckoutModal/CheckoutModal';
import {
  CheckoutModalProvider,
  useCheckoutModal,
} from '@/components/modules/checkout/CheckoutModal/CheckoutModal.hooks';
import CheckoutTerms from '@/components/modules/checkout/CheckoutTerms/CheckoutTerms';
import PromotedPaymentMethods from '@/components/modules/checkout/PromotedPaymentMethods/PromotedPaymentMethods';
import CreditCardModal from '@/components/modules/modals/CreditCardModal';
import SwishModal from '@/components/modules/modals/SwishModal';
import Modal from '@/components/modules/modals/redesign/Modal/Modal';
import GoBack from '@/components/modules/pages/bokningar/GoBack';
import FloatingCTAWrapper from '@/components/modules/pages/booking/checkout/FloatingCTAWrapper';
import SelectPaymentOptions from '@/components/templates/checkout/SelectPaymentOptions/SelectPaymentOptions';
import UserInfo from '@/components/templates/checkout/UserInfo/UserInfo';
import UserLogin from '@/components/templates/checkout/UserLogin/UserLogin';
import VerifyOTP from '@/components/templates/login/VerifyOTP';
import VerifySuccess from '@/components/templates/login/VerifySuccess';
import { SCREEN_NAME } from '@/constants/analytics';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { TermLinkIdentifier } from '@/constants/terms';
import { classnames, getUserInitials, isSistaminuten, url } from '@/helpers';
import { getThreeDSecureRedirectResultIfAny } from '@/helpers/adyen';
import {
  bundleCheckoutTrackingManager,
  getBundleSubmitLabel,
  getCheckoutSubmitIconVariantIfAny,
  getCheckoutTerms,
  mapBundleStateToCheckoutDetailsProps,
} from '@/helpers/checkout';
import { isNationalId } from '@/helpers/utilsTS';
import withBundleCheckoutFallback from '@/hoc/withBundleCheckoutFallback';
import withWalletPayment from '@/hoc/withWalletPayment';
import { useAppSelector } from '@/hooks';
import { UseCardsProvider, useCardsManager } from '@/hooks/adyen/useCards';
import { useGetAmplitudeExperimentVariant } from '@/hooks/useAmplitudeExperiment';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import { LoginContext } from '@/hooks/useLogin';
import useMobileView from '@/hooks/useMobileView';
import { usePaymentsHistory } from '@/hooks/usePaymentsHistory';
import useTrackScreenView, { trackScreenView } from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { saveCofCheckoutStateToLocalStorage } from '@/pages/validate-cof-payment-redirect/ValidateCofPaymentRedirect.hooks';
import {
  CheckoutMissingAction,
  CheckoutSummary,
  SelectedPaymentMethod,
  selectedPaymentMethodSchema,
} from '@/types/checkout';
import { PaymentCard } from '@/types/paymentcards';
import { BundleState, bundleStateSchema } from '@/types/state/bundle';
import * as Sentry from '@sentry/react';
import { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BundleCheckoutProvider, FormDataProvider, useBundleCheckout, useFormData } from './BundleCheckout.hooks';

export const baseTranslationKey = 'pages.bundles.checkout.BundleCheckout';

function handleUpdateUserInfoCallback({ success, retry }, closeUpdateUserInfo: () => void) {
  if (success) closeUpdateUserInfo();

  toast(
    ({ closeToast }) => (
      <Snackbar
        label={_s(`${baseTranslationKey}.editProfileModal.snackbar.label.${success ? 'success' : 'error'}`)}
        type={success ? 'success' : 'danger'}
        action={
          <button onClick={success ? closeToast : retry}>
            {_s(`${baseTranslationKey}.editProfileModal.snackbar.action.${success ? 'success' : 'error'}`)}
          </button>
        }
      />
    ),
    { autoClose: 2000 },
  );
}

function trackBundleCheckoutScreenView(
  bundle: BundleState,
  summary: CheckoutSummary,
  selectedPaymentMehtod: SelectedPaymentMethod,
) {
  const trackingProps = bundleCheckoutTrackingManager().buildTrackingProps(bundle, summary, selectedPaymentMehtod);
  trackScreenView({
    name: 'screen_view_checkout_bundle_summary',
    properties: { ...trackingProps.bundleProps, ...trackingProps.paymentMethodsProps, ...trackingProps.extraProps },
  });
}

const BundleCheckout = () => {
  const {
    selectedPaymentMethod,
    summary,
    missingActions,
    submitting,
    cofThreeDS,
    swish,
    bundleCheckoutAPI,
    onChangePaymentMethod,
    onDismissSwishQRCode,
  } = useBundleCheckout();

  // experiment with radio buttons
  const usePromotedPaymentMethods =
    useGetAmplitudeExperimentVariant()('promoted-payment-methods-web')?.value === 'variant-options-visible';
  // experiment with radio buttons and popular badge
  const useHighlightedPaymentMethods =
    useGetAmplitudeExperimentVariant()('highlighted-payment-methods')?.value === 'variant-popular-badge-visible';

  const isUsingRadioButtonsVariant = Boolean(usePromotedPaymentMethods || useHighlightedPaymentMethods);

  // refs
  const selectPaymentMethodContainerRef = useRef<HTMLDivElement>(null);
  const confirmationContainerRef = useRef<HTMLDivElement>(null);
  const tooltipTriggerRef = useRef<HTMLButtonElement>(null);
  const tooltipContainerRef = useRef<HTMLDivElement>(null);

  // local state
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  // selectors
  const user = useAppSelector((state) => state.users)?.user;
  const bundle = useAppSelector((state) => state.bundle);

  // hooks
  const isCheckoutCTAInView = useIntersectionObserver(confirmationContainerRef);
  const { isMobileView } = useMobileView();
  const {
    view,
    email,
    handleEmailSubmit,
    updateEmail,
    handleLogin,
    handleEmailResendClicked,
    updateView,
    clearLoginState,
  } = useContext(LoginContext);
  const pinInputProps = usePinInput({ fields: 6, validateCallback: handleLogin });
  const { onModalClose: onCheckoutModalClose, ...checkoutModalContext } = useCheckoutModal();
  const updateUserInfoProps = useUpdateUserInfo({
    callback: (props) =>
      handleUpdateUserInfoCallback({ retry: props.retry, success: props.success }, handleCloseEditProfile),
    triggerSource: SCREEN_NAME.CHECKOUT_BOOKING_SUMMARY,
  });
  const { register, errors } = useFormData();

  // local methods
  const handleCloseCheckoutModal = () => {
    onCheckoutModalClose();
    trackBundleCheckoutScreenView(bundle, summary, selectedPaymentMethod);
  };

  const handleCloseEditProfile = () => {
    setShowEditProfile(false);
    trackBundleCheckoutScreenView(bundle, summary, selectedPaymentMethod);
  };

  const handleCloseTerms = () => {
    trackBundleCheckoutScreenView(bundle, summary, selectedPaymentMethod);
  };

  const handleCloseLoginModal = () => {
    clearLoginState();
    trackBundleCheckoutScreenView(bundle, summary, selectedPaymentMethod);
  };

  const handleDismissSwishQRCode = () => {
    trackBundleCheckoutScreenView(bundle, summary, selectedPaymentMethod);
    onDismissSwishQRCode();
  };

  const handleShowTooltip = () => {
    setTooltipVisible(true);
  };

  const handleHideTooltip = () => {
    setTooltipVisible(false);
  };

  const handleChangePaymentMethod = (method: SelectedPaymentMethod) => {
    if (method.type === CHECKOUT_PAYMENT_METHOD.NEW_COF) {
      if (isUsingRadioButtonsVariant) {
        checkoutModalContext.onModalShow('cof');
      }
      return;
    }
    onChangePaymentMethod(method);
    trackBundleCheckoutScreenView(bundle, summary, method);
    onCheckoutModalClose();
  };

  const handleOnCreditCardSelect = (card: PaymentCard) => {
    handleChangePaymentMethod({
      type: CHECKOUT_PAYMENT_METHOD.STORED_COF,
      brand: card.brand,
      id: card.id,
      lastFour: card.lastFour,
    });
  };

  const handleOnRemoveCard = (card: PaymentCard) => {
    if (selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.STORED_COF && selectedPaymentMethod.id === card.id) {
      handleChangePaymentMethod({
        type: CHECKOUT_PAYMENT_METHOD.NONE,
      });
    }
  };

  const handleBeforeThreeDSRedirect = () => {
    const validatedAdyenPaymentMethod = selectedPaymentMethodSchema.safeParse(selectedPaymentMethod);
    const hasSwish = summary.availablePaymentMethods.find((method) => method.type === CHECKOUT_PAYMENT_METHOD.SWISH);

    if (validatedAdyenPaymentMethod.success === false) {
      Sentry.captureException(validatedAdyenPaymentMethod.error);
      return;
    }

    saveCofCheckoutStateToLocalStorage({
      isAddNewCard: true,
      payLater: !summary.isOnlinePaymentRequired && !hasSwish,
      selectedPaymentMethod: validatedAdyenPaymentMethod.data,
    });
  };

  // computed & mappings
  const requireLogin = !isSistaminuten() && !user;
  const showThreeDSecure = Boolean(cofThreeDS?.adyenActionJson);
  const checkoutDetailsProps = mapBundleStateToCheckoutDetailsProps(bundle);
  const showFloatingCTA = isCheckoutCTAInView !== undefined && Boolean(!isCheckoutCTAInView);
  const showSwishQrCode = Boolean(swish?.showQrCode);
  const trackingProps = bundleCheckoutTrackingManager().buildTrackingProps(bundle, summary, selectedPaymentMethod);

  const isWellnessQualified = useMemo(() => {
    const service = bundle.place.services
      .flatMap((service) => service.services)
      .find((service) => service.id === bundle.bundle.service.mpId);

    return Boolean(service?.about?.settings?.healthCareCard);
  }, [bundle.place.services, bundle.bundle.service.mpId]);

  const checkoutTerms = getCheckoutTerms(
    'bundle',
    TermLinkIdentifier.PAYMENT,
    getBundleSubmitLabel({ paymentMethod: selectedPaymentMethod.type, isMobileView, isFloating: !isCheckoutCTAInView }),
  );

  // lifecycle
  useTrackScreenView({
    name: 'screen_view_checkout_bundle_summary',
    properties: { ...trackingProps.bundleProps, ...trackingProps.paymentMethodsProps, ...trackingProps.extraProps },
  });

  useTrackScreenView(
    {
      name: 'screen_view_select_payment_method',
      properties: { trigger_source: SCREEN_NAME.CHECKOUT_BUNDLE_SUMMARY },
    },
    [checkoutModalContext.isOpen, checkoutModalContext.screen],
    [checkoutModalContext.isOpen && checkoutModalContext.screen === 'list'],
  );

  useTrackScreenView(
    {
      name: 'screen_view_checkout_bundle_swish_summary',
      properties: { ...trackingProps.bundleProps, ...trackingProps.paymentMethodsProps, ...trackingProps.extraProps },
    },
    [showSwishQrCode],
    [showSwishQrCode],
  );

  useTrackScreenView(
    { name: 'screen_view_update_profile', properties: { trigger_source: SCREEN_NAME.CHECKOUT_BUNDLE_SUMMARY } },
    [showEditProfile],
    [showEditProfile],
  );

  useTrackScreenView(
    { name: 'screen_view_login_start', properties: { trigger_source: SCREEN_NAME.CHECKOUT_BUNDLE_SUMMARY } },
    [view],
    [view === 'requestToken' && !user],
  );

  useTrackScreenView(
    { name: 'screen_view_login_fail', properties: { trigger_source: SCREEN_NAME.CHECKOUT_BUNDLE_SUMMARY } },
    [pinInputProps.error, pinInputProps.clipError],
    [pinInputProps.error || pinInputProps.clipError],
  );

  useEffect(() => {
    if (user && view === 'verifyOtp') {
      updateView('verifySuccess');
      trackScreenView({
        name: 'screen_view_login_success',
        properties: { trigger_source: SCREEN_NAME.CHECKOUT_BUNDLE_SUMMARY },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, view]);

  useEffect(() => {
    sessionStorage.setItem('paymentCheckoutReturnUrl', `/bundles/checkout/${bundle.place.id}/${bundle.bundle.id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageViewLayout
      type="subView"
      title={_s(`${baseTranslationKey}.page-title`)}
      back
      wrapperClass={isMobileView ? 'bg-brown-50' : 'bg-gradient'}>
      {submitting && <LoadingPlaceHolder className="fixed h-screen" />}
      <div className="bg-brown-50 lg:bg-transparent">
        <div className="lg:py-xxl lg:container">
          <div className="gap-xxl flex items-start">
            <div className="gap-lg flex grow-[9999] basis-[600px] flex-col">
              <CardWrapper className="space-y-xs pb-md w-full lg:hidden">
                <CheckoutDetails {...checkoutDetailsProps} />
                <div className="px-lg">
                  <BundleNoticeBanner />
                </div>
              </CardWrapper>
              {requireLogin && (
                <CardWrapper>
                  <UserLogin
                    email={email}
                    handleEmailSubmit={handleEmailSubmit}
                    updateEmail={updateEmail}
                    triggerSource={SCREEN_NAME.CHECKOUT_BUNDLE_SUMMARY}
                  />
                </CardWrapper>
              )}
              {!requireLogin && (
                <>
                  <CardWrapper>
                    <UserInfo
                      email={user?.contact?.email ?? ''}
                      name={`${user?.about?.givenName ?? ''} ${user?.about?.familyName ?? ''}`}
                      phone={user?.contact?.phone ?? ''}
                      initials={getUserInitials(user)}
                      onClick={() => setShowEditProfile(true)}
                    />
                  </CardWrapper>
                  <div id="tooltip-slot" className="relative" ref={tooltipContainerRef} />
                  {tooltipVisible && (
                    <TooltipPortal
                      onClose={handleHideTooltip}
                      triggerRef={tooltipTriggerRef}
                      targetRef={tooltipContainerRef}
                      onClickOutside={handleHideTooltip}
                      offsetTop={44}
                      offsetLeft={-24}>
                      <div className="p-lg space-y-md">
                        <div className="flex items-start justify-between">
                          <span className="text-md max-w-[200px] font-bold text-white" id="popover-title">
                            {_s(`${baseTranslationKey}.formfields.nationalId.tooltip.title`)}
                          </span>
                          <button onClick={handleHideTooltip} aria-label={_s('close')}>
                            <Icon variant="close-alt" color="white" />
                          </button>
                        </div>
                        <p className="text-black-300 text-sm" id="popover-content">
                          {_s(`${baseTranslationKey}.formfields.nationalId.tooltip.message`)}
                        </p>
                      </div>
                    </TooltipPortal>
                  )}
                  {isWellnessQualified && (
                    <Fragment>
                      <CardWrapper className="pb-lg">
                        <div className="pl-lg py-md">
                          <Header label={_s(`${baseTranslationKey}.formfields.header`)} size="md" />
                        </div>
                        <div className="flex flex-col">
                          <ListInput
                            id="nationalId"
                            label={_s(`${baseTranslationKey}.formfields.nationalId.label`)}
                            placeholder={_s(`${baseTranslationKey}.formfields.nationalId.placeholder`)}
                            {...(Boolean(errors.nationalId?.message)
                              ? {
                                  error: errors.nationalId?.message,
                                }
                              : {
                                  rightIcon: (
                                    <button
                                      className="focus-visible:outline-black-900 rounded-sm focus:outline-none focus-visible:outline"
                                      ref={tooltipTriggerRef}
                                      onClick={handleShowTooltip}>
                                      <Icon variant="info-circle" color="information-700" />
                                    </button>
                                  ),
                                })}
                            {...register('nationalId', {
                              validate: (nationalId) => {
                                if (nationalId.trim() === '') return true;
                                return !isNationalId(nationalId.trim()) ? _s('invalidNationalId') : true;
                              },
                            })}
                          />
                        </div>
                      </CardWrapper>
                    </Fragment>
                  )}

                  <CardWrapper>
                    <div ref={selectPaymentMethodContainerRef}>
                      {usePromotedPaymentMethods || useHighlightedPaymentMethods ? (
                        <PromotedPaymentMethods
                          summary={summary}
                          selectedPaymentMethod={selectedPaymentMethod}
                          onChangePaymentMethod={handleChangePaymentMethod}
                          triggerSource={SCREEN_NAME.CHECKOUT_BUNDLE_SUMMARY}
                          useHighlighted={useHighlightedPaymentMethods}
                          error={missingActions.includes('payment-method')}
                          {...checkoutModalContext}
                        />
                      ) : (
                        <SelectPaymentOptions
                          method={selectedPaymentMethod}
                          onClick={() => checkoutModalContext.onModalShow()}
                          error={missingActions.includes('payment-method')}
                        />
                      )}
                    </div>
                  </CardWrapper>

                  <div ref={confirmationContainerRef}>
                    <CardWrapper>
                      <div className="gap-xl p-lg flex flex-col">
                        <SubmitCTA />
                        <CheckoutTerms
                          description={checkoutTerms.description}
                          linkLabel={checkoutTerms.linkLabel}
                          termsPageId={checkoutTerms.termsPageId}
                          onClose={handleCloseTerms}
                          triggerSource={SCREEN_NAME.CHECKOUT_BUNDLE_SUMMARY}
                        />
                      </div>
                    </CardWrapper>
                  </div>
                  <FloatingCTAWrapper
                    description={checkoutTerms.description}
                    linkLabel={checkoutTerms.linkLabel}
                    termsPageId={checkoutTerms.termsPageId}
                    show={showFloatingCTA}
                    onCloseTerms={handleCloseTerms}>
                    <SubmitCTA floating />
                  </FloatingCTAWrapper>
                </>
              )}
            </div>
            <div className="sticky top-20 hidden lg:flex lg:grow-[100] lg:basis-[390px]">
              <CardWrapper className="space-y-lg w-full">
                <CheckoutDetails key={2} {...checkoutDetailsProps} />
                <BundleNoticeBanner />
              </CardWrapper>
            </div>
          </div>
        </div>
      </div>
      {checkoutModalContext.screen === 'cof' ? (
        <CreditCardModal
          isOpen={checkoutModalContext.isOpen}
          onBack={checkoutModalContext.onModalBack}
          onClose={handleCloseCheckoutModal}
          onCardSelect={handleOnCreditCardSelect}
          onBeforeThreeDSRedirect={handleBeforeThreeDSRedirect}
          onRemoveCard={handleOnRemoveCard}
          triggerSource={SCREEN_NAME.CHECKOUT_BUNDLE_SUMMARY}
          returnPath={`/bundles/checkout/${bundle.place?.id}/${bundle.bundle?.id}`}
          {...checkoutModalContext}
        />
      ) : (
        <CheckoutModal
          summary={summary}
          selectedPaymentMethod={selectedPaymentMethod}
          onChangePaymentMethod={handleChangePaymentMethod}
          onApplyGiftcard={() => null}
          onModalClose={handleCloseCheckoutModal}
          triggerSource={SCREEN_NAME.CHECKOUT_BUNDLE_SUMMARY}
          {...checkoutModalContext}
        />
      )}
      {showThreeDSecure && (
        <Modal isOpen={!submitting}>
          <Modal.Content floating={!isMobileView}>
            <div className={classnames('gap-xl pb-lg px-lg flex flex-col', isMobileView && 'px-lg')}>
              <ThreeDSComponent
                action={JSON.parse(cofThreeDS?.adyenActionJson)}
                handleOnAdditionalDetails={(data) => bundleCheckoutAPI().submitThreeDS(data, false)}
              />
            </div>
          </Modal.Content>
        </Modal>
      )}
      {showSwishQrCode && <SwishModal onClose={handleDismissSwishQRCode} qrCode={swish.payment.qrCode} />}
      {(view === 'verifyOtp' || view === 'verifySuccess') && (
        <Modal isOpen>
          <Modal.Content floating={!isMobileView}>
            <Modal.Header
              title={_s(`loginModal.verifyOtp.title`)}
              {...(view === 'verifyOtp' && { onClose: handleCloseLoginModal })}
            />
            <div className={classnames('gap-xl pb-lg px-lg flex flex-col', isMobileView && 'px-lg')}>
              {view === 'verifyOtp' && (
                <VerifyOTP
                  email={email}
                  source={SCREEN_NAME.CHECKOUT_BUNDLE_SUMMARY}
                  handleEmailResendClicked={handleEmailResendClicked}
                  {...pinInputProps}
                />
              )}
              {view === 'verifySuccess' && <VerifySuccess email={email} onAnimationComplete={handleCloseLoginModal} />}
            </div>
          </Modal.Content>
        </Modal>
      )}
      <Modal isOpen={showEditProfile} onRequestClose={handleCloseEditProfile}>
        <Modal.Content floating={!isMobileView}>
          <Modal.Header title={_s(`${baseTranslationKey}.editProfileModal.title`)} onClose={handleCloseEditProfile} />
          <div className={classnames(isMobileView && 'px-lg')}>
            <UpdateUserInfo
              {...updateUserInfoProps}
              givenName
              familyName
              dob
              gender
              locality
              postalCode
              streetAddress
              mobile
            />
          </div>
        </Modal.Content>
      </Modal>
      <SEO
        {...seoPropsFromBaseString('checkoutBundle', `${url.getBaseUrl()}bundles/checkout`)}
        noindex={isSistaminuten()}
        image={url.getBaseImageUrl()}
      />
    </PageViewLayout>
  );
};

const SubmitCTA = ({ floating = false }: { floating?: boolean }) => {
  const { selectedPaymentMethod, summary, submitting, missingActions, onCheckoutMissingAction, bundleCheckoutAPI } =
    useBundleCheckout();
  const { isMobileView } = useMobileView();
  const { handleSubmit } = useFormData();
  const paymentMethod = selectedPaymentMethod.type;
  const { final } = summary.availablePaymentMethods.find((method) => method.type === paymentMethod);
  const payLater = !summary.isOnlinePaymentRequired;
  const invalidPaymentMethod = paymentMethod === CHECKOUT_PAYMENT_METHOD.NONE;

  const handleOnClick = () => {
    const missingActions: CheckoutMissingAction[] = [];
    if (invalidPaymentMethod) missingActions.push('payment-method');

    missingActions.forEach((action) => onCheckoutMissingAction(action));

    if (missingActions.length) {
      return;
    }
    switch (paymentMethod) {
      case CHECKOUT_PAYMENT_METHOD.STORED_COF:
        return bundleCheckoutAPI().submitCoF(selectedPaymentMethod.id);
      case CHECKOUT_PAYMENT_METHOD.QLIRO:
        return bundleCheckoutAPI().initQliro();
      case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
        return bundleCheckoutAPI().submitGooglePay(final, payLater);
      case CHECKOUT_PAYMENT_METHOD.APPLE_PAY:
        return bundleCheckoutAPI().submitApplePay(final, payLater);
      case CHECKOUT_PAYMENT_METHOD.SWISH:
        return bundleCheckoutAPI().submitSwish();
    }
  };

  const ctaIconVariant = getCheckoutSubmitIconVariantIfAny(selectedPaymentMethod);

  const buttonProps: ButtonProps = {
    size: floating ? 'sm' : 'lg',
    block: !floating,
    disabled: submitting || Boolean(missingActions.length),
    label: getBundleSubmitLabel({ paymentMethod, isFloating: floating, isMobileView }),
    onClick: handleSubmit(handleOnClick),
    leftIcon: ctaIconVariant ? <Icon variant={ctaIconVariant} noFilter /> : null,
    iconNoFilter: Boolean(ctaIconVariant),
  };

  return <Button {...buttonProps} />;
};

export default withBundleCheckoutFallback(
  withWalletPayment(({ isLoading: checkWalletMethodsLoading }) => {
    const location = useLocation();
    const history = useHistory();
    const user = useAppSelector((state) => state?.users?.user);
    const bundleState = useAppSelector((state) => state.bundle);
    const parsedBundleState = bundleStateSchema.safeParse(bundleState);

    if (parsedBundleState.success === false) {
      return <GoBack />;
    }

    const { cards, loading, error } = useCardsManager([], !!user);

    const isLoadingCards = loading && !error && !cards.length;

    const { paymentHistory, loading: loadingPaymentHistory } = usePaymentsHistory(bundleState.place.id);

    useEffect(() => {
      const threeDSecureRedirectResultSuccess = getThreeDSecureRedirectResultIfAny(location, history);
      if (threeDSecureRedirectResultSuccess || threeDSecureRedirectResultSuccess === undefined) return;
      toast(({ closeToast }) => (
        <Snackbar label={_s(`${baseTranslationKey}.threeDSecureRedirectError`)} type="danger" onClose={closeToast} />
      ));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (checkWalletMethodsLoading || isLoadingCards || loadingPaymentHistory) return <LoadingPlaceHolder />;
    return (
      <UseCardsProvider initialCards={cards}>
        <FormDataProvider>
          <BundleCheckoutProvider cards={cards} paymentHistory={paymentHistory}>
            <CheckoutModalProvider>
              <BundleCheckout />
            </CheckoutModalProvider>
          </BundleCheckoutProvider>
        </FormDataProvider>
      </UseCardsProvider>
    );
  }),
);
