import Header from '@/components/elements/Header/Header';
import { Button } from '@/components/elements/forms/buttons';
import ListInput from '@/components/elements/forms/listInput/ListInput/ListInput';
import Icon from '@/components/icons/Icon';
import { isStandardFormField } from '@/helpers/checkout';
import { useCheckoutFormData } from '@/hooks/useCheckoutFormData';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { useBookingCheckout } from '@/pages/booking/checkout/BookingCheckout.hooks';
import { FormField } from '@/types/state/shared';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import Modal from '../../modals/redesign/Modal/Modal';
import CheckoutFormFields, { PROFILE_FORM_FIELD_ID } from '../CheckoutFormFields/CheckoutFormFields';
import { useCheckoutFormManager } from '../CheckoutFormFields/CheckoutFormFields.hooks';

const baseTranslationKey = 'components.modules.checkout.AdditionalParticipantModal';

const AdditionalParticipantsModal = ({
  formFields,
  updateParticipant,
  initialUser,
  participantFormId,
  capacity,
  onSubmit,
  onClose,
}: {
  formFields?: FormField[];
  updateParticipant?: boolean;
  initialUser?: Record<string, string>;
  participantFormId: string;
  capacity: number;
  onSubmit: () => void;
  onClose: () => void;
}) => {
  const { isMobileView } = useMobileView();
  const { onUpdateGroupBooking } = useBookingCheckout();

  /**
   * create a separate form context for the participants by using the manager directly and not
   * from the provider to avoid conflicts with the main form
   */
  const formContext = useCheckoutFormManager();
  const { register, handleSubmit, formState } = formContext;
  const { formData, onUpdateFormData } = useCheckoutFormData();
  const standardFields = [
    PROFILE_FORM_FIELD_ID.GIVEN_NAME,
    PROFILE_FORM_FIELD_ID.FAMILY_NAME,
    PROFILE_FORM_FIELD_ID.EMAIL,
    PROFILE_FORM_FIELD_ID.MOBILE,
  ];
  const customFields = formFields.filter(
    (field) => !standardFields.find((standardField) => standardField === field.id),
  );

  const getRequiredFields = () => {
    const fields = [
      PROFILE_FORM_FIELD_ID.EMAIL,
      PROFILE_FORM_FIELD_ID.MOBILE,
      PROFILE_FORM_FIELD_ID.FAMILY_NAME,
      PROFILE_FORM_FIELD_ID.GIVEN_NAME,
    ];
    const requiredFields = {
      email: false,
      givenName: false,
      familyName: false,
      mobile: false,
    };

    formFields.forEach((formField) => {
      fields.forEach((field) => {
        if (field === formField.id && formField.required) {
          requiredFields[field] = true;
        }
      });
    });
    return requiredFields;
  };

  const requiredFields = getRequiredFields();

  return (
    <Modal isOpen={true}>
      <Modal.Content floating={!isMobileView}>
        <Modal.Header title={_s(`${baseTranslationKey}.title`)} onClose={onClose} />
        <div className={`gap-lg flex flex-col`}>
          <div className="pb-lg">
            <div className="pb-md px-lg">
              <Header size="sm" label={_s(`${baseTranslationKey}.subTitle`)} />
            </div>
            <ul>
              <div className="flex">
                <ListInput
                  id="givenName"
                  autoComplete="given-name"
                  placeholder={`${_s(`${baseTranslationKey}.inputLabel.givenName`)}`}
                  label={`${_s(`${baseTranslationKey}.inputLabel.givenName`)}${requiredFields.givenName ? '*' : ''}`}
                  leftIcon={<Icon variant="user" />}
                  error={formState.errors['givenName']?.message ? formState.errors['givenName'].message.toString() : ''}
                  {...register('givenName', {
                    ...(requiredFields.givenName ? { required: _s('requiredField') } : {}),
                  })}
                />
                <ListInput
                  id="familyName"
                  autoComplete="family-name"
                  placeholder={`${_s(`${baseTranslationKey}.inputLabel.familyName`)}`}
                  label={`${_s(`${baseTranslationKey}.inputLabel.familyName`)}${requiredFields.familyName ? '*' : ''}`}
                  leftIcon={<Icon variant="user" />}
                  error={
                    formState.errors['familyName']?.message ? formState.errors['familyName'].message.toString() : ''
                  }
                  {...register('familyName', {
                    ...(requiredFields.familyName ? { required: _s('requiredField') } : {}),
                  })}
                />
              </div>
              <ListInput
                id="mobile"
                autoComplete="tel"
                label={`${_s(`${baseTranslationKey}.inputLabel.phone`)}${requiredFields.mobile ? '*' : ''}`}
                placeholder="07X XXX XX XX"
                leftIcon={<Icon variant="phone" />}
                error={formState.errors['mobile']?.message ? formState.errors['mobile'].message.toString() : ''}
                {...register('mobile', {
                  ...(requiredFields.mobile ? { required: _s('requiredField') } : {}),
                  validate: (mobile) =>
                    Boolean(!mobile.trim()) ? true : !isMobilePhone(mobile.trim()) ? _s('invalidPhone') : true,
                })}
              />
              <ListInput
                id="email"
                autoComplete="email"
                label={`${_s(`${baseTranslationKey}.inputLabel.email`)}${requiredFields.email ? '*' : ''}`}
                placeholder="exempel@email.com"
                leftIcon={<Icon variant="email" />}
                error={formState.errors['email']?.message ? formState.errors['email'].message.toString() : ''}
                {...register('email', {
                  ...(requiredFields.email ? { required: _s('requiredField') } : {}),
                  validate: (email) => {
                    return !isEmail(email) ? _s('invalidEmail') : true;
                  },
                })}
              />
            </ul>
          </div>

          {customFields?.length > 0 && (
            <div className="bg-white">
              <CheckoutFormFields
                formFields={customFields}
                initialFormValues={initialUser}
                onUpdateFormField={() => {}}
                context={formContext}
              />
            </div>
          )}

          <div className="p-lg gap-sm flex flex-col bg-white">
            {!updateParticipant && (
              <Button
                size="md"
                label={_s(`${baseTranslationKey}.addButton`)}
                block
                onClick={() => {
                  handleSubmit(
                    (value) => {
                      if (Object.keys(formData).length < capacity) {
                        onUpdateFormData(participantFormId, true, value);
                      }
                      onUpdateGroupBooking({ hasAddedParticipant: true });
                      onSubmit();
                    },
                    (errors) => {
                      const fieldNames = Array.from(
                        new Set(
                          Object.keys(errors).map((formField) =>
                            isStandardFormField(formField) ? formField : 'custom_field',
                          ),
                        ),
                      );
                    },
                  )();
                }}
              />
            )}
            {updateParticipant && (
              <>
                <Button
                  size="md"
                  label={_s(`${baseTranslationKey}.updateButton`)}
                  block
                  onClick={() => {
                    handleSubmit(
                      (value) => {
                        onUpdateFormData(participantFormId, true, value);
                        onSubmit();
                      },
                      (errors) => {
                        const fieldNames = Array.from(
                          new Set(
                            Object.keys(errors).map((formField) =>
                              isStandardFormField(formField) ? formField : 'custom_field',
                            ),
                          ),
                        );
                      },
                    )();
                  }}
                />
                <Button
                  onClick={() => {
                    onUpdateGroupBooking({ hasAddedParticipant: Object.keys(formData).length >= 2 });
                    onUpdateFormData(participantFormId, true);
                    onSubmit();
                  }}
                  size="md"
                  leftIcon={<Icon color="danger-700" variant="trash" />}
                  className="text-danger-700"
                  variant="link"
                  label={_s(`${baseTranslationKey}.removeButton`)}
                  block
                />
              </>
            )}
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default AdditionalParticipantsModal;
