import { useEffect, useState } from 'react';

/**
 * Keep track of a browser navigation event and get the updated pathname
 */
export const useHandlePopstate = () => {
  const [url, setUrl] = useState<string>(null);
  const [didUpdate, setDidUpdate] = useState<boolean>(false);

  useEffect(() => {
    const handleOnBrowserNavigation = () => {
      setUrl((prev) => {
        const newUrl = window.location.pathname + window.location.search;
        if (prev === newUrl) return prev;
        setDidUpdate(true);
        return newUrl;
      });
    };

    window.addEventListener('popstate', handleOnBrowserNavigation);

    return () => {
      window.removeEventListener('popstate', handleOnBrowserNavigation);
    };
  }, []);

  useEffect(() => {
    if (didUpdate) {
      const timeout = setTimeout(() => setDidUpdate(false), 0);
      return () => clearTimeout(timeout);
    }
  }, [didUpdate]);

  return { url, didUpdate };
};
