import { CheckIcon, RadioIcon } from '@/components/icons';
import { isSistaminuten } from '@/helpers';
import { HTMLAttributes, MouseEventHandler } from 'react';
import css from './RadioButton.module.scss';

export type RadioButtonProps = {
  children: React.ReactNode;
  checked: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  variant: 'small' | 'large';
  className?: string;
  disabled?: boolean;
  style?: HTMLAttributes<HTMLButtonElement>['style'];
  checkPosition?: 'top' | 'center';
  iconPosition?: 'left' | 'right';
  showChecked?: boolean;
  iconType?: 'checkbox' | 'radio';
};

const getIcon = (iconType: string, variantClass: string) => {
  return iconType === 'checkbox' ? (
    <CheckIcon className={`${variantClass}`} />
  ) : (
    <RadioIcon className={`${variantClass}`} />
  );
};

const RadioButton = ({
  children,
  checked = false,
  disabled = false,
  onClick,
  variant,
  className = '',
  style = {},
  checkPosition = 'center',
  iconPosition = 'right',
  showChecked = true,
  iconType = 'checkbox',
}: RadioButtonProps) => {
  const variantClass =
    iconType === 'checkbox'
      ? variant === 'large'
        ? 'h-10 w-10'
        : 'h-6 w-6'
      : variant === 'large'
      ? 'h-8 w-8'
      : 'h-5 w-5';

  const showCheckedClass =
    showChecked && checked
      ? isSistaminuten()
        ? '!border-sm_primary !bg-sm_primary-100'
        : '!border-primary !bg-primary-50'
      : '';

  const checkPositionClass =
    checkPosition === 'top' ? (iconType === 'checkbox' ? 'top-4' : 'top-0 mt-5') : 'top-1/2 -translate-y-1/2';

  return (
    <button
      type="button"
      className={`
        border-black-300
        relative
        !mr-2
        flex
        w-full
        items-center
        justify-between
        rounded-md
        bg-white
        transition-colors
        duration-300
        focus:outline-none
        focus-visible:outline-1
        focus-visible:outline-black
        ${iconType === 'checkbox' ? 'border-[1px] py-3' : ''}
        ${isSistaminuten() ? 'hover:border-sm_primary' : 'hover:border-primary'}
        ${showCheckedClass}
        ${css.button}
        ${css[variant]} ${className}`}
      onClick={onClick}
      style={style}
      disabled={disabled}>
      {iconPosition === 'left' && (
        <span
          className={`
          absolute
          ${disabled ? 'opacity-40' : ''}
          ${checkPositionClass}
          ${iconType === 'checkbox' ? 'text-primary left-0' : 'left-3'}
          flex
          items-center
          justify-center
          rounded-full
          ${showChecked ? 'border-black-300 border-[1px]' : ''}
          transition-all
          duration-300
          ${iconType === 'checkbox' ? showCheckedClass : 'bg-primary-50'}
          ${variantClass}`}>
          {showChecked && checked && getIcon(iconType, variantClass)}
        </span>
      )}
      {children}
      {iconPosition === 'right' && (
        <span
          className={`
          absolute
          ${disabled ? 'opacity-40' : ''}
          ${checkPositionClass}
          right-4
          flex
          items-center
          justify-center
          rounded-full
          ${showChecked ? 'border-black-300 border-[1px]' : ''}
          text-primary
          transition-all
          duration-300
          ${showCheckedClass}
          ${variantClass}`}>
          {showChecked && checked && getIcon(iconType, variantClass)}
        </span>
      )}
    </button>
  );
};

export default RadioButton;
