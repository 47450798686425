import Card from '@/components/elements/Card/Card';
import { Button } from '@/components/elements/forms/buttons';
import { ListItem } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import { _s } from '@/locale';

const baseTranslationKey = 'features.ProfilePages.components.OpeningHours';
const WEEKDAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
type Hours = Record<(typeof WEEKDAYS)[number], string>;

// @TODO add sales trigger link to button when adding support for unverified places and basic subscriptions
const OpeningHours = ({ openingHours }: { openingHours?: Hours }) => {
  return (
    <Card size="lg">
      <div className="gap-xs flex flex-col">
        <h2 className="text-h-s">{_s(`${baseTranslationKey}.title`)}</h2>
        <ul>
          {WEEKDAYS.map((day) => {
            const isOpen = openingHours && openingHours[day];
            const openingHoursText = isOpen ? openingHours[day] : _s(`${baseTranslationKey}.closed`);
            return (
              <ListItem
                key={day}
                leftPadding={false}
                rightPadding={false}
                underline
                rightSlot={
                  <span className={`text-m ${isOpen ? 'text-fg_secondary' : 'text-fg_negative'}`}>
                    {openingHours ? openingHoursText : _s(`${baseTranslationKey}.missingHours`)}
                  </span>
                }>
                <span className="text-l text-fg_primary">{_s(day)}</span>
              </ListItem>
            );
          })}
        </ul>
        {!openingHours && (
          <Button
            size="md"
            label={_s(`${baseTranslationKey}.cta`)}
            iconNoFilter
            block
            rightIcon={<Icon variant="pen" color="fg_primary_inverse" />}
          />
        )}
      </div>
    </Card>
  );
};

export default OpeningHours;
