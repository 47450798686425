import { useEffect, useRef, forwardRef, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// Define the type for the props
interface PortalProps {
  children: ReactNode; // This makes sure that the 'children' prop is recognized
  [key: string]: any;  // For any other dynamic props
}

const Portal = forwardRef<HTMLDivElement, PortalProps>(({ children, ...props }, ref) => {
  const portalElement = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const p = document.createElement('div');
    document.body.appendChild(p);
    portalElement.current = p;

    return () => {
      if (portalElement.current) {
        document.body.removeChild(portalElement.current);
      }
    };
  }, []);

  const duration = 200;
  const styles = `
    .fade-enter { opacity: 0.01; }
    .fade-enter.fade-enter-active { opacity: 1; transition: opacity ${duration}ms; }
    .fade-leave { opacity: 1; }
    .fade-leave.fade-leave-active { opacity: 0.01; transition: opacity ${duration}ms; }
  `;

  return portalElement.current
    ? createPortal(
        <div>
          <style>{styles}</style>
          <TransitionGroup {...props}>
            <CSSTransition timeout={{ enter: duration, exit: duration }} classNames="fade">
              {children}
            </CSSTransition>
          </TransitionGroup>
        </div>,
        portalElement.current,
      )
    : null;
});

export default Portal;
