export const serpSeo = [
  {
    keywords: ['frisör', 'frisor'],
    description: 'seo.haircutDescription',
    cta: 'seo.haircutTitleCta',
  },
  {
    keywords: ['träning', 'traning', 'gym'],
    description: 'seo.gymDescription',
    cta: 'seo.gymTitleCta',
  },
  {
    keywords: ['skönhet', 'skonhet', 'skönhetsbehandlingar', 'skonhetsbehandlingar'],
    description: 'seo.beautyDescription',
    cta: 'seo.beautyTitleCta',
  },
  {
    keywords: ['hudvård', 'hudvard'],
    description: 'seo.facialsDescription',
    cta: 'seo.facialsTitleCta',
  },
  {
    keywords: ['massage'],
    description: 'seo.massageDescription',
    cta: 'seo.massageTitleCta',
  },
  {
    keywords: ['naglar'],
    description: 'seo.nailsDescription',
    cta: 'seo.nailsTitleCta',
  },
  {
    keywords: ['fillers'],
    description: 'seo.fillersDescription',
    cta: 'seo.fillersTitleCta',
  },
  {
    keywords: ['friskvård', 'friskvard'],
    description: 'seo.wellnessDescription',
    cta: 'seo.wellnessTitleCta',
  },
  {
    keywords: ['fransar'],
    description: 'seo.lashesDescription',
    cta: 'seo.lashesTitleCta',
  },
  {
    keywords: ['akupunktur'],
    description: 'seo.acupunctureDescription',
    cta: 'seo.acupunctureTitleCta',
  },
  {
    keywords: ['coaching', 'coachning'],
    description: 'seo.coachingDescription',
    cta: 'seo.coachingTitleCta',
  },
  {
    keywords: ['däck', 'dack', 'däckbyte', 'dackbyte'],
    description: 'seo.tireDescription',
    cta: 'seo.tireTitleCta',
  },
  {
    keywords: ['floating'],
    description: 'seo.floatingDescription',
    cta: 'seo.floatingTitleCta',
  },
  {
    keywords: ['fotvård', 'fotvard', 'pedikyr'],
    description: 'seo.pedicureDescription',
    cta: 'seo.pedicureTitleCta',
  },
  {
    keywords: ['hårvård', 'hardvard'],
    description: 'seo.hairCareDescription',
    cta: 'seo.hairCareTitleCta',
  },
  {
    keywords: ['healing'],
    description: 'seo.healingDescription',
    cta: 'seo.healingTitleCta',
  },
  {
    keywords: ['kiropraktik', 'kiropraktor'],
    description: 'seo.chiropracticDescription',
    cta: 'seo.chiropracticTitleCta',
  },
  {
    keywords: ['kosmetisk tatuering', 'tatuering', 'tattoo'],
    description: 'seo.tattooDescription',
    cta: 'seo.tattooTitleCta',
  },
  {
    keywords: ['kroppsscanning'],
    description: 'seo.bodyScanDescription',
    cta: 'seo.bodyScanTitleCta',
  },
  {
    keywords: ['laserbehandling'],
    description: 'seo.laserDescription',
    cta: 'seo.laserTitleCta',
  },
  {
    keywords: ['makeup'],
    description: 'seo.makeupDescription',
    cta: 'seo.makeupTitleCta',
  },
  {
    keywords: ['nagelvård', 'nagelvard'],
    description: 'seo.nailCareDescription',
    cta: 'seo.nailCareTitleCta',
  },
  {
    keywords: ['naprapat', 'naprapati'],
    description: 'seo.naprapatDescription',
    cta: 'seo.naprapatTitleCta',
  },
  {
    keywords: ['osteopat', 'osteopati'],
    description: 'seo.osteopathyDescription',
    cta: 'seo.osteopathyTitleCta',
  },
  {
    keywords: ['städning', 'stadning', 'hemstäd', 'hemstad'],
    description: 'seo.cleaningDescription',
    cta: 'seo.cleaningTitleCta',
  },
  {
    keywords: ['tandblekning'],
    description: 'seo.teethWhiteningDescription',
    cta: 'seo.teethWhiteningTitleCta',
  },
  {
    keywords: ['tandvård', 'tandvard'],
    description: 'seo.dentalDescription',
    cta: 'seo.dentalTitleCta',
  },
  {
    keywords: ['utbildningar', 'utbildning'],
    description: 'seo.educationDescription',
    cta: 'seo.educationTitleCta',
  },
  {
    keywords: ['veterinärer', 'veterinarer', 'veterinär', 'veterinar'],
    description: 'seo.vetDescription',
    cta: 'seo.vetTitleCta',
  },
  {
    keywords: ['yoga'],
    description: 'seo.yogaDescription',
    cta: 'seo.yogaTitleCta',
  },
  {
    keywords: ['zumba'],
    description: 'seo.zumbaDescription',
    cta: 'seo.zumbaTitleCta',
  },
];
