import { _s } from '@/locale';
import { PaymentMethodListItem } from '@/types/checkout';
import { PaymentCard } from '@/types/paymentcards';

const baseTranslationKey = 'constants.checkout';

export const CHECKOUT_PAYMENT_METHOD = {
  NONE: -1,
  PAY_AT_PLACE: 0,
  KLARNA: 1,
  QLIRO: 3,
  STORED_COF: 4,
  NEW_COF: 5,
  GOOGLE_PAY: 6,
  APPLE_PAY: 7,
  SWISH: 8,
} as const;

export const COUPON_PAYMENT_METHOD = {
  GIFTCARD: 'GIFTCARD',
  VALUECARD: 'VALUECARD',
  WELLNESSCARD: 'WELLNESSCARD',
} as const;

export const NEW_ONLINE_CARD_LIST_ITEM = (onlinePaymentRequired: boolean = false): PaymentMethodListItem => ({
  title: _s(`${baseTranslationKey}.NEW_ONLINE_CARD_LIST_ITEM.title`),
  subTitle: onlinePaymentRequired
    ? _s(`${baseTranslationKey}.NEW_ONLINE_CARD_LIST_ITEM.subTitleOnlinePaymentRequired`)
    : _s(`${baseTranslationKey}.NEW_ONLINE_CARD_LIST_ITEM.subTitle`),
  type: CHECKOUT_PAYMENT_METHOD.NEW_COF,
});

export const APPLE_PAY_LIST_ITEM = (onlinePaymentRequired: boolean): PaymentMethodListItem => ({
  title: _s(`${baseTranslationKey}.APPLE_PAY_LIST_ITEM.title`),
  subTitle: onlinePaymentRequired
    ? _s(`${baseTranslationKey}.APPLE_PAY_LIST_ITEM.subTitleOnlinePaymentRequired`)
    : _s(`${baseTranslationKey}.APPLE_PAY_LIST_ITEM.subTitle`),
  type: CHECKOUT_PAYMENT_METHOD.APPLE_PAY,
});

export const GOOGLE_PAY_LIST_ITEM = (onlinePaymentRequired: boolean): PaymentMethodListItem => ({
  title: _s(`${baseTranslationKey}.GOOGLE_PAY_LIST_ITEM.title`),
  subTitle: onlinePaymentRequired
    ? _s(`${baseTranslationKey}.GOOGLE_PAY_LIST_ITEM.subTitleOnlinePaymentRequired`)
    : _s(`${baseTranslationKey}.GOOGLE_PAY_LIST_ITEM.subTitle`),
  type: CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY,
});

export const KLARNA_LIST_ITEM: PaymentMethodListItem = {
  title: _s(`${baseTranslationKey}.KLARNA_LIST_ITEM.title`),
  subTitle: _s(`${baseTranslationKey}.KLARNA_LIST_ITEM.subTitle`),
  type: CHECKOUT_PAYMENT_METHOD.KLARNA,
};

export const QLIRO_LIST_ITEM: PaymentMethodListItem = {
  title: _s(`${baseTranslationKey}.QLIRO_LIST_ITEM.title`),
  subTitle: _s(`${baseTranslationKey}.QLIRO_LIST_ITEM.subTitle`),
  type: CHECKOUT_PAYMENT_METHOD.QLIRO,
};

export const PAY_AT_PLACE_LIST_ITEM: PaymentMethodListItem = {
  title: _s(`${baseTranslationKey}.PAY_AT_PLACE_LIST_ITEM.title`),
  subTitle: _s(`${baseTranslationKey}.PAY_AT_PLACE_LIST_ITEM.subTitle`),
  type: CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE,
};

export const BOKADIREKT_GIFTCARD_LIST_ITEM: PaymentMethodListItem = {
  title: _s(`${baseTranslationKey}.BOKADIREKT_GIFTCARD_LIST_ITEM.title`),
  subTitle: _s(`${baseTranslationKey}.BOKADIREKT_GIFTCARD_LIST_ITEM.subTitle`),
  type: COUPON_PAYMENT_METHOD.GIFTCARD,
};

export const BOKADIREKT_VALUECARD_LIST_ITEM: PaymentMethodListItem = {
  title: _s(`${baseTranslationKey}.BOKADIREKT_VALUECARD_LIST_ITEM.title`),
  subTitle: _s(`${baseTranslationKey}.BOKADIREKT_VALUECARD_LIST_ITEM.subTitle`),
  type: COUPON_PAYMENT_METHOD.VALUECARD,
};

export const BOKADIREKT_WELLNESSCARD_LIST_ITEM: PaymentMethodListItem = {
  title: _s(`${baseTranslationKey}.BOKADIREKT_WELLNESSCARD_LIST_ITEM.title`),
  subTitle: _s(`${baseTranslationKey}.BOKADIREKT_WELLNESSCARD_LIST_ITEM.subTitle`),
  type: COUPON_PAYMENT_METHOD.WELLNESSCARD,
};

export const SWISH_LIST_ITEM: PaymentMethodListItem = {
  title: _s(`${baseTranslationKey}.SWISH_LIST_ITEM.title`),
  subTitle: _s(`${baseTranslationKey}.SWISH_LIST_ITEM.subTitle`),
  type: CHECKOUT_PAYMENT_METHOD.SWISH,
};

export const STORED_ONLINE_CARD_LIST_ITEM = (card: PaymentCard, payLater?: boolean): PaymentMethodListItem => ({
  subTitle: payLater
    ? _s(`${baseTranslationKey}.STORED_ONLINE_CARD_LIST_ITEM.subTitle`)
    : _s(`${baseTranslationKey}.STORED_ONLINE_CARD_LIST_ITEM.subTitleOnlinePaymentRequired`),
  type: CHECKOUT_PAYMENT_METHOD.STORED_COF,
  brand: card.brand,
  id: card.id,
  lastFour: card.lastFour,
});

export const COF_PAYMENT_REDIRECT_LOCALSTORAGE_KEY = 'payment-redirect-cof';
