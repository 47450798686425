import { Fragment, ReactNode, useEffect } from 'react';

type PopoverProps = {
  id: string;
  children: ReactNode;
  className?: string;
};

const initializeCSSAnchorPolyfill = () => {
  let initialized = false;
  return () => {
    if (initialized) return;
    initialized = true;

    // Check if the CSS Anchor Positioning feature is supported
    if (!('anchorName' in document.documentElement.style)) {
      const script = document.createElement('script');
      script.src = 'https://unpkg.com/@oddbird/css-anchor-positioning';
      script.type = 'text/javascript';
      script.onerror = (error) => {
        console.error('Failed to load CSS Anchor Positioning polyfill:', error);
      };
      document.body.appendChild(script); // Append the script to the document
    }
  };
};

const Popover = ({ id, children, className }: PopoverProps) => {
  useEffect(() => {
    const initializeCSSAnchor = initializeCSSAnchorPolyfill();
    initializeCSSAnchor();
  }, []);

  return (
    <Fragment>
      <style>
        {`

        .trigger-${id} {
          anchor-name: --${id};
        }

        .popover-${id} {
          position: absolute;
          margin: 0;
          inset: auto;
          top: anchor(bottom);
          right: anchor(right);

          position-anchor: --${id};
        }

        .popover-${id}:popover-open {
          display: block;
        }

        `}
      </style>
      {/*// @ts-ignore - popover is not supported */}
      <div id={id} popover="auto" className={`popover-${id} ${className}`}>
        {children}
      </div>
    </Fragment>
  );
};

export default Popover;
