import Checkbox from '@/components/elements/forms/Checkbox/Checkbox';
import { Button } from '@/components/elements/forms/buttons';
import { _s } from '@/locale';
import { ChangeEventHandler, MouseEventHandler } from 'react';
import styles from './CookiesContent.module.scss';

const CookiesContent = ({
  onClickSave,
  trackingChecked,
  onTrackingCheckedChange,
  onTrackingLabelClick,
}: {
  onClickSave: MouseEventHandler<HTMLButtonElement>;
  onTrackingCheckedChange: ChangeEventHandler<HTMLInputElement>;
  onTrackingLabelClick: MouseEventHandler<HTMLLabelElement>;
  trackingChecked: boolean;
}) => (
  <div className="pb-safe">
    <div className={styles.pageHeader}>
      <div className="container"></div>
    </div>
    <div className={`${styles.cookiesContent} container mt-10 pb-4`}>
      <p className="text-black-700 mb-10 whitespace-pre-wrap text-base">{_s('cookiesPage.cookiesContent')}</p>
      <h3 className="font-semibold">
        <Checkbox checked disabled label={<label>{_s('cookiesPage.mandatoryCookies')}</label>} />
      </h3>
      <p className="text-black-700 mb-4">{_s('cookiesPage.mandatoryCookiesContent')}</p>
      <ul className={`${styles.cookiesList}`}>
        <li>
          <span className="text-black-900 mr-2 text-base">{_s('cookiesPage.sessionIdentifier')}</span>
          <span className="bg-[#f9f2f4] text-[#c7254e]">ASP.NET_SessionId, PHPSESSID, connect.sid</span>
          <p className="text-black-600 text-sm">{_s('cookiesPage.sessionIdentifierContent')}</p>
        </li>
        <li>
          <span className="text-black-900 mr-2 text-base">{_s('cookiesPage.securityToken')}</span>
          <span className="bg-[#f9f2f4] text-[#c7254e]">BokadirektUser, BokadirektAccount</span>
          <p className="text-black-600 text-sm">{_s('cookiesPage.securityTokenContent')}</p>
        </li>
        <li>
          <span className="text-black-900 mr-2 text-base">{_s('cookiesPage.sessionsToken')}</span>
          <span className="bg-[#f9f2f4] text-[#c7254e]">BokadirektSession</span>
          <p className="text-black-600 text-sm">{_s('cookiesPage.sessionsTokenContent')}</p>
        </li>
        <li>
          <span className="text-black-900 mr-2 text-base">{_s('cookiesPage.cookiesTitle')}</span>
          <span className="bg-[#f9f2f4] text-[#c7254e]">BokadirektCookiePreferencesMP</span>
          <p className="text-black-600 text-sm">{_s('cookiesPage.cookieSettingsContent')}</p>
        </li>
        <li>
          <span className="text-black-900 mr-2 text-base">{_s('cookiesPage.xsrf')}</span>
          <span className="bg-[#f9f2f4] text-[#c7254e]">_sAntiXsrfToken, __RequestVerificationToken_*</span>
          <p className="text-black-600 text-sm">{_s('cookiesPage.xsrfContent')}</p>
        </li>
        <li>
          <span className="text-black-900 mr-2 text-base">{_s('cookiesPage.correlationValueForLogin')}</span>
          <span className="bg-[#f9f2f4] text-[#c7254e]">.AspNet.Correlation.*</span>
          <p className="text-black-600 text-sm">{_s('cookiesPage.correlationValueForLoginContent')}</p>
        </li>
        <li>
          <span className="text-black-900 mr-2 text-base">{_s('cookiesPage.csrf')}</span>
          <span className="bg-[#f9f2f4] text-[#c7254e]">CsrfId</span>
          <p className="text-black-600 text-sm">{_s('cookiesPage.csrfContent')}</p>
        </li>
        <li>
          <span className="text-black-900 mr-2 text-base">{_s('cookiesPage.experiment')}</span>
          <span className="bg-[#f9f2f4] text-[#c7254e]">BokadirektExperiment</span>
          <p className="text-black-600 text-sm">{_s('cookiesPage.experimentContent')}</p>
        </li>
      </ul>
      <h3 className="font-semibold">
        <Checkbox
          onChange={onTrackingCheckedChange}
          checked={trackingChecked}
          label={<label onClick={onTrackingLabelClick}>{_s('cookiesPage.analysisAndMarketing')}</label>}
        />
      </h3>
      <p className="text-black-700 mb-4">{_s('cookiesPage.analysisAndMarketingContent')}</p>
      <ul className={`${styles.cookiesList}`}>
        <li>
          <span className="text-black-900 mr-2 text-base">Google Analytics</span>
          <p className="text-black-600 text-sm">
            {_s('cookiesPage.analyticsContent')}&nbsp;
            <a
              className="text-link"
              target="_blank"
              rel="nofollow external noopener noreferrer"
              href="https://support.google.com/analytics/answer/6004245">
              {_s('readMore')}
            </a>
          </p>
        </li>
        <li>
          <span className="text-black-900 mr-2 text-base">Facebook</span>
          <p className="text-black-600 text-sm">
            {_s('cookiesPage.facebookContent')}&nbsp;
            <a
              className="text-link"
              target="_blank"
              rel="nofollow external noopener noreferrer"
              href="https://www.facebook.com/help/www/213802165366955">
              {_s('here')}
            </a>
          </p>
        </li>
        <li>
          <span className="text-black-900 mr-2 text-base">Amplitude</span>
          <p className="text-black-600 text-sm">
            {_s('cookiesPage.amplitudeContent')}&nbsp;
            <a
              target="_blank"
              className="text-link"
              rel="nofollow external noopener noreferrer"
              href="https://amplitude.com/privacy">
              {_s('readMore')}
            </a>
          </p>
        </li>
        <li>
          <span className="text-black-900 mr-2 text-base">Customer.io</span>
          <p className="text-black-600 text-sm">
            {_s('cookiesPage.customerIoContent')}&nbsp;
            <a
              className="text-link"
              target="_blank"
              rel="nofollow external noopener noreferrer"
              href="https://customer.io/legal/privacy-policy/">
              {_s('readMore')}
            </a>
          </p>
        </li>
        <li>
          <span className="text-black-900 mr-2 text-base">Microsoft Clarity</span>
          <p className="text-black-600 text-sm">
            {_s('cookiesPage.microsoftClarityContent')}&nbsp;
            <a
              className="text-link"
              target="_blank"
              rel="nofollow external noopener noreferrer"
              href="https://privacy.microsoft.com/en-us/privacystatement">
              {_s('readMore')}
            </a>
          </p>
        </li>
        <li>
          <span className="text-black-900 mr-2 text-base">Quantcast</span>
          <p className="text-black-600 text-sm">
            {_s('cookiesPage.quantcastContent')}&nbsp;
            <a
              className="text-link"
              target="_blank"
              rel="nofollow external noopener noreferrer"
              href="https://legal.quantcast.com/#quantcast-website-and-corporate-privacy-policy">
              {_s('readMore')}
            </a>
          </p>
        </li>
        <li>
          <span className="text-black-900 mr-2 text-base">TikTok</span>
          <p className="text-black-600 text-sm">
            {_s('cookiesPage.tiktokContent')}&nbsp;
            <a
              className="text-link"
              target="_blank"
              rel="nofollow external noopener noreferrer"
              href="https://www.tiktok.com/legal/page/eea/privacy-policy/sv">
              {_s('readMore')}
            </a>
          </p>
        </li>
        <li>
          <span className="text-black-900 mr-2 text-base">Adform</span>
          <p className="text-black-600 text-sm">
            {_s('cookiesPage.adformContent')}&nbsp;
            <a
              className="text-link"
              target="_blank"
              rel="nofollow external noopener noreferrer"
              href="https://site.adform.com/privacy-center/platform-privacy/product-and-services-privacy-policy/">
              {_s('readMore')}
            </a>
          </p>
        </li>
      </ul>
      <Button onClick={onClickSave}>{_s('cookiesPage.saveSettings')}</Button>
    </div>
  </div>
);
export default CookiesContent;
