import { _s } from '@/locale';
import { FILTER_ID } from '@/types/state/search';
import { FilterOption } from './Filter.types';

export const FILTER_OPTIONS: FilterOption[] = [
  { label: _s('serpFilters.payLater'), id: FILTER_ID.PAY_LATER },
  { label: _s('serpFilters.giftcard'), id: FILTER_ID.GIFTCARD },
  { label: _s('serpFilters.wellnesscard'), id: FILTER_ID.WELLNESSCARD },
  { label: _s('serpFilters.bundle'), id: FILTER_ID.BUNDLE },
  { label: _s('serpFilters.campaign'), id: FILTER_ID.CAMPAIGN },
  { label: _s('serpFilters.certified'), id: FILTER_ID.CERTIFIED },
];

export function getSelectedFilters(prefs: string = '') {
  const prefsArray = prefs.split(',');
  return FILTER_OPTIONS.filter((filter) => prefsArray.includes(filter.id.toString()));
}
