import { promiseWrapper } from '@/helpers';
import * as associationPagesServices from '@/services/associationPagesServices';
import { Association, associationSchema } from '@/types/api/services/associationPages';
import { captureException } from '@sentry/react';
import { Dispatch, useEffect, useState } from 'react';

/**
 * Check that slug of ssr loaded page matches the current slug
 */
const checkSlug = (ssrSlug: string, currentSlug: string) => {
  return ssrSlug === currentSlug;
};

export const useAssociationPage = (ssrPage: { data: Association; error: boolean } | null, slug: string) => {
  const ssrLoadedPage = ssrPage && checkSlug(ssrPage?.data?.slug, slug) ? ssrPage : null;
  const [page, setPage] = useState<Association>(ssrLoadedPage?.data ?? null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (ssrLoadedPage || ssrPage?.error) return;
    fetchPage(slug, setError, setPage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (ssrLoadedPage || ssrPage?.error) return { page, loading: false, error: ssrPage?.error ?? false };

  return { page, error };
};

const fetchPage = async (slug: string, setError: Dispatch<any>, setPage: Dispatch<Association>) => {
  const { data, error } = await promiseWrapper(associationPagesServices.getAssociation(slug));

  const validated = associationSchema.safeParse(data);

  if (!validated.success) {
    captureException('fetch association page error ', error ?? validated.error);
    return setError(true);
  }

  setPage(validated.data);
};
