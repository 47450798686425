import { Button } from '@/components/elements/forms/buttons';
import Icon from '@/components/icons/Icon';
import { Lightbox } from '@/components/modules/lightbox';
import { getImagePreview } from '@/helpers';
import withMobileView from '@/hoc/withMobileView';
import useMobileView from '@/hooks/useMobileView';
import { __, _s } from '@/locale';
import { useState } from 'react';

const baseTranslationKey = 'features.ProfilePages.components.Photos';

type PhotosProps = {
  photos: string[];
  place: any;
};

const Photos = ({ photos, place }: PhotosProps) => {
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState<boolean>(false);
  const { isMobileView } = useMobileView();

  const openLightbox = (index: number) => {
    setCurrentImage(index);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setLightboxIsOpen(false);
  };

  const gotoPrevious = () => {
    setCurrentImage((prev) => Math.max(prev - 1, 0));
  };

  const gotoNext = () => {
    setCurrentImage((prev) => (prev + 1) % photos.length);
  };

  const gotoImage = (index: number) => {
    setCurrentImage(index);
  };

  const getLightbox = () => {
    const lightboxPhotos = photos.map((url) => ({ src: url }));
    return (
      <Lightbox
        images={lightboxPhotos}
        onClose={closeLightbox}
        backdropClosesModal
        onClickPrev={gotoPrevious}
        onClickNext={gotoNext}
        currentImage={currentImage}
        isOpen={lightboxIsOpen}
        showThumbnails
        onClickThumbnail={gotoImage}
        imageCountSeparator={__('of')}
      />
    );
  };

  return (
    <>
      <div id="photos" className="gap-md flex">
        <div
          key={0}
          className={`flex-2 relative flex ${
            isMobileView ? 'h-[265px]' : 'h-[482px]'
          } min-w-[200px] flex-1 cursor-pointer items-center justify-center overflow-hidden rounded-lg bg-[#999] `}
          onClick={() => openLightbox(0)}>
          <img
            loading="lazy"
            src={getImagePreview(photos[0], '512x512', place.about.slug + '-' + 1)}
            className="h-full w-full object-cover"
            height="512px"
            width="512px"
            alt=""
            onError={(e) => (e.currentTarget.src = '/images/bd-fallback-img200x200.png')}
          />
          {photos.length > 1 && isMobileView && (
            <Button
              className="right-xs bottom-xs absolute"
              variant="secondary"
              iconNoFilter
              rightIcon={<Icon variant="image" color="fg_primary" />}
              size="sm"
              label={_s(`${baseTranslationKey}.cta`)}
            />
          )}
        </div>
        {photos.length > 1 && !isMobileView && (
          <div className={`gap-md flex flex-1 ${photos.length <= 4 ? 'flex-col' : 'flex-wrap'}`}>
            {photos.slice(1, photos.length <= 4 ? 3 : 5).map((img, k) => {
              return (
                <div
                  key={k}
                  className={`relative flex max-h-[233px] min-w-[200px] flex-1 cursor-pointer items-center justify-center overflow-hidden rounded-lg bg-[#999]`}
                  onClick={() => openLightbox(k)}>
                  <img
                    loading="lazy"
                    src={getImagePreview(img, '248x248', place.about.slug + '-' + (k + 1))}
                    height="248px"
                    width="248px"
                    className="h-full w-full object-cover"
                    alt=""
                    onError={(e) => (e.currentTarget.src = '/images/bd-fallback-img200x200.png')}
                  />
                  {((photos.length <= 4 && k === 1) || (photos.length > 4 && k === 3)) && (
                    <Button
                      className="right-xs bottom-xs absolute"
                      variant="secondary"
                      iconNoFilter
                      rightIcon={<Icon variant="image" color="fg_primary" />}
                      size="sm"
                      label={_s(`${baseTranslationKey}.cta`)}
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}

        {lightboxIsOpen && getLightbox()}
      </div>
    </>
  );
};

export default withMobileView(Photos);
