import { useContext } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import { CloseIconThin } from '@/components/icons';
import defaults from '../theme';
import deepMerge from '../utils/deepMerge';
import { ThemeContext } from '../Lightbox'; 

function Header({ customControls, onClose, showCloseButton, closeButtonTitle, ...props }) {
  const theme = useContext(ThemeContext);
  const classes = StyleSheet.create(deepMerge(defaultStyles, theme));
  return (
    <div className={css(classes.header)} {...props}>
      {customControls ? customControls : <span />}
      {!!showCloseButton && (
        <button
          aria-label={closeButtonTitle}
          title={closeButtonTitle}
          className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center p-2 outline-none md:h-[100px] md:w-[100px]"
          onClick={onClose}>
          <CloseIconThin className="h-6 w-6 text-white" />
        </button>
      )}
    </div>
  );
}

Header.propTypes = {
  customControls: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool,
};

const defaultStyles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: defaults.header.height,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    margin: 0,
  },
};

export default Header;
