import { LinkButton } from '@/components/elements/forms/buttons';
import Icon from '@/components/icons/Icon';
import { isServer, trackMpEvent } from '@/helpers';
import { __ } from '@/locale';
import React from 'react';

export default class GiftCardButton extends React.Component {
  handleClick = (e) => {
    trackMpEvent('buy_gift_card_clicked', {
      screen_name: 'company_details',
      company_id: this.props.place ? this.props.place.id : undefined,
      trigger_source: 'company_details',
    });
  };

  render() {
    const { place, block } = this.props;

    if (
      isServer ||
      !(
        place &&
        place.about &&
        place.about.settings &&
        (place.about.settings.sellsGiftCard || place.about.settings.sellsValueCard)
      )
    ) {
      return null;
    }

    let url = `/places/${place.about.slug}-${place.id}`;
    let label = __('buy') + ' ';

    let slash = '';
    if (place.about.settings.sellsGiftCard) {
      label += __('giftcardLabel');
      slash = ' / ';
    }

    if (place.about.settings.sellsValueCard) {
      url += '/valuecards/buy';
      label += slash + __('valuecardLabel');
    } else {
      url += '/giftcard/checkout';
    }

    return (
      <LinkButton
        variant="primary"
        to={url}
        onClick={this.handleClick}
        size="sm"
        block={block}
        leftIcon={<Icon variant="gift" noFilter className="!max-w-[20px]" />}>
        {label}
      </LinkButton>
    );
  }
}
