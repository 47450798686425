import { isSistaminuten } from '@/helpers';
import { _s } from '@/locale';
import { BottomNavBarItem, NavItemIdentifier } from '@/types/navigation';

const tBase = (key: string) => _s(`constants.bottomTabBarNavigation.${key}`);

const searchItem: BottomNavBarItem = {
  id: 'tab-nav-search',
  icon: 'search',
  label: tBase('search'),
  to: '/vad/hela_sverige',
  identifier: NavItemIdentifier.Search,
};

const bookingsItem: BottomNavBarItem = {
  id: 'tab-nav-bookings',
  icon: 'calendar',
  label: tBase('bookings'),
  to: '/bokningar',
  identifier: NavItemIdentifier.Bookings,
};

const sistaminutenItem: BottomNavBarItem = {
  id: 'tab-nav-sistaminuten',
  icon: 'smt',
  label: tBase('sistaminuten'),
  to: '/sistaminuten',
  identifier: NavItemIdentifier.Sistaminuten,
};

const dealsItem: BottomNavBarItem = {
  id: 'tab-nav-deals',
  icon: 'deal',
  label: tBase('deals'),
  to: '/deals',
  identifier: NavItemIdentifier.Deals,
};

const favoritesItem: BottomNavBarItem = {
  id: 'tab-nav-favourites',
  icon: 'favorite',
  label: tBase('favorites'),
  to: '/favoriter',
  identifier: NavItemIdentifier.Favorites,
};

const moreItem: BottomNavBarItem = {
  id: 'tab-nav-more',
  icon: 'more',
  label: tBase('more'),
  identifier: NavItemIdentifier.More,
};

const homeItem: BottomNavBarItem = {
  id: 'tab-nav-home',
  icon: 'home',
  label: tBase('home'),
  to: '/',
  identifier: NavItemIdentifier.Home,
};

const bottomNavBarNavigationItemsSistaminuten: BottomNavBarItem[] = [homeItem, searchItem, moreItem];
const bottomNavBarNavigationItemsBokadirekt: BottomNavBarItem[] = [
  searchItem,
  bookingsItem,
  sistaminutenItem,
  dealsItem,
  favoritesItem,
  moreItem,
];

export const bottomNavBarNavigationItems = isSistaminuten()
  ? bottomNavBarNavigationItemsSistaminuten
  : bottomNavBarNavigationItemsBokadirekt;
