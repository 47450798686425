import React, { useEffect, useRef, useState } from 'react';

type TextAreaProps = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  rows?: number;
  cols?: number;
  disabled?: boolean;
};

const TextArea = ({ value, onChange, placeholder, rows = 4, cols = 50, disabled }: TextAreaProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current && textAreaRef.current.value) {
      setIsFocused(true);
    }
  }, []);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (!textAreaRef.current?.value) {
      setIsFocused(false);
    }
  };

  return (
    <div className="relative w-full">
      <textarea
        id="textarea"
        ref={textAreaRef}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        rows={rows}
        disabled={disabled}
        className={`${
          disabled ? 'border-border_disabled' : 'border-border_regular'
        }  pb-xs pt-xl px-sm  placeholder:text-l w-full rounded-sm border outline-none`}
      />

      {placeholder && (
        <label
          htmlFor="textarea"
          className={`${
            disabled ? 'text-fg_disabled' : 'text-fg_secondary cursor-text'
          } left-sm top-sm absolute transition-all duration-200 ease-in-out ${
            isFocused || value ? 'text-xs' : 'text-l'
          }`}
          style={{
            transform: isFocused || value ? 'translateY(0) translateX(0)' : 'translateY(0) translateX(0)',
          }}>
          {placeholder}
        </label>
      )}
    </div>
  );
};

export default TextArea;
