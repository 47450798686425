import { setBookingStartingPoint } from '@/helpers';
import { useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { getSuggestionsFromSearchValue } from './KeywordAutoSuggest.helpers';
import {
  KeywordAutoSuggestAction,
  KeywordAutoSuggestManagerProps,
  KeywordAutoSuggestState,
} from './KeywordAutoSuggest.type';

const keywordAutoSuggestReducer = (
  state: KeywordAutoSuggestState,
  action: KeywordAutoSuggestAction,
): KeywordAutoSuggestState => {
  switch (action.type) {
    case 'ON_INPUT_CHANGE': {
      return { ...state, keyword: action.payload };
    }

    case 'ON_SUGGESTION_LOADED': {
      return { ...state, ...action.payload };
    }

    case 'SET_SHOW_SUGGESTIONS': {
      return { ...state, showSuggestions: action.payload };
    }
    default:
      return state;
  }
};

const useKeywordAutoSuggestManager = ({ initialState, onSelectKeywordSuggestion }: KeywordAutoSuggestManagerProps) => {
  const [state, dispatch] = useReducer(keywordAutoSuggestReducer, initialState);
  const history = useHistory();

  const handleOnChange = (_, { newValue: payload = '' }) => {
    dispatch({ type: 'ON_INPUT_CHANGE', payload });
  };

  const handleOnFocus = () => {
    dispatch({ type: 'SET_SHOW_SUGGESTIONS', payload: true });
  };
  const handleOnBlur = (_, { highlightedSuggestion }) => {};
  const handleOnKeyDown = (event: React.KeyboardEvent) => {};

  const handleOnSuggestionsFetchRequested = async ({ value, reason }) => {
    if (reason !== 'input-focused' && reason !== 'input-changed') {
      return;
    }

    const { newServices, suggestions } = await getSuggestionsFromSearchValue(value);
    dispatch({ type: 'ON_SUGGESTION_LOADED', payload: { suggestions, newServices } });
  };

  const handleOnSuggestionsClearRequested = (props) => {
    dispatch({ type: 'ON_SUGGESTION_LOADED', payload: { suggestions: [], newServices: [] } });
  };

  const handleOnSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    dispatch({ type: 'SET_SHOW_SUGGESTIONS', payload: false });

    if (suggestion.type === 'place') {
      const { placeId, slug } = suggestion;
      setBookingStartingPoint('search_autocomplete');
      history.push(`/places/${slug}-${placeId}`);
      return;
    }

    onSelectKeywordSuggestion(suggestionValue?.text?.replace('%', '') ?? suggestion.text ?? '');
  };

  return {
    keyword: state.keyword ?? '',
    suggestions: state.suggestions,
    showSuggestions: state.showSuggestions,
    onChange: handleOnChange,
    onBlur: handleOnBlur,
    onFocus: handleOnFocus,
    onKeyDown: handleOnKeyDown,
    onSuggestionsFetchRequested: handleOnSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleOnSuggestionsClearRequested,
    onSuggestionSelected: handleOnSuggestionSelected,
  };
};

export default useKeywordAutoSuggestManager;
