import { Button, Fab } from '@/components/elements/forms/buttons';
import Popover from '@/components/elements/Popover';
import { LoadingIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import { ModalHeader } from '@/components/modules/mobile';
import { ModalContent, ModalDialog } from '@/components/modules/modals';
import { ReviewCard, ReviewStats } from '@/components/modules/review';
import { isServer, server } from '@/helpers';
import { __ } from '@/locale';
import { Fragment, useEffect, useState } from 'react';

const theStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 10050,
    overflow: 'scroll',
    outline: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
};

export function ReviewsHeader() {
  const supportsPopover = !isServer && HTMLElement.prototype.hasOwnProperty('popover');
  const [popoverVisible, setPopoverVisible] = useState(false);
  const togglePopover = () => {
    setPopoverVisible(!popoverVisible);
  };

  return (
    <Fragment>
      <div className="mb-2 flex items-center">
        <h2 className="flex-1 text-2xl font-semibold">{__('reviewsTitle')}</h2>
        <button
          id="reviews-info"
          popovertarget="reviews-info-popover"
          className="trigger-reviews-info-popover focus-visible:outline-black-900 rounded-sm focus:outline-none focus-visible:outline"
          onClick={supportsPopover ? undefined : togglePopover}>
          <Icon variant="info-circle" color="information-700" size="md" />
        </button>
      </div>

      {(supportsPopover || (!supportsPopover && popoverVisible)) && (
        <Popover id="reviews-info-popover" className={`${!supportsPopover ? 'z-[100]' : ''}`}>
          <div className="space-y-md bg-black-900 p-lg w-full max-w-[400px] rounded-lg">
            <div className="flex items-start justify-between">
              <span className="max-w-[200px] text-lg font-bold text-white">{__('leaveReviewPopover.title')}</span>
            </div>
            <p className="text-black-300" id="popover-content">
              {__('leaveReviewPopover.body')}
            </p>
          </div>
        </Popover>
      )}
    </Fragment>
  );
}

function Reviews(props) {
  const { place, hideStaff = false, isMpReviews } = props;
  const {
    reviews: { topReviews: { items: topReviews = [] } = {}, stats, ratingCounts, reviewCount = 0, reviewCounts },
    about: { settings: { subscriptionType } } = {},
  } = place;
  const [showModal, setShowModal] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [nextReviewPageData, setNextReviewPageData] = useState({});
  const [haveMoreReviews, setHaveMoreReviews] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [ratingFilter, setRatingFilter] = useState(0);

  useEffect(() => {
    fetchReviews();
  }, [ratingFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  function fetchReviewsByRating(rating = 0) {
    setRatingFilter(rating);
    if (rating !== ratingFilter) {
      setReviews([]);
      setNextReviewPageData({});
      setHaveMoreReviews(true);
    }
    setShowModal(true);
  }

  async function fetchReviews() {
    if (!haveMoreReviews) {
      return;
    }
    setIsLoading(true);
    const result = await server.request
      .get('/places/getReviews/' + place.id, {
        params: {
          page: nextReviewPageData.page,
          limit: nextReviewPageData.limit,
          'mp-reviews': isMpReviews,
          rating: ratingFilter,
        },
      })
      .then(server.handleSuccess)
      .catch(server.handleError);
    setReviews(reviews.concat(result.items));
    setNextReviewPageData(result.nextPage || {});
    setHaveMoreReviews(
      result.nextPage && result.nextPage && result.nextPage.limit && result.nextPage.limit > 0 ? true : false,
    );
    setIsLoading(false);
  }

  return (
    <div id="reviews" className="relative scroll-mt-[120px]">
      <ReviewsHeader {...props} />
      <div>
        <ReviewStats
          stats={stats}
          ratingCounts={ratingCounts}
          reviewCounts={reviewCounts}
          onRatingClick={fetchReviewsByRating}
        />
        {topReviews.length > 0 && (
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            {topReviews.map((review, key) => (
              <ReviewCard
                review={review}
                key={key}
                hideStaff={hideStaff}
                showReplies={subscriptionType !== 'essential'}
              />
            ))}
          </div>
        )}
        {reviewCount > 4 && (
          <div className="flex justify-center pt-6">
            <span
              className="text-link text-md cursor-pointer"
              onClick={() => {
                fetchReviewsByRating(0);
              }}>
              {__('showAllReviews')}
            </span>
          </div>
        )}
        {!isServer && (
          <ModalDialog
            isOpen={showModal}
            appElement={document.getElementById('root')}
            onRequestClose={() => setShowModal(false)}
            style={theStyle}>
            <ModalContent size="lg">
              <div className="sm:hidden">
                <ModalHeader
                  action={() => setShowModal(false)}
                  cancel
                  top={
                    <span className="pl-2 font-semibold">
                      {place.about && place.about.name ? place.about.name : ''}
                    </span>
                  }
                />
              </div>
              <div className="hidden w-full justify-end sm:flex">
                <span className="mr-3 mt-3">
                  <Fab icon={<Icon variant="close" />} onClick={() => setShowModal(false)} size="md" variant="link" />
                </span>
              </div>
              <div className="px-4 py-8 sm:py-0">
                <h2 className="text-lg font-semibold">{__('reviewsTitle')}</h2>
                <ReviewStats
                  stats={stats}
                  ratingCounts={ratingCounts}
                  reviewCounts={reviewCounts}
                  onRatingClick={fetchReviewsByRating}
                  ratingFilter={ratingFilter}
                />
                <div className="mb-6 flex flex-row flex-wrap items-stretch justify-between gap-4">
                  {reviews.map((review, key) => (
                    <ReviewCard
                      review={review}
                      key={key}
                      full
                      hideStaff={hideStaff}
                      showReplies={subscriptionType !== 'essential'}
                    />
                  ))}
                  {isLoading && (
                    <div className="flex w-full justify-center">
                      <LoadingIcon className="h-8 w-8" />
                    </div>
                  )}
                  {reviews.length > 0 && haveMoreReviews && (
                    <div className="mx-auto">
                      <Button variant="primary" size="sm" onClick={fetchReviews}>
                        {__('showMore')}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </ModalContent>
          </ModalDialog>
        )}
      </div>
    </div>
  );
}

export default Reviews;
