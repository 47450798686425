import { _s } from '@/locale';
import moment from 'moment';

export const DATE_FORMAT = 'YYYY-MM-DD';

export function getDateTimeFilterLabel(
  startDate = moment().format(DATE_FORMAT),
  endDate = moment().add(3, 'weeks').format(DATE_FORMAT),
  timeOfDay,
) {
  let dateLabel = _s('anyDate');

  if (startDate && endDate) {
    const startDateObject = moment.utc(startDate);
    const endDateObject = moment.utc(endDate);
    const startDateMonthLabel = _s(startDateObject.format('MMM').replace('.', ''));
    const endDateMonthLabel = _s(endDateObject.format('MMM').replace('.', ''));
    const isSameMonth = startDateMonthLabel === endDateMonthLabel;
    const startDateLabel = `${startDateObject.format('D')}${isSameMonth ? '' : ` ${startDateMonthLabel}`}`;
    const endDateLabel = `${endDateObject.format('D')} ${endDateMonthLabel}, ${endDateObject.format('YYYY')}`;
    dateLabel = `${startDateLabel} - ${endDateLabel}`;
  }

  return `${dateLabel}${timeOfDay ? ` - ${_s(`timeOfDay_${timeOfDay}`)}` : ''}`;
}
