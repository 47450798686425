import { APPOINTMENT_STATUS, statuses } from '@/constants/appointmentConstants';
import { _s } from '@/locale';
import { ConfirmedBooking } from '@/types/api/services/booking';
import { formattedDateTimeFromTimestamp } from './checkout';
import { hasNotPasted } from './date';
import { bookingsAllowedToBeCancelled } from './employee';
import { isSistaminuten as isSistaminutenInstance } from './general';
import { getPlaceTimezone } from './placeHelper';

export function isBookingInThePast(appointment: ConfirmedBooking) {
  return !hasNotPasted(appointment.start * 1000, getPlaceTimezone(appointment.place));
}

export function getEmployee(appointment: ConfirmedBooking, fromBookAgain: boolean = false) {
  return appointment.extra?.anyEmployee || (fromBookAgain && appointment.extra?.anyEmployeeSelected)
    ? { id: 0, about: { name: _s('anyEmployee'), avatar: '' } }
    : appointment.services.length > 0 && appointment.services[0].employee && appointment.services[0].employee.id
    ? appointment.services[0].employee
    : { id: 0, about: { name: _s('anyEmployee'), avatar: '' } };
}

export function getStatus(code: number, past: boolean) {
  let to = code;
  if (past) {
    to = code + 100;
  }
  if (statuses[to]) {
    return statuses[to];
  }
  return { className: '', label: '', tracking: '' };
}

export function getCanCancelAppointmentProps(appointment: ConfirmedBooking): {
  canCancel: boolean;
  cancelInformation?: string;
} {
  let canCancel = false;
  let cancelInformation = undefined;

  if (appointment.status !== APPOINTMENT_STATUS.CONFIRMED) {
    return { canCancel, cancelInformation };
  }

  const hasCancelTime = appointment.cancelMargin || 0;
  const potentialEmployee = appointment?.services[0]?.employee || {};
  const allowCancelBooking = bookingsAllowedToBeCancelled(potentialEmployee);
  const hasCancelMargin = appointment.cancelMargin;

  const isSistaminuten = isSistaminutenInstance() || Boolean(appointment.extra?.isSistaminutentiderBooking);
  const placeInactivated = appointment.place?.about?.active !== true;

  if (isSistaminuten || placeInactivated || !allowCancelBooking || !hasCancelTime || !hasCancelMargin) {
    return { canCancel, cancelInformation };
  }

  const timezone = getPlaceTimezone(appointment.place);
  const deadline = appointment.start * 1000 - hasCancelTime * 60 * 1000;
  canCancel = hasNotPasted(deadline, timezone);

  cancelInformation = (() => {
    if (canCancel) {
      const formattedCancelTime = formattedDateTimeFromTimestamp(deadline);
      return _s('cancelBefore', { time: formattedCancelTime });
    }

    const minutes = hasCancelTime;

    if (!minutes) {
      return _s('cannotCancelOnline');
    }

    let cancelString = '';
    if (minutes < 60) {
      cancelString = _s('minutes', { count: minutes });
    } else {
      cancelString = _s('hours', { count: minutes / 60 });
      if (minutes % 60 !== 0) {
        cancelString += ' ' + _s('minutes', { count: minutes % 60 });
      }
    }
    return _s('cancelAppointmentNotAllowed', { var: cancelString });
  })();

  return { canCancel, cancelInformation };
}

export function getIsBookable(appointment: ConfirmedBooking): boolean {
  const placeInactivated = appointment.place?.about?.active !== true;
  if (placeInactivated) {
    return false;
  }
  let isBookable = true;
  appointment.services.forEach((data) => {
    const { service, employee } = data;
    if (
      !(
        service &&
        service.extra &&
        (!service.extra.inactive || (service.extra.inactive && service.about?.settings?.isAddOn)) &&
        employee &&
        employee.about &&
        !employee.about.inactive &&
        employee.about.isBookable
      )
    ) {
      isBookable = false;
    }
  });
  return isBookable;
}
