import { LinkButton } from '@/components/elements/forms/buttons';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import SEO from '@/components/modules/SEO';
import { config } from '@/config';
import { isServer, trackPage } from '@/helpers';
import { __ } from '@/locale';
import React from 'react';

class NotFound extends React.Component {
  componentDidMount() {
    trackPage();
  }

  UNSAFE_componentWillMount() {
    if (isServer && this.props.staticContext) {
      // this is only available when rendering on the server
      this.props.staticContext.status = 404;
    }
  }

  render() {
    let toDetails = '';
    if (!isServer) {
      document.body.classList.add('landing');
      const isLanding = localStorage.getItem('isLanding') || false;
      const slug = localStorage.getItem('slug') || 'slug-name';
      toDetails = isLanding
        ? '/' + (isLanding.indexOf('-') !== -1 ? config.landingPath : config.portalPath) + '/' + slug + '/' + isLanding
        : '/';
    }

    return (
      <PageViewLayout type="mainView">
        <div className="bg-notfound-page-cover min-h-[800px] bg-center bg-no-repeat">
          <div className="container">
            <h1 className="text-black-900 md:text-black-600 mb-4 pt-10 text-6xl font-semibold md:pt-52 md:text-6xl">
              Oops!
            </h1>
            <p className="text-black-900 md:text-black-600 mb-4 text-sm font-semibold">{__('pageNotFound')}</p>
            <p className="text-black-900 md:text-black-600 mb-4 text-sm font-light">{__('errorCode')}</p>
            <LinkButton variant="primary" className="mt-1" to={toDetails}>
              {__('goHome')}
            </LinkButton>
          </div>
        </div>
        <SEO title={__('seo.404Title')} description={__('seo.404Description')} />
      </PageViewLayout>
    );
  }
}

export default NotFound;
