/* eslint-disable */
import { Card } from '@/components/elements/cards';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import SEO from '@/components/modules/SEO';
import Breadcrumbs from '@/components/modules/breadcrumbs/Breadcrumbs';
import Navigation from '@/components/modules/pages/support/Navigation';
import { RandomSupportFaq } from '@/components/modules/pages/support/RandomSupportFaq';
import { supportFaq } from '@/constants/faq';
import { isMobile, isServer, isSistaminuten, trackPage, url } from '@/helpers';
import { __, _s } from '@/locale';
import '@/styles/pages/articles/_support.scss';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Support = () => {
  useEffect(() => {
    if (isServer) return;
    window.scrollTo(0, 0);
    trackPage();
    // trackMpEvent('screen_shown', { screen_name: 'support' });
    sessionStorage.removeItem('bd_sf');
  }, []);

  return (
    <PageViewLayout title="" type="mainView" wrapperClass="bg-gradient">
      <Breadcrumbs
        className="hidden px-4 py-2 text-sm sm:block sm:px-10"
        items={[
          { title: __('Start'), url: '/' },
          { title: __('support'), url: '/support' },
        ]}
      />
      <div
        className="h-[170px] w-auto bg-cover bg-center bg-no-repeat lg:h-[300px]"
        style={{ backgroundImage: `url(/images/hero-support.jpg)` }}>
        <div className="container mx-auto flex h-full items-center justify-center">
          <h1 className="text-lg font-bold uppercase text-white lg:text-5xl">{_s('support')}</h1>
        </div>
      </div>
      <div className="bg-gradient pb-12">
        <div className="container pt-6">
          <div>
            <h3 className="mb-1 text-lg font-bold lg:text-4xl">
              {_s(isMobile() ? 'supportPage.helpCategories' : 'supportPage.faqLong')}
            </h3>
            <span className="text-black-600 text-sm lg:text-2xl">{_s('supportPage.regardingYourCase')}</span>
          </div>
          <div className="hidden flex-wrap sm:-mx-2 sm:flex lg:-mx-4">
            {supportFaq.map((section, key) => (
              <div key={key} className="flex flex-1 basis-full sm:basis-1/2 sm:p-2 md:basis-1/3 lg:p-4">
                <Card
                  style={{ margin: 0 }}
                  className="text-black-900 tracking-content flex flex-1 flex-col bg-white text-base">
                  <h3 className="splitter flex items-center justify-start gap-x-4 text-2xl tracking-normal">
                    <section.icon className="h-5" />
                    {section.title}
                  </h3>
                  {section.questions.slice(0, 3).map((q, i) => {
                    return (
                      <Link
                        to={{ pathname: section.path, state: { active: q.id } }}
                        className="text-black-900 mb-4"
                        key={i}>
                        {q.question}
                      </Link>
                    );
                  })}
                  <div className="h-8"></div>
                  <Link className="text-black-900 mt-auto text-lg font-bold" to={section.path}>
                    {__('supportPage.viewAll')}
                  </Link>
                </Card>
              </div>
            ))}
          </div>
          <div className="mt-6 sm:hidden">
            <Navigation />
          </div>
          <div className="mb-10 mt-6 hidden h-[1px] bg-white sm:block"></div>
          <RandomSupportFaq />
        </div>
      </div>
      <SEO
        title={__('seo.helpTitle')}
        description={__('seo.helpDescription')}
        url={`${url.getBaseUrl()}support`}
        image={url.getBaseImageUrl()}>
        {isSistaminuten() && <meta name="robots" content="noindex" />}
      </SEO>
    </PageViewLayout>
  );
};

export default Support;
