import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { cofPaymentDataSchema } from '@/types/adyen';
import { z } from 'zod';
import { adyenPaymentMethodSchema } from '../adyen';

export const checkDiscountCodeResponseSchema = z.object({
  code: z.string(),
  discount: z.number(),
});

export type CheckDiscountCodeResponse = z.infer<typeof checkDiscountCodeResponseSchema>;

const giftcardProductTypeSchema = z.union([
  z.literal('giftcard'),
  z.literal('wellnesscard'),
  z.literal('placecard'),
  z.literal('valuecard'),
]);

export const baseCreateGiftcardSchema = z.object({
  guestInformation: z
    .object({
      email: z.string(),
      buyerFirstname: z.string(),
      buyerLastname: z.string(),
    })
    .optional(),
  amount: z.number(),
  quantity: z.number(),
  vatRate: z.union([z.literal(0), z.literal(600), z.literal(2500)]).optional(),
  serviceType: z.union([z.literal('massage'), z.literal('yoga'), z.literal('pt')]).optional(),
  discountCode: z.string().optional(),
});

export const physicalCreateGiftcardSchema = baseCreateGiftcardSchema.extend({
  address: z
    .object({
      name: z.string(),
      street: z.string(),
      city: z.string(),
      zip: z.string(),
      apartmentNumber: z.number().optional(),
    })
    .optional(),
  deliveryType: z.literal('physical'),
  personalGreeting: z
    .object({
      recipientName: z.string().optional(),
      message: z.string().optional(),
      giverName: z.string().optional(),
    })
    .optional(),
  productType: giftcardProductTypeSchema,
});

const owner = z.object({
  ownerName: z.string(),
  ownerType: z.union([z.literal('Portal'), z.literal('Person'), z.literal('Location')]),
  ownerId: z.string(),
});

const employeeValueCard = z.object({
  id: z.number(),
  name: z.string(),
  price: z.number(),
  value: z.number(),
  daysOfValidity: z.number(),
  description: z.string().optional(),
});

export type EmployeeValueCard = z.infer<typeof employeeValueCard>;

const employee = z.object({
  confirmationEmail: z.string(),
  id: z.string(),
  name: z.string(),
  owners: z.array(owner),
  ownerCompany: z.string(),
  timeZone: z.string(),
  valueCards: z.array(employeeValueCard).optional(),
});

export type Employee = z.infer<typeof employee>;

export const digitalCreateGiftcardSchema = baseCreateGiftcardSchema.extend({
  ssn: z.string().optional(),
  employee: employee.optional(),
  deliveryType: z.literal('digital'),
  productType: giftcardProductTypeSchema,
  mpPlaceId: z.number().optional(),
});

export const giftcardOrderRequestSchema = z.union([digitalCreateGiftcardSchema, physicalCreateGiftcardSchema]);

export type GiftcardOrderRequest = z.infer<typeof giftcardOrderRequestSchema>;

export const adyenDigitalCreateGiftcardSchema = digitalCreateGiftcardSchema.extend({
  guestId: z.string().optional(),
  paymentMethod: adyenPaymentMethodSchema,
  paymentData: cofPaymentDataSchema,
});

export const adyenPhysicalCreateGiftcardSchema = physicalCreateGiftcardSchema.extend({
  guestId: z.string().optional(),
  paymentMethod: adyenPaymentMethodSchema,
  paymentData: cofPaymentDataSchema,
});

export const adyenGiftcardOrderRequestSchema = z.union([
  adyenDigitalCreateGiftcardSchema,
  adyenPhysicalCreateGiftcardSchema,
]);

export type AdyenGiftcardOrderRequest = z.infer<typeof adyenGiftcardOrderRequestSchema>;

const swishDigitalCreateGiftcardSchema = digitalCreateGiftcardSchema.extend({
  placeSlug: z.string(),
});

const swishPhysicalCreateGiftcardSchema = physicalCreateGiftcardSchema.extend({
  placeSlug: z.string(),
});

export const swishGiftcardOrderRequestSchema = z.union([
  swishDigitalCreateGiftcardSchema,
  swishPhysicalCreateGiftcardSchema,
]);

export type SwishGiftcardOrderRequest = z.infer<typeof swishGiftcardOrderRequestSchema>;

const qliroDigitalCreateGiftcardSchema = digitalCreateGiftcardSchema.extend({ successPath: z.string() });
const qliroPhysicalCreateGiftcardSchema = physicalCreateGiftcardSchema.extend({ successPath: z.string() });

export const qliroGiftcardOrderRequestSchema = z.union([
  qliroDigitalCreateGiftcardSchema,
  qliroPhysicalCreateGiftcardSchema,
]);

export type QliroGiftcardOrderRequest = z.infer<typeof qliroGiftcardOrderRequestSchema>;

export const PRODUCT_TYPE = {
  UGC: 1,
  UGCW: 2,
  EGC: 3,
  VCEGC: 4,
} as const;

export const orderSchema = z.object({
  email: z.string(),
  price: z.string(),
  quantity: z.number(),
  paymentMethod: z.union([
    z.literal(CHECKOUT_PAYMENT_METHOD.KLARNA),
    z.literal(CHECKOUT_PAYMENT_METHOD.QLIRO),
    z.literal(CHECKOUT_PAYMENT_METHOD.STORED_COF),
    z.literal(CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY),
    z.literal(CHECKOUT_PAYMENT_METHOD.APPLE_PAY),
    z.literal(CHECKOUT_PAYMENT_METHOD.SWISH),
  ]),
  deliveryType: z.union([z.literal('digital'), z.literal('physical')]),
  status: z.union([z.literal(1), z.literal(2), z.literal(3)]),
  productType: z.union([
    z.literal(PRODUCT_TYPE.UGC),
    z.literal(PRODUCT_TYPE.UGCW),
    z.literal(PRODUCT_TYPE.EGC),
    z.literal(PRODUCT_TYPE.VCEGC),
  ]),
  discount: z
    .object({
      code: z.string(),
      amount: z.number(),
    })
    .optional(),
});

export type Order = z.infer<typeof orderSchema>;

export const checkDiscountCodeRequestSchema = z.object({
  code: z.string(),
  orderTotal: z.number(),
});

export type CheckDiscountCodeRequest = z.infer<typeof checkDiscountCodeRequestSchema>;

export const placeGiftcardOrderSchema = z.object({
  email: z.string(),
  price: z.number(),
  quantity: z.number(),
});

export type PlaceGiftcardOrder = z.infer<typeof placeGiftcardOrderSchema>;
