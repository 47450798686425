import { DropdownButton } from '@/components/elements/forms/DropdownButton';
import Icon from '@/components/icons/Icon';

import { useState } from 'react';
import { ViewToggleProps } from './ViewToggle.types';

const useViewToggle = <V extends string>(props: ViewToggleProps<V>) => {
  const [view, setView] = useState(props.initialState ?? props.views[0]);
  const toggleView = props.views.find((v) => v.view !== view.view);

  return {
    view: view.view,
    setView,
    render: (
      <DropdownButton
        toggleOpen={() => setView(toggleView)}
        isActive={false}
        icon={<Icon className="pointer-events-none" variant={toggleView.icon} size="xs" />}
        newDesign
        className="pl-2">
        <span className="text-m whitespace-nowrap">{toggleView.label}</span>
      </DropdownButton>
    ),
  };
};

export default useViewToggle;
