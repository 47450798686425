import { z } from 'zod';
import {
  CAMPAIGNS_SHOWN,
  SCREEN_NAME,
  SEARCH_CONTEXT,
  SEARCH_FILTERS,
  SEARCH_LOCATION_FILTER,
  SEARCH_TIME_FILTER,
  SORTED_BY,
} from '../constants/tracking';

const screenNameSchema = z.enum([
  SCREEN_NAME.SEARCH,
  SCREEN_NAME.SEARCH_RESULTS_MAP,
  SCREEN_NAME.DEALS,
  SCREEN_NAME.DEALS_MAP,
]);

export type ScreenName = z.infer<typeof screenNameSchema>;

export const searchContextSchema = z.enum([
  SEARCH_CONTEXT.SEARCH_AUTOCOMPLETE,
  SEARCH_CONTEXT.FREE_SEARCH,
  SEARCH_CONTEXT.NAVIGATION,
  SEARCH_CONTEXT.HOME_PAGE_CATEGORY,
  SEARCH_CONTEXT.HOME_PAGE_FREE_SEARCH,
  SEARCH_CONTEXT.HOME_PAGE_SEARCH_AUTOCOMPLETE,
  SEARCH_CONTEXT.FOOTER_CATEGORY,
]);

export type SearchContext = z.infer<typeof searchContextSchema>;

const searchFiltersSchema = z.array(
  z.enum([
    SEARCH_FILTERS.PAY_LATER,
    SEARCH_FILTERS.GIFT_CARD,
    SEARCH_FILTERS.WELLNESS_CARD,
    SEARCH_FILTERS.ASSOCIATIONS,
    SEARCH_FILTERS.CAMPAIGNS,
    SEARCH_FILTERS.BUNDLES,
  ]),
);

export type SearchFilters = z.infer<typeof searchFiltersSchema>;

const sortedBySchema = z.enum([
  SORTED_BY.POPULAR,
  SORTED_BY.CLOSEST,
  SORTED_BY.FIRST_AVAILABLE_TIME,
  SORTED_BY.HIGHEST_RATING,
  SORTED_BY.HIGHEST_DISCOUNT,
]);

export type SortedBy = z.infer<typeof sortedBySchema>;

const campaignsShownSchema = z.array(
  z.enum([CAMPAIGNS_SHOWN.RAKETEN, CAMPAIGNS_SHOWN.SISTAMINUTEN, CAMPAIGNS_SHOWN.DEALS]),
);

export type CampaignsShown = z.infer<typeof campaignsShownSchema>;

const searchLocationFilterSchema = z.enum([SEARCH_LOCATION_FILTER.GPS_LOCATION, SEARCH_LOCATION_FILTER.USER_INPUT]);

export type SearchLocationFilter = z.infer<typeof searchLocationFilterSchema>;

const searchTimeFilterSchema = z.enum([SEARCH_TIME_FILTER.DEFAULT, SEARCH_TIME_FILTER.USER_INPUT]);

export type SearchTimeFilter = z.infer<typeof searchTimeFilterSchema>;

const searchTrackingPropsSchema = z.object({
  screen_name: screenNameSchema,
  search_context: searchContextSchema,
  search_query: z.string().optional(),
  search_filters: searchFiltersSchema.optional(),
  search_location_filter: searchLocationFilterSchema.optional(),
  search_location_city: z.array(z.string()).optional(),
  search_time_filter: searchTimeFilterSchema.optional(),
  sorted_by: sortedBySchema.optional(),
  campaigns_shown: campaignsShownSchema.optional(),
  nbr_search_results: z.number().optional(),
});

export type TrackingProps = z.infer<typeof searchTrackingPropsSchema>;
