function isPhoneNumber(value) {
  let input = value.replace(/\s+/g, '').replace(/-+/g, '').replace(/\++/g, '');
  return input.match(/^[/\d]{9,12}?$/) !== null;
}

function formatNumber(value) {
  const num = parseFloat(value);
  return num % 1 === 0 ? num.toFixed(0) : num.toFixed(2);
}

function isZipcode(value) {
  if (!value || !value.length) return false;
  let input = value.replace(/\s+/g, '').replace('-', '');
  return input.match(/^\d{5}$/) !== null;
}

function isString(value) {
  return Object.prototype.toString.call(value) === '[object String]';
}

function isEmptyStr(value) {
  return isString(value) && value.length === 0;
}

function isNotEmptyStr(value) {
  return isString(value) && value.length > 0;
}

function invert(validation) {
  return (value) => !validation(value);
}

function validate(validations, errorMessage) {
  return (value) => {
    const result = validations.map((validation) => validation(value));
    return result.find((result) => result === false) !== undefined ? errorMessage : undefined;
  };
}

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

/**
 * Handles the result of a Promise by returning an object with either a `data` property
 * containing the resolved value, or an `error` property containing the reason for rejection.
 */
function promiseWrapper(promise) {
  // seems like we need a polyfill for older browser support, mainly old ios safari
  Promise.allSettled =
    Promise.allSettled ||
    ((promise) =>
      Promise.all(
        promise.map((p) =>
          p
            .then((value) => ({
              status: 'fulfilled',
              value,
            }))
            .catch((reason) => ({
              status: 'rejected',
              reason,
            })),
        ),
      ));
  return Promise.allSettled([promise]).then(([result]) => {
    const { status } = result;
    if (status === 'rejected') {
      return { data: undefined, error: result.reason };
    } else {
      return { data: result.value, error: undefined };
    }
  });
}
function isGoogleCrawler() {
  return !!window.navigator.userAgent
    .toLowerCase()
    .match(/googlebot|bot|crawler|chrome-lighthouse|google page speed insights/i);
}

export {
  escapeRegexCharacters,
  formatNumber,
  invert,
  isEmptyStr,
  isGoogleCrawler,
  isNotEmptyStr,
  isPhoneNumber,
  isZipcode,
  promiseWrapper,
  validate,
};
