import { LinkButton } from '@/components/elements/forms/buttons/Button/Button';
import { __, _s } from '@/locale';

export const FooterExternalLinks = ({ displayRow = false }: { displayRow?: boolean }) => {
  return displayRow ? (
    <div className="flex w-full flex-wrap justify-between">
      <LinkButton
        target="_blank"
        size="sm"
        variant="link"
        className="px-[0px!important] text-[#336CA8] underline"
        to="https://business.bokadirekt.se/">
        {__('footerNavigation.b2b')}
      </LinkButton>
      <a className="flex flex-wrap gap-4" href="https://bokadirekt.smart.link/cl1p2rx9j">
        <img src="/images/download-app-store.png" className="h-[40px]" alt={_s('availableAppStore')} />
        <img src="/images/download-google-play.png" className="h-[40px]" alt={_s('availablePlayStore')} />
      </a>
    </div>
  ) : (
    <div className="flex w-full flex-col gap-2 lg:max-w-[270px]">
      <p className="text-md mb-2 font-bold md:text-lg md:font-normal">{_s('footerNavigation.downloadApp')}</p>
      <p className="mb-5 text-sm">{_s('footerNavigation.downloadAppDescription')}</p>
      <a
        className="align-center justify-left mb-6 flex flex-wrap md:mb-12"
        href="https://bokadirekt.smart.link/cl1p2rx9j">
        <img src="/images/download-app-store.png" className="mr-3 h-[40px]" alt={_s('availableAppStore')} />
        <img src="/images/download-google-play.png" className="h-[40px]" alt={_s('availablePlayStore')} />
      </a>
      <p className="text-md mb-2 font-bold md:text-lg md:font-normal">{_s('footerNavigation.b2b')}</p>
      <p className="mb-5 text-sm">{_s('footerNavigation.b2bDescription')}</p>
      <div className="md:items-left flex">
        <LinkButton
          target="_blank"
          variant="primary"
          size="md"
          className="w-full lg:w-auto"
          to="https://business.bokadirekt.se/">
          {__('footerNavigation.b2b')}
        </LinkButton>
      </div>
    </div>
  );
};
