import { bookActions } from '@/actions';
import Avatar from '@/components/elements/Avatar';
import { Card } from '@/components/elements/cards';
import { Button, Fab } from '@/components/elements/forms/buttons';
import { PlusAltIcon, SuccessIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import { EmployeeBadges } from '@/components/modules/employee';
import { ReadMoreModal } from '@/components/modules/modals';
import { ServicePicker } from '@/components/modules/pages/bookService/ServicePicker';
import {
  activeUsersCount,
  allowMarketing,
  capitalizeFirstLetter,
  findEmployee,
  getEmployeesWhoPerformServices,
  getServiceCampaignIfAny,
  getServiceDuration,
  getServicePrice,
  getTotalPrice,
  isMobile,
  isSistaminuten as isSistaminutenInstance,
  performedServices,
  shouldShowFrom,
  showPrices,
  trackPageCio,
  url,
} from '@/helpers';
import { __ } from '@/locale';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class EmployeePicker extends React.Component {
  constructor(props) {
    super(props);
    this.pickEmployee = this.pickEmployee.bind(this);
    this.state = { showSelect: false };

    if (props.employee && props.place) {
      this.state.employee = findEmployee(props.employee, props.place.employees);
    }
  }

  checkEmployeeList(employees) {
    const active = activeUsersCount(employees, this.props);
    return active.employeeCount;
  }

  pickEmployee(id, silent = false) {
    const { place, dispatch } = this.props;
    const selectedEmployee = findEmployee(id, place.employees);

    this.setState({ employee: selectedEmployee });
    if (id && selectedEmployee && selectedEmployee.about) {
      dispatch(bookActions.pickEmployee(id, selectedEmployee.about.priceListId || 0));
      this.goToAppointment();
    } else {
      dispatch(bookActions.removeEmployee());
      this.setState({ employee: undefined });
    }
  }

  goToAppointment = (e) => {
    if (this.props.callback) {
      this.props.callback();
    }
  };

  getEmployeeRow(employee, callback, skip, last) {
    const { noButtons, place } = this.props;

    const checked = this.state.employee && employee.id === this.state.employee.id;
    const isSistaminuten = isSistaminutenInstance() || Boolean(place.lastMinuteDiscount);

    return (
      <div
        className="border-black-100 w-full border-b-[1px] last:border-b-0"
        key={employee.id}
        data-id={employee.id}
        onClick={callback}>
        <div className={`my-1 flex items-start rounded-lg border-none p-3 ${checked ? 'bg-primary-50' : ''}`}>
          <div className="mr-2">
            <Avatar src={employee.about.avatar} alt="avatar" size="60" />
          </div>
          <div className="my-auto flex w-full flex-row pl-3 pr-2">
            <div className="flex flex-1 flex-col">
              <span className="font-semibold">{employee.about.name}</span>
              <span className="text-black-600 text-sm">{employee.about.jobTitle}</span>
              <div className="mt-1 flex items-center gap-1">
                <EmployeeBadges employee={employee} isSistaminuten={isSistaminuten} />
              </div>
            </div>
            <div className={`flex items-center ${checked ? '' : 'invisible'}`}>
              <SuccessIcon className="text-primary-500 h-5 w-5" />
            </div>
          </div>
          {!noButtons && <Button variant="secondary">{__('select')}</Button>}
        </div>
      </div>
    );
  }

  isPerfomingServices(employee) {
    const { services = [] } = this.props;
    return performedServices(services, [employee]).length;
  }

  addService = (e) => {
    const { dispatch, services } = this.props;
    const { employee } = this.state;
    const selectedEmployee = this.getEmployee(employee || {});

    if (selectedEmployee) {
      dispatch(bookActions.pickEmployee(selectedEmployee.id, selectedEmployee.about.priceListId));
      dispatch(bookActions.keepEmployee());
    }

    this.setState({ showServices: true });
    // trackMpEvent('screen_shown', this.getTrackingProps('booking_choose_service_prompt'), false);
    if (allowMarketing(services)) {
      trackPageCio('booking_choose_service_prompt', {
        merchant_id: this.props.place?.id,
        merchant_name: this.props.place?.about?.name,
        serviceName: (services || []).map((item) => item.name).join(', '),
      });
    }
  };

  getTrackingProps(screen) {
    return {
      screen_name: screen,
      company_id: this.props.place?.id,
    };
  }

  getHeader(type = 'chooseService') {
    return (
      <div className="steps-container">
        <h2 className="text-2xl font-semibold" style={{ textAlign: 'center' }}>
          {__(type)}
        </h2>
      </div>
    );
  }

  getEmployee(employee) {
    const { place, services } = this.props;
    let selectedEmployee = null;

    const selected = place && place.employees ? place.employees.filter((theEmpl) => theEmpl.id === employee.id) : [];

    if (selected.length > 0) {
      selectedEmployee = selected[0];
    }

    if (!selectedEmployee) {
      selectedEmployee = { id: 0, about: { name: __('anyEmployee'), avatar: '', isBookable: true } };
      selectedEmployee.services = performedServices(services, place.employees);
    }

    return selectedEmployee;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.services && this.props.services && this.props.services.length !== prevProps.services.length) {
      const selectedServiceId = url.returnGetParameter('ss');
      let found = false;
      this.props.services.forEach((service) => {
        if (service.id === selectedServiceId) {
          found = true;
        }
      });
      if (!found) {
        this.props.history.replace({ search: url.changeGetParameter('ss', this.props.services[0].id) });
        // this.props.history.replace(url.changeGetParameter('ss', this.props.services[0].id));
      }
    }
  }

  removeService(serviceId) {
    const { dispatch, time, services, employee, place } = this.props;
    dispatch(bookActions.popService(serviceId, time && time.timestamp, services, employee, place));
  }

  hidePopup = (e) => {
    this.setState({ showServices: false });
  };

  render() {
    const { services, place, noButtons, campaigns } = this.props;
    const { employee = {} } = this.state;

    if (!place || !services) {
      return null;
    }

    const top = this.getHeader();
    const priceListId = employee && employee.about ? employee.about.priceListId : 0;

    const total = getTotalPrice(services, place, priceListId, {}, 0, 1, true, campaigns);
    const totalWithoutCampaigns = getTotalPrice(services, place, priceListId);
    const totalHtml =
      total === totalWithoutCampaigns ? (
        total
      ) : (
        <span className="offer-price">
          {total + ' '}
          <s>{'(ord. ' + totalWithoutCampaigns + ')'}</s>
        </span>
      );

    const isPriceVariable =
      shouldShowFrom(services, place) && total !== __('variablePrice') && total !== __('freePrice');
    const shouldShowPrices = showPrices(place, services);

    const selectedEmployee = this.getEmployee(this.state.employee || {});
    const label = place && place.about && place.about.employeesAlias ? place.about.employeesAlias : __('staff');

    const servicesIds = services.map((item) => item.id);
    const servicesList = services.map((service, i) => {
      let price = getServicePrice(service, priceListId, place);
      const { campaignService } = getServiceCampaignIfAny(service, place.campaigns, servicesIds);
      if (campaignService) {
        const offerPrice = getServicePrice(service, priceListId, place, campaignService);
        if (offerPrice && offerPrice !== price) {
          price = (
            <span className="offer-price">
              {offerPrice + ' '}
              <s>{'(ord. ' + price + ')'}</s>
            </span>
          );
        }
      }

      const serviceDuration = getServiceDuration(
        service,
        this.state.employee && this.state.employee.about ? this.state.employee.about.priceListId : null,
      );
      const name = isMobile()
        ? capitalizeFirstLetter(service.name.split('.').join('. '))
        : capitalizeFirstLetter(service.name);

      return (
        <div key={i} className="flex items-start pb-4">
          <div className="flex flex-grow flex-col pr-2">
            <span className="mb-1 block max-w-[450px] font-semibold">{name}</span>
            <span className="text-black-600 mb-1 text-sm">
              {serviceDuration}
              {price && serviceDuration && ', '}
              {price && <span className="text-black-600 text-inherit">{price}</span>}
            </span>
            {service.about && service.about.description && (
              <ReadMoreModal
                className="text-sm"
                modalProps={{ title: service.name, info: service.about.description }}
              />
            )}
          </div>
          {services.length > 1 && (
            <Fab
              icon={<Icon variant="close" />}
              onClick={() => this.removeService(service.id)}
              size="md"
              variant="link"
            />
          )}
        </div>
      );
    });

    const employeesWhoPerformServices = getEmployeesWhoPerformServices(
      place.employees,
      services.map((s) => s.id),
    );

    const employees = employeesWhoPerformServices.map((employee, i) => {
      return this.getEmployeeRow(
        employee,
        () => {
          this.pickEmployee(employee.id, false);
        },
        true,
        i === employeesWhoPerformServices.length - 1,
      );
    });

    let employeeList = (
      <div className="employees row no-gutters">
        {employees.length !== 1 && (
          <div
            onClick={() => {
              this.pickEmployee(0);
            }}
            className="border-black-100 w-full border-b-[1px] last:border-b-0">
            <div
              className={`my-1 flex items-start rounded-lg border-none p-3 ${
                !this.state.employee ? 'bg-primary-50' : ''
              }`}>
              <div className="mr-2">
                <Avatar alt="avatar" size="60" />
              </div>
              <div className="my-auto flex w-full flex-row pl-3 pr-2">
                <div className="flex flex-1 flex-col">
                  <span className="font-semibold">{__('anyEmployee')}</span>
                  <span className="text-black-600 text-sm">{__('availableAllEmployees')}</span>
                </div>
                <div className={`flex items-center ${!this.state.employee ? '' : 'invisible'}`}>
                  <SuccessIcon className="text-primary-500 h-5 w-5" />
                </div>
              </div>
              {!noButtons && <Button>{__('select')}</Button>}
            </div>
          </div>
        )}
        {employees}
      </div>
    );

    return (
      <div className="p-4">
        <Card className="!m-0">
          <span className="flex items-center pb-6" style={{ flexWrap: 'wrap' }}>
            <span className="flex-1 whitespace-nowrap pr-2 font-semibold">
              {__('service', { count: this.props.services.length })}
            </span>
            {(!this.props.employee || !this.props.employee.services || this.props.employee.services.length > 1) &&
              this.state.employee && (
                <Button
                  variant="link"
                  onClick={this.addService}
                  className="!p-0 !outline-none focus:!ring-0 "
                  icon={
                    <span className="bg-primary-50 rounded-full p-1">
                      <PlusAltIcon className="text-primary-500 h-2  w-2 " />
                    </span>
                  }>
                  {__('add-more-services')}
                </Button>
              )}
          </span>
          {servicesList}
        </Card>
        <ServicePicker
          employeeData={selectedEmployee}
          source="employee_choose_service_prompt"
          placeData={place}
          showServices={this.state.showServices}
          append={true}
          hidePopup={this.hidePopup}
          top={top}
        />
        <div className="select-employee">
          <h2 className="py-6 text-2xl font-semibold">
            {__('pick')} {label}
          </h2>
          <div id="staff" className="employee-list last-of-type:pb-16">
            {employeeList}
          </div>
        </div>
        <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow">
          <div className="space-x-sm flex justify-between">
            {shouldShowPrices && (
              <span className="flex-1">
                <p>{isPriceVariable ? __('from') : __('total')}</p>
                <span className="font-semibold">{totalHtml}</span>
              </span>
            )}
            <Button block className="max-w-[400px] basis-0" onClick={this.goToAppointment}>
              {__('continue')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { services, place, employee, campaigns } = state.book;
  const { show, loadSource } = state.loading;
  return {
    place,
    services,
    employee,
    show,
    loadSource,
    campaigns,
  };
}

export default withRouter(connect(mapStateToProps)(EmployeePicker));
