import Card from '@/components/elements/Card/Card';
import { ListItem } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import { isServer } from '@/helpers';
import { _s } from '@/locale';
import { PLACE_IMPRESSION, placeService } from '@/services';
import { useEffect, useState } from 'react';

const baseTranslationKey = 'features.ProfilePages.components.Contact';

type ContactProps = {
  email: string;
  isVerified: boolean;
  phone?: string;
  fullNumber: boolean;
  onShowFullPhoneNumber: () => void;
  onPhoneNumberClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  placeId: number;
};

const Contact = ({
  placeId,
  email,
  isVerified,
  phone,
  fullNumber,
  onShowFullPhoneNumber,
  onPhoneNumberClick,
}: ContactProps) => {
  const salesTrigger = 'https://www.bddev.se/'; // TODO
  const [emailImage, setEmailImage] = useState<string | null>(null);

  const handleCopyPhone = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  useEffect(() => {
    if (!isServer && email) {
      const x = 2;
      const y = 15;

      const canvasElement = document.getElementById('em-canvas') as HTMLCanvasElement;
      let canvasTxt = canvasElement.getContext('2d');
      canvasTxt.canvas.height = 20;
      canvasTxt.font = '18px Brown';
      canvasTxt.fillStyle = '#336CA8';
      canvasTxt.fillText(email, x, y);
      canvasTxt.beginPath();

      const textWidth = canvasTxt.measureText(email).width;
      const underlineHeight = 2;
      const padding = 5;
      canvasTxt.moveTo(x, y + padding);
      canvasTxt.lineTo(x + textWidth, y + padding);
      canvasTxt.lineWidth = underlineHeight;
      canvasTxt.strokeStyle = '#336CA8';
      canvasTxt.stroke();

      setEmailImage(canvasTxt.canvas.toDataURL());
    }
  }, []);

  const handleMailClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    placeService.storePlaceImpressions([placeId], PLACE_IMPRESSION.PLACE_EMAIL_ADDRESS_CLICK);
    setTimeout(() => {
      window.location.href = `mailto:${email}`;
    }, 200);
  };

  return (
    <Card size="lg">
      <div className="gap-xs flex flex-col">
        <h2 className="text-h-s">{_s(`${baseTranslationKey}.title`)}</h2>
        <ul>
          {!isVerified && (
            <ListItem
              leftPadding={false}
              className="gap-sm"
              underline
              leftSlot={<Icon variant="email" color="fg_secondary" />}>
              <a className="text-l text-fg_link underline" href={salesTrigger}>
                {_s(`${baseTranslationKey}.addEmail`)}
              </a>
            </ListItem>
          )}

          {Boolean(email) && isVerified && (
            <ListItem
              underline
              leftPadding={false}
              className="gap-sm"
              leftSlot={<Icon variant="email" color="fg_secondary" />}>
              <button onClick={handleMailClick}>
                <img className="w-full" alt="" src={emailImage} loading="lazy" />
              </button>
            </ListItem>
          )}

          {!isVerified && (
            <ListItem leftPadding={false} className="gap-sm" leftSlot={<Icon variant="phone" color="fg_secondary" />}>
              <a className="text-l text-fg_link underline" href={salesTrigger}>
                {_s(`${baseTranslationKey}.addPhone`)}
              </a>
            </ListItem>
          )}

          {Boolean(phone) && isVerified && (
            <ListItem
              leftPadding={false}
              leftSlot={<Icon variant="phone" color="fg_secondary" />}
              className="gap-sm"
              rightSlot={
                <button
                  className="flex outline-none"
                  onClick={() => (fullNumber ? handleCopyPhone(phone) : onShowFullPhoneNumber())}>
                  <Icon
                    variant={fullNumber ? 'copy' : 'eye-open'}
                    color="fg_secondary"
                    style={{ height: '20px', width: '20px' }}
                  />
                </button>
              }>
              <a className="text-l text-fg_link underline" href={`tel:${phone}`} onClick={onPhoneNumberClick}>
                {phone}
              </a>
            </ListItem>
          )}
          <canvas id="em-canvas" style={{ width: '100%', display: 'none' }} />
        </ul>
      </div>
    </Card>
  );
};

export default Contact;
