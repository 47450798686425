import { combineReducers } from 'redux';
import { associationPage, associationPages } from './associationPagesReducer';
import { autocomplete } from './autocompleteReducer';
import { blog } from './blogReducer';
import { book } from './bookReducer';
import { bundle } from './bundleReducer';
import { deploy } from './deployReducer';
import { featurePromos } from './featurePromoReducer';
import { flags } from './flagsReducer';
import { homepage } from './homepageReducer';
import { archive, landingPage } from './landingPageReducer';
import { listType } from './listReducer';
import { loading } from './loadingReducer';
import { modal } from './modalReducer';
import { navigationDrawer } from './navigationDrawerReducer';
import { place } from './placeReducer';
import { searchV2 } from './searchV2Reducer';
import { turnstile } from './turnstileReducer';
import { users } from './usersReducer';

export default combineReducers({
  users,
  place,
  modal,
  searchV2,
  homepage,
  archive,
  landingPage,
  book,
  loading,
  listType,
  autocomplete,
  deploy,
  blog,
  bundle,
  featurePromos,
  navigationDrawer,
  flags,
  turnstile,
  associationPage,
  associationPages,
});
