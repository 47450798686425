import Card from '@/components/elements/Card/Card';
import Icon from '@/components/icons/Icon';
import { Lightbox } from '@/components/modules/lightbox';
import { useEffect, useRef, useState } from 'react';

const InstagramFeed = (props) => {
  const { profile, media, hasCard = true } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(3);
  const carouselRef = useRef(null);

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setTimeout(() => {
      if (!carouselRef.current) return;

      const carouselWidth = carouselRef.current.offsetWidth;

      setVisibleSlides(
        carouselWidth >= 460 ? 3 :
        carouselWidth >= 300 ? 2 : 1
      );
      
      setCurrentIndex(0); // Reset index on resize to prevent incorrect offsets
      setLightboxIndex(0);
    }, 250);
  };

  const lightboxPrevious = () => {
    setLightboxIndex((prevIndex) => (prevIndex - 1 + media.length) % media.length);
    gotoPrevious();
  };

  const lightboxNext = () => {
    setLightboxIndex((prevIndex) => {
      const maxIndex = media.length - 1;
      return prevIndex < maxIndex ? prevIndex + 1 : prevIndex;
    });
    gotoNext();
  };

  const openLightbox = (index) => {
    setLightboxIndex(index);
    const maxIndex = media.length - visibleSlides;
    setCurrentIndex(index < maxIndex ? index : maxIndex);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  const gotoPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const gotoNext = () => {
    setCurrentIndex((prevIndex) => {
      const maxIndex = media.length - visibleSlides;
      return prevIndex < maxIndex ? prevIndex + 1 : prevIndex;
    });
  };

  const fallbackLinkText = (
    <>
      <Icon variant="instagram" className="inline-block h-6" alt="instagram" color="white" />
      <span className="ml-1">View on Instagram</span>
    </>
  );

  // do not render if no profile or no media
  if (!profile || !media?.length) {
    return null;
  }

  const content = (
    <>
      <h2 className={`mb-3 flex w-full items-center gap-2 text-start ${hasCard ? 'px-lg' : ''}`}>
        {hasCard && <Icon variant="instagram" className="h-6" alt="instagram" />}
        <span className="text-black-900 text-md flex-1">Instagram</span>
        <span className="truncate">@{profile}</span>
      </h2>

      <div className={`relative overflow-hidden ${hasCard ? 'mx-lg py-md' : ''}`}>
        {/* Left Arrow */}
        {media.length > visibleSlides && (
          <button
            onClick={gotoPrevious}
            className="absolute left-2 top-1/2 z-10 flex h-10 w-10 -translate-y-1/2 items-center justify-center rounded-full bg-white shadow-md focus:outline-none">
            <Icon variant="chevron-s-left" />
          </button>
        )}

        {/* Media Carousel */}
        <div
          ref={carouselRef}
          className="flex transition-transform duration-500 ease-in-out -mr-2"
          style={{
            transform: `translateX(-${currentIndex * (100 / visibleSlides)}%)`,
          }}>
          {media.map((item, index) => {
            return (
              <div
                key={item.id}
                onClick={() => openLightbox(index)}
                className="aspect-[4/5] mr-2 w-full flex flex-shrink-0 cursor-pointer items-center justify-center overflow-hidden rounded-md bg-[#999] bg-cover bg-center bg-no-repeat focus:outline-none"
                style={{
                  width: `calc(${100 / visibleSlides}% - 8px)`,
                }}>
                {item.media_type === 'IMAGE' || item.media_type === 'CAROUSEL_ALBUM' ? (
                  <img
                    loading="lazy"
                    src={item.media_url}
                    alt={item.caption || 'Instagram Image'}
                    className="h-full w-full object-cover"
                  />
                ) : item.media_type === 'VIDEO' ? (
                  <video poster={item.thumbnail_url} className="h-full w-full object-cover" onClick={(e) => e.preventDefault()}>
                    <source src={item.media_url} />
                  </video>
                ) : null}
              </div>
            );
          })}
        </div>

        {/* Right Arrow */}
        {media.length > visibleSlides && (
          <button
            onClick={gotoNext}
            className="absolute right-2 top-1/2 z-10 flex h-10 w-10 -translate-y-1/2 items-center justify-center rounded-full bg-white shadow-md focus:outline-none">
            <Icon variant="chevron-s-right" />
          </button>
        )}
      </div>

      {/* Lightbox for larger image view */}
      {isLightboxOpen && (
        <Lightbox
          images={media.map((item) => ({
            src: item.media_url,
            caption: item.caption,
            type: item.media_type === 'VIDEO' ? 'video' : 'image',
          }))}
          currentImage={lightboxIndex}
          onClose={closeLightbox}
          onClickNext={lightboxNext}
          onClickPrev={lightboxPrevious}
          isOpen={isLightboxOpen}
          backdropClosesModal={true}
          fallbackLinkText={fallbackLinkText}
        />
      )}
    </>
  );

  return hasCard ? <Card>{content}</Card> : content;
};

export default InstagramFeed;
