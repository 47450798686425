import { BookAppointmentProvider } from '@/components/modules/modals/BookAppointment/BookAppointment.hooks';
import SearchResultsPage from '@/features/searchV2/pages';
import { SearchManagerProps, SearchProvider } from '@/hooks/search/useSearch';

const SearchPage = ({ type = 'default' }: SearchManagerProps) => {
  return (
    <SearchProvider type={type}>
      <BookAppointmentProvider>
        <SearchResultsPage />
      </BookAppointmentProvider>
    </SearchProvider>
  );
};

export default SearchPage;
