import * as animationData from '@/assets/animations/loadingDotsBrand.json';
import AnimationWrapper from '../AnimationWrapper';
import { AnimationProps } from '../types';

const LoadingDotsDestructiveBold = (props: AnimationProps) => {
  const animationProps = { ...props, animationData };
  return <AnimationWrapper {...animationProps} />;
};

export default LoadingDotsDestructiveBold;
