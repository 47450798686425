import { config } from '@/config';
import { getLang } from '@/locale';
import { CheckoutButtonType, CheckoutType } from '@/types/adyen';

const locale = getLang() === 'en' ? 'en_US' : 'sv_SE';
const environment = config.env === 'production' || config.env === 'staging' ? 'LIVE' : 'TEST';
const clientKey = config.adyenClientKey;
const clientKeyGiftcard = config.giftcardAdyenClientKey;
const googlepayConfiguration = config.googlepay;

export type AdyenCheckoutConfigutation = typeof checkoutConfig;

type CheckoutComponentConfig = {
  [Key in CheckoutType]: (typeof checkoutComponentConfig)[Key];
};

const checkoutComponentConfig = {
  card: {},
  applepay: {
    buttonType: 'plain',
    buttonColor: 'black',
  },
  googlepay: {
    buttonSizeMode: 'fill',
    buttonColor: 'black',
    buttonType: 'pay',
    allowedAuthMethods: ['PAN_ONLY'],
  },
};

export const getCheckoutComponentConfig = <T extends CheckoutType>(
  type: T,
  buttonType: CheckoutButtonType = 'pay',
): CheckoutComponentConfig[T] => {
  const checkoutConfig = checkoutComponentConfig[type];
  if ('buttonType' in checkoutConfig) {
    return {
      ...checkoutConfig,
      challengeWindowSize: '05',
      buttonType,
    };
  }
  return checkoutConfig;
};

const paymentMethodsConfiguration = (amount: number) => ({
  card: {
    hasHolderName: true,
    holderNameRequired: true,
    name: 'Credit or debit card',
    amount: { value: amount, currency: 'SEK' },
  },
  applepay: {
    amount: {
      value: amount,
      currency: 'SEK',
    },
    countryCode: 'SE',
  },
  googlepay: {
    configuration: googlepayConfiguration,
  },
});

export const checkoutConfig = (type: CheckoutType, amount: number) => ({
  paymentMethodsConfiguration: {
    [type]: paymentMethodsConfiguration(amount)[type],
  },
  locale,
  environment,
  clientKey,
});

export const threeDSConfig = (isGiftcard?: boolean) => {
  return {
    locale,
    environment,
    clientKey: isGiftcard ? clientKeyGiftcard : clientKey,
  };
};
