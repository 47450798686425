import { z } from 'zod';

export const productTypeSchema = z.enum(['placecard', 'giftcard', 'wellnesscard', 'valuecard']);

export type ProductType = z.TypeOf<typeof productTypeSchema>;

const campaignTypeSchema = z.enum([
  'fathersDay',
  'christmas',
  'birthday',
  'giveAwayGift',
  'massage',
  'footcare',
  'acupuncture',
  'pt',
  'yoga',
]);

export type CampaignType = z.TypeOf<typeof campaignTypeSchema>;
