import { APP_ROUTES } from '@/constants/pages';
import { _s } from '@/locale';
import { baseTranslationKey } from './GiftcardCheckout';
import { CampaignType, ProductType } from './GiftcardCheckout.types';

export function getGiftcardCheckoutProductType(
  params: { slugId: string; type: string },
  pathname: string,
): ProductType | undefined {
  if (!params.slugId && !params.type) {
    if (pathname.startsWith(APP_ROUTES.WELLNESSCARD_CHECKOUT)) {
      return 'wellnesscard';
    }

    return 'giftcard';
  }

  switch (params.type) {
    case 'valuecard':
      return 'valuecard';
    case 'giftcard':
      return 'placecard';
  }
}

export function getReturnUrl(productType: ProductType, slugId?: string) {
  switch (productType) {
    case 'giftcard':
      return '/presentkort';
    case 'wellnesscard':
      return '/friskvardskort';
    case 'placecard':
      return `/places/${slugId}/giftcard/checkout`;
    case 'valuecard':
      return `/places/${slugId}/valuecard/checkout`;
  }
}

export function getProductInfo(type: ProductType | CampaignType, practitioner?: string) {
  switch (type) {
    case 'giftcard':
      return {
        title: _s(`${baseTranslationKey}.productInfo.${type}.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.${type}.subTitle`)],
        imgSrc: {
          desktop2xl: `/images/product-info-${type}-desktop-2xl.png`,
          desktop: `/images/product-info-${type}-desktop-lg.png`,
          mobile: `/images/product-info-${type}-mobile.png`,
        },
      };
    case 'wellnesscard':
      return {
        title: _s(`${baseTranslationKey}.productInfo.${type}.title`),
        subTitles: [
          _s(`${baseTranslationKey}.productInfo.${type}.subTitle`),
          _s(`${baseTranslationKey}.productInfo.${type}.subTitle2`),
        ],
        imgSrc: {
          desktop2xl: `/images/product-info-${type}-desktop-2xl.png`,
          desktop: `/images/product-info-${type}-desktop-lg.png`,
          mobile: `/images/product-info-${type}-mobile.png`,
        },
      };
    case 'fathersDay':
      return {
        title: _s(`${baseTranslationKey}.productInfo.campaigns.${type}.title`),
        subTitles: [
          _s(`${baseTranslationKey}.productInfo.campaigns.${type}.subTitle`),
          _s(`${baseTranslationKey}.productInfo.campaigns.${type}.subTitle2`),
        ],
        imgSrc: {
          desktop2xl: `/images/product-info-giftcard-${type}.png`,
          desktop: `/images/product-info-giftcard-${type}.png`,
          mobile: `/images/product-info-giftcard-${type}-mobile.png`,
        },
      };
    case 'christmas':
      return {
        title: _s(`${baseTranslationKey}.productInfo.campaigns.${type}.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.campaigns.${type}.subTitle`)],
        imgSrc: {
          desktop2xl: `/images/product-info-giftcard-${type}.png`,
          desktop: `/images/product-info-giftcard-${type}.png`,
          mobile: `/images/product-info-giftcard-${type}-mobile.png`,
        },
      };
    case 'birthday':
    case 'giveAwayGift':
      return {
        title: _s(`${baseTranslationKey}.productInfo.campaigns.${type}.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.campaigns.${type}.subTitle`)],
        imgSrc: {
          desktop2xl: `/images/product-info-giftcard-desktop-2xl.png`,
          desktop: `/images/product-info-giftcard-desktop-lg.png`,
          mobile: `/images/product-info-giftcard-mobile.png`,
        },
      };
    case 'massage':
    case 'footcare':
    case 'acupuncture':
    case 'pt':
    case 'yoga':
      return {
        title: _s(`${baseTranslationKey}.productInfo.campaigns.${type}.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.campaigns.${type}.subTitle`)],
        imgSrc: {
          desktop2xl: `/images/product-info-wellnesscard-${type}.png`,
          desktop: `/images/product-info-wellnesscard-${type}.png`,
          mobile: `/images/product-info-wellnesscard-${type}-mobile.png`,
        },
      };
    case 'placecard':
      return {
        title: _s(`${baseTranslationKey}.productInfo.placecard.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.placecard.subTitle`, { practitioner })],
        imgSrc: {
          desktop2xl: `/images/product-info-placecard-desktop-2xl.png`,
          desktop: `/images/product-info-placecard-desktop-lg.png`,
          mobile: `/images/product-info-placecard-mobile.png`,
        },
      };
    case 'valuecard':
      return {
        title: _s(`${baseTranslationKey}.productInfo.valuecard.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.valuecard.subTitle`, { practitioner })],
        imgSrc: {
          desktop2xl: `/images/product-info-giftcard-desktop-2xl.png`,
          desktop: `/images/product-info-giftcard-desktop-lg.png`,
          mobile: `/images/product-info-giftcard-mobile.png`,
        },
      };

    default:
      return {
        title: _s(`${baseTranslationKey}.productInfo.giftcard.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.giftcard.subTitle`)],
        imgSrc: {
          desktop2xl: `/images/product-info-giftcard-desktop-2xl.png`,
          desktop: `/images/product-info-giftcard-desktop-lg.png`,
          mobile: `/images/product-info-giftcard-mobile.png`,
        },
      };
  }
}

export function getCampaignType({
  campaignType,
  productType,
}: {
  campaignType?: string;
  productType: ProductType;
}): CampaignType | null {
  if (productType === 'placecard' || productType === 'valuecard') {
    return null;
  }

  if (productType === 'wellnesscard') {
    switch (campaignType) {
      case 'massage':
        return 'massage';
      case 'fotvard':
        return 'footcare';
      case 'akupunktur':
        return 'acupuncture';
      case 'personlig-traning':
        return 'pt';
      case 'yoga':
        return 'yoga';
      default:
        return null;
    }
  }

  switch (campaignType) {
    case 'fars-dag':
      return 'fathersDay';
    case 'julklapp':
      return 'christmas';
    case 'fodelsedag':
      return 'birthday';
    case 'ga-bort-present':
      return 'giveAwayGift';
    default:
      return null;
  }
}
