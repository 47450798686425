import { bookActions } from '@/actions';
import Avatar from '@/components/elements/Avatar';
import { EmployeeBox } from '@/components/modules/employee';
import {
  findEmployee,
  hoursFromSeconds,
  isMobile,
  isSistaminuten as isSistaminutenInstance,
  startOfDay,
} from '@/helpers';
import { __ } from '@/locale';
import React from 'react';
import { connect } from 'react-redux';
import { ModalContent, ModalDialog } from '../../modals';

class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.pickEmployee = this.pickEmployee.bind(this);
    this.renderMobile = this.renderMobile.bind(this);
    this.state = { showSelect: false };
  }

  componentWillMount() {
    if (this.props.employee) {
      this.pickEmployee(this.props.employee, true);
    }
  }

  isAvailable(id) {
    const { dispatch, time, availability } = this.props;
    if (time && time.selected) {
      const active =
        availability.employee && availability.employee[id]
          ? this.checkAvailability(availability.employee[id], time.selected)
          : { status: 'unavailable' };
      if (active.status === 'unavailable') {
        dispatch(bookActions.removeHour());
      }
    }
  }

  pickEmployee(id, silent = false) {
    const { place, dispatch, services, time } = this.props;
    const selectedEmployee = findEmployee(id, place.employees);
    const isSistaminuten = isSistaminutenInstance() || Boolean(place?.lastMinuteDiscount);

    let day = startOfDay();
    if (time && time.timestamp) {
      day = time.timestamp;
    }

    this.setState({ employee: selectedEmployee });

    if (id) {
      dispatch(bookActions.pickEmployee(id, selectedEmployee.about.priceListId || 0));
      this.isAvailable(id);
    } else {
      dispatch(bookActions.removeEmployee());
    }

    dispatch(
      bookActions.getAvailability({
        services,
        date: day,
        employee: selectedEmployee.id || 0,
        source: 'pickEmployee',
        isSistaminuten,
      }),
    );

    if (!silent) {
      this.handleSelect();
    }
  }

  handleSelect() {
    if (this.state.showSelect) {
      this.setState({ showSelect: false });
    } else {
      this.setState({ showSelect: true });
    }
  }

  checkAvailability(list, time) {
    let selected = false;
    let available = false;
    let firstInterval = false;

    list.forEach((item) => {
      if (parseInt(item.from, 10) === parseInt(time, 10)) {
        selected = { status: 'available', extra: null };
        available = true;
      }
      if (item.from > time && !available && !firstInterval) {
        selected = { status: 'unavailable', extra: __('available') + hoursFromSeconds(item.from) };
        firstInterval = true;
      }
    });

    return selected ? selected : { status: 'unavailable' };
  }

  getProps() {
    const open = this.state.showSelect ? true : false;
    const selectEmployee = this.usersList();
    const selectedEmployee = this.selected();
    const theStyle = isMobile()
      ? {
          overlay: {
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 10050,
            overflow: 'scroll',
            outline: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
        }
      : {
          overlay: {
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 10050,
            overflow: 'hidden',
            outline: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
          content: {
            maxWidth: 400,
            maxHeight: 355,
          },
        };
    return {
      open,
      theStyle,
      selectEmployee,
      selectedEmployee,
    };
  }

  selected() {
    return this.state.employee ? (
      <EmployeeBox avatarSize="sm" employee={this.state.employee} showNote={!isMobile()} />
    ) : (
      <EmployeeBox avatarSize="sm" employee={{ id: 0, about: { name: __('anyEmployee'), avatar: '' } }} />
    );
  }

  usersList() {
    const { place, availability, time } = this.props;
    return this.state.showSelect ? (
      <div className="employees">
        <span
          onClick={() => {
            this.pickEmployee(0);
          }}
          className="employee-item">
          <Avatar size="md" />
          <span className="employee-info">{__('anyEmployee')}</span>
        </span>
        {place.employees.map((employee) => {
          // Skip employees that don't have this service in their list
          if (!availability || (availability.employee && !availability.employee[employee.id])) {
            return null;
          }
          const employeeStatus = {};
          if (time.selected || availability.employee[employee.id].length === 0) {
            const active = this.checkAvailability(availability.employee[employee.id], time.selected);
            employeeStatus.type = active.status;
            employeeStatus.extra = active.extra;
          }

          return (
            <span
              className="employee-item"
              key={employee.id}
              data-id={employee.id}
              onClick={() => {
                this.pickEmployee(employee.id);
              }}>
              <Avatar src={employee.about.avatar} alt="avatar" size="md" />
              <span className="employee-info">
                {employee.about.name}
                {employeeStatus.type === 'available' && <span className="available">{__('available')}</span>}
                {employeeStatus.type === 'unavailable' && (
                  <span className="unavailable">
                    {__('unavailable')} <span className="extra">{employeeStatus.extra}</span>
                  </span>
                )}
              </span>
            </span>
          );
        })}
      </div>
    ) : null;
  }

  render() {
    const props = this.getProps();
    const { open, selectEmployee, selectedEmployee, theStyle } = props;

    return (
      <div className="pick-employee">
        <span className="employee-item cursor-pointer" onClick={this.handleSelect}>
          {selectedEmployee}
        </span>
        <span className="select-employee" onClick={this.handleSelect}>
          {this.state.employee ? __('change') : __('select')}
        </span>
        <ModalDialog
          isOpen={open}
          appElement={document.getElementById('root')}
          onRequestClose={() => this.setState({ showSelect: false })}
          style={theStyle}>
          <ModalContent size="md" className="p-4">
            <button
              type="button"
              className="close p-4"
              style={!isMobile() ? { position: 'absolute', top: 0, right: 0 } : {}}
              aria-label="Close"
              onClick={this.handleSelect}>
              {!isMobile() && (
                <span aria-hidden="true" className="reset-search">
                  &nbsp;
                </span>
              )}
            </button>
            <h3>{__('selectProfessional')}</h3>
            <div className="employee-list">{selectEmployee}</div>
          </ModalContent>
        </ModalDialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { services, place, availability, time, employee } = state.book;
  const { show, loadSource } = state.loading;
  return {
    place,
    services,
    availability,
    time,
    employee,
    show,
    loadSource,
  };
}

const connectedEmployees = connect(mapStateToProps)(Employees);
export { connectedEmployees as Employees };
