import { Button } from '@/components/elements/forms/buttons';
import { CloseIcon } from '@/components/icons';
import { SMART_LINKS } from '@/constants/smartLinkConstants';
import { isServer, setCookie, showIphoneTopBanner } from '@/helpers';
import { _s } from '@/locale';
import * as moment from 'moment';

function IPhoneTopBanner({ source }: { source: string }) {
  if (isServer) return null;

  const showBanner = showIphoneTopBanner();
  if (!showBanner) return null;

  let closedIPhoneTopBanner = localStorage.getItem('closedIPhoneTopBanner') || '0';
  const closeBanner = () => {
    const closedTimes = '' + (parseInt(closedIPhoneTopBanner) + 1);
    localStorage.setItem('closedIPhoneTopBanner', closedTimes);
    setCookie('showIPhoneTopBanner', 'false', { path: '/', expires: moment().add(1, 'days').toDate() });
    window.location.reload();
  };
  const downloadApp = () => {};
  const downloadUrl =
    source === 'home'
      ? SMART_LINKS.HOME
      : source === 'search_results'
      ? SMART_LINKS.SERP
      : source === 'company_details'
      ? SMART_LINKS.PLACE_TOP
      : '#';
  return (
    <div className="bg-black-100 border-black-300 flex items-center border-y-[1px] py-2 pl-2 pr-4">
      <Button
        variant="link"
        className="!mr-2 !p-0"
        onClick={closeBanner}
        leftIcon={<CloseIcon className="text-black-900 h-4 w-4 p-[2px]" />}></Button>
      <img src="/images/top-banner-logo.svg" className="mr-2" alt="bokadirekt logo" width="30" height="30" />
      <div className="mr-2 flex flex-col">
        <span className="text-black-900 text-sm leading-[16px]">{_s('bookBeautyServicesEasy')}</span>
        <img src="/images/top-banner-stars.svg" className="mb-[3px]" alt="4,9 stars" width="59" height="9" />
        <span className="text-black-600 text-m">{_s('appStore')}</span>
      </div>
      <a href={downloadUrl} className="ml-auto text-sm font-semibold uppercase text-[#007df8]" onClick={downloadApp}>
        {_s('download')}
      </a>
    </div>
  );
}

export default IPhoneTopBanner;
