import { useReducer } from 'react';
import { FilterAction, FilterOption, FiltersManagerProps, FilterState } from './Filter.types';

function filterReducer(state: FilterState, action: FilterAction) {
  switch (action.type) {
    case 'ON_SHOW_OPTIONS': {
      return { ...state, showOptions: action.payload };
    }
    case 'ON_CHANGE_PREFS': {
      return { ...state, prefs: action.payload };
    }
    default:
      return state;
  }
}

const useFilterManager = ({ initialState, urlPrefs, applyOnToggle, onChangeAppliedFilters }: FiltersManagerProps) => {
  const [state, dispatch] = useReducer(filterReducer, initialState);

  const handleShowOptions = (show: boolean) => {
    dispatch({ type: 'ON_SHOW_OPTIONS', payload: show });
    // sync state prefs with url prefs when closing/opening the filter options
    dispatch({ type: 'ON_CHANGE_PREFS', payload: urlPrefs });
  };

  const handleOnToggleFilterOption = (option: FilterOption) => {
    const existingPrefs = (state.prefs ?? '')
      .split(',')
      .filter(Boolean)
      .map((pref) => +pref);

    const newPrefs = (() => {
      if (existingPrefs.includes(option.id)) {
        return existingPrefs.filter((pref) => +pref !== option.id).join(',');
      }
      return [...existingPrefs, option.id].join(',');
    })();

    dispatch({ type: 'ON_CHANGE_PREFS', payload: newPrefs });

    if (applyOnToggle) {
      onChangeAppliedFilters(newPrefs);
    }
  };

  const handleApplyFilters = () => {
    onChangeAppliedFilters(state.prefs);
    handleShowOptions(false);
  };

  return {
    prefs: state.prefs,
    urlPrefs: urlPrefs,
    showOptions: state.showOptions,
    onShowOptions: handleShowOptions,
    onToggleFilterOption: handleOnToggleFilterOption,
    onApplyFilters: handleApplyFilters,
  };
};

export default useFilterManager;
