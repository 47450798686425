import { server } from '@/helpers';
import { LandingPage } from '@/types/landingPages';
import { Dispatch, useEffect, useState } from 'react';

/**
 * Check that slug of ssr loaded page matches the current slug
 */
const checkSlug = (ssrSlug: string, currentSlug: string) => {
  return ssrSlug === currentSlug;
};

export const useLandingPage = (ssrLandingPage: { data: LandingPage | null; error: boolean } | null, slug: string) => {
  const ssrLoadedLandingPage = ssrLandingPage && checkSlug(ssrLandingPage?.data?.slug, slug) ? ssrLandingPage : null;

  const [landingPage, setLandingPage] = useState<LandingPage>(ssrLoadedLandingPage?.data ?? null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (ssrLoadedLandingPage || ssrLandingPage?.error) return;
    fetchLandingPage(slug, setError, setLandingPage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (ssrLoadedLandingPage || ssrLandingPage?.error)
    return { landingPage, loading: false, error: ssrLandingPage?.error ?? false };

  return { landingPage, error };
};

const fetchLandingPage = async (slug: string, setError: Dispatch<any>, setLandingPage: Dispatch<LandingPage>) => {
  const res = await server.request.get(`/landing-pages/${slug}`).then(server.handleSuccess).catch(server.handleError);

  if ('code' in res) return setError(res);

  setLandingPage(res);
};
