import { LinkButton } from '@/components/elements/forms/buttons';
import { LPCTAButtonField } from '@/types/landingPages';

type CTAButtonProps = LPCTAButtonField & {
  landingPageID: number;
};

const trackCTAButtonClicked = () => {};

const CTAButton = ({ url, label, landingPageID }: CTAButtonProps) => (
  <LinkButton variant="primary" to={url} onClick={trackCTAButtonClicked}>
    {label}
  </LinkButton>
);

export default CTAButton;
