import Card from '@/components/elements/Card/Card';
import { Button } from '@/components/elements/forms/buttons';
import Alert from '@/components/elements/notifications/Alert/Alert';
import Icon from '@/components/icons/Icon';
import { getImagePreview, getPlaceImage } from '@/helpers';
import { _s } from '@/locale';

const baseTranslationKey = 'features.ProfilePages.components.PlaceDetails';

const PlaceDetails = ({
  place,
  isVerified,
  isPremium,
  isBasic,
}: {
  place: any;
  isVerified: boolean;
  isPremium?: boolean;
  isBasic?: boolean;
}) => {
  const placeLogo = getImagePreview(getPlaceImage(place), '275x275');
  return (
    <Card size="lg">
      <div className="gap-xl flex flex-col">
        {placeLogo && (
          <div className="flex justify-center">
            <a
              href="#photos"
              className="bg-black-50 relative flex h-[270px] w-[270px] min-w-[270px] items-center justify-center overflow-hidden rounded-md">
              <img
                width="275"
                height="275"
                src={placeLogo}
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/images/bd-fallback-img200x200.png')}
              />
            </a>
          </div>
        )}
        <div className="gap-sm flex flex-col">
          <div className="text-fg_primary text-xl">{place.about.name}</div>
          <div className="gap-xs flex items-center">
            {!isVerified && (
              <>
                <Icon size="sm" variant="close-circle" color="fg_secondary" />
                <span className="text-l text-fg_secondary flex items-center">{_s(`notVerified`)}</span>
              </>
            )}
            {isVerified && (
              <>
                <Icon size="sm" color="fg_positive" ext="svg" variant="verification" />
                <span className="text-l text-fg_positive flex items-center">{_s(`verified`)}</span>
              </>
            )}
          </div>
        </div>
        {!isPremium && !isBasic && (
          <Button
            size="md"
            iconNoFilter
            rightIcon={<Icon variant="website" color="fg_primary_inverse" />}
            label={_s(`${baseTranslationKey}.cta`)}
          />
        )}
        {isVerified &&
          Boolean(place?.about?.associations?.length) &&
          place.about.associations.map((association) => {
            return (
              <Alert
                key={association.id}
                className="p-sm bg-surface_l4"
                leftSlot={
                  <div className="gap-xs flex flex-col">
                    <div className="gap-2xs flex items-center">
                      <Icon size="xs" variant="industry-organization" color="fg_secondary"></Icon>
                      <span className="text-xxs text-fg_secondary">{_s(`${baseTranslationKey}.alert`)}</span>
                    </div>
                    <div className="text-m text-fg_primary">{association.name}</div>
                  </div>
                }
                variant="information"
                rightSlot={<img className="h-[28px] w-auto" src={association.imgSrc} alt={association.name} />}
              />
            );
          })}
      </div>
    </Card>
  );
};

export default PlaceDetails;
