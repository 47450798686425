import { Button } from '@/components/elements/forms/buttons';
import { url } from '@/helpers';
import { SearchManagerOutput } from '@/hooks/search/useSearch';
import { _s } from '@/locale';
import { SEARCH_RESULT_PER_PAGE_LIMIT } from '../constants';

type SearchEmptyStateProps = SearchManagerOutput & {
  showExtendedSearch: boolean;
  onRequestExtendedSearchClick: () => void;
};

const SearchEmptyState = (props: SearchEmptyStateProps) => {
  const keyword = url.decodeURIComponentSafe(props.q);
  const location = url.decodeURIComponentSafe(props.location);

  const title = `${keyword ? `“${keyword}` : 'din sökning'} ${
    location ? ` ${_s('in')} ${location}${keyword ? '“' : ''}` : '“'
  }`;

  const isPrevousPagesPopulated = Object.keys(props.places)
    .filter((key) => +key !== props.page)
    .every((key) => props.places[key].length === SEARCH_RESULT_PER_PAGE_LIMIT);

  return (
    <div>
      <h1 className="text-h-m mb-4 md:text-2xl">{`${_s('serpNoMatches.noResultMatches')} ${title}`}</h1>
      <ul className="list-disc space-y-2 pl-4">
        <li>
          <p>{_s('serpNoMatches.tip.tip1')}</p>
        </li>
        <li>
          <p>{_s('serpNoMatches.tip.tip2')}</p>
        </li>
        <li>
          <p>{_s('serpNoMatches.tip.tip3')}</p>
        </li>
      </ul>
      {props.showExtendedSearch && (props.page === 0 || isPrevousPagesPopulated) && (
        <div className="pt-md">
          <p className="mb-4">{_s('serpNoMatches.extendedSearch.description')}</p>
          <Button variant="secondary" onClick={props.onRequestExtendedSearchClick}>
            {_s('serpNoMatches.extendedSearch.cta')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SearchEmptyState;
