import { _s } from '@/locale';
import { useRef } from 'react';
import Autosuggest from 'react-autosuggest';
import { AutoSuggestInputTheme, AutoSuggestionSection } from '../shared/types';
import { LocationAutoSuggestManagerOutput, LocationSuggestion } from './LocationAutoSuggest.types';

type LocationAutosuggestProps = LocationAutoSuggestManagerOutput & {
  inputRef: React.RefObject<HTMLDivElement>;
  renderInputComponent: (inputProps: any) => React.ReactNode;
  renderSuggestion: (suggestion: any, options: any) => React.ReactNode;
  renderSectionTitle: (section: AutoSuggestionSection<LocationSuggestion>) => React.ReactNode;
  theme: AutoSuggestInputTheme;
};

const LocationAutosuggest = ({
  inputRef,
  location,
  onSuggestionSelected,
  onSuggestionsClearRequested,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  onSuggestionsFetchRequested,
  suggestions,
  showSuggestions,
  renderInputComponent,
  renderSuggestion,
  renderSectionTitle,
  theme = {
    container: 'react-autosuggest__container',
    containerOpen: 'react-autosuggest__container--open',
    input: 'react-autosuggest__input',
    inputOpen: 'react-autosuggest__input--open',
    inputFocused: 'react-autosuggest__input--focused',
    suggestionsContainer: 'react-autosuggest__suggestions-container',
    suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open ',
  },
}: LocationAutosuggestProps) => {
  const autosuggestRef = useRef();

  return (
    <Autosuggest
      ref={autosuggestRef}
      id="location-autosuggest"
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={(suggestion) => suggestion.label}
      inputProps={{
        placeholder: _s('Enter city or area'),
        value: location,
        type: 'search',
        onChange,
        onFocus,
        onBlur,
        onKeyDown,
        ref: inputRef,
      }}
      focusInputOnSuggestionClick={false}
      alwaysRenderSuggestions={showSuggestions}
      getSectionSuggestions={(section) => section.suggestions}
      renderSuggestion={renderSuggestion}
      renderSectionTitle={renderSectionTitle}
      renderInputComponent={renderInputComponent}
      multiSection
      theme={theme}
    />
  );
};

export default LocationAutosuggest;
