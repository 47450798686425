import { decodeUnicodeString } from '@/helpers';
import { __ } from '@/locale';
import React from 'react';
import * as sanitizeHtml from 'sanitize-html';

export default class About extends React.Component {
  render() {
    const __html = sanitizeHtml(decodeUnicodeString(this.props.description ?? ''), {
      allowedTags: [],
      allowedAttributes: {},
    });

    return (
      <div id="about" className="details-section scroll-mt-[120px]">
        <h2 className="mb-2 text-2xl font-semibold">{__('aboutUs')}</h2>
        {this.props.description ? (
          <div
            id="description"
            className="text-black-600 whitespace-pre-wrap text-sm"
            dangerouslySetInnerHTML={{ __html }}
          />
        ) : (
          <span className="text-black-600">{__('noInfoYet')}</span>
        )}
      </div>
    );
  }
}
