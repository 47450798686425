/* eslint-disable */
import { isSistaminuten, trackPage, trackMpEvent, trackMpMetaCustomEvent, url } from '@/helpers';
import { _s } from '@/locale';
import { Helmet } from 'react-helmet';
import Card from '@/components/elements/Card/Card';
import Icon from '@/components/icons/Icon';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import { arrayOfStrings } from '@/helpers/utilsTS';
import { useEffect } from 'react';
import useScrollToTop from '@/hooks/useScrollToTop';

const getSeo = () => {
  const title = _s('seo.appsDownloadTitle');
  const description = _s('seo.appsDownloadDescription');
  const pageUrl = `${url.getBaseUrl()}apps`;
  const imageUrl = url.getBaseImageUrl();
  const isNoIndex = isSistaminuten();

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={pageUrl} />

      <meta property="og:url" content={pageUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      {isNoIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};

const trackPageVisit = () => {
  trackPage();
  trackMpEvent('screen_shown', { screen_name: 'download_app' });
};

const trackClick = (app: 'ios' | 'android') => (e) => {
  trackMpEvent(`download_app_clicked`, { app });
  trackMpMetaCustomEvent(`download_${app}_app_clicked`, {});
};

const AppsDownload = () => {
  useScrollToTop();

  useEffect(trackPageVisit, []);

  const appsButton = (classname) => (
    <a className={classname} href="https://bokadirekt.smart.link/cl1p2rx9j">
      <img
        onClick={trackClick('ios')}
        src="/images/download-app-store.png"
        className="h-[40px]"
        alt={_s('availableAppStore')}
      />
      <img
        onClick={trackClick('android')}
        src="/images/download-google-play.png"
        className="h-[40px]"
        alt={_s('availablePlayStore')}
      />
    </a>
  );
  const bulletPoints = arrayOfStrings(_s('appsPage.bulletPoints'));
  return (
    <PageViewLayout type="mainView" wrapperClass="bg-primary-200">
      <div className="p-xl flex justify-center">
        <Card className="flex justify-center md:w-10/12 xl:w-[992px]">
          <div className="gap-lg flex flex-col items-center md:flex-row">
            <picture>
              <source srcSet="/images/appsDownload/download-bokadirekt-apps.png" media="(min-width: 768px)" />
              <img src="/images/appsDownload/download-bokadirekt-apps-mobile.png" alt="" loading="lazy" />
            </picture>
            {appsButton('flex md:hidden gap-3')}
            <div className="gap-lg flex flex-col">
              <h1 className="text-2xl text-[30px] font-bold md:text-4xl">{_s('appsPage.title')}</h1>
              <h3 className="text-sm">{_s('appsPage.subtitle')}</h3>
              {bulletPoints.length && (
                <ul>
                  {bulletPoints.map((item, i) => (
                    <ListItem
                      key={i}
                      className="py-xs gap-sm"
                      leftPadding={false}
                      leftSlot={<Icon variant="check" color="success-500" />}>
                      <ListItemContent titleClassName="text-black-600 text-base" title={item} />
                    </ListItem>
                  ))}
                </ul>
              )}
              {appsButton('hidden md:flex gap-4')}
            </div>
          </div>
        </Card>
      </div>
      {getSeo()}
    </PageViewLayout>
  );
};

export default AppsDownload;
