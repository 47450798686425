import { useContext } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import defaults from '../theme';
import deepMerge from '../utils/deepMerge';
import { ThemeContext } from '../Lightbox'; 

function Footer({ caption, countCurrent, countSeparator, countTotal, showCount, ...props }) {
  const theme = useContext(ThemeContext);
  if (!caption && !showCount) return null;
  const classes = StyleSheet.create(deepMerge(defaultStyles, theme));

  const imageCount = showCount ? (
    <div className={css(classes.footerCount)}>
      {countCurrent}
      {countSeparator}
      {countTotal}
    </div>
  ) : (
    <span />
  );

  return (
    <div className={'lightbox-caption ' + css(classes.footer)} {...props}>
      {caption ? <figcaption className={css(classes.footerCaption)}>{caption}</figcaption> : <span />}
      {imageCount}
    </div>
  );
}

Footer.propTypes = {
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  countCurrent: PropTypes.number,
  countSeparator: PropTypes.string,
  countTotal: PropTypes.number,
  showCount: PropTypes.bool,
};

const defaultStyles = {
  footer: {
    boxSizing: 'border-box',
    color: defaults.footer.color,
    cursor: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    left: 0,
    lineHeight: 1.3,
    paddingBottom: defaults.footerGutter.vertical,
    paddingLeft: defaults.footerGutter.horizontal,
    paddingRight: defaults.footerGutter.horizontal,
    paddingTop: defaults.footerGutter.vertical,
  },
  footerCount: {
    color: defaults.footerCount.color,
    fontSize: defaults.footerCount.fontSize,
    paddingLeft: '1em', // add a small gutter for the caption
  },
  footerCaption: {
    flex: '1 1 0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: '1',
  },
};

export default Footer;
