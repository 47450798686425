import { _s } from '@/locale';
import { SortOption } from '@/types/state/search';
import { SortedBy } from './Sorting.types';

export const SORTING_OPTIONS: SortedBy[] = [
  { value: 'popular', label: _s('sorting_popular') },
  { value: 'closest', label: _s('sorting_closest') },
  { value: 'availability', label: _s('sorting_availability') },
  { value: 'reviews_score', label: _s('sorting_rating_score') },
];

export const DEFAULT_SORTING_OPTION: SortedBy = {
  value: 'popular',
  label: _s('sort'),
};

export function getAppliedSorting(sort?: SortOption): SortedBy {
  return SORTING_OPTIONS.find((option) => option.value === sort) || DEFAULT_SORTING_OPTION;
}
