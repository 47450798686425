import { classnames } from '@/helpers';
import { customTwMerge } from '@/helpers/theme';

export const SYMBOL_SIZE = {
  sm: 'w-[32px] h-[32px]',
  md: 'w-[48px] h-[48px]',
  lg: 'w-[64px] h-[64px]',
};

type SymbolProps = {
  children: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
  classNames?: string;
};

const initialFontSize: Record<'sm' | 'md' | 'lg', string> = {
  sm: 'text-s',
  md: 'text-l',
  lg: 'text-xl',
};

const Symbol = ({ children, size = 'md', classNames }: SymbolProps) => {
  return (
    <div className="relative inline-block">
      <div
        className={customTwMerge(
          classnames(
            SYMBOL_SIZE[size],
            'bg-bg_secondary text-fg_secondary p-2xs relative flex items-center justify-center overflow-hidden rounded-sm',
          ),
          classNames,
        )}>
        <span className={classnames(initialFontSize[size])}>{children}</span>
      </div>
    </div>
  );
};

export default Symbol;
