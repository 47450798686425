import PaymentMethodsList from '@/components/templates/checkout/PaymentMethodList/PaymentMethodsList';
import {
  APPLE_PAY_LIST_ITEM,
  BOKADIREKT_GIFTCARD_LIST_ITEM,
  BOKADIREKT_VALUECARD_LIST_ITEM,
  BOKADIREKT_WELLNESSCARD_LIST_ITEM,
  CHECKOUT_PAYMENT_METHOD,
  COUPON_PAYMENT_METHOD,
  GOOGLE_PAY_LIST_ITEM,
  KLARNA_LIST_ITEM,
  NEW_ONLINE_CARD_LIST_ITEM,
  PAY_AT_PLACE_LIST_ITEM,
  QLIRO_LIST_ITEM,
  STORED_ONLINE_CARD_LIST_ITEM,
  SWISH_LIST_ITEM,
} from '@/constants/checkout';
import { hasPaymentMethod, isHidden, isHighlighted } from '@/helpers/checkout';
import { useCardsContext } from '@/hooks/adyen/useCards';
import { TriggerSource } from '@/types/analytics';
import {
  AvailablePaymentMethod,
  CheckoutSummary,
  CouponMethod,
  PaymentMethodListItem,
  SelectedPaymentMethod,
} from '@/types/checkout';

function mapPaymentMethodHiddenProp(paymentMethod: PaymentMethodListItem, forceHide?: boolean): PaymentMethodListItem {
  return { ...paymentMethod, hidden: forceHide || isHidden(paymentMethod) };
}

function mapPaymentMethodSelectedProp(
  paymentMethod: PaymentMethodListItem,
  selectedPaymentMethod?: SelectedPaymentMethod,
  alreadySelected?: { value: boolean },
): PaymentMethodListItem {
  let isSelected = false;
  if (selectedPaymentMethod?.type === paymentMethod.type) {
    if (paymentMethod.type === CHECKOUT_PAYMENT_METHOD.STORED_COF) {
      if ('id' in selectedPaymentMethod) {
        if (selectedPaymentMethod.id === paymentMethod.id) {
          isSelected = true;
        }
      } else {
        if (!alreadySelected?.value) {
          isSelected = true;
        }
      }
    } else {
      isSelected = true;
    }
  }

  if (isSelected) {
    alreadySelected.value = true;
  }

  return { ...paymentMethod, isSelected };
}

function mapPaymentMethodHighlightedProp(paymentMethod: PaymentMethodListItem): PaymentMethodListItem {
  return {
    ...paymentMethod,
    highlighted: isHighlighted(paymentMethod.type),
  };
}

const paymentMethodsToHideMap: {
  [key in TriggerSource]?: PaymentMethodListItem['type'][];
} = {
  checkout_booking_update_payment_method: [
    COUPON_PAYMENT_METHOD.GIFTCARD,
    COUPON_PAYMENT_METHOD.VALUECARD,
    COUPON_PAYMENT_METHOD.WELLNESSCARD,
    CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE,
  ],
  checkout_bundle_summary: [
    COUPON_PAYMENT_METHOD.GIFTCARD,
    COUPON_PAYMENT_METHOD.VALUECARD,
    COUPON_PAYMENT_METHOD.WELLNESSCARD,
    CHECKOUT_PAYMENT_METHOD.KLARNA,
    CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE,
  ],
};

function sortPaymentMethodByHiddenProp(a: PaymentMethodListItem, b: PaymentMethodListItem) {
  return Number(a.hidden) - Number(b.hidden);
}

const PickPaymentMethod = ({
  summary,
  onPaymentSelected,
  triggerSource,
  selectedPaymentMethod,
  useRadioButtons = false,
  methodsVisible = 0,
  useHighlighted = false,
}: {
  summary: CheckoutSummary;
  triggerSource: TriggerSource;
  selectedPaymentMethod?: SelectedPaymentMethod;
  useRadioButtons?: boolean;
  methodsVisible?: number;
  useHighlighted?: boolean;
  onPaymentSelected: (method: SelectedPaymentMethod | CouponMethod) => void;
}) => {
  const { cards } = useCardsContext();
  const { availablePaymentMethods, payLater, acceptsGiftcard, acceptsValuecard, acceptsWellnesscard } = summary;

  const storedCoFPaymentMethod = cards?.map((card) => STORED_ONLINE_CARD_LIST_ITEM({ ...card }, payLater)) || [];
  const mapPaymentMethodListItem = (
    paymentMethod: AvailablePaymentMethod,
  ): PaymentMethodListItem | PaymentMethodListItem[] => {
    switch (paymentMethod.type) {
      case CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE:
        return { ...PAY_AT_PLACE_LIST_ITEM, ...paymentMethod };
      case CHECKOUT_PAYMENT_METHOD.KLARNA:
        return { ...KLARNA_LIST_ITEM, ...paymentMethod };
      case CHECKOUT_PAYMENT_METHOD.QLIRO:
        return { ...QLIRO_LIST_ITEM, ...paymentMethod };
      case CHECKOUT_PAYMENT_METHOD.NEW_COF:
        return { ...NEW_ONLINE_CARD_LIST_ITEM(!payLater), ...paymentMethod };
      case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
        return { ...GOOGLE_PAY_LIST_ITEM(!payLater), ...paymentMethod };
      case CHECKOUT_PAYMENT_METHOD.APPLE_PAY:
        return { ...APPLE_PAY_LIST_ITEM(!payLater), ...paymentMethod };
      case CHECKOUT_PAYMENT_METHOD.STORED_COF: {
        return Boolean(storedCoFPaymentMethod.length)
          ? storedCoFPaymentMethod.map((method, index) =>
              useRadioButtons
                ? {
                    ...method,
                    hidden: index === 0 ? paymentMethod.hidden : true, // only one saved card visible
                  }
                : { ...method },
            )
          : [];
      }
      case CHECKOUT_PAYMENT_METHOD.SWISH:
        return { ...SWISH_LIST_ITEM, ...paymentMethod };
      default:
        return null;
    }
  };

  const showGiftcard = acceptsGiftcard;
  const showValuecard = acceptsValuecard;
  const showWellnesscard = acceptsWellnesscard;

  let alreadySelected = { value: false };
  const hideCoupons = useRadioButtons
    ? [
        ...availablePaymentMethods.filter(
          (method) =>
            method.type !== CHECKOUT_PAYMENT_METHOD.NONE && method.type !== CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE,
        ),
      ].length !== 0
    : false;

  const supportedMethods: PaymentMethodListItem[] = [
    ...availablePaymentMethods
      .filter((method) => method.type !== CHECKOUT_PAYMENT_METHOD.NONE)
      .map((method) => mapPaymentMethodListItem(method))
      .flat()
      .sort(useRadioButtons ? sortPaymentMethodByHiddenProp : () => 0), // show first not hidden methods
    ...(showGiftcard
      ? [hideCoupons ? { ...BOKADIREKT_GIFTCARD_LIST_ITEM, hidden: hideCoupons } : BOKADIREKT_GIFTCARD_LIST_ITEM]
      : []),
    ...(showValuecard
      ? [hideCoupons ? { ...BOKADIREKT_VALUECARD_LIST_ITEM, hidden: hideCoupons } : BOKADIREKT_VALUECARD_LIST_ITEM]
      : []),
    ...(showWellnesscard
      ? [
          hideCoupons
            ? { ...BOKADIREKT_WELLNESSCARD_LIST_ITEM, hidden: hideCoupons }
            : BOKADIREKT_WELLNESSCARD_LIST_ITEM,
        ]
      : []),
  ]
    .map((method, index) => mapPaymentMethodHiddenProp(method, methodsVisible && methodsVisible <= index))
    .map((method, index) => mapPaymentMethodSelectedProp(method, selectedPaymentMethod, alreadySelected))
    .map((method) => (useHighlighted ? mapPaymentMethodHighlightedProp(method) : method));

  const unsupportedMethods: PaymentMethodListItem[] = useRadioButtons
    ? []
    : [
        ...Object.values(CHECKOUT_PAYMENT_METHOD)
          .filter((method) => {
            const showAsUnsupported = (() => {
              // @TODO: remove when swish is fully released to merchants
              if (method === CHECKOUT_PAYMENT_METHOD.SWISH) {
                return false;
              }

              if (method === CHECKOUT_PAYMENT_METHOD.NONE) {
                return false;
              }

              if (method === CHECKOUT_PAYMENT_METHOD.KLARNA) {
                return !hasPaymentMethod(summary, CHECKOUT_PAYMENT_METHOD.QLIRO);
              }

              if (method === CHECKOUT_PAYMENT_METHOD.QLIRO) {
                return !hasPaymentMethod(summary, CHECKOUT_PAYMENT_METHOD.KLARNA);
              }
              return true;
            })();

            return showAsUnsupported;
          })
          .filter((method) => !availablePaymentMethods.map((method) => method.type).includes(method))
          .map((method) => mapPaymentMethodListItem({ type: method }))
          .flat()
          .filter((method) => {
            /**
             * Dont show Apple Pay or Google Pay as unsupported by merchant if CoF is available
             * since in that case the merchant supports Apple Pay and Google Pay but the client
             * is not supporting it (i.e browser support etc..).
             */
            if (
              method.type === CHECKOUT_PAYMENT_METHOD.APPLE_PAY ||
              method.type === CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY
            ) {
              return (
                supportedMethods.findIndex(
                  (supportedMethod) => supportedMethod.type === CHECKOUT_PAYMENT_METHOD.NEW_COF,
                ) === -1
              );
            }
            return true;
          }),
        ...(!showGiftcard ? [BOKADIREKT_GIFTCARD_LIST_ITEM] : []),
        ...(!showWellnesscard ? [BOKADIREKT_WELLNESSCARD_LIST_ITEM] : []),
      ]
        .map((method) => mapPaymentMethodHiddenProp(method))
        .filter((method) => {
          const methodsToHide = paymentMethodsToHideMap[triggerSource] || [];
          return !methodsToHide.includes(method.type);
        });

  const hasUnsupportedMethods = !!unsupportedMethods.length;

  return (
    <>
      <PaymentMethodsList
        paymentMethods={supportedMethods}
        onClick={onPaymentSelected}
        useRadioButtons={useRadioButtons}
        isGiftcardCheckout={triggerSource === 'checkout_giftcard_summary'}
        type="supported"
      />
      {hasUnsupportedMethods && (
        <PaymentMethodsList
          paymentMethods={unsupportedMethods}
          onClick={() => null}
          useRadioButtons={useRadioButtons}
          type="unsupported"
        />
      )}
    </>
  );
};

export default PickPaymentMethod;
