import Card from '@/components/elements/Card/Card';
import { Fab } from '@/components/elements/forms/buttons';
import { ListItem } from '@/components/elements/lists';
import { CompanyAvatar } from '@/components/elements/redesign/Avatar';
import Rating from '@/components/elements/redesign/Rating/Rating';
import Icon from '@/components/icons/Icon';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { useRef } from 'react';
import Slider from 'react-slick';

const baseTranslationKey = 'features.ProfilePages.components.Companies';

type CompaniesProps = {
  companies: { id: number; name: string; address: { street?: string; city?: string }; rating: number }[];
  title: string;
  city?: string;
};

const Companies = ({ companies, title, city }: CompaniesProps) => {
  const { isMobileView } = useMobileView();
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: isMobileView ? 1.35 : 2.35,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <Card size={isMobileView ? 'lg' : 'xl'} childClassName="pr-0">
      <div className="gap-md flex flex-col">
        <div className="pr-3xl flex justify-between">
          <h2 className="text-h-s">
            <span className="font-bold">{title}</span>
            &nbsp;
            <span>
              {_s(`${baseTranslationKey}.title`)} &nbsp;
              {city ? _s('in') + ' ' + city : ''}
            </span>
          </h2>
          {!isMobileView && (
            <div className="gap-md flex">
              <Fab
                icon={<Icon variant="chevron-left" color="fg_primary" />}
                onClick={prevSlide}
                size="sm"
                variant="secondary"
              />
              <Fab
                icon={<Icon variant="chevron-right" color="fg_primary" />}
                onClick={nextSlide}
                size="sm"
                variant="secondary"
              />
            </div>
          )}
        </div>

        <div className="slider-container">
          <Slider ref={sliderRef} {...settings}>
            {companies.map((card) => {
              const formattedAddressString = `${card.address.street ? card.address.street + ', ' : ''}${
                card.address.city
              }`;
              return (
                <div key={card.id}>
                  <div className="mr-md bg-surface_l2 border-black-200 p-md flex-2 gap-xs flex flex-col rounded-lg border">
                    <h2 className="text-fg_primary text-l truncate">{card.name}</h2>
                    <ListItem
                      leftPadding={false}
                      to={`/places/${card.id}`}
                      className="!py-0 !pr-0"
                      leftSlot={<CompanyAvatar variant="default" size="md" alt="" />}>
                      {card.rating !== undefined && card.rating !== null && <Rating rating={card.rating} size="md" />}
                      <div className="text-m truncate">{formattedAddressString}</div>
                    </ListItem>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </Card>
  );
};

export default Companies;
