import { classnames } from '@/helpers';
import { ReactNode } from 'react';

type EmptyStateProps = {
  icon?: React.ReactNode;
  title?: string;
  body?: string | ReactNode;
  cta?: React.ReactNode;
  className?: string;
  containerClassName?: string;
};
const EmptyState = ({ icon, title, body, cta, className = '', containerClassName }: EmptyStateProps) => {
  return (
    <div
      className={`p-xl flex h-full w-full justify-center ${
        containerClassName ?? ''
      } bg-bg_secondary_neutral rounded-lg`}>
      <div className="flex flex-col justify-center">
        <div className={classnames(`gap-md p-lg flex flex-col justify-center`, className)}>
          {icon && (
            <div className="flex justify-center">
              <div className={`h-auto w-[82px]`}>{icon}</div>
            </div>
          )}
          {(title || body) && (
            <div className={`gap-xs flex flex-col`}>
              {title && (
                <div className="text-center">
                  <h2 className="text-fg_primary text-h-s">{title}</h2>
                </div>
              )}
              {body && (
                <div className="text-center">
                  <p className={`text-s text-fg_secondary`}>{body}</p>
                </div>
              )}
            </div>
          )}
          {cta && <div className="flex justify-center">{cta}</div>}
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
