import { classnames } from '@/helpers';
import { twMerge } from 'tailwind-merge';

const SPACING = {
  '0': '',
  xs: 'py-xxs',
  sm: 'py-md',
  md: 'py-lg',
  lg: 'py-xxl',
};

const HR = ({
  verticalSpacing = '0',
  className = '',
}: {
  verticalSpacing?: '0' | 'xs' | 'sm' | 'md' | 'lg';
  className?: string;
}) => {
  return (
    <span className={classnames('inline-flex w-full', SPACING[verticalSpacing])}>
      <span className={twMerge(classnames('bg-black-100 my-auto h-[1px] w-full'), className)} />
    </span>
  );
};

export default HR;
