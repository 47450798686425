import { server } from '@/helpers';
import { z } from 'zod';

const saveExternalRequest = z.object({
  placeId: z.number(),
  stars: z.number(),
  message: z.string().optional(),
});

type SaveExternalRequest = z.infer<typeof saveExternalRequest>;

const updateExternalRequest = z.object({
  reviewId: z.number(),
  placeId: z.number(),
  stars: z.number().optional(),
  message: z.string().optional(),
});

type UpdateExternalRequest = z.infer<typeof updateExternalRequest>;

const getPlaceReviews = (placeId: number, page: number, limit: number) =>
  server.request
    .get(`/places/reviews/${placeId}`, {
      params: {
        page,
        limit,
      },
    })
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);

const getReviewData = (userId, bookingId, reviewId) =>
  server.request
    .get(`/reviews/booking-review/${userId}/${bookingId}/` + (reviewId ? '?reviewId=' + reviewId : ''))
    .then(server.handleSuccess)
    .catch(server.handleError);

const save = (data) => server.request.post(`/reviews/save`, data).then(server.handleSuccess).catch(server.handleError);

const saveExternal = (data: SaveExternalRequest) =>
  server.request.post(`/reviews/external/save`, data).then(server.handleSuccess).catch(server.handleErrorObject);

const updateExternal = (data: UpdateExternalRequest) =>
  server.request.post(`/reviews/external/update`, data).then(server.handleSuccess).catch(server.handleErrorObject);

const deleteExternal = (reviewId: string) =>
  server.request.delete(`/reviews/external/${reviewId}`).then(server.handleSuccess).catch(server.handleErrorObject);

const uploadFile = (data) =>
  server.request
    .post(`/reviews/upload-file`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(server.handleSuccess)
    .catch(server.handleError);

const deleteFile = (data) =>
  server.request.post(`/support-form/delete-file`, data).then(server.handleSuccess).catch(server.handleError);

export const reviewsServices = {
  save,
  uploadFile,
  deleteFile,
  getReviewData,
  saveExternal,
  updateExternal,
  deleteExternal,
  getPlaceReviews,
};
