import Card from '@/components/elements/Card/Card';
import { ListItem } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import { _s } from '@/locale';
import { PLACE_IMPRESSION, placeService } from '@/services';

const baseTranslationKey = 'features.ProfilePages.components.Links';

type LinksProps = {
  instagram?: string;
  facebook?: string;
  website?: string;
  onWebsiteClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  placeId: number;
};

const Links = ({ placeId, instagram, facebook, website, onWebsiteClick }: LinksProps) => {
  const instagramLink = instagram ? 'https://www.instagram.com/' + instagram : ''; // @TODO add sales trigger link to button when adding support for unverified places and basic subscriptions
  const faceBookLink = facebook ? 'https://www.facebook.com/' + facebook : ''; // @TODO add sales trigger link to button when adding support for unverified places and basic subscriptions

  const handleCopyText = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const handleInstagramClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (instagram) {
      placeService.storePlaceImpressions([placeId], PLACE_IMPRESSION.PLACE_INSTAGRAM_CLICK);
    }
  };

  const handleFacebookClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (facebook) {
      placeService.storePlaceImpressions([placeId], PLACE_IMPRESSION.PLACE_FACEBOOK_CLICK);
    }
  };

  return (
    <Card size="lg">
      <div className="gap-xs flex flex-col">
        <h2 className="text-h-s">{_s(`${baseTranslationKey}.title`)}</h2>
        <ul>
          {website && (
            <ListItem
              leftPadding={false}
              className="gap-sm"
              underline
              leftSlot={<Icon variant="website" color="fg_secondary" />}
              {...(website
                ? {
                    rightSlot: (
                      <button className="flex outline-none" onClick={() => handleCopyText(website)}>
                        <Icon variant="copy" color="fg_primary" style={{ height: '20px', width: '20px' }} />
                      </button>
                    ),
                  }
                : {})}>
              <a
                href={website}
                target="_blank"
                rel="noreferrer"
                onClick={onWebsiteClick}
                className="text-l text-fg_link underline">
                {website.replace(/^https?:\/\//, '')}
              </a>
            </ListItem>
          )}
          {instagram && (
            <ListItem
              leftPadding={false}
              className="gap-sm"
              underline
              leftSlot={<Icon variant="instagram" color="fg_secondary" />}
              {...(instagram
                ? {
                    rightSlot: (
                      <button className="flex outline-none" onClick={() => handleCopyText(instagramLink)}>
                        <Icon variant="copy" color="fg_primary" style={{ height: '20px', width: '20px' }} />
                      </button>
                    ),
                  }
                : {})}>
              <a
                href={instagramLink}
                target="_blank"
                rel="noreferrer"
                onClick={handleInstagramClick}
                className="text-l text-fg_link underline">
                {instagram ?? _s(`${baseTranslationKey}.addInstagram`)}
              </a>
            </ListItem>
          )}
          {facebook && (
            <ListItem
              leftPadding={false}
              className="gap-sm"
              leftSlot={<Icon variant="facebook" color="fg_secondary" />}
              {...(facebook
                ? {
                    rightSlot: (
                      <button className="flex outline-none" onClick={() => handleCopyText(faceBookLink)}>
                        <Icon variant="copy" color="fg_primary" style={{ height: '20px', width: '20px' }} />
                      </button>
                    ),
                  }
                : {})}>
              <a
                href={faceBookLink}
                onClick={handleFacebookClick}
                target="_blank"
                rel="noreferrer"
                className="text-l text-fg_link underline">
                {facebook ?? _s(`${baseTranslationKey}.addFacebook`)}
              </a>
            </ListItem>
          )}
        </ul>
      </div>
    </Card>
  );
};

export default Links;
