import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { getGeoLocation } from '@/helpers';
import { _s } from '@/locale';
import { Dispatch, createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

type GeolocationPosition = {
  latitude: number;
  longitude: number;
};

function displayErrorToast() {
  toast(({ closeToast }) => <Snackbar label={_s('locationPermissionError')} onClose={closeToast} />);
}

async function getUserLocationPermission(
  setPermission: Dispatch<PermissionState | null>,
  setLoading: Dispatch<boolean>,
) {
  const permission = await navigator.permissions.query({ name: 'geolocation' });
  setPermission(permission.state);
  setLoading(false);
}

function requestUserLocationPermission(
  setPosition: Dispatch<GeolocationPosition | null>,
  setPermission: Dispatch<PermissionState | null>,
  setLoading: Dispatch<boolean>,
  callback: (position: GeolocationPosition) => void,
) {
  setLoading(true);

  getGeoLocation(
    (lat: number, lon: number) => {
      setPosition({ latitude: lat, longitude: lon });
      callback({ latitude: lat, longitude: lon });
      getUserLocationPermission(setPermission, setLoading);
      setLoading(false);
    },
    null,
    null,
    () => {
      displayErrorToast();
      setLoading(false);
    },
    (lat: number, lon: number) => {
      callback({ latitude: lat, longitude: lon });
      setPosition({ latitude: lat, longitude: lon });
      setPermission('denied');
      setLoading(false);
    },
  );
}

export type UserLocationPermissionManager = ReturnType<typeof useUserLocationPermissioManager>;

const useUserLocationPermissioManager = () => {
  const [permission, setPermission] = useState<PermissionState | null>(null);
  const [position, setPosition] = useState<GeolocationPosition | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if ('geolocation' in navigator && navigator.permissions && navigator.permissions.query) {
      getUserLocationPermission(setPermission, setLoading);
    }
  }, []);

  const handleRequestUserLocationPermission = (callback: (position: GeolocationPosition) => void) => {
    requestUserLocationPermission(setPosition, setPermission, setLoading, callback);
  };

  return {
    position,
    permission,
    loading,
    onRequestUserLocationPermission: handleRequestUserLocationPermission,
  };
};

const UseLocationPermissionContext = createContext<UserLocationPermissionManager>({
  loading: false,
  permission: null,
  position: null,
  onRequestUserLocationPermission: () => {},
});

export const useLocationPermissionContext = () => {
  const context = useContext(UseLocationPermissionContext);
  if (!context) {
    throw new Error('useLocationPermissionContext must be used within a LocationPermissionProvider');
  }
  return context;
};

export const LocationPermissionProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <UseLocationPermissionContext.Provider value={useUserLocationPermissioManager()}>
      {children}
    </UseLocationPermissionContext.Provider>
  );
};
