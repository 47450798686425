import { CofPaymentData, MerchantAccount } from '@/types/adyen';
import {
  CreatePaymentRequest,
  CreatePaymentResponse,
  SubmitAdditionalDetailsRequest,
  SubmitAdditionalDetailsResponse,
} from '@/types/api/services/adyen';
import { server } from '../helpers';

export const adyenServices = {
  getCards,
  saveUserCard,
  createSession,
  removeCard,
  validateMerchant,
  validate3DS,
  createPayment,
  submitDetails,
  submitAdditionalDetails,
};

function getCards(guestId?: string) {
  return server.request
    .get(`/adyen/cards${guestId ? `/${guestId}` : ''}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function saveUserCard(params: { guestId?: string; paymentData: CofPaymentData }) {
  return server.request.post('/adyen/cards', params).then(server.handleSuccess).catch(server.handleError);
}

function createSession(returnPath: string, amount?: number, guestId?: string, isGiftcardCheckout?: boolean) {
  return server.request
    .post('/adyen/session', { returnPath, amount, guestId, isGiftcardCheckout })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function removeCard(cardId: string) {
  return server.request.post(`/adyen/remove-card/${cardId}`).then(server.handleSuccess).catch(server.handleError);
}

function validate3DS({ details }: { details: { redirectResult: string } } | { details: { threeDSResult: string } }) {
  return server.request
    .post('/adyen/validate-3ds', { details })
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);
}

function validateMerchant(validationURL: string, merchantAccount: MerchantAccount) {
  return server.request
    .post('/adyen/validate-merchant', { validationURL, merchantAccount })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function submitAdditionalDetails(params: {
  bookingId: number;
  details: object;
  reference: string;
}): SubmitAdditionalDetailsResponse {
  return server.request.post('/adyen/complete', params).then(server.handleSuccess).catch(server.handleErrorObject);
}

function createPayment(params: CreatePaymentRequest): Promise<CreatePaymentResponse> {
  return server.request.post('/adyen/payments', params).then(server.handleSuccess).catch(server.handleErrorObject);
}

function submitDetails(params: SubmitAdditionalDetailsRequest): Promise<CreatePaymentResponse> {
  return server.request
    .post('/adyen/payments/details', params)
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);
}
