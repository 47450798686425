import { server } from '@/helpers';
import { useEffect, useState } from 'react';
import { InstagramFeed } from '@/components/modules/salon';

function EmployeeInstagramFeed(props) {
  const { employee } = props;
  const [mediaFeed, setMediaFeed] = useState([]);

  const profile = employee?.contact?.instagram;

  useEffect(() => {
    fetchInstagramPhotos();
  }, []);

  async function fetchInstagramPhotos() {
    try {
      const result = await server.request
        .get('/places/employee/ig-feed/' + employee.id)
        .then(server.handleSuccess)
        .catch(server.handleError);

        if (result && result.length > 0) {
          setMediaFeed(result);
        }
    } catch (error) {
      console.error('Failed to fetch Instagram photos:', error);
    }
  }

  return <InstagramFeed profile={profile} media={mediaFeed} hasCard={false} />;
}

export default EmployeeInstagramFeed;
