/* eslint react/jsx-no-target-blank: 0 */
import { loadingActions } from '@/actions';
import { Button } from '@/components/elements/forms/buttons';
import { LoadingIcon } from '@/components/icons';
import { getAddress, getCookie, getDirectionsUrl, getGeoLocation, isIOS, isMobile, setCookie, url } from '@/helpers';
import { __ } from '@/locale';
import React from 'react';
import { connect } from 'react-redux';
import Actionsheet from './Actionsheet';
import { MapStatic } from '@/components/modules/map';

class Location extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showActionsheet: false };
  }

  getParams() {
    const {
      contact,
      showAddress = true,
      showMap = false,
      showOnlyDirections = false,
      showOnlyMap = false,
      useMapTiler = false,
    } = this.props;
    if (!(contact && contact.position && contact.position.lat && contact.position.lon))
      return {
        discard: true,
      };

    const address = getAddress(contact.address);
    const gotoLatitude = contact.position.lat;
    const gotoLongitude = contact.position.lon;
    const gotoLocation = gotoLatitude + ':' + gotoLongitude;

    return {
      gotoLatitude,
      gotoLongitude,
      address,
      gotoLocation,
      contact,
      showAddress,
      showMap,
      showOnlyDirections,
      showOnlyMap,
      useMapTiler,
    };
  }

  openInAppleMaps(params) {
    const url =
      (!this.state.showDirections ? 'http://maps.apple.com/?address=' : 'http://maps.apple.com/?daddr=') +
      encodeURI(params.gotoLatitude + ',' + params.gotoLongitude);
    window.location.href = url;
  }

  openInGoogleMaps(params) {
    const coordinatesUri = encodeURI(params.gotoLatitude + ',' + params.gotoLongitude);
    const addressUri = encodeURI(params.address);
    const url = !this.state.showDirections
      ? `https://www.google.com/maps/search/?api=1&query=${params.showOnlyDirections ? coordinatesUri : addressUri}`
      : `https://www.google.com/maps/dir/?api=1&destination=${coordinatesUri}`;
    window.open(url, '_blank');
  }

  openInMaps(showDirections = false) {
    if (isIOS()) {
      this.setState({ showActionsheet: true, showDirections });
    } else {
      const params = this.getParams();
      if (showDirections) {
        this.setState({ showDirections }, () => {
          this.openInGoogleMaps(params);
        });
      } else {
        this.openInGoogleMaps(params);
      }
    }
  }

  render() {
    const params = this.getParams();
    const {
      discard,
      contact,
      gotoLongitude,
      gotoLatitude,
      showAddress,
      showMap,
      showOnlyDirections,
      showOnlyMap,
      useMapTiler,
    } = params;
    if (discard) return null;

    const address = contact && contact.address ? contact.address : null;

    let actionsheetMenus = [
      {
        text: __('openInGoogleMaps'),
        action: () => this.openInGoogleMaps(params),
      },
    ];
    if (isIOS()) {
      actionsheetMenus.push({
        text: __('openInAppleMaps'),
        action: () => this.openInAppleMaps(params),
      });
    }

    return (
      <div id={showMap ? 'location-map' : 'location'}>
        <div className={`text-inherit ${showMap ? 'mb-4' : ''}`} onClick={this.handleAddressClick(params)}>
          {showAddress && (
            <p className="cursor-pointer">
              <span>{address && address.street ? address.street.trim() : ''}</span>
              {address && address.zipcode ? ', ' : ''}
              <span>{address && address.zipcode ? address.zipcode.trim() : ''}</span>
              {address && address.city ? ', ' : ''}
              <span>{address && address.city ? address.city.trim() : ''}</span>
            </p>
          )}
        </div>
        {showMap && (
          <>
            {!showOnlyDirections && (
              <div onClick={this.handleMapClick(params)} className="link col-12 cursor-pointer p-0">
                <MapStatic
                  className="rounded-lg"
                  zoom={15}
                  latitude={gotoLatitude}
                  longitude={gotoLongitude}
                  width="270"
                  height="110"
                  useStaticImage={true}
                  useMapTiler={useMapTiler}
                />
              </div>
            )}
            {!showOnlyMap && <div className="mt-4 flex justify-center">{this.getDirectionsHtml(params)}</div>}
          </>
        )}
        {isMobile() && (
          <Actionsheet
            menus={actionsheetMenus}
            show={this.state.showActionsheet}
            hide={() => (e) => this.setState({ showActionsheet: false })}
          />
        )}
      </div>
    );
  }

  getDirectionsHtml(params) {
    const { loadSource, show } = this.props;

    const buttonProps = {
      ...(show && loadSource === 'loadLocation'
        ? {
            rightIcon: <LoadingIcon />,
          }
        : {}),
    };

    return (
      <Button variant="secondary" onClick={this.handleButtonClick(params)} {...buttonProps}>
        {__('getDirection')}
      </Button>
    );
  }

  getCurrentPosition(params) {
    const { dispatch } = this.props;
    if (navigator.geolocation) {
      getGeoLocation(
        (lat, lon) => {
          dispatch(loadingActions.hide());
          this.setLatitudeAndLongitude(lat, lon);
          this.gotoDirectionsMap(params, lat + ':' + lon);
        },
        () => {},
        () => {
          url.fakeLoading(dispatch, 'loadLocation', 120000);
        },
        () => {
          dispatch(loadingActions.hide());
          this.gotoDirectionsMap(params);
        },
      );
    } else {
      this.gotoDirectionsMap(params);
    }
  }

  handleAddressClick = (params) => (e) => {
    this.openInMaps();
  };

  handleButtonClick = (params) => (e) => {
    this.openInMaps(true);
  };

  handleMapClick = (params) => (e) => {
    this.openInMaps();
  };

  getDirections(params) {
    const userPosition = getCookie('position'); // user location
    if (userPosition && userPosition.position) {
      const fromLocation = userPosition.position.replace('-', ':');
      this.gotoDirectionsMap(params, fromLocation);
    } else {
      this.getCurrentPosition(params);
    }
  }

  gotoDirectionsMap(params, fromLocation) {
    const { address, gotoLocation } = params;
    window.open(getDirectionsUrl(address, gotoLocation, fromLocation), '_blank');
  }

  setLatitudeAndLongitude(latitude, longitude) {
    const pos = { position: latitude + '-' + longitude };
    setCookie('position', pos);
  }
}

function mapStateToProps(state) {
  const { show, loadSource } = state.loading;
  return {
    loadSource,
    show,
  };
}

const connectedLocation = connect(mapStateToProps)(Location);
export { connectedLocation as Location };
