import { classnames } from '@/helpers';
import css from './Icon.module.scss';
import { IconProps, IconVariant } from './types';

const getImageNameWithCacheBreaker = (variant: IconVariant) => {
  switch (variant) {
    case 'phone':
      return 'phone2';
    default:
      return variant;
  }
};

const Icon = ({ ext, variant, color = 'black-900', className = '', size, noFilter = false, ...props }: IconProps) => {
  const extension = ext ?? (props.style?.height > 24 || props.style?.width > 24) ? 'svg' : 'png';

  return (
    <img
      src={`/images/icons/${getImageNameWithCacheBreaker(variant)}.${extension}`}
      {...props}
      className={classnames(noFilter ? '' : `${css.icon} ${css[color]}`, size && `${css.icon} ${css[size]}`, className)}
      alt={props.alt ?? ''}
    />
  );
};

export default Icon;
