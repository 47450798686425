import { getTrackingPlace, getTrackingSearch, trackMpEvent } from '@/helpers';
import { SearchManagerOutput } from '@/hooks/search/useSearch';
import { DEFAULT_MY_LOCATION_KEYWORD } from '@/hooks/search/useSearch.constants';
import { SearchType } from '@/hooks/search/useSearch.helpers';
import { SearchState } from '@/reducers/searchV2Reducer';
import { FILTER_ID, UrlSearchState } from '@/types/state/search';
import {
  CAMPAIGNS_SHOWN,
  SEARCH_FILTERS,
  SEARCH_LOCATION_FILTER,
  SEARCH_TIME_FILTER,
  SORTED_BY,
} from '../constants/tracking';
import {
  CampaignsShown,
  ScreenName,
  SearchContext,
  SearchFilters,
  SearchLocationFilter,
  SearchTimeFilter,
  SortedBy,
  TrackingProps,
} from '../types/tracking';

export function trackSearchPerformed(props: TrackingProps) {
  trackMpEvent('search_performed', props);
}

export function getSearchPerformedTrackingProps({
  urlState,
  searchState,
  context,
  screenName,
}: {
  urlState: UrlSearchState;
  searchState: SearchState;
  context: SearchContext;
  screenName: ScreenName;
}): TrackingProps {
  return {
    screen_name: screenName,
    search_context: context,
    search_query: urlState?.q.trim?.().length > 0 ? urlState.q : undefined,
    search_location_filter: getSearchLocationFilter(urlState),
    search_filters: getSearchFilters(urlState),
    sorted_by: getSortedBy(urlState),
    search_time_filter: getSearchTimeFilter(urlState),
    campaigns_shown: getCampaignsShown(searchState),
    search_location_city: getSearchLocationCity(urlState),
    nbr_search_results: searchState.results > 0 ? searchState.results : undefined,
  };
}

function getCampaignsShown(state: SearchState): CampaignsShown | undefined {
  const campaigns = [];

  if (state.topSearch?.places?.length > 0) {
    campaigns.push(CAMPAIGNS_SHOWN.RAKETEN);
  }

  // @TODO: implement CAMPAIGNS_SHOWN.SISTAMINUTEN and CAMPAIGNS_SHOWN.DEALS when its time..

  return campaigns.length > 0 ? campaigns : undefined;
}

function getSearchLocationCity(state: UrlSearchState): string[] | undefined {
  const locations = [];

  if (state.location) {
    locations.push(state.location);
  }

  return locations.length > 0 ? locations : undefined;
}

function getSearchTimeFilter(state: UrlSearchState): SearchTimeFilter {
  if (state.timeOfDay || state.startDate || state.endDate) {
    return SEARCH_TIME_FILTER.USER_INPUT;
  }

  return SEARCH_TIME_FILTER.DEFAULT;
}

function getSearchLocationFilter(state: UrlSearchState): SearchLocationFilter {
  if (state.location === DEFAULT_MY_LOCATION_KEYWORD) {
    return SEARCH_LOCATION_FILTER.GPS_LOCATION;
  } else if (state.location) {
    return SEARCH_LOCATION_FILTER.USER_INPUT;
  }

  return undefined;
}

function getSortedBy(state: UrlSearchState): SortedBy {
  switch (state.sort) {
    case 'popular':
      return SORTED_BY.POPULAR;
    case 'closest':
      return SORTED_BY.CLOSEST;
    case 'reviews_score':
      return SORTED_BY.HIGHEST_RATING;
    default:
      return SORTED_BY.POPULAR;
  }
}

function getSearchFilters(state: UrlSearchState): SearchFilters | undefined {
  const prefs = (state.prefs?.split(',') ?? []).map((pref) => +pref);

  const filters = [];

  for (const pref of prefs) {
    if (pref === FILTER_ID.WELLNESSCARD) {
      filters.push(SEARCH_FILTERS.WELLNESS_CARD);
    }

    if (pref === FILTER_ID.GIFTCARD) {
      filters.push(SEARCH_FILTERS.GIFT_CARD);
    }

    if (pref === FILTER_ID.PAY_LATER) {
      filters.push(SEARCH_FILTERS.PAY_LATER);
    }

    if (pref === FILTER_ID.CERTIFIED) {
      filters.push(SEARCH_FILTERS.ASSOCIATIONS);
    }

    if (pref === FILTER_ID.CAMPAIGN) {
      filters.push(SEARCH_FILTERS.CAMPAIGNS);
    }

    if (pref === FILTER_ID.BUNDLE) {
      filters.push(SEARCH_FILTERS.BUNDLES);
    }
  }

  return filters.length > 0 ? filters : undefined;
}

export function getPageScreenName(type: SearchType, currentView: 'list' | 'map', isMobileView: boolean): ScreenName {
  switch (type) {
    case 'default':
      return isMobileView && currentView !== 'list' ? 'search_results_map' : 'search_results';
    case 'sistaminuten':
      return isMobileView && currentView !== 'list' ? 'deals_map' : 'deals';
    default:
      const _exhaustiveCheck: never = type;
      throw new Error(`Unhandled type: ${_exhaustiveCheck}`);
  }
}

export function trackPlaceClick(
  searchManager: SearchManagerOutput,
  place: any,
  index: number,
  hasDiscount: boolean,
  screenName: ScreenName,
) {
  const trackingProps = getTrackingPlace(place, screenName);
  const hasDiscount20 = !place.topSearch && hasDiscount && place?.about?.settings?.topOfSearch;

  trackingProps['position'] = index;
  trackingProps['has_discount'] = hasDiscount20 ? 'yes' : 'no';
  trackingProps['has_raketen'] = place.topSearch && hasDiscount ? 'yes' : 'no';

  if (hasDiscount20) {
    trackingProps['discount_percentage'] = '20';
  }

  trackMpEvent('company_clicked', {
    ...trackingProps,
    ...getTrackingSearch({ q: searchManager.q, location: searchManager.location }),
  });
}
