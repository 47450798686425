import {
  AdyenAdditionalDetails,
  CreatePaymentResponse as AdyenCreatePaymentResponse,
} from '@/types/api/services/adyen';
import { server } from '../helpers';

import {
  AdyenGiftcardOrderRequest,
  CheckDiscountCodeResponse,
  Order,
  QliroGiftcardOrderRequest,
  SwishGiftcardOrderRequest,
} from '@/types/api/services/giftcard';
import { CreatePaymentResponse as QliroPaymentResponse } from '@/types/api/services/qliro';
import { SwishPaymentResponse } from '@/types/api/services/swish';

function checkDiscountCode(code: string, orderTotal: number): Promise<CheckDiscountCodeResponse> {
  return server.request
    .post('/giftcards/discount', { code, orderTotal })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function createGiftcardAdyenOrder(
  body: AdyenGiftcardOrderRequest,
): Promise<AdyenCreatePaymentResponse & { pspRef: string }> {
  return server.request.post('/adyen/giftcard/orders', body).then(server.handleSuccess).catch(server.handleErrorObject);
}

function submitGiftcardAdyenPaymentDetails(
  body: AdyenAdditionalDetails,
): Promise<AdyenCreatePaymentResponse & { pspRef: string }> {
  return server.request
    .post('/adyen/giftcard/details', body)
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);
}

function createGiftcardSwishOrder(body: SwishGiftcardOrderRequest): Promise<SwishPaymentResponse> {
  return server.request.post('/swish/giftcard/orders', body).then(server.handleSuccess).catch(server.handleErrorObject);
}

function createGiftcardQliroOrder(body: QliroGiftcardOrderRequest): Promise<QliroPaymentResponse> {
  return server.request.post('/qliro/giftcard/orders', body).then(server.handleSuccess).catch(server.handleErrorObject);
}

function getGiftcardOrder(id: string): Promise<Order> {
  return server.request.get(`/giftcard/orders/${id}`).then(server.handleSuccess).catch(server.handleErrorObject);
}

export function saveGiftcard(token: string) {
  return server.request
    .get(`/giftcards/save?token=${token}`)
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);
}

export function getGiftcardCode(token: string) {
  return server.request
    .get(`/giftcards/token?token=${token}`)
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);
}

export const giftCardServices = {
  checkDiscountCode,
  createGiftcardAdyenOrder,
  submitGiftcardAdyenPaymentDetails,
  getGiftcardOrder,
  createGiftcardSwishOrder,
  createGiftcardQliroOrder,
};
