import { modalActions, userActions } from '@/actions';
import { showNavigationDrawer } from '@/actions/navigationDrawerActions';
import { NAVIGATION_TRIGGER, NavigationTrigger } from '@/constants/navigation';
import { isServer, url } from '@/helpers';
import { NavItemIdentifier } from '@/types/navigation';
import { Dispatch } from 'redux';

const getNavClickEventName = (identifier: NavItemIdentifier) => `navigation_${identifier}_click`;

export const handleLogoutClick = async (dispatch: Dispatch<any>, identifier: NavItemIdentifier, screenName: string) => {
  dispatch(userActions.logout());
};

const handleBuyGiftcardClick = (identifier: NavItemIdentifier) => {};

const handleProfileClick = (name) => {};

export const handleLoginClick = async (
  dispatch: Dispatch<any>,
  identifier?: NavItemIdentifier,
  screenName?: string,
  triggerSource?: string,
) => {
  if (!isServer) {
    const hasValidTriggerSource = Object.values(NAVIGATION_TRIGGER).includes(triggerSource as NavigationTrigger);
    dispatch(
      modalActions.login({
        show: true,
        ...(hasValidTriggerSource ? { triggerSource: triggerSource as NavigationTrigger } : {}),
      }),
    );
  }
};

const handleHamburgerClick = (dispatch) => {
  dispatch(showNavigationDrawer({ show: true }));
};

export const handleNavItemClicked =
  (dispatch: Dispatch<any>, screenName, triggerSource = 'header_menu') =>
  (identifier: NavItemIdentifier) =>
  () => {
    if (identifier === NavItemIdentifier.Logout) return handleLogoutClick(dispatch, identifier, screenName);
    if (identifier === NavItemIdentifier.GiftCardBuyNew) handleBuyGiftcardClick(identifier);
    if (identifier === NavItemIdentifier.Profile) handleProfileClick(identifier);
    if (identifier === NavItemIdentifier.LogIn)
      return handleLoginClick(dispatch, identifier, screenName, triggerSource);
    if (identifier === NavItemIdentifier.Hamburger) return handleHamburgerClick(dispatch);
  };

export const getScreenName = (propSource?: string) => {
  const source = propSource ?? 'outside';
  return source === 'list' || source === 'mapview'
    ? `search_results${source === 'list' ? '' : '_map'}`
    : isServer
    ? ''
    : url.getScreenNameFromUrl(window?.location?.href);
};
