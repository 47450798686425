import { classnames } from '@/helpers';
import * as React from 'react';
import { HTMLAttributes } from 'react';

const base = 'p-4 sm:p-6 border border-solid border-black-100 my-6 rounded-lg';
const shadow = 'shadow';
const attachedTopClass = 'rounded-t-none mt-0 border-t-0';

export const cardHeaderClass = 'text-h-m mt-0 mb-6 font-semibold';

type CardProps = HTMLAttributes<HTMLDivElement> & {
  hidden?: boolean;
  noShadow?: boolean;
  attachedTop?: boolean;
};

export const Card = ({ children, className, hidden, noShadow, attachedTop, ...props }: CardProps) => (
  <div className={classnames(base, className, !noShadow && shadow, attachedTop && attachedTopClass)} {...props}>
    {children}
  </div>
);

export const CardShadow = ({ children }: { children: React.ReactNode }) => <div className="shadow">{children}</div>;

export const CardImageHeader = ({ src, alt = '', source1 = '' }) => (
  <div className="border-black-100 mt-6 border-[1px] border-b-0">
    <picture>
      {!!source1 && <source srcSet={source1} />}
      <img src={src} alt={alt} className="w-full max-w-full object-cover object-center" />
    </picture>
  </div>
);
