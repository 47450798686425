import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translations from './translations';

const isServer = typeof window === 'undefined';
const savedLanguage =
  !isServer && localStorage && localStorage.getItem('language') ? localStorage.getItem('language') : null;

const availableLangs = [...Object.keys(translations)];

if (savedLanguage && !availableLangs.includes(savedLanguage)) {
  localStorage.setItem('language', 'sv');
}

const DETECTION_OPTIONS = {
  lookupLocalStorage: 'language',
  order: ['localStorage'],
  caches: ['localStorage'],
};

const resources = {
  en: { translations: translations['en'] },
  sv: { translations: translations['sv'] },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    detection: DETECTION_OPTIONS,
    fallbackLng: 'sv',
    ns: ['translations'],
    defaultNS: 'translations',
    // keySeparator: false,
    react: {
      useSuspense: false,
    },
    returnObjects: true,
    // interpolation: {
    //   escapeValue: false, // default true, escapes passed in values to avoid XSS injection
    // }
  });

const __ = i18n.t;

const _s = (key: string, params: Record<string, any> = null): string => i18n.t(key, params) as string;
const _a = (key: string, params: Record<string, any> = null): any[] => i18n.t(key, params) as any[];
export const _sTitle = (key: string, params: Record<string, any> = null): string =>
  _s(key, params).replace(/&amp;/g, '&');

const setLang = (lang) =>
  i18n.changeLanguage(lang, () => {
    if (!isServer && savedLanguage !== lang) {
      window.location.reload();
    }
  });

const getLang = () => i18n.language;
const getLanguages = () => ['sv', 'en'];
const getLocale = () => (getLang() === 'sv' ? 'sv-SE' : 'en-GB');

export { __, _a, _s, getLang, getLanguages, getLocale, i18n, setLang };
