import Label from '@/components/elements/Label/Label';
import { BeautyCategory } from '@/constants/beautyCategories';
import { SearchContext } from '@/features/searchV2/types/tracking';
import { isSistaminuten } from '@/helpers';
import { themed } from '@/helpers/theme';
import { DEFAULT_SEARCH_LOCATION } from '@/hooks/search/useSearch.constants';
import { Link, withRouter } from 'react-router-dom';

const getImageName = (imageName: string) =>
  imageName ? (isSistaminuten() ? `${imageName}-sistaminutentider` : imageName) : null;

type Props = { dataset: number; category: BeautyCategory; source: string; search?: any };

const DCCategory = (props: Props) => {
  const { category, source } = props;
  const { img, name, q, description, cta, onClick, url, overlay, hideName = false } = category;
  const pathname = url ? url : `/${encodeURI(`${q.toLowerCase()}/${DEFAULT_SEARCH_LOCATION}`)}`;
  const showHoverState = description && cta && !category.overlay;
  const imageName = getImageName(img);
  const searchContext: SearchContext = 'home_page_category';
  const to = { pathname: pathname, state: { searchContext } };

  const toList = (e, to) => {};

  const flexStyles = 'basis-full sm:basis-1/2 md:basis-1/3 ';

  return (
    <div className={`text-black-900 p-1 sm:p-2 lg:p-4 ${source !== 'home' ? '' : flexStyles}`}>
      <div className="relative">
        <Link to={to} onClick={onClick ? onClick : (e) => toList(e, to)} className="text-black-900">
          {imageName && (
            <div className="group relative">
              <picture>
                <source srcSet={`/images/${imageName}.webp`} type="image/webp" />
                <source srcSet={`/images/${imageName}.jpg`} type="image/jpeg" />
                <img
                  src={`/images/${imageName}.jpg`}
                  alt=""
                  className={`${themed(
                    'md:h-30 h-52 lg:h-48',
                    'h-52 sm:h-36 md:h-32 lg:h-44 xl:h-48',
                  )}  w-full rounded-md object-cover`}
                />
              </picture>
              {showHoverState && (
                <div className="bg-black-900 absolute left-0 top-0 z-10 hidden h-full w-full flex-col justify-start gap-2 rounded-md bg-opacity-90 p-4 text-white group-hover:flex">
                  <div className="flex-shrink overflow-hidden overflow-ellipsis text-sm text-white">{description}</div>
                  <div
                    className={`font-semibold ${themed(
                      'text-[#51A492]',
                      'text-sm_primary',
                    )} flex-shrink-0 flex-grow-0`}>
                    {cta}
                  </div>
                </div>
              )}
            </div>
          )}
          {!hideName && (
            <h3
              className={`text-base font-semibold ${
                source !== 'home' ? 'border-black-100 -mt-2 border-b pb-4' : 'mt-2'
              }`}>
              {name}
            </h3>
          )}
        </Link>
        {overlay && (
          <Link
            to={overlay.cta.to}
            className="bg-scrim-60 space-x-xs absolute left-0 top-0 flex w-full flex-row items-start rounded-tl-md rounded-tr-md p-3 text-white">
            <span className="tracking-content text-m flex-1">
              {overlay.text}
              {overlay.cta && (
                <>
                  &nbsp;
                  <span className="text-information-500 whitespace-nowrap underline">{overlay.cta.label}</span>
                </>
              )}
            </span>
            <Label label={overlay.label.label} className={overlay.label.className} />
          </Link>
        )}
      </div>
    </div>
  );
};

export default withRouter(DCCategory);
