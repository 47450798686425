import pinStyles from './Markers.module.scss';
import { config } from '@/config';
import { addStylesheet, isServer } from '../../../helpers';

let MapContainer = () => null;
let TileLayer = () => null;
let Marker = false;
let DivIcon = false;
if (!isServer) {
  const RL = require('react-leaflet');
  const L = require('leaflet');
  MapContainer = RL.MapContainer;
  TileLayer = RL.TileLayer;
  Marker = RL.Marker;
  DivIcon = L.DivIcon;
}

const StaticMap = ({
  latitude,
  longitude,
  zoom = 15,
  width = '600',
  height = '400',
  useStaticImage = true,
  useMapTiler = false,
}) => {
  if (useStaticImage && useMapTiler) {
    const { mapId = 'streets-v2', apiKey } = config.mapTiler;

    const pinSrc = 'https://www.bokadirekt.se/images/map-pin-empty.svg';
    const marker = `icon:${pinSrc}|anchor:center|${longitude},${latitude}`;
    const mapUrl = `https://api.maptiler.com/maps/${mapId}/static/${longitude},${latitude},${zoom}/${width}x${height}.png?key=${apiKey}&markers=${marker}`;

    return <img width={width} height={height} src={mapUrl} alt="map" loading="lazy" className="rounded-lg" />;
  }

  addStylesheet('https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.2.0/leaflet.css');

  const ic = new DivIcon({
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    className: 'place-pin',
    html: '<img class="' + pinStyles.placePinImage + '" src="/images/map-pin-empty.svg"/>',
  });

  return (
    <MapContainer
      className="rounded-lg"
      center={[latitude, longitude]}
      zoom={zoom}
      style={{ width: width + 'px', height: height + 'px' }}
      dragging={false}
      touchZoom={false}
      scrollWheelZoom={false}
      doubleClickZoom={false}
      boxZoom={false}
      keyboard={false}
      zoomControl={false}>
      {useMapTiler ? (
        <TileLayer
          url={`https://api.maptiler.com/maps/${mapId}/256/{z}/{x}/{y}.png?key=${apiKey}`}
          attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a>'
        />
      ) : (
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        />
      )}
      <Marker position={[latitude, longitude]} icon={ic} />
    </MapContainer>
  );
};

export default StaticMap;
