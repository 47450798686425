import { config } from '@/config';
import { MerchantAccount } from '@/types/adyen';

export const environment = config.env === 'production' || config.env === 'staging' ? 'PRODUCTION' : 'TEST';

const allowedPaymentMethods = (
  merchantAccount: MerchantAccount,
): google.payments.api.PaymentDataRequest['allowedPaymentMethods'] => [
  {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: ['PAN_ONLY'],
      allowedCardNetworks: ['AMEX', 'DISCOVER', 'MASTERCARD', 'VISA'],
    },
    tokenizationSpecification: {
      type: 'PAYMENT_GATEWAY',
      parameters: {
        gateway: 'adyen',
        gatewayMerchantId: (() => {
          switch (merchantAccount) {
            case 'ecom':
              return config.googlepay.gatewayMerchantId.ecom;
            case 'gc':
              return config.googlepay.gatewayMerchantId.gc;
            default:
              const _exhaustiveCheck: never = merchantAccount;
              throw new Error(`Unhandled merchant account: ${_exhaustiveCheck}`);
          }
        })(),
      },
    },
  },
];

export const baseRequestWithoutTransactionInfo = (
  merchantAccount: MerchantAccount,
): Omit<google.payments.api.PaymentDataRequest, 'transactionInfo'> => ({
  apiVersion: 2,
  apiVersionMinor: 0,
  allowedPaymentMethods: allowedPaymentMethods(merchantAccount),
  merchantInfo: { merchantId: config.googlepay.merchantId, merchantName: config.googlepay.merchantName },
});
