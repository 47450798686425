import { _s } from '@/locale';
import { PHYSICAL_FEE } from '@/pages/presentkort/GiftcardCheckout.hooks';
import { CheckoutTracking } from '@/types/analytics';
import { trackEventTikTok, trackMpAnalyticsEvent, trackMpEvent, trackMpRevenueGiftcards } from './analytics';

export const getGiftcardTotalAmount = ({
  amount,
  quantity,
  isPhysical,
}: {
  amount: number;
  quantity: number;
  isPhysical: boolean;
}) => amount * quantity + (isPhysical ? PHYSICAL_FEE : 0);

export function trackGiftcardPurchase(props: CheckoutTracking.Giftcard) {
  const { is_wellness, is_digital, giftcard_total, campaign, giftcard_type, place_id } = props.giftcardProps;

  switch (giftcard_type) {
    case 'giftcard':
    case 'wellnesscard': {
      trackMpAnalyticsEvent('gift_card_purchase_success', {
        card_type: is_wellness ? 'wellness' : 'universal',
        amount: giftcard_total,
        value: giftcard_total,
        currency: 'SEK',
      });

      trackMpAnalyticsEvent('gc_purchase_success', {
        gc_type: is_wellness ? 'wellness' : 'universal',
      });

      trackMpEvent(`gift_card_${is_wellness ? 'wellness_' : ''}purchase`, {
        order_value: giftcard_total,
        is_digital,
        campaign,
        ...props.paymentMethodsProps,
      });

      trackMpRevenueGiftcards({
        value: giftcard_total || 0,
        currency: 'SEK',
        contentName: `UGC${is_wellness ? 'W' : ''}`,
        contentType: 'product',
        description: is_wellness ? 'wellness_card' : 'gift_card',
      });

      trackEventTikTok(`${is_wellness ? 'CompletePayment' : 'CompleteRegistration'}`, {
        value: giftcard_total, // number. Value of the order or items sold. Example: 100.
        currency: 'SEK', // string. The 4217 currency code. Example: 'USD'.
        contents: [
          {
            content_id: `UGC${is_wellness ? 'W' : ''}`, // string. ID of the product. Example: '1077218'.
            content_type: 'product', // string. Either product or product_group.
            content_name: `${is_wellness ? 'Wellness card' : 'Universal gift card'}`, // string. The name of the page or product. Example: 'shirt'.
          },
        ],
        content_type: 'product',
      });

      trackEventTikTok(`${is_wellness ? 'WellnessCardPurchase' : 'UniversalGiftCardPurchase'}`, {
        value: giftcard_total, // number. Value of the order or items sold. Example: 100.
        currency: 'SEK', // string. The 4217 currency code. Example: 'USD'.
        contents: [
          {
            content_id: `UGC${is_wellness ? 'W' : ''}`, // string. ID of the product. Example: '1077218'.
            content_type: 'product', // string. Either product or product_group.
            content_name: `${is_wellness ? 'Wellness card' : 'Universal gift card'}`, // string. The name of the page or product. Example: 'shirt'.
          },
        ],
        content_type: 'product',
      });
      return;
    }

    case 'placecard':
    case 'valuecard': {
      const isValueCard = giftcard_type === 'valuecard';

      trackMpAnalyticsEvent('gift_card_purchase_success', {
        card_type: 'individual',
        amount: giftcard_total,
        value: giftcard_total,
        currency: 'SEK',
      });
      trackMpAnalyticsEvent('gc_purchase_success', {
        gc_type: 'individual',
      });
      trackMpEvent(isValueCard ? 'value_card_purchase' : 'place_gift_card_purchase', {
        place_id: place_id,
        order_value: giftcard_total,
      });
      trackMpRevenueGiftcards({
        value: giftcard_total || 0,
        currency: 'SEK',
        contentName: isValueCard ? 'VC' : 'IGC',
        contentType: 'product',
        description: isValueCard ? 'value_card' : 'place_gift_card',
      });
    }
  }
}

export const getGiftcardErrorMessage = (message?: string) => {
  switch (message) {
    case 'not_found':
      return _s('saveGiftcard.error.notFound');
    case 'expired':
      return _s('saveGiftcard.error.expired');
    case 'empty':
      return _s('saveGiftcard.error.empty');
    case 'already_saved':
      return _s('saveGiftcard.error.alreadySaved');
    case 'already_saved_another_account':
      return _s('saveGiftcard.error.alreadySavedAnotherAccount');
    case 'not_supported':
      return _s('saveGiftcard.error.notSupported');
    case 'invalid_type':
      return _s('saveGiftcard.error.invalidType');
    case 'invalid':
      return _s('saveGiftcard.error.invalid');
    case 'link_use_expired':
      return _s('saveGiftcard.error.linkUseExpired');
    case 'link_already_used':
      return _s('saveGiftcard.error.linkAlreadyUsed');
    default:
      return _s('unknownError');
  }
};
