import Label from '@/components/elements/Label/Label';
import { PlaceEmployee } from '@/types/state/shared';

type Props = {
  employee: PlaceEmployee;
  isSistaminuten: boolean;
  inModal?: boolean;
};

const EmployeeBadges = ({ employee, isSistaminuten, inModal = false }: Props) => {
  const onlinePaymentForSistaminutenOnly = Boolean(employee?.about?.settings?.onlinePaymentForSistaminutenOnly);
  const hideOnlinePayments = !isSistaminuten && onlinePaymentForSistaminutenOnly;
  const acceptsKlarna = employee?.about?.settings?.hasKlarna;
  const acceptsQliro = !hideOnlinePayments && employee?.about?.settings?.useQliro;
  const hasCertificate = employee?.about?.certificates?.length || false;
  const acceptsGiftcard = !isSistaminuten && employee?.about?.settings?.acceptsGiftCard;
  const hasBadges = acceptsGiftcard || acceptsKlarna || acceptsQliro || hasCertificate;

  if (!hasBadges) return null;

  return (
    <>
      {acceptsKlarna && <Label variant="klarna" />}
      {acceptsQliro && <Label variant="qliro" />}
      {hasCertificate && !inModal && (
        <a href="#certificates">
          <Label variant="certificate" />
        </a>
      )}
      {hasCertificate && inModal && <Label variant="certificate" />}
      {acceptsGiftcard && <Label variant="giftcard" />}
    </>
  );
};

export default EmployeeBadges;
