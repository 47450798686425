import { _s } from '@/locale';
import { useEffect, useRef, useState } from 'react';

type ReadMoreProps = {
  text: String;
  expanded?: Boolean;
  maxLines?: number;
  className?: string;
  bottomSlot?: React.ReactNode;
};

const ReadMore = ({
  expanded = false,
  text,
  maxLines = 2,
  bottomSlot,
  className = 'text-sm text-black-600',
}: ReadMoreProps) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [needsTruncation, setNeedsTruncation] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    if (!elementRef?.current) {
      return;
    }

    const lineHeight = parseFloat(getComputedStyle(elementRef.current).lineHeight);
    const maxHeight = lineHeight * maxLines;
    setNeedsTruncation(elementRef.current.scrollHeight > maxHeight || Boolean(bottomSlot));
  }, [text, maxLines, bottomSlot]);

  const handleToggleTruncate = () => {
    setIsExpanded((isExpanded) => !isExpanded);
  };

  return text ? (
    <div
      className={`${className} ${maxLines === 1 ? 'flex items-start' : ''} ${isExpanded ? 'whitespace-pre-wrap' : ''}`}>
      <div
        ref={elementRef}
        style={{
          display: isExpanded ? 'block' : '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          WebkitLineClamp: isExpanded ? 'unset' : maxLines,
          textOverflow: 'ellipsis',
        }}>
        {text}
      </div>

      {isExpanded && bottomSlot && <div className="mt-sm">{bottomSlot}</div>}

      {needsTruncation && (
        <button
          className={`text-fg_link underline outline-none ${maxLines === 1 ? 'whitespace-nowrap' : 'pt-sm'}`}
          onClick={handleToggleTruncate}>
          {isExpanded ? _s('showLess') : _s('showMore')}
        </button>
      )}
    </div>
  ) : null;
};
export default ReadMore;
