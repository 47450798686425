import { baseRequestWithoutTransactionInfo, environment } from '@/config/googlepay';
import { MerchantAccount } from '@/types/adyen';

export function getGooglePayPaymentDataRequest(
  transactionInfo: google.payments.api.TransactionInfo,
  merchantAccount: MerchantAccount,
): google.payments.api.PaymentDataRequest {
  const paymentDataRequest: google.payments.api.PaymentDataRequest = {
    ...baseRequestWithoutTransactionInfo(merchantAccount),
    transactionInfo,
  };

  return paymentDataRequest;
}

export const getGoogleIsReadyToPayRequest = (merchantAccount: MerchantAccount) => ({
  ...baseRequestWithoutTransactionInfo(merchantAccount),
});

export const buildBrowserInfo = () => {
  const browserInfo = {
    userAgent: navigator.userAgent,
    language: navigator.language,
    colorDepth: window.screen.colorDepth,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    timeZoneOffset: new Date().getTimezoneOffset(),
    javaEnabled: navigator.javaEnabled(),
  };
  return browserInfo;
};

let client: google.payments.api.PaymentsClient;

const createClient = (merchantAccount: MerchantAccount): google.payments.api.PaymentsClient => {
  const client = new google.payments.api.PaymentsClient({
    merchantInfo: { merchantId: baseRequestWithoutTransactionInfo(merchantAccount).merchantInfo.merchantId },
    environment,
  });

  return client;
};

export const clientInstance = (merchantAccount: MerchantAccount): google.payments.api.PaymentsClient => {
  return client ?? (client = createClient(merchantAccount));
};
