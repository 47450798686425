import { loggedUser } from '@/helpers';
import { DEFAULT_SEARCH_LOCATION } from '@/hooks/search/useSearch.constants';
import { _s } from '@/locale';
import { NavItemIdentifier } from '@/types/navigation';
import { APP_ROUTES } from './pages';

const tBase = (key: string) => _s(`footerNavigation.${key}`);

type FooterLink = {
  url: string;
  title: string;
  link?: boolean;
  identifier?: NavItemIdentifier;
};
export enum FooterColumnGroup {
  Bokadirekt = 'bokadirekt',
  PopularHealth = 'popular-health',
  PopularMix = 'popular-mix',
  PopularBeauty = 'popular-beauty',
  More = 'more',
  Sistaminutentider = 'sistaminutentider',
}

export type FooterColumn = {
  title: string;
  links: FooterLink[];
  group: FooterColumnGroup;
  className?: string;
};

export const footerTosItems: FooterLink[] = [
  { url: '/articles/ethical-policy', title: tBase('ethicalPolicy'), link: true },
  { url: '/articles/privacy-policy', title: tBase('privacyPolicy'), link: true },
  { url: '/articles/cookies', title: tBase('cookies'), link: true },
  { url: '/articles/terms', title: tBase('termsConditions'), link: true },
];

const businessConnectLink: FooterLink = {
  url: 'https://business.bokadirekt.se/',
  title: tBase('connectBussiness'),
};

const getSerpLinkUrl = (keyword: string) => {
  return `/${keyword}/${DEFAULT_SEARCH_LOCATION}`;
};

export const footerNavigationItems = (isSistaminuten: boolean, isListPage: boolean): FooterColumn[] =>
  [
    !isSistaminuten && {
      ...{
        group: FooterColumnGroup.Bokadirekt,
        title: tBase('myAccount'),
        links: [
          loggedUser
            ? undefined
            : {
                url: '#',
                title: tBase('login'),
                identifier: NavItemIdentifier.LogIn,
              },
          {
            url: '/bokningar',
            title: tBase('myBookings'),
            identifier: NavItemIdentifier.Bookings,
            link: true,
          },
          {
            url: '/favoriter',
            title: tBase('myFavorites'),
            identifier: NavItemIdentifier.Favorites,
            link: true,
          },
          {
            url: '/giftcards',
            title: tBase('useGiftcard'),
            identifier: NavItemIdentifier.GiftCardUse,
            link: true,
          },
          {
            url: '/giftcards',
            title: tBase('useWellnesscard'),
            identifier: NavItemIdentifier.WellnessCardUse,
          },
        ].filter((a) => a),
      },
    },
    {
      group: FooterColumnGroup.Bokadirekt,
      title: tBase('contact'),
      links: (
        [
          { url: '/support', title: tBase('support'), link: true },
          {
            url: 'https://konto.bokadirekt.se/login?ReturnUrl=%2Fsignin%3Ftype%3Dmerchant',
            title: tBase('companyLogin'),
          },
          { url: '/articles/about', title: tBase('aboutBokadirekt'), link: true },
        ] as FooterLink[]
      ).concat(isSistaminuten ? [...footerTosItems, businessConnectLink] : []),
    },
    !isSistaminuten && {
      ...{
        group: FooterColumnGroup.PopularMix,
        title: tBase('popularBokadirekt'),
        links: [
          { url: getSerpLinkUrl('frisör'), title: tBase('hairdresser'), link: true },
          { url: getSerpLinkUrl('naglar'), title: tBase('nails'), link: true },
          { url: getSerpLinkUrl('hudvård'), title: tBase('skincare'), link: true },
          { url: getSerpLinkUrl('fransförlängning'), title: tBase('eyelashExtension'), link: true },
          { url: getSerpLinkUrl('lashlift'), title: tBase('lashlift'), link: true },
          { url: getSerpLinkUrl('browlift'), title: tBase('browlift'), link: true },
          { url: getSerpLinkUrl('fillers'), title: tBase('fillers'), link: true },
          { url: getSerpLinkUrl('ipl'), title: tBase('ipl'), link: true },
          { url: getSerpLinkUrl('vaxning'), title: tBase('waxing'), link: true },
          { url: getSerpLinkUrl('fotvård'), title: tBase('pedicure'), link: true },
          { url: getSerpLinkUrl('massage'), title: tBase('massage'), link: true },
          { url: getSerpLinkUrl('träning'), title: tBase('exercise'), link: true },
          { url: getSerpLinkUrl('friskvård'), title: tBase('wellness'), link: true },
          { url: getSerpLinkUrl('acne'), title: tBase('acne'), link: true },
          { url: getSerpLinkUrl('fysioterapi'), title: tBase('physiotherapy'), link: true },
          { url: getSerpLinkUrl('naprapat'), title: tBase('naprapat'), link: true },
          { url: getSerpLinkUrl('kiropraktik'), title: tBase('chiropractic'), link: true },
          { url: getSerpLinkUrl('svettbehandling'), title: tBase('sweatTreatment'), link: true },
        ],
        className: `block ${isListPage ? '2xl:hidden' : 'lg:hidden'}`,
      },
    },
    !isSistaminuten && {
      ...{
        group: FooterColumnGroup.PopularBeauty,
        title: tBase('popularBeauty'),
        links: [
          { url: getSerpLinkUrl('frisör'), title: tBase('hairdresser'), link: true },
          { url: getSerpLinkUrl('naglar'), title: tBase('nails'), link: true },
          { url: getSerpLinkUrl('hudvård'), title: tBase('skincare'), link: true },
          { url: getSerpLinkUrl('fransförlängning'), title: tBase('eyelashExtension'), link: true },
          { url: getSerpLinkUrl('lashlift'), title: tBase('lashlift'), link: true },
          { url: getSerpLinkUrl('browlift'), title: tBase('browlift'), link: true },
          { url: getSerpLinkUrl('fillers'), title: tBase('fillers'), link: true },
          { url: getSerpLinkUrl('ipl'), title: tBase('ipl'), link: true },
          { url: getSerpLinkUrl('vaxning'), title: tBase('waxing'), link: true },
          { url: getSerpLinkUrl('fotvård'), title: tBase('pedicure'), link: true },
        ],
        className: `hidden ${isListPage ? '2xl:block' : 'lg:block'}`,
      },
    },
    !isSistaminuten && {
      ...{
        group: FooterColumnGroup.PopularHealth,
        title: tBase('popularHealth'),
        links: [
          { url: getSerpLinkUrl('massage'), title: tBase('massage'), link: true },
          { url: getSerpLinkUrl('träning'), title: tBase('exercise'), link: true },
          { url: getSerpLinkUrl('friskvård'), title: tBase('wellness'), link: true },
          { url: getSerpLinkUrl('acne'), title: tBase('acne'), link: true },
          { url: getSerpLinkUrl('fysioterapi'), title: tBase('physiotherapy'), link: true },
          { url: getSerpLinkUrl('naprapat'), title: tBase('naprapat'), link: true },
          { url: getSerpLinkUrl('kiropraktik'), title: tBase('chiropractic'), link: true },
          { url: getSerpLinkUrl('svettbehandling'), title: tBase('sweatTreatment'), link: true },
        ],
        className: `hidden ${isListPage ? '2xl:block' : 'lg:block'}`,
      },
    },
    isSistaminuten && {
      ...{
        group: FooterColumnGroup.Sistaminutentider,
        title: tBase('categories'),
        links: [
          { url: getSerpLinkUrl('frisör'), title: tBase('hairdresser'), link: true },
          { url: getSerpLinkUrl('träning'), title: tBase('exercise'), link: true },
          { url: getSerpLinkUrl('hudvård'), title: tBase('skincare'), link: true },
          { url: getSerpLinkUrl('massage'), title: tBase('massage'), link: true },
          { url: getSerpLinkUrl('naglar'), title: tBase('nails'), link: true },
          { url: getSerpLinkUrl('fillers'), title: tBase('fillers'), link: true },
          { url: getSerpLinkUrl('friskvård'), title: tBase('wellness'), link: true },
          { url: getSerpLinkUrl('fransförlängning'), title: tBase('eyelashExtension'), link: true },
        ],
      },
    },
    !isSistaminuten && {
      ...{
        group: FooterColumnGroup.More,
        title: tBase('more'),
        links: [
          {
            url: APP_ROUTES.GIFTCARD_CHECKOUT,
            title: tBase('buyGiftcard'),
            identifier: NavItemIdentifier.GiftCardBuy,
            link: true,
          },
          {
            url: APP_ROUTES.WELLNESSCARD_CHECKOUT,
            title: tBase('buyWellnesscardMassage'),
            identifier: NavItemIdentifier.WellnessCardBuy,
            link: true,
          },
          { url: 'https://careers.bokadirekt.se', title: tBase('careers') },
          { url: '/newsletter', title: tBase('newsletter'), link: true },
          { url: '/blogg/', title: tBase('blog') },
          { url: '/fakta-och-rad', title: tBase('factsAndAdvice'), link: true },
          { url: '/faq-vanliga-fragor', title: tBase('faqBeautyTreatments'), link: true },
          { url: '/bransch-organisationer', title: tBase('associationPages'), link: true },
        ],
      },
    },
  ].filter((shown) => shown);
