import { Button } from '@/components/elements/forms/buttons';
import { LoadingIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import FakeSearchInput from '@/components/modules/mobile/FakeSearchInput';
import { DEFAULT_SEARCH_KEYWORD, DEFAULT_SEARCH_LOCATION } from '@/hooks/search/useSearch.constants';
import { Position, positionSchema } from '@/hooks/search/useSearch.helpers';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { autocompleteService } from '@/services';
import { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import KeywordAutoSuggest from '../../components/AutoSuggest/KeywordAutoSuggest/KeywordAutoSuggest';
import useKeywordAutoSuggestManager from '../../components/AutoSuggest/KeywordAutoSuggest/KeywordAutoSuggest.hooks';
import LocationAutosuggest from '../../components/AutoSuggest/LocationAutoSuggest/LocationAutoSuggest';
import useLocationAutoSuggestManager from '../../components/AutoSuggest/LocationAutoSuggest/LocationAutoSuggest.hooks';
import { LocationSuggestion } from '../../components/AutoSuggest/LocationAutoSuggest/LocationAutoSuggest.types';
import {
  AutoSuggestInputComponent,
  KeywordAutoSuggestSectionTitle,
  KeywordSuggestionItem,
  LocationAutoSuggestSectionTitle,
  LocationSuggestionItem,
} from '../../components/AutoSuggest/shared/templates';
import { AutoSuggestInputTheme } from '../../components/AutoSuggest/shared/types';
import { SEARCH_CONTEXT } from '../../constants/tracking';
import { SearchContext } from '../../types/tracking';

const AUTOGUSGEST_THEME: AutoSuggestInputTheme = {
  container: 'relative w-full',
  suggestionsContainer: 'absolute z-[100] w-full right-0 left-0 max-h-[550px]',
  suggestion: 'cursor-pointer even:border-t even:border-b even:border-black-200 px-sm hover:bg-black-50',
  suggestionsContainerOpen:
    'z-[100] border border-black-200 bg-white pt-sm mt-xs rounded-md max-h-[550px] overflow-y-auto',
};

const FakeSearchFormDesktop = () => {
  const [locationId, setLocationId] = useState<string>(undefined);
  const [position, setPosition] = useState<Position>(undefined);
  const [searchContext, setSearchContext] = useState<SearchContext>('home_page_free_search');

  const history = useHistory();
  const location = useLocation();
  const keywordAutoSuggestManager = useKeywordAutoSuggestManager({
    initialState: {
      keyword: '',
      suggestions: [],
      newServices: [],
      showSuggestions: true,
    },
    onSelectKeywordSuggestion: handleOnSelectKeywordSuggestion,
  });

  const locationAutoSuggestManager = useLocationAutoSuggestManager({
    initialState: {
      locationValue: '',
      hasHistory: false,
      suggestions: [],
      storedSuggestions: [],
      showSuggestions: true,
      isFetchingUserPosition: false,
    },
    onSelectLocationSuggestion: handleOnUpdateLocation,
  });
  const keywordInputRef = useRef(null);
  const locationInputRef = useRef(null);

  function handleOnUpdateLocation(params) {
    // reset previous state
    setLocationId(undefined);
    setPosition(undefined);

    if (params.locationId) {
      setLocationId(params.locationId);
    }

    const position = `${params['lat']}-${params['lon']}`;

    if (positionSchema.safeParse(position).success) {
      setPosition(position);
    }
  }
  function handleOnSelectKeywordSuggestion() {
    setSearchContext('home_page_search_autocomplete');
  }

  function handleOnClearKeyword() {
    keywordInputRef?.current?.focus?.();
    keywordAutoSuggestManager.onChange(null, { newValue: '' });
  }

  function handleClearLocation() {
    locationInputRef?.current?.focus?.();
    locationAutoSuggestManager.onChange({ target: { value: '' } });
  }

  function handleOnSearchClick() {
    const state = {
      keyword: keywordAutoSuggestManager.keyword || DEFAULT_SEARCH_KEYWORD,
      location: locationAutoSuggestManager.location || DEFAULT_SEARCH_LOCATION,
    };

    const query = new URLSearchParams(location.search);

    /**
     * If locationId is present, use it instead of lat and lon
     */
    if (locationId) {
      query.set('locationId', locationId);
    } else if (position) {
      const [lat, lon] = position.split('-');
      query.set('lat', lat);
      query.set('lon', lon);
    }

    history.push({
      pathname: `/${state.keyword}/${state.location}`,
      search: query.toString(),
      state: { searchContext },
    });
  }

  function handleRemoveLocationHistorySuggestion(suggestion: LocationSuggestion) {
    autocompleteService.deleteLocationSuggestion(suggestion.label);
    locationAutoSuggestManager.onClearHistorySuggestion(suggestion);
  }

  function handleOnAutoSuggestKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter' || event.keyCode === 13) {
      handleOnSearchClick();
    }
  }

  return (
    <div className="flex items-stretch">
      <div className="grid w-full grid-cols-2">
        <KeywordAutoSuggest
          inputRef={keywordInputRef}
          {...keywordAutoSuggestManager}
          onKeyDown={handleOnAutoSuggestKeyDown}
          theme={AUTOGUSGEST_THEME}
          renderInputComponent={({ key, ...inputProps }) => (
            <AutoSuggestInputComponent
              key={key}
              inputProps={inputProps}
              onClear={handleOnClearKeyword}
              leftSlot={<Icon variant="search" size="sm" />}
              className="rounded-bl-full rounded-tl-full bg-white"
            />
          )}
          renderSuggestion={(suggestion) => <KeywordSuggestionItem suggestion={suggestion} />}
          renderSectionTitle={(section) => <KeywordAutoSuggestSectionTitle {...section} />}
        />
        <LocationAutosuggest
          inputRef={locationInputRef}
          {...locationAutoSuggestManager}
          onKeyDown={(event) => handleOnAutoSuggestKeyDown(event)}
          theme={AUTOGUSGEST_THEME}
          renderInputComponent={({ key, ...inputProps }) => (
            <AutoSuggestInputComponent
              key={key}
              inputProps={inputProps}
              onClear={handleClearLocation}
              leftSlot={
                locationAutoSuggestManager.isFetchingUserPosition ? (
                  <LoadingIcon />
                ) : (
                  <Icon variant="location" size="sm" />
                )
              }
              className="border-l-0 bg-white"
            />
          )}
          renderSuggestion={(suggestion) => (
            <LocationSuggestionItem
              suggestion={suggestion}
              onRemoveHistorySuggestion={handleRemoveLocationHistorySuggestion}
            />
          )}
          renderSectionTitle={(section) => <LocationAutoSuggestSectionTitle {...section} />}
        />
      </div>
      <Button
        variant="primary"
        className="h-auto min-w-[96px] rounded-br-full rounded-tr-full"
        onClick={handleOnSearchClick}>
        {_s('search')}
      </Button>
    </div>
  );
};

const FakeSearchFormMobile = () => {
  const history = useHistory();

  return (
    <form
      onSubmit={() =>
        history.push({
          pathname: `/${DEFAULT_SEARCH_KEYWORD}/${DEFAULT_SEARCH_LOCATION}`,
          state: { searchContext: SEARCH_CONTEXT.HOME_PAGE_FREE_SEARCH },
        })
      }
      className="relative overflow-hidden rounded-full">
      <FakeSearchInput
        className="absolute h-full w-full"
        isLoading={false}
        onClick={() => {
          history.push({ pathname: '/search', state: { fromLandingPage: true } });
        }}
        icon="search"
        valid={false}
        onClear={() => null}
        content={_s('searchForToday')}
        size="md"
      />
      <button className="bg-primary-900 absolute right-0 top-0 h-full w-[74px] text-white">{_s('search')}</button>
    </form>
  );
};

const FakeSearchForm = () => {
  const { isMobileView } = useMobileView();

  if (isMobileView) return <FakeSearchFormMobile />;

  return <FakeSearchFormDesktop />;
};

export default FakeSearchForm;
