import { bookActions, loadingActions } from '@/actions';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { Button } from '@/components/elements/forms/buttons';
import { ChevronIcon } from '@/components/icons';
import { config } from '@/config';
import {
  chunk,
  firstWeekDay,
  getDayInfo,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  getMonthsFromFirstDay,
  getPlaceTimezone,
  isServer,
  lastWeekDay,
  startOfDay,
} from '@/helpers';
import { __ } from '@/locale';
import { bookServices } from '@/services';
import '@/styles/vendors/_react-slick.scss';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';

class MonthPicker extends React.Component {
  constructor(props) {
    super(props);
    this.blockNavigation = false;

    moment.tz.setDefault(getPlaceTimezone(props.place));

    let day = startOfDay();
    if (props.time && props.time.timestamp && props.time.timestamp > day) {
      day = props.time.timestamp;
    }

    const firstDayOfMonth = getFirstDayOfMonth(day);
    const startingFrom = firstWeekDay(firstDayOfMonth);
    const selectedMonth = firstDayOfMonth;

    const { months, lastValidDate } = this.getMonths();

    this.state = {
      loading: true,
      selectedMonth,
      months,
      firstDay: getDayInfo(startOfDay()),
      monthData: {},
      startFrom: startingFrom,
      monthLabel: this.getMonthLabel(selectedMonth),
      selectedDay: getDayInfo(selectedMonth),
      lastValidDate,
      hour: props.time && props.time.selected ? props.time.selected : '',
      disablePrev: months[0] === selectedMonth ? true : false,
      disableNext: months[months.length - 1] === selectedMonth,
    };
    this.state.days = this.getMonthDays(selectedMonth, this.state);

    let weekDays = [...config.weekDaysShort];
    weekDays.push(weekDays.shift());
    this.state.weekDays = weekDays;

    if (!isServer) window.scrollTo(0, 0);

    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
  }

  componentDidMount() {
    this.getMonthData(this.state.selectedMonth).then((monthData) => {
      this.setState(this.getMonthsRelatedData(monthData));
    });
  }

  componentDidUpdate(prevProps) {
    const currentIds = this.props.services.map((service) => service.id);
    const prevIds = prevProps.services.map((service) => service.id);

    if (prevProps.employee !== this.props.employee || currentIds.join('-') !== prevIds.join('-')) {
      this.getMonthData(this.state.selectedMonth).then((monthData) => {
        this.setState(this.getMonthsRelatedData(monthData));
      });
    }
  }

  getMonthsRelatedData(monthData) {
    const { months, lastValidDate } = this.getMonths(monthData);
    const { selectedMonth } = this.state;
    return {
      monthData,
      months,
      lastValidDate,
      disablePrev: months[0] === selectedMonth ? true : false,
      disableNext: months[months.length - 1] === selectedMonth,
    };
  }

  handleNextSwipe() {
    this.handleNext();
  }

  handleNextClick() {
    this.handleNext();
  }

  handleNext() {
    if (this.blockNavigation || this.state.disableNext) return false;

    this.blockNavigation = true;

    const currentIndex = this.state.months.indexOf(this.state.selectedMonth);
    const selectedDate = this.state.months[currentIndex + 1];
    if (!selectedDate) {
      this.blockNavigation = false;
      return false;
    }

    this.getMonthData(selectedDate).then((data) => {
      const { months, lastValidDate } = this.getMonths(data);

      this.setState(
        {
          monthData: data,
          months,
          lastValidDate,
          days: this.getMonthDays(selectedDate, this.state),
          hour: '',
          selectedMonth: selectedDate,
          startFrom: selectedDate,
          selectedDay: getDayInfo(selectedDate),
          disablePrev: false,
          disableNext: months.length - 1 === currentIndex + 1,
          monthLabel: this.getMonthLabel(selectedDate),
        },
        () => {
          this.monthSlider.slickGoTo(currentIndex + 1);
          this.blockNavigation = false;
          const { dispatch, services, place } = this.props;
          dispatch(bookActions.setCampaigns(services, place.campaigns, selectedDate));
        },
      );
    });
  }

  handlePrevSwipe() {
    this.handlePrev();
  }

  handlePrevClick() {
    this.handlePrev();
  }

  handlePrev() {
    if (this.blockNavigation || this.state.disablePrev) return false;

    this.blockNavigation = true;

    const currentIndex = this.state.months.indexOf(this.state.selectedMonth);
    const selectedDate = this.state.months[currentIndex - 1];
    if (!selectedDate) {
      this.blockNavigation = false;
      return false;
    }

    // Get week availability
    this.getMonthData(selectedDate).then((data) => {
      const { months, lastValidDate } = this.getMonths(data);

      this.setState(
        {
          monthData: data,
          months,
          lastValidDate,
          days: this.getMonthDays(selectedDate, this.state),
          selectedMonth: selectedDate,
          startFrom: selectedDate,
          selectedDay: getDayInfo(selectedDate),
          monthLabel: this.getMonthLabel(selectedDate),
          hour: '',
          disablePrev: currentIndex - 1 === 0,
          disableNext: false,
        },
        () => {
          this.monthSlider.slickGoTo(currentIndex - 1);
          this.blockNavigation = false;

          const { dispatch, services, place } = this.props;
          dispatch(bookActions.setCampaigns(services, place.campaigns, selectedDate));
        },
      );
    });
  }

  getMonthDays(startingFrom, state) {
    const { months = [], days = {} } = state;
    const index = months.indexOf(startingFrom);
    const next = months[index + 1];
    const prev = months[index - 1];
    const newDays = {};
    if (!days[startingFrom]) {
      newDays[startingFrom] = this.getDays(startingFrom);
    } else {
      newDays[startingFrom] = days[startingFrom];
    }
    if (next) {
      if (!days[next]) {
        newDays[next] = this.getDays(next);
      } else {
        newDays[next] = days[next];
      }
    }
    if (prev) {
      if (!days[prev]) {
        newDays[prev] = this.getDays(prev);
      } else {
        newDays[prev] = days[prev];
      }
    }

    return newDays;
  }

  getDays(startingFrom) {
    const display = [];

    let start = firstWeekDay(startingFrom);
    const end = lastWeekDay(getLastDayOfMonth(startingFrom));

    while (start <= end) {
      const day = getDayInfo(start);
      day.timestamp = start;
      day.disabled = this.checkDay(day);
      display.push(day);
      start += 86400 * 1000;
    }

    return display;
  }

  getMonths(monthData) {
    const { lastWorkingDays, place } = this.props;

    let monthsCount = 1;
    let lastValidDate = undefined;

    if (monthData) {
      const dates = Object.keys(monthData);
      lastValidDate = Number(dates[dates.length - 1]);
    } else {
      if (lastWorkingDays && lastWorkingDays.last && place.source !== 2) {
        lastValidDate = lastWorkingDays.last;
      } else {
        const { about } = place || {};
        const { book } = about || {};
        const bookAhead = place.source === 2 ? lastWorkingDays.bookAhead : book.bookAhead;
        lastValidDate = lastWeekDay(moment().clone().startOf('isoWeek').add(bookAhead, 'weeks').valueOf());
      }
    }

    if (!lastValidDate || isNaN(lastValidDate)) {
      monthsCount = 13; // same default as Weekpicker has
    } else {
      monthsCount = getMonthsFromFirstDay(lastValidDate);
    }

    let months = [];
    let startingFrom = new Date(getFirstDayOfMonth());

    while (monthsCount) {
      months.push(startingFrom.getTime());

      if (startingFrom.getUTCMonth() === 11) {
        startingFrom = new Date(Date.UTC(startingFrom.getUTCFullYear() + 1, 0, 1));
      } else {
        startingFrom = new Date(Date.UTC(startingFrom.getUTCFullYear(), startingFrom.getUTCMonth() + 1, 1));
      }

      monthsCount--;
    }
    return { months, lastValidDate };
  }

  getMonthData(day) {
    const { employee, dispatch, services } = this.props;
    dispatch(loadingActions.show('loadHours'));

    const start = firstWeekDay(day);
    const end = lastWeekDay(getLastDayOfMonth(day));

    return bookServices.getIntervalDays(services, start, end, employee).then((data) => {
      dispatch(loadingActions.hide());
      if (
        data.availability &&
        data.availability.fromErpOverview &&
        data.availability.fromErpOverview.datesWithOpeningHours
      ) {
        const days = data.availability.fromErpOverview.datesWithOpeningHours;
        return Object.assign({}, ...days.map((day) => ({ [moment.utc(day, 'YYYY-MM-DD').valueOf()]: day })));
      }
      return {};
    });
  }

  checkDay(dayInfo) {
    const { place } = this.props;

    const day = startOfDay();
    if (place.program) {
      let lay = place.program[dayInfo.weekDay];
      // Check if today is checked
      if (dayInfo.timestamp === day) {
        const now = new Date().getTime();
        if (!lay || lay.to <= (now - day) / 1000) {
          return true;
        } else {
          return false;
        }
      } else {
        if (place.program[dayInfo.weekDay] && place.program[dayInfo.weekDay].length) {
          lay = place.program[dayInfo.weekDay][0];
        }

        if (typeof lay === 'undefined' || (lay && !lay.to)) {
          return true;
        }

        return false;
      }
    }

    return true;
  }

  getMonthLabel(day) {
    const today = new Date(startOfDay());
    const current = new Date(day);
    let label = config.monthsLong[current.getUTCMonth()];
    if (today.getUTCFullYear() !== current.getUTCFullYear()) {
      label += ' ' + current.getUTCFullYear();
    }

    return label;
  }

  selectWeek = (week) => (e) => {
    const { dispatch } = this.props;
    const { monthData } = this.state;
    let selectedDate = false;
    week.forEach((day) => {
      const timestamp = day.timestamp;
      if (!selectedDate && monthData[timestamp]) {
        selectedDate = timestamp;
      }
    });

    if (!selectedDate) {
      selectedDate = week[0].timestamp;
    }
    dispatch(bookActions.pickDate(selectedDate));
    this.props.showWeekView(e);
  };

  render() {
    const { monthData, months, monthLabel, selectedMonth, lastValidDate } = this.state;
    const { show, loadSource } = this.props;
    const settings = {
      dots: false,
      infinite: false,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      draggable: false,
      onSwipe: (direction) => {
        direction === 'left' ? this.handleNextSwipe() : this.handlePrevSwipe();
      },
    };

    let loader = null;
    let hourLoad = null;

    if (show) {
      switch (loadSource) {
        case 'pickService':
        case 'pickEmployee':
          loader = <LoadingPlaceHolder text={__('loadingHours')} style={{ marginTop: '50px' }} />;
          break;
        case 'loadHours':
          hourLoad = <LoadingPlaceHolder text={__('loadingHours')} style={{ marginTop: '50px' }} />;
          break;
        default:
          break;
      }
    }

    if (this.monthSlider) {
      this.monthSlider.slickGoTo(this.state.months.indexOf(selectedMonth));
    }

    if (!this.state.days) {
      return false;
    }

    const weeks = chunk(this.state.days[selectedMonth], 7);
    return (
      <div className="calendar month-view">
        {loader}
        <p className="sticky !top-16 z-[100] flex h-[50px] flex-col items-center justify-center bg-white px-3  font-semibold uppercase">
          {monthLabel}
        </p>
        <div className="date-slider sticky top-[123px] z-[100] h-auto shadow">
          {hourLoad}
          <Slider ref={(c) => (this.monthSlider = c)} className="slick-calendar" {...settings}>
            {months.map((month, key) => {
              return (
                <div className="bg-[#eff0f3]" key={key}>
                  <div
                    className="flex h-10 w-full items-center justify-around border-l-[6px] border-white !bg-white"
                    style={{ margin: 0, fontSize: '14px' }}>
                    {this.state.weekDays.map((day, key) => {
                      return (
                        <div key={key}>
                          <div className="md:hidden">{day[0]}</div>
                          <div className="hidden md:block">{day}</div>
                        </div>
                      );
                    })}
                  </div>
                  {weeks.map((days, key) => {
                    const older = days[6].timestamp < this.state.firstDay.timestamp ? true : false;
                    const afterLastDay = days[0].timestamp > lastValidDate;
                    const current =
                      days[6].timestamp >= this.state.firstDay.timestamp &&
                      days[0].timestamp <= this.state.firstDay.timestamp;

                    let active = false;
                    let weekDays = [];

                    days.forEach((day, k) => {
                      const current = this.state.firstDay.timestamp === day.timestamp;
                      const isAvailable = monthData && monthData[day.timestamp] ? true : false;
                      active = active || isAvailable;

                      weekDays.push(
                        <div
                          className={`text-primary relative min-w-[40px] bg-none text-center font-semibold !leading-10 ${
                            !isAvailable ? 'text-black-400 line-through' : 'cursor-pointer'
                          }`}
                          key={k}>
                          {day.dd}
                          {current && (
                            <span className="bg-primary absolute bottom-[3px] left-1/2 block h-[6px] w-[6px] -translate-x-1/2 rounded-full"></span>
                          )}
                        </div>,
                      );
                    });

                    const weekDisabled = !active || older || afterLastDay;

                    return (
                      <div
                        className={`mb-2 flex h-10 w-full items-center justify-around border-l-[6px] ${
                          weekDisabled ? 'bg-[#eff0f3]' : 'hover:border-primary !bg-white'
                        } ${current ? 'border-primary' : weekDisabled ? '' : 'border-white'}`}
                        key={key}
                        onClick={!weekDisabled ? this.selectWeek(days) : null}>
                        {weekDays}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </Slider>
          <div className="absolute -top-11 left-0 z-20">
            <Button
              className="focus:!ring-0"
              variant="link"
              onClick={this.handlePrevClick}
              disabled={this.state.disablePrev}
              leftIcon={<ChevronIcon className="h-4 w-3 rotate-90" />}>
              {__('earlier')}
            </Button>
          </div>
          <div className="absolute -top-11 right-0 z-20">
            <Button
              className="focus:!ring-0"
              variant="link"
              onClick={this.handleNextClick}
              disabled={this.state.disableNext}
              rightIcon={<ChevronIcon className="h-4 w-3 -rotate-90" />}>
              {__('later')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { services, employee, time, place } = state.book;
  const { show, loadSource } = state.loading;

  return {
    services,
    employee,
    time,
    place,
    show,
    loadSource,
  };
}

const connectedMonthPicker = connect(mapStateToProps)(withRouter(MonthPicker));
export { connectedMonthPicker as MonthPicker };
