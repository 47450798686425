import { z } from 'zod';

const TIME_OF_DAY_OPTION = ['morning', 'afternoon', 'evening'] as const;
const SORT_OPTION = ['popular', 'reviews_score', 'closest', 'availability'] as const;

export const FILTER_ID = {
  PAY_LATER: 4,
  GIFTCARD: 3,
  WELLNESSCARD: 9,
  BUNDLE: 10,
  CAMPAIGN: 5,
  CERTIFIED: 6,
} as const;

export const URL_FILTER_ID_MAP = {
  [FILTER_ID.PAY_LATER]: 'kq',
  [FILTER_ID.GIFTCARD]: 'gc',
  [FILTER_ID.WELLNESSCARD]: 'wellness',
  [FILTER_ID.BUNDLE]: 'bundles',
  [FILTER_ID.CAMPAIGN]: 'dc',
  [FILTER_ID.CERTIFIED]: 'cp',
} as const;

export type TimeOfDayOption = (typeof TIME_OF_DAY_OPTION)[number];

export type SortOption = (typeof SORT_OPTION)[number];

export type DateTimeFilter = {
  startDate?: string;
  endDate?: string;
  timeOfDay?: TimeOfDayOption;
};

/**
 * Attention!
 * Do NOT add any new entries here that are not optional!
 * (or make any of these not optional) since this schema is used to validate
 * single query params from the URL.
 */
export const urlSearchStateSchema = z.object({
  q: z.string().optional(),
  location: z.string().optional(),
  locationId: z.string().optional(),
  page: z.number().int().nonnegative().optional(),
  prefs: z.string().optional(),
  sort: z.enum(SORT_OPTION).optional(),
  lat: z.number().optional(),
  lon: z.number().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  timeOfDay: z.enum(TIME_OF_DAY_OPTION).optional(),
});

export type UrlSearchState = z.infer<typeof urlSearchStateSchema>;
