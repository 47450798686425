import { MutableRefObject } from 'react';
import { z } from 'zod';

const merchantAccountSchema = z.enum(['ecom', 'gc']);

export type MerchantAccount = z.infer<typeof merchantAccountSchema>;

export type CheckoutError = 'PaymentError' | 'StoreTokenError' | 'MountError' | 'NotAvailableError';
export type CheckoutType = 'card' | 'googlepay' | 'applepay';

/**
 * ButtonType is used to customize the copy and look of the button for googe and apple pay.
 * https://developers.google.com/pay/api/web/guides/resources/customize
 */
export type CheckoutButtonType = 'plain' | 'buy' | 'book' | 'checkout' | 'pay';

/**
 * @deprecated - remove with old booking-checkout
 */
export type AdyenCheckoutConfig = {
  type: CheckoutType;
  onAuthorize: (data?: unknown) => void;
  onError: (errorType: CheckoutError, checkoutType: CheckoutType) => void;
  onChange?: (isValid: boolean) => void;
  onMounted?: (component: MutableRefObject<HTMLDivElement>, callback: Function) => void;
  amount?: number;
  guestId?: string;
  threeDSecureRedirectPath?: string;
  buttonType?: CheckoutButtonType;
};

export type AdyenCheckoutConfiguration = {
  onAuthorize: (data: unknown) => void;
  onError: (errorType: CheckoutError) => void;
  onChange?: (isValid: boolean) => void;
  onMounted?: (component: MutableRefObject<HTMLDivElement>, callback: Function) => void;
  amount?: number;
  guestId?: string;
  isGiftcardCheckout?: boolean;
};

export const googlePayPaymentDataSchema = z.object({
  type: z.literal('googlepay'),
  googlePayToken: z.string(),
  googlePayCardNetwork: z.string().optional(),
});

export const applePayPaymentDataSchema = z.object({
  type: z.literal('applepay'),
  applePayToken: z.string(),
});

export const storedCardPaymentDataSchema = z.object({
  type: z.literal('storedCard'),
  storedPaymentMethodId: z.string(),
});

export const newOnlineCardPaymentDataSchema = z.object({
  type: z.literal('newCard'),
  encryptedCardNumber: z.string(),
  encryptedExpiryMonth: z.string(),
  encryptedExpiryYear: z.string(),
  encryptedSecurityCode: z.string(),
  holderName: z.string(),
});

export type NewOnlineCardPaymentData = z.infer<typeof newOnlineCardPaymentDataSchema>;

export const cofPaymentDataSchema = z.object({
  paymentMethod: z.union([
    googlePayPaymentDataSchema,
    applePayPaymentDataSchema,
    storedCardPaymentDataSchema,
    newOnlineCardPaymentDataSchema,
  ]),
  browserInfo: z
    .object({
      userAgent: z.string(),
      language: z.string(),
      colorDepth: z.number(),
      screenHeight: z.number(),
      screenWidth: z.number(),
      timeZoneOffset: z.number(),
      javaEnabled: z.boolean(),
    })
    .optional(),
  redirectInfo: z
    .object({
      returnPath: z.string(),
    })
    .optional(),
});

export type CofPaymentData = z.infer<typeof cofPaymentDataSchema>;
