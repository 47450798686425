import { loadingActions } from '@/actions';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { Button } from '@/components/elements/forms/buttons';
import { getPlaceTimezone, isMobile } from '@/helpers';
import { __, getLang } from '@/locale';
import { bookServices } from '@/services';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import Day from './Day';
require('moment-timezone');

class ListView extends React.Component {
  constructor(props) {
    super(props);

    moment.tz.setDefault(getPlaceTimezone(props.place));

    const { schedule = {} } = props.place || {};
    this.state = {
      start: schedule.start || undefined,
      timeSlots: schedule.timeSlots || [],
      timeSlotsPerDay: this.getTimeSlotsPerDay(schedule.timeSlots || []),
      loadMore: schedule.loadMore || false,
      loading: false,
      searchUntil: false,
    };
  }

  getTimeSlotsPerDay(timeSlots) {
    const slots = {};
    timeSlots.forEach((timeSlot, i) => {
      const timeSlotMoment = moment(timeSlot.start);
      const startOfDayUnix = timeSlotMoment.clone().utc(true).startOf('day').unix();
      const hour = timeSlotMoment.clone().utc(true).unix() - startOfDayUnix;
      timeSlot.from = hour;

      if (!slots[startOfDayUnix]) {
        slots[startOfDayUnix] = {
          day: startOfDayUnix * 1000,
          program: [],
        };
      }

      slots[startOfDayUnix].program.push(timeSlot);
    });

    return slots;
  }

  handleLoadMore = (e) => {
    const { dispatch } = this.props;
    const { loading } = this.state;
    if (!loading) {
      dispatch(loadingActions.show());
      this.setState({ loading: true }, async () => {
        const { start, timeSlots, loadMore } = await bookServices.getListViewSchedule(
          this.props.place.id,
          this.state.start,
        );
        const slots = this.state.timeSlots.concat(timeSlots);
        dispatch(loadingActions.hide());
        this.setState({
          start,
          loadMore,
          timeSlots: slots,
          timeSlotsPerDay: this.getTimeSlotsPerDay(slots),
          loading: false,
          searchUntil: Boolean(start && timeSlots.length === 0),
        });
      });
    }
  };

  render() {
    const { place } = this.props;
    const { start, loadMore, loading, timeSlotsPerDay, searchUntil } = this.state;

    if (!(place && place.schedule)) {
      return null;
    }

    const services = Object.keys(timeSlotsPerDay).map((key, i) => (
      <Day place={place} schedule={timeSlotsPerDay[key]} key={i} index={i} isOpen={i < 30 ? true : false} />
    ));

    const noServices = (
      <>
        <p className="no-items mb-4">{__('noServices')}</p>
        <img src="/images/no-service-ph.svg" alt="" className="mx-auto" />
      </>
    );

    const startDate = moment(start);
    startDate.locale(getLang());

    return (
      <div id="services" className="services list-view" data-children=".item">
        {services && services.length > 0 ? services : noServices}
        <div style={{ height: isMobile() ? '12px' : '24px', position: 'relative', marginLeft: '-4px' }}>
          {loading && <LoadingPlaceHolder style={{ height: '92px', backgroundPosition: 'top center', zIndex: '8' }} />}
        </div>
        <div style={{ margin: isMobile() ? '0 0 0 24px' : '0' }}>
          {loadMore && (
            <Button style={{ marginRight: '8px' }} onClick={this.handleLoadMore}>
              {__('extendButton')}
            </Button>
          )}
          {searchUntil && (
            <span className="mt-3 block">{__('searchedUntil', { time: startDate.format('MMM YYYY') })}</span>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ListView);
