import { ChevronIcon } from '@/components/icons';
import { __ } from '@/locale';
import { Link } from 'react-router-dom';

const BuyGiftCard = ({ slug }: { slug: string }) => (
  <Link
    className="border-black-100 mb-10 mt-8 flex items-center justify-between rounded-lg border p-4"
    to={`/places/${slug}/giftcard/checkout`}>
    <div className="text-primary">
      <h2 className="text-base font-semibold">{__('buyValueCard.checkout.buyGiftCard') as string}</h2>
      <div className="text-black-600">{__('buyValueCard.checkout.buyGiftCardDesc') as string}</div>
    </div>
    <div>
      <ChevronIcon className="text-primary m-2 h-5 w-5 -rotate-90" />
    </div>
  </Link>
);

export default BuyGiftCard;
