/* eslint jsx-a11y/anchor-is-valid: 0 */
import IconRow from '@/components/elements/IconRow';
import { Button, LinkButton } from '@/components/elements/forms/buttons';
import { GlobeIcon, HeartIcon, LocationIcon, MailIcon, PhoneAltIcon, TimeIcon } from '@/components/icons';
import {
  getImagePreview,
  getPlaceImage,
  hasFeatureSetting,
  isEmpty,
  isMobile,
  isServer,
  isSistaminuten as isSistaminutenInstance,
  trackMpEvent,
} from '@/helpers';

import { themed } from '@/helpers/theme';
import { __ } from '@/locale';
import React from 'react';
import { Link } from 'react-router-dom';
import { FavoritesButton, GiftCardButton, Location, Program, SalonInfo } from '.';
import AssociationBadge from './AssociationBadge';

export default class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.showPhone = this.showPhone.bind(this);
    this.state = this.getState(props.place);
  }

  showPhone() {
    trackMpEvent('show_phone_clicked', {
      screen_name: 'company_details',
      company_id: this.props.place.id,
    });

    this.setState({
      phoneIsShowing: true,
      phone: this.props.place?.contact?.phone,
    });
  }

  getState(place) {
    const phone = place?.contact?.phone?.trim();
    const hasPhone = phone && phone !== '0';

    return {
      phoneIsShowing: false,
      phone: hasPhone ? `${phone.slice(0, -2)} XX` : null,
      noPhone: !hasPhone,
      email: place?.contact?.email ?? null,
    };
  }

  componentDidMount() {
    // create email image from a canvas
    if (!isServer && this.state.email) {
      const canvasElement = document.getElementById('em-canvas');
      let canvasTxt = canvasElement.getContext('2d');
      canvasTxt.canvas.height = 20;
      canvasTxt.font = '15px Gibson';
      canvasTxt.fillStyle = isSistaminutenInstance() ? '#333333' : '#23A994';
      canvasTxt.fillText(this.state.email, 2, 15);
      this.setState({
        emailImage: canvasTxt.canvas.toDataURL(),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.place && this.props.place && prevProps.place.id !== this.props.place.id) {
      this.setState(this.getState(this.props.place));
    }
  }

  handleWebsiteClick = (e) => {
    trackMpEvent('webpage_clicked', {
      screen_name: 'company_details',
      company_id: this.props.place.id,
    });
  };

  handleEmailClick = (e) => {
    trackMpEvent('email_clicked', {
      screen_name: 'company_details',
      company_id: this.props.place.id,
    });

    window.location.href = 'mailto:' + this.state.email;
  };

  showFavoriteModal = (value, callback) => {
    this.setState({ showFavoriteModal: value || false }, () => {
      if (callback) {
        callback();
      }
    });
  };

  getWebsiteHtml() {
    const { place = {} } = this.props;
    const { about = {}, contact = {} } = place;
    const { website } = contact;
    const { subscriptionType } = about.settings;

    if (subscriptionType === 'essential') return null;

    let url = (website || '').trim();
    if (url && url.indexOf(' ') === -1 && url.indexOf('http') === -1) {
      url = 'http://' + url;
    }

    const isWebsiteClickable = url && url.indexOf('http') === 0;
    if (isWebsiteClickable) {
      return (
        <IconRow
          tag="li"
          icon={<GlobeIcon className={themed('text-primary', 'text-black-800')} />}
          iconPosition="center"
          className="border-black-100 border-b-[1px] border-solid py-4">
          <LinkButton
            to={url}
            className={`overflow-hidden text-ellipsis !p-0`}
            onClick={this.handleWebsiteClick}
            target="_blank"
            rel="noopener noreferrer">
            {website}
          </LinkButton>
        </IconRow>
      );
    }
    if (website) {
      return <li className="website">{website}</li>;
    }

    return null;
  }

  getEmailHtml() {
    return this.state.email && this.state.emailImage ? (
      <IconRow
        tag="li"
        icon={<MailIcon className={themed('text-primary', 'text-black-800')} />}
        iconPosition="center"
        className="border-black-100 border-b-[1px] border-solid py-4">
        <a onClick={this.handleEmailClick}>
          <img className="w-full" alt="" src={this.state.emailImage} loading="lazy" />
        </a>
      </IconRow>
    ) : null;
  }

  getPhoneHtml() {
    return !this.state.noPhone ? (
      <IconRow
        tag="li"
        icon={<PhoneAltIcon />}
        iconPosition="center"
        className="border-black-100 border-b-[1px] border-solid py-4">
        <span className="text-black-600">
          {!this.state.phoneIsShowing ? (
            <>
              {this.state.phone}
              <Button
                variant="link"
                onClick={this.showPhone}
                label={__('shownNumber')}
                className="no-underline hover:underline"
              />
            </>
          ) : (
            <a className="text-black-600" href={`tel:${this.state.phone}`}>
              {this.state.phone}
            </a>
          )}
        </span>
      </IconRow>
    ) : null;
  }

  renderExtraInfoOpeningHours(place) {
    return (
      !isEmpty(place.about.settings.openingHoursExtraInformation) && (
        <IconRow
          tag="li"
          icon={<TimeIcon />}
          iconPosition="top"
          className="border-black-100 w-full border-b-[1px] border-solid py-4">
          <div className="text-black-600 min-h-[20px] w-full whitespace-pre-wrap">
            <ul>
              <li key="8" className="!text-black-900 font-bold">
                {__('openingHoursOtherInfo')}
              </li>
              <li className={`relative flex w-full list-none flex-row leading-[28px]`}>
                {place.about.settings.openingHoursExtraInformation}
              </li>
            </ul>
          </div>
        </IconRow>
      )
    );
  }

  renderMobile() {
    const { place, useMapTiler = false } = this.props;
    const isSistaminuten = isSistaminutenInstance() || Boolean(place.lastMinuteDiscount);
    return (
      <div id="sidebar">
        {this.props.hasPlace && (
          <div className="salon-info">
            <Link to={'/places/' + place.about.slug + '-' + place.id}>
              <img
                src={
                  place.about && place.about.profileImage
                    ? place.about.profileImage
                    : place.about && place.about.images
                    ? place.about.images[0]
                    : '/images/no-salon-image.png'
                }
                alt=""
                width="270"
                height="140"
                style={{ width: '100%', height: 'auto', borderRadius: '12px' }}
                loading="lazy"
              />
              <SalonInfo place={place} noRating noAddress noGiftcard link inSidebar />
            </Link>
          </div>
        )}
        <h6>
          <span className="font-semibold">{__('contactDetails')}</span>
        </h6>
        <ul className="items">
          {!isEmpty(place.program) && (
            <IconRow
              tag="li"
              icon={<TimeIcon />}
              iconPosition="top"
              className="border-black-100 w-full border-b-[1px] border-solid py-4">
              <Program place={place} program={place.program} />
            </IconRow>
          )}
          {this.renderExtraInfoOpeningHours(place)}
          {this.getPhoneHtml()}
          {!isSistaminuten && (
            <IconRow
              icon={<HeartIcon className={themed('text-primary', 'text-black-800')} />}
              className="border-black-100 w-full border-b-[1px] border-solid py-4"
              tag="li">
              <FavoritesButton
                withText
                withIcon={false}
                className="!ml-0"
                showModal={this.state.showFavoriteModal}
                showFavoriteModal={this.showFavoriteModal}
                place={place}
              />
            </IconRow>
          )}
          {this.getEmailHtml()}
          {this.getWebsiteHtml()}
          <IconRow
            icon={<LocationIcon className={themed('text-primary', 'text-black-800')} />}
            className={`w-full pt-4 ${themed('text-primary', 'text-black-800')}`}
            tag="li">
            <Location
              placeId={place.id}
              contact={place.contact}
              showMap={false}
              showOnlyDirections={hasFeatureSetting(place, 'hide_location_image')}
            />
          </IconRow>
          <Location
            placeId={place.id}
            contact={place.contact}
            showOnlyDirections={hasFeatureSetting(place, 'hide_location_image')}
            showAddress={false}
            useMapTiler={useMapTiler}
          />
          <canvas id="em-canvas" style={{ display: 'none' }} />
        </ul>
      </div>
    );
  }

  render() {
    const { place, useMapTiler = false } = this.props;
    const isSistaminuten = isSistaminutenInstance() || Boolean(place.lastMinuteDiscount);

    if (!(place && place.contact)) {
      return null;
    }

    if (isMobile()) {
      return this.renderMobile();
    }

    let placeLogo = getPlaceImage(place);
    placeLogo = getImagePreview(placeLogo, '275x275');

    const showViewGallery = Boolean(place.about && place.about.images && place.about.images.length);
    const associations = place.about && place.about.associations ? place.about.associations : undefined;
    return (
      <div className="w-full">
        {this.props.hasPlace && (
          <div className="salon-info">
            <Link to={'/places/' + place.about.slug + '-' + place.id}>
              <img
                width="275"
                height="275"
                src={placeLogo ? placeLogo : '/images/no-salon-image.png'}
                alt=""
                style={{ width: '275px', borderRadius: '12px', height: 'auto' }}
                loading="lazy"
              />
              <SalonInfo place={place} noRating noAddress noGiftcard link inSidebar />
            </Link>
          </div>
        )}
        {this.props.showPlaceLogo && placeLogo && (
          <a
            href="#photos"
            className="bg-black-50 relative mb-3 flex h-[270px] w-[270px] min-w-[270px] items-center justify-center overflow-hidden rounded-md">
            <img
              width="275"
              height="275"
              src={placeLogo}
              alt=""
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/images/bd-fallback-img200x200.png')}
            />
            {showViewGallery && (
              <span
                className="text-m absolute bottom-0 right-0 h-[33px] w-[94px] cursor-pointer text-center !leading-[33px] text-white"
                style={{ backgroundColor: 'rgba(42,46,67,0.7)' }}>
                {__('viewGallery')}
              </span>
            )}
          </a>
        )}
        {!isSistaminuten && (
          <div className="flex justify-center">
            <GiftCardButton place={place} block />
          </div>
        )}
        {associations && associations.length > 0 && (
          <div className="my-6 flex w-full flex-col space-y-2">
            {associations.map((association, key) => (
              <div key={key}>
                <AssociationBadge association={association} />
              </div>
            ))}
          </div>
        )}
        <div className="sticky">
          <div className="flex items-center justify-between">
            <span className="font-semibold">{__('contactDetails')}</span>
            {!isSistaminuten && (
              <FavoritesButton
                showModal={this.state.showFavoriteModal}
                showFavoriteModal={this.showFavoriteModal}
                place={place}
              />
            )}
          </div>
          <ul className="items">
            {!isEmpty(place.program) && (
              <IconRow
                tag="li"
                icon={<TimeIcon />}
                iconPosition="top"
                className="border-black-100 w-full border-b-[1px] border-solid py-4">
                <Program place={place} program={place.program} />
              </IconRow>
            )}
            {this.renderExtraInfoOpeningHours(place)}
            {this.getPhoneHtml()}
            {this.getEmailHtml()}
            {this.getWebsiteHtml()}
            <IconRow
              tag="li"
              icon={<LocationIcon />}
              iconPosition="top"
              className={`pt-4 ${themed('text-primary', 'text-black-800 underline')}`}>
              <Location
                placeId={place.id}
                contact={place.contact}
                showMap={false}
                showOnlyDirections={hasFeatureSetting(place, 'hide_location_image')}
              />
            </IconRow>
            <Location
              placeId={place.id}
              contact={place.contact}
              showOnlyDirections={hasFeatureSetting(place, 'hide_location_image')}
              showAddress={false}
              useMapTiler={useMapTiler}
            />
            <canvas id="em-canvas" style={{ width: '100%', display: 'none' }} />
          </ul>
        </div>
      </div>
    );
  }
}
