import { checkoutConfig } from '@/config/adyenNew';
import { adyenServices } from '@/services';
import { AdyenCheckoutConfiguration } from '@/types/adyen';
import AdyenCheckout from '@adyen/adyen-web';
import { useEffect, useRef, useState } from 'react';

type SessionState = 'active' | 'disabled' | 'hidden';

const useAddCard = ({
  onAuthorize,
  onError,
  onChange,
  onMounted,
  amount,
  guestId,
  isGiftcardCheckout,
}: AdyenCheckoutConfiguration) => {
  const [checkout, setCheckout] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [sessionState, setSessionState] = useState<SessionState>('active');
  const paymentContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let mounted = true;
    const initCheckout = async () => {
      setLoading(true);
      try {
        const session = await adyenServices.createSession('/', amount ?? 0, guestId, isGiftcardCheckout);
        if (!session || !paymentContainer.current) {
          return;
        }

        const checkout = await AdyenCheckout({
          ...checkoutConfig(amount ?? 0, isGiftcardCheckout),
          session,
          onError: () => {
            if (!mounted) return;
            setSessionState('disabled');
            onError('PaymentError');
          },
          onChange: (state) => {
            if (!mounted) return;
            setError(false);
            setSessionState('active');
            onChange?.(state.isValid);
          },
          onSubmit: (response) => {
            if (!mounted) return;
            if (response.isValid) {
              onAuthorize(response.data);
            } else {
              onError('PaymentError');
            }
          },
        });

        const checkoutComponent = checkout.create('card', {});
        setCheckout(checkoutComponent);
        setLoading(false);
      } catch (error) {
        console.error(error);
        if (!mounted) return;
        setLoading(false);
        onError('MountError');
      }
    };

    initCheckout();

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    checkout?.mount?.(paymentContainer.current);
    onMounted?.(paymentContainer, checkout.submit);

    return () => {
      if (checkout) checkout.unmount?.();
    };
  }, [checkout]);

  const handleSubmit = () => {
    setSessionState('hidden');
    checkout.submit();
  };

  return { checkout, paymentContainer, loading, error, handleSubmit, sessionState };
};

export default useAddCard;
