import { ReactComponent as AccountIcon } from '@/assets/icons/tabs/account/AccountIcon.svg';
import { ReactComponent as AccountSelectedIcon } from '@/assets/icons/tabs/account/AccountSelectedIcon.svg';
import { ReactComponent as CalendarIcon } from '@/assets/icons/tabs/calendar/CalendarIcon.svg';
import { ReactComponent as CalendarSelectedIcon } from '@/assets/icons/tabs/calendar/CalendarSelectedIcon.svg';
import { ReactComponent as DealsIcon } from '@/assets/icons/tabs/deals/DealsIcon.svg';
import { ReactComponent as DealsSelectedIcon } from '@/assets/icons/tabs/deals/DealsSelectedIcon.svg';
import { ReactComponent as FavoriteIcon } from '@/assets/icons/tabs/favorite/FavoriteIcon.svg';
import { ReactComponent as FavoriteSelectedIcon } from '@/assets/icons/tabs/favorite/FavoriteSelectedIcon.svg';
import { ReactComponent as HomeIcon } from '@/assets/icons/tabs/home/HomeIcon.svg';
import { ReactComponent as HomeSelectedIcon } from '@/assets/icons/tabs/home/HomeSelectedIcon.svg';
import { ReactComponent as MoreIcon } from '@/assets/icons/tabs/more/MoreIcon.svg';
import { ReactComponent as MoreSelectedIcon } from '@/assets/icons/tabs/more/MoreSelectedIcon.svg';
import { ReactComponent as SearchIcon } from '@/assets/icons/tabs/search/SearchIcon.svg';
import { ReactComponent as SearchSelectedIcon } from '@/assets/icons/tabs/search/SearchSelectedIcon.svg';
import { ReactComponent as SistaminutenIcon } from '@/assets/icons/tabs/sistaminuten/SistaminutenIcon.svg';
import { ReactComponent as SistaminutenSelectedIcon } from '@/assets/icons/tabs/sistaminuten/SistaminutenSelectedIcon.svg';
import { TabIcon as TabIconProps } from '../types';

const TabIcon = ({ icon, selected }: TabIconProps & { selected: boolean }) => {
  switch (icon) {
    case 'home':
      return selected ? <HomeSelectedIcon /> : <HomeIcon />;
    case 'search':
      return selected ? <SearchSelectedIcon /> : <SearchIcon />;
    case 'calendar':
      return selected ? <CalendarSelectedIcon /> : <CalendarIcon />;
    case 'favorite':
      return selected ? <FavoriteSelectedIcon /> : <FavoriteIcon />;
    case 'account':
      return selected ? <AccountSelectedIcon /> : <AccountIcon />;
    case 'more':
      return selected ? <MoreSelectedIcon /> : <MoreIcon />;
    case 'deal':
      return selected ? <DealsSelectedIcon /> : <DealsIcon />;
    case 'smt':
      return selected ? <SistaminutenSelectedIcon /> : <SistaminutenIcon />;
    default:
      const never: never = icon;
      throw new Error(`Unhandled icon: ${never}`);
  }
};

export default TabIcon;
