import { bundleActions } from '@/actions/bundleActions';
import BottomCard from '@/components/elements/Card/Card';
import { LinkButton } from '@/components/elements/forms/buttons';
import { ButtonProps } from '@/components/elements/forms/buttons/Button/Button';
import { classnames, getBundleValidity, url } from '@/helpers';
import { themed } from '@/helpers/theme';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { Fragment, useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BundleReadMore from './BundleReadMore';

export type PlaceBundleService = {
  mpId: number;
  name: string;
  quantity: number;
};

export type PlaceBundle = {
  id: number;
  name: string;
  description?: string;
  price: number;
  priceLabel: string;
  finalPrice: number;
  finalPriceLabel: string;
  validMonths?: number;
  durationLabel?: string;
  service: PlaceBundleService;
  employees: number[];
};

type BundleProps = {
  place: any;
  bundle: PlaceBundle;
  filterQuery?: string;
};

const Bundle = ({ bundle, place, filterQuery }: BundleProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const { isMobileView } = useMobileView();

  useEffect(() => {
    const selectedBundleId = parseInt(url.returnGetParameter('sb'));
    if (bundle.id === selectedBundleId) {
      setShowMoreInfo(true);
    }
  }, []);

  const handleOnCloseMoreInfo = () => {
    history.replace({ search: url.changeGetParameter('sb') });
  };

  const handleOnOpenMoreInfo = () => {
    history.replace({ search: url.changeGetParameter('sb', bundle.id) });
  };

  const handleBuyClick = () => {
    dispatch(bundleActions.pickBundleToCheckout({ bundle, place }));
  };

  const handleBuyBundleClick = () => {
    handleBuyClick();
    history.push('/bundles/checkout');
  };

  const highlightServiceName = () => {
    if (!filterQuery) {
      return <span className="service-name">{bundle.name}</span>;
    }

    return (
      <span className="service-name">
        <Highlighter
          highlightClassName="highlight"
          searchWords={filterQuery.split(' ')}
          autoEscape={true}
          textToHighlight={bundle.name}
        />
      </span>
    );
  };

  const getBuyButton = (block?: boolean, size?: ButtonProps['size']) => {
    return (
      <LinkButton
        className="min-w-[75px]"
        block={block}
        size={size}
        variant="primary"
        to="/bundles/checkout"
        onClick={handleBuyClick}>
        {_s('buy')}
      </LinkButton>
    );
  };

  const getReadMoreButton = () => {
    return (
      <Fragment>
        {/* spacer to avoid elements going under fixed bottom card */}
        {isMobileView && <div className="h-[128px]" />}
        {/* spacer to avoid elements going under fixed bottom card */}
        <div
          className={classnames(isMobileView ? 'fixed left-0 right-0 mt-auto' : '-mx-3xl -mb-3xl sticky', 'bottom-0')}>
          <BottomCard className="pb-lg h-auto w-full bg-white">{getBuyButton(true, 'md')}</BottomCard>
        </div>
      </Fragment>
    );
  };

  const getBundlePriceFormatted = () => {
    if (bundle.finalPrice && bundle.finalPrice !== bundle.price) {
      return (
        <span className={themed('text-information', 'text-green-sm')}>
          {`${bundle.finalPriceLabel} `}
          <s className="text-black-600">{`(${bundle.priceLabel})`}</s>
        </span>
      );
    }

    return <span>{bundle.priceLabel}</span>;
  };

  return (
    <li
      className={`!last-of-type:border-none hover:bg-black-50 !flex cursor-pointer items-start justify-between p-[10px]`}
      onClick={handleBuyBundleClick}>
      <div className="flex max-w-sm flex-col">
        {highlightServiceName()}
        <span className="text-m my-1">{`(${_s('buyBundles.quantity', { count: bundle.service.quantity })})`}</span>
        <div className="my-1 flex flex-col md:flex-row">
          <span className="text-black-600 text-sm">
            {bundle.durationLabel}
            {bundle.durationLabel ? ', ' : ''}
            {getBundlePriceFormatted()}
          </span>
        </div>
        {bundle.validMonths && (
          <span className="text-black-600 my-1 text-sm">{getBundleValidity(bundle.validMonths)}</span>
        )}
        {bundle.description && bundle?.employees?.length > 0 && (
          <BundleReadMore
            bundle={bundle}
            place={place}
            bundlePrice={getBundlePriceFormatted()}
            actionButton={getReadMoreButton()}
            showMoreInfo={showMoreInfo}
            handleOnOpenMoreInfo={handleOnOpenMoreInfo}
            handleOnCloseMoreInfo={handleOnCloseMoreInfo}
          />
        )}
      </div>
      {getBuyButton()}
    </li>
  );
};

export default Bundle;
