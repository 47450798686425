import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { Button } from '@/components/elements/forms/buttons';
import { ChevronIcon } from '@/components/icons';
import { bookConstants } from '@/constants';
import withWalletPayment from '@/hoc/withWalletPayment';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import { bookActions, loadingActions } from '../../../../actions';
import { config } from '../../../../config';
import {
  firstWeekDay,
  getCalendarWeek,
  getDayInfo,
  // saveBodyScroll,
  getPlaceTimezone,
  hasDynamicPricing,
  hoursFromSeconds,
  isServer,
  startOfDay,
} from '../../../../helpers';
import { __ } from '../../../../locale';
import { bookServices } from '../../../../services';
require('moment-timezone');

class LastMinuteWeekPicker extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.blockNavigation = false;
    moment.tz.setDefault(getPlaceTimezone(props.place));

    this.months = config.monthsShort;
    this.days = config.weekDaysShort;

    const weeks = this.getWeeks();
    const dateRelated = this.getSelectedDateInfo(startOfDay(), weeks);

    this.state = {
      loading: true,
      weeks: weeks,
      firstDay: getDayInfo(startOfDay()), // today
      hour: props.time && props.time.selected ? props.time.selected : '',
      weekData: {},
      intervals: [],
      ...dateRelated,
      showLogin: false,
    };

    if (!isServer) window.scrollTo(0, 0);

    props.dispatch(
      bookActions.getAvailability({
        services: props.services,
        date: this.prepDate(dateRelated.startFrom),
        employee: props.employee || 0,
        source: 'loadHours',
        isSistaminuten: true,
      }),
    );

    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
    this.pickDay = this.pickDay.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    let nextState = { clicked: false };

    if (this.props.show !== prevProps.show) {
      this.setState(nextState);
      return;
    }

    if (
      this.props.set !== prevProps.set ||
      this.props.availability !== prevProps.availability ||
      this.props.time !== prevProps.time ||
      this.props.employee !== prevProps.employee
    ) {
      const time = this.props.time && this.props.time.timestamp;
      if (time) {
        const dateRelated = this.getSelectedDateInfo(time);
        nextState = { ...nextState, ...dateRelated };
        nextState.loading = false;
        // nextState.disableNext = !this.hasAnAvailableTime(nextState.intervals, this.props.availability);
      }

      this.setState(nextState, () => {});
    }
  }

  prepDate(date) {
    return date < startOfDay() ? startOfDay() : date;
  }

  getWeeks() {
    let startingFrom = firstWeekDay();

    let i = this.getWeeksCount();
    const weeks = [];
    while (i) {
      weeks.push(startingFrom);
      startingFrom += 3600 * 24 * 7 * 1000;
      i--;
    }
    return weeks;
  }

  getWeekDays(startFrom, weeks) {
    if (!weeks) {
      weeks = this.state.weeks;
    }

    const index = weeks.indexOf(startFrom);
    if (index >= 0) {
      return this.getDays(startFrom);
    }

    return [];
  }

  getDays(startingFrom) {
    const display = [];
    let i = 7;
    while (i) {
      const day = getDayInfo(startingFrom);
      day.timestamp = startingFrom;
      display.push(day);
      startingFrom += 86400 * 1000;
      i--;
    }

    return display;
  }

  getSelectedDateInfo(timestamp, weeks) {
    let startingFrom = firstWeekDay(timestamp);
    if (!weeks) {
      weeks = this.state.weeks;
    }
    let selectedWeek = weeks.indexOf(startingFrom);
    const selectedDate = moment(startingFrom);
    const data = {
      selectedWeek: selectedWeek,
      startFrom: startingFrom,
      year: this.getYearLabel(startingFrom),
      weekOfYear: selectedDate.isoWeek(),
      selectedDay: getDayInfo(startingFrom),
      weekDays: this.getWeekDays(startingFrom, weeks),
      disablePrev: weeks[0] === startingFrom ? true : false,
      disableNext: weeks[weeks.length - 1] === startingFrom,
    };
    return data;
  }

  handleNextSwipe() {
    this.handleNext();
  }

  handleNextClick() {
    this.handleNext();
  }

  handleNext() {
    if (this.blockNavigation || this.state.disableNext) return false;

    const selectedDate = this.state.startFrom + 3600 * 24 * 7 * 1000;
    const dateRelated = this.getSelectedDateInfo(selectedDate);

    this.setState({ ...dateRelated });
  }

  handlePrevSwipe() {
    this.handlePrev();
  }

  handlePrevClick() {
    this.handlePrev();
  }

  handlePrev() {
    if (this.blockNavigation || this.state.disablePrev) return false;

    const selectedDate = this.state.startFrom - 3600 * 24 * 7 * 1000;
    const dateRelated = this.getSelectedDateInfo(selectedDate, this.state.weeks);

    this.setState({ ...dateRelated });
  }

  currentWeekHasNoSlots(intervals) {
    let allEmpty = true;
    intervals.forEach((weekDay) => {
      if (Boolean(weekDay.options.length)) {
        weekDay.options.forEach((available) => {
          allEmpty = false;
        });
      }
    });
    return allEmpty;
  }

  getWeekData(date) {
    const { employee, dispatch, place, services } = this.props;
    const { availabilityOverview } = this.state;
    const firstAvailableDate =
      availabilityOverview && availabilityOverview.firstAvailableDate
        ? moment(availabilityOverview.firstAvailableDate).unix() * 1000
        : null;
    dispatch(loadingActions.show('loadHours'));
    return bookServices.getWeekDays(services, date, employee, firstAvailableDate).then((data) => {
      dispatch(bookActions.pickDate(date, data.weekDays || {}, data.fromErp || []));
      dispatch(bookActions.setFirstDay(data));
      dispatch(bookActions.setCampaigns(services, place.campaigns, date));
      dispatch(loadingActions.hide());

      if (data.weekDays) {
        if (data.fromErpOverview) {
          this.setState({ availabilityOverview: data.fromErpOverview });
        } else {
        }
        return data.weekDays;
      }

      return {};
    });
  }

  getYearLabel(day) {
    return getCalendarWeek(day);
  }

  pickDay(selectedDay) {
    if (this.state.clicked) {
      return null;
    }

    const { dispatch, services, employee, place } = this.props;

    const selected = startOfDay(selectedDay);
    const dateRelated = this.getSelectedDateInfo(selected, this.state.weeks);
    this.setState({ clicked: true, ...dateRelated });

    dispatch(loadingActions.show('loadHours'));
    dispatch(
      bookActions.getAvailability({
        services,
        date: dateRelated.startFrom,
        employee: employee || 0,
        isSistaminuten: true,
      }),
    );
    dispatch(bookActions.setCampaigns(services, place.campaigns, dateRelated.startFrom));
  }

  hasAnAvailableTime(intervals = [], availability) {
    let allEmpty = true;

    intervals.forEach((weekDay) => {
      if (Boolean(weekDay.options.length)) {
        weekDay.options.forEach((available) => {
          allEmpty = false;
        });
      }
    });

    if (allEmpty && availability && availability.firstDay && availability.firstDay.none) {
      return false;
    } else {
      return true;
    }
  }

  getWeeksCount() {
    let weeksCount = 1;

    const start = moment(); //.clone().add(2, 'days');
    const end = start.clone().add(18, 'hours');
    if (start.isoWeek() !== end.isoWeek()) {
      weeksCount = 2;
    }

    return weeksCount;
  }

  makeOption(options) {
    const { services } = this.props;
    const ret = [];
    for (const op in options) {
      const span = options[op].hour;
      const time = hoursFromSeconds(span.from);
      ret.push({
        label: time,
        timestamp: span.from,
        employees: options[op].employee,
        disabled: options[op].disabled,
        resources: span.resources,
        capacity: services.length === 1 ? span.capacity || undefined : undefined,
      });
    }

    return ret;
  }

  /**
   * @typedef ErpTimeSlot
   * @property {string} id the Id
   * @property {string} start the starting time
   * @property {number} capacity the capacity
   * @property {number} price the price in cents
   * @property {number[]} serviceIds services that will be booked on this slot
   * @property {number} listPrice the list price in cents
   * @property {number} employeeId which calendar will be booked on this slot
   *
   * Pick a specific ErpTimeSlot to book using ERP availabilities
   * @param {ErpTimeSlot} timeSlot the time slot to book
   */
  async pickHourErp(timeSlot) {
    const { dispatch, place, services, hasActiveGooglePayPaymentMethod, hasActiveApplePayPaymentMethod } = this.props;

    const timeSlotEmployee = place.employees.filter((e) => e.id === timeSlot.employeeId)[0];
    const timeSlotMoment = moment(timeSlot.start);
    const timeSlotUnix = moment.utc(timeSlotMoment).unix() + timeSlotMoment.utcOffset() * 60;
    const startOfDayUnix = moment.utc(timeSlotMoment).startOf('day').unix();
    const startOfDayUnixOld = startOfDay(startOfDayUnix * 1000);
    const hour = timeSlotUnix - startOfDayUnix;

    let dynamicPriceListIdKey = bookConstants.DYNAMIC_PRICE_KEY_DEFAULT;
    if ((services || []).some((service) => hasDynamicPricing(service))) {
      dispatch(loadingActions.show('loadHours'));
      try {
        const hasDynamicPrice = await bookServices.hasDynamicPrice(place.id, timeSlot.start);
        if (hasDynamicPrice?.dynamicPriceListIdKey) {
          dynamicPriceListIdKey = hasDynamicPrice.dynamicPriceListIdKey;
        }
      } catch (e) {}
      dispatch(loadingActions.hide());
    }

    this.setState({ selectedDay: getDayInfo(startOfDayUnixOld), clicked: true, hour: hour });
    /*
     * ToDo: Employee is always known here, even if they didn't chose anyone (it's taken from the random in the week picker)
     * Maybe it needs some changes late in the process so the system doens't think someone chose an employee?
     */
    dispatch(
      bookActions.pickHour(startOfDayUnixOld, hour, [timeSlotEmployee], dynamicPriceListIdKey, [], timeSlot.capacity),
    );
    dispatch(bookActions.setCampaigns(services, place.campaigns, startOfDayUnixOld));

    const fromUrl = window.location.href;
    this.props.history.push({
      pathname: '/booking/checkout',
      state: {
        employee: timeSlotEmployee,
        fromUrl,
        hasActiveGooglePayPaymentMethod,
        hasActiveApplePayPaymentMethod,
        isSistaminuten: true,
      },
    });
  }

  hideTooltip(isDisabled) {
    if (isDisabled) {
      this.setState({ showTooltip: false });
    }
  }

  moveMouse(e, isDisabled) {
    if (isDisabled) {
      const x = e.clientX - 10 + 'px',
        y = e.clientY + 20 + 'px';
      this.setState({ showTooltip: { x: x, y: y } });
    }
  }

  getNotAvailableButton(allEmpty) {
    const { availability, place } = this.props;
    const { availabilityOverview } = this.state;
    const { loading /*, selectedDay*/ } = this.state;
    const buttonContainerClassName = 'w-full border-[1px] border-black-100 pb-10 text-center';
    const illustration = <img src="/images/calendar-no-time-icon.svg" className="mx-auto py-8" alt="" width={260} />;
    let button = null;
    const bokadirektPlaceUrl = `https://www.bokadirekt.se/places/${place.about.slug}-${place.id}`;

    if (availabilityOverview) {
      let nextAvailableText = null;
      let firstAvailableTime = null;
      if (allEmpty && !loading && availabilityOverview.firstAvailableDate) {
        const firstAvailableDate = moment(availabilityOverview.firstAvailableDate);
        firstAvailableTime = moment
          .utc([firstAvailableDate.year(), firstAvailableDate.month(), firstAvailableDate.date()])
          .valueOf();
        nextAvailableText =
          firstAvailableDate.date() +
          ' ' +
          this.months[firstAvailableDate.month()] +
          ' ' +
          firstAvailableDate.year() +
          ', ' +
          config.weekDaysLong[firstAvailableDate.day()];
      }

      button =
        allEmpty && !loading ? (
          <div className={buttonContainerClassName}>
            {illustration}
            <h3 className="daypicker">
              {__('notAvailableSistaminutentider')}
              <a href={bokadirektPlaceUrl} target="_blank" rel="noreferrer" className="text-black-700 underline">
                Bokadirekt
              </a>
              {nextAvailableText && (
                <span className="firstday">
                  <span>{__('nextAvailableWeek')}: </span>
                  <span className="font-semibold" onClick={() => this.pickDay(firstAvailableTime)}>
                    {nextAvailableText}
                  </span>
                </span>
              )}
            </h3>
            {nextAvailableText && (
              <Button onClick={() => this.pickDay(firstAvailableTime)}>{__('showNextTime')}</Button>
            )}
          </div>
        ) : null;
      if (availabilityOverview && !availabilityOverview.firstAvailableDate) {
        button =
          allEmpty && !loading ? (
            <div className={buttonContainerClassName}>
              {illustration}
              <h3 className="daypicker">{__('notAvailableCouldNotFind')}</h3>
            </div>
          ) : null;
      }
    } else {
      let nextAvailableText = null;
      if (allEmpty && !loading && availability && availability.firstDay) {
        const dayInfo = getDayInfo(availability.firstDay.time);
        nextAvailableText =
          dayInfo.dd + ' ' + this.months[dayInfo.mm] + ' ' + dayInfo.yyyy + ', ' + config.weekDaysLong[dayInfo.weekDay];
      }

      button =
        allEmpty && !loading ? (
          <div className={buttonContainerClassName}>
            {illustration}
            <h3 className="daypicker">
              {__('notAvailableSistaminutentider')}
              <a href={bokadirektPlaceUrl} target="_blank" rel="noreferrer" className="text-black-700 underline">
                Bokadirekt
              </a>
              {nextAvailableText && (
                <span className="firstday">
                  <span>{__('nextAvailableWeek')}: </span>
                  <span className="font-semibold" onClick={() => this.pickDay(this.props.availability.firstDay.time)}>
                    {nextAvailableText}
                  </span>
                </span>
              )}
            </h3>
            {nextAvailableText && (
              <Button onClick={() => this.pickDay(this.props.availability.firstDay.time)}>{__('showNextTime')}</Button>
            )}
          </div>
        ) : null;
      if (availability && availability.firstDay && availability.firstDay.none) {
        button =
          allEmpty && !loading ? (
            <div className={buttonContainerClassName}>
              {illustration}
              <h3 className="daypicker">{__('notAvailableCouldNotFind')}</h3>
            </div>
          ) : null;
      }
    }

    return button;
  }

  allowsGroupBookings() {
    const { services = [] } = this.props;
    for (let i = 0; i < services.length; i++) {
      const { capacity } = (services[i] && services[i].about) || {};
      if (!(capacity && capacity > 1)) return false;
    }

    return true;
  }

  render() {
    const { year, weekOfYear, firstDay, showTooltip, weekDays, weeks, selectedWeek } = this.state;
    const { show, loadSource, campaignStart, campaignEnd, isLoading: loadingActiveWalletMethods } = this.props;
    const settings = {
      dots: false,
      infinite: false,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      draggable: false,
      onSwipe: (direction) => {
        direction === 'left' ? this.handleNextSwipe() : this.handlePrevSwipe();
      },
    };

    if (this.slider) {
      this.slider.slickGoTo(weeks.indexOf(selectedWeek));
    }

    let i = 0;
    let j = 0;
    let k = 0;
    let allEmpty = true;
    const calendarTimeSlots = (this.props.availability && this.props.availability.calendarTimeSlots) || {};

    let availabilities = [];
    let availableDays = {};

    availabilities = weekDays.map((weekDay) => {
      return (
        <div
          className="border-black-100 flex-shrink flex-grow basis-0 border-b-[1px] border-l-[1px] text-center last-of-type:border-r-[1px]"
          key={i++}>
          {Object.keys(calendarTimeSlots)
            .filter((key) => {
              const fe = calendarTimeSlots[key];
              return moment(fe.start).isSame(moment(new Date(parseInt(weekDay.timestamp, 10))), 'day');
            })
            .map((key) => {
              allEmpty = false;
              availableDays[weekDay.dd] = '';
              const fe = calendarTimeSlots[key];
              const hasCampaign =
                campaignStart &&
                campaignEnd &&
                parseInt(weekDay.day / 1000, 10) >= campaignStart &&
                parseInt(weekDay.day / 1000, 10) <= campaignEnd;

              return (
                <span
                  key={j++}
                  className={`text-m mx-1 my-[6px] flex h-auto cursor-pointer flex-col rounded-sm border-[1px] border-solid text-center font-semibold !leading-9 md:m-2 ${
                    weekDay.day === this.state.selectedDay.timestamp && this.state.hour === moment(fe.start).hour()
                      ? hasCampaign
                        ? '!bg-information !text-white'
                        : '!bg-primary !text-white'
                      : ''
                  } ${
                    hasCampaign
                      ? ' text-information border-information hover:bg-information bg-information-50 border-[1px] border-solid hover:text-white'
                      : ' text-black-800 border-sm_primary bg-sm_primary-100 hover:bg-sm_primary'
                  }`}
                  onClick={() => {
                    this.pickHourErp(fe);
                  }}>
                  <span>{moment(fe.start).format('HH:mm')}</span>
                  {this.allowsGroupBookings() && (
                    <span className=" bg-black-600 text-m rounded-bl-[5px] rounded-br-[5px] font-semibold !leading-4 text-white">
                      {fe.capacity} {__('cap')}
                    </span>
                  )}
                </span>
              );
            })}
        </div>
      );
    });

    availableDays = Object.keys(availableDays);

    const button = this.getNotAvailableButton(allEmpty);
    let loader = null;
    let hourLoad = null;

    if (show) {
      switch (loadSource) {
        case 'pickService':
        case 'pickEmployee':
          loader = <LoadingPlaceHolder text={__('loadingHours')} style={{ marginTop: '140px' }} />;
          break;
        case 'loadHours':
          hourLoad = <LoadingPlaceHolder text={__('loadingHours')} />;
          break;
        default:
          break;
      }
    }

    return (
      <div className="calendar week-view">
        {showTooltip && (
          <span
            className="tooltip shadow-black-50 h-auto max-w-[200px] rounded bg-white px-4 py-3 text-sm opacity-100"
            style={{ position: 'fixed', top: showTooltip.y, left: showTooltip.x }}>
            {__('noAppointmentsInThisDate')}
          </span>
        )}
        {loadingActiveWalletMethods && <LoadingPlaceHolder style={{ marginTop: '140px' }} />}
        {loader}
        <p className="sticky !top-16 z-[100] m-0 flex flex-col items-center justify-center bg-white p-3 uppercase">
          <span className="text-black-900 font-semibold">{year}</span>
          <span className="text-black-700 text-m">{__('week') + ' ' + weekOfYear}</span>
        </p>
        <div className="sticky !top-32 z-[100] bg-white shadow">
          <Slider ref={(c) => (this.slider = c)} className="slick-calendar" {...settings}>
            <div className="!flex w-full justify-between">
              {weekDays.map((day, key) => {
                const hasProgram = availableDays.indexOf(day.dd + '') !== -1;
                const isDisabled = !!(day.timestamp < firstDay.timestamp || !hasProgram);
                const hasCampaign =
                  !isDisabled &&
                  campaignStart &&
                  campaignEnd &&
                  parseInt(day.timestamp / 1000, 10) >= campaignStart &&
                  parseInt(day.timestamp / 1000, 10) <= campaignEnd;
                return (
                  <div
                    key={k++}
                    className={`border-black-200 flex-grow basis-0 border-b-[1px] border-l-[1px] bg-white text-center last-of-type:border-r-[1px] colIndex-${
                      key % 7
                    } ${
                      day.timestamp === startOfDay()
                        ? 'text-black-900'
                        : isDisabled
                        ? 'text-black-300'
                        : 'text-black-900'
                    }`}>
                    <span className="block pt-2 text-center text-sm capitalize text-inherit">
                      {firstDay.timestamp === day.timestamp ? __('today') : this.days[day.weekDay]}
                    </span>
                    <span className="relative mx-auto mb-1 block h-11 text-lg font-semibold !leading-[44px] text-inherit">
                      {day.dd}
                      {day.timestamp === startOfDay() && (
                        <span className="bg-black-900 absolute bottom-[2px] left-1/2 h-[6px] w-[6px] -translate-x-1/2 rounded-full"></span>
                      )}
                    </span>
                    {hasCampaign && (
                      <span className="bg-highlight text-m block text-center text-white">{__('campaignOffer')}</span>
                    )}
                  </div>
                );
              })}
            </div>
          </Slider>
          <div>
            <div className="absolute -top-12 left-0 z-20">
              <Button
                className="focus:!ring-0"
                variant="link"
                onClick={this.handlePrevClick}
                disabled={this.state.disablePrev}
                leftIcon={<ChevronIcon className="h-4 w-3 rotate-90" />}>
                {__('earlier')}
              </Button>
            </div>
            <div className="absolute -top-12 right-0 z-20">
              <Button
                className="focus:!ring-0"
                variant="link"
                onClick={this.handleNextClick}
                disabled={this.state.disableNext}
                rightIcon={<ChevronIcon className="h-4 w-3 -rotate-90" />}>
                {__('later')}
              </Button>
            </div>
          </div>
        </div>
        <div className="hours sticky">
          {hourLoad}
          <div className="flex" style={{ minHeight: '300px' }}>
            {!allEmpty && availabilities}
            {button}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { services, availability, employee, time, place, set, isNotAvailable } = state.book;
  const { show, loadSource } = state.loading;
  const { user } = state.users;

  return {
    services,
    availability,
    employee,
    place,
    time,
    set,
    isNotAvailable,
    show,
    loadSource,
    user,
  };
}

const connectedLastMinuteWeekPicker = connect(mapStateToProps)(withRouter(withWalletPayment(LastMinuteWeekPicker)));
export { connectedLastMinuteWeekPicker as LastMinuteWeekPicker };
