import Icon from '@/components/icons/Icon';
import { classnames } from '@/helpers';
import { _s } from '@/locale';
import { ReactNode } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
import { KeywordSuggestion } from '../KeywordAutoSuggest/KeywordAutoSuggest.type';
import { LocationSuggestion } from '../LocationAutoSuggest/LocationAutoSuggest.types';
import { AutoSuggestionSection } from './types';

export const AutoSuggestInputComponent = ({
  inputProps,
  onClear,
  leftSlot = null,
  className = '',
}: {
  inputProps: any;
  onClear: any;
  leftSlot: ReactNode;
  className?: string;
}) => {
  const { ref, ...rest } = inputProps;
  const hasInputValue = Boolean(inputProps?.value?.length);
  return (
    <div className={twMerge('space-x-sm px-md relative flex items-center border border-black', className)}>
      {leftSlot && <div className="min-w-[24px] flex-shrink-0">{leftSlot}</div>}
      <input
        ref={ref}
        {...rest}
        className={twJoin('py-sm w-full text-ellipsis bg-transparent', hasInputValue && 'pr-lg')}
      />
      {hasInputValue && (
        <button className="absolute right-0 top-0 z-10 h-full !w-auto px-4" onClick={onClear} aria-label="clear">
          <Icon variant="close-circle" />
        </button>
      )}
    </div>
  );
};

export const KeywordSuggestionItem = ({ suggestion }: { suggestion: KeywordSuggestion }) => {
  switch (suggestion.type) {
    case 'spell':
      return (
        <span className="spelling-suggestion">
          <em>{suggestion.text.replace(/ /g, '\u00a0')}</em>
        </span>
      );
    case 'place':
      return (
        <span className="place-suggestion space-x-md py-md flex items-center">
          <img
            className="place-image"
            alt=""
            width={24}
            height={24}
            src={suggestion.profileImage ? suggestion.profileImage : 'https://www.bokadirekt.se/favicon.ico'}
          />
          <span className="place-label">{suggestion.text}</span>
        </span>
      );
    case 'history':
    case 'popular':
    case 'category':
      return (
        <span className="history-suggestion space-x-md py-md flex items-center">
          <Icon variant="search" size="xs" />
          <span>{suggestion.text}</span>
        </span>
      );
    default:
      const never: never = suggestion;
      throw new Error(`Need to handle suggestion type ${never}`);
  }
};

export const LocationSuggestionItem = ({
  suggestion,
  onRemoveHistorySuggestion,
}: {
  suggestion: LocationSuggestion;
  onRemoveHistorySuggestion: (suggestion: LocationSuggestion) => void;
}) => {
  const handleOnRemoveSuggestion = (e) => {
    e.preventDefault();
    onRemoveHistorySuggestion(suggestion);
  };
  return (
    <div className="space-x-md py-md flex items-center">
      {suggestion.type === 'geolocation' ? (
        <Icon variant="location-2" size="xs" color="information-500" />
      ) : (
        <Icon variant="location" size="xs" />
      )}
      <span className={classnames(suggestion.type, 'flex-1')}>{suggestion.label}</span>
      {suggestion.type === 'history' && (
        <button id="remove_suggested" onClick={handleOnRemoveSuggestion} className="flex-shrink-0 px-4">
          <div className="pointer-events-none block">
            <Icon variant="close-circle" />
          </div>
        </button>
      )}
    </div>
  );
};

export const KeywordAutoSuggestSectionTitle = (section: AutoSuggestionSection<KeywordSuggestion>) => {
  return section.title ? (
    <div className="text-black-600 text-m py-3 pl-3 font-semibold uppercase">{_s(section.title)}</div>
  ) : null;
};

export const LocationAutoSuggestSectionTitle = (section: AutoSuggestionSection<LocationSuggestion>) => {
  return section.title ? (
    <div className="text-black-600 text-m py-3 pl-3 font-semibold uppercase">{_s(section.title)}</div>
  ) : null;
};
