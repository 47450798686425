export function capitalizeFirstLetter(string: string) {
  if (!string || !string.length) {
    return '';
  }
  const toString = string.toString();
  return toString.charAt(0).toUpperCase() + toString.slice(1);
}

export const isString = (val: any) => typeof val === 'string' || val instanceof String;

export const sanitizeTitle = (title: string) => title.replace(/&amp;/g, '&');

export function truncateStringIfExceeding(
  str: string,
  maxCharsIncSpaces: number,
  maxCharsExSpaces: number,
  symbol: string = '...',
): string {
  let trimmed: string = str;

  // Truncate if non-space characters exceed the limit
  if (trimmed.replace(/\s/g, '').length > maxCharsExSpaces) {
    let count = 0;
    trimmed = trimmed
      .split('')
      .filter((char) => {
        if (char !== ' ') count++;
        return count <= maxCharsExSpaces;
      })
      .join('');
  }

  // Truncate if total length exceeds the limit with ellipsis
  return trimmed.length > maxCharsIncSpaces ? trimmed.slice(0, maxCharsIncSpaces - 1) + symbol : trimmed;
}

export function decodeUnicodeString(input: string) {
  const isEncoded = /\\u[\dA-F]{4}/i.test(input);

  if (!isEncoded) {
    return input;
  }

  return input.replace(/\\u[\dA-F]{4}/gi, function (match) {
    return String.fromCharCode(parseInt(match.replace(/\\u/, ''), 16));
  });
}
