import Label from '@/components/elements/Label/Label';
import RadioButton from '@/components/elements/forms/RadioButton';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import Accordion from '@/components/elements/lists/Accordion/Accordion';
import Divider from '@/components/elements/lists/Divider/Divider';
import Alert from '@/components/elements/notifications/Alert/Alert';
import Icon from '@/components/icons/Icon';
import { PaymentMethod } from '@/constants/paymentMethodConstants';
import { classnames } from '@/helpers';
import { getSelectedPaymentDetails } from '@/helpers/checkout';
import { _s } from '@/locale';
import { PaymentMethodListItem } from '@/types/checkout';
import { Fragment, useState } from 'react';

type PaymentMethodListType = 'supported' | 'unsupported' | 'bokaPayment';

type PaymentMethodsListProps = {
  paymentMethods: PaymentMethodListItem[];
  type: PaymentMethodListType;
  useRadioButtons?: boolean;
  onClick: (paymentMethod: PaymentMethodListItem) => void;
  isGiftcardCheckout?: boolean;
};

const baseTranslationKey = 'components.modules.checkout.PaymentMethodsList';

const PaymentMethodsList = ({
  paymentMethods = [],
  type,
  useRadioButtons = false,
  onClick,
  isGiftcardCheckout = false,
}: PaymentMethodsListProps) => {
  const isSelectedHidden = paymentMethods.some((item) => item.hidden && item.isSelected);
  const [showHidden, setShowHidden] = useState(isSelectedHidden);
  const [showUnsupported, setShowUnsupported] = useState(!useRadioButtons);

  const [hidden, visible] = paymentMethods.reduce(
    (acc, item) => (acc[item.hidden && type === 'supported' ? 0 : 1].push(item), acc),
    [[], []] as [PaymentMethodListItem[], PaymentMethodListItem[]],
  );

  return (
    <div data-cy="paymentMethodList">
      {type !== 'bokaPayment' && !useRadioButtons && (
        <Divider
          label={
            type === 'supported'
              ? _s(`${baseTranslationKey}.supported.title`)
              : _s(`${baseTranslationKey}.unsupported.title`)
          }
          size="md"
        />
      )}
      {type === 'unsupported' && useRadioButtons && (
        <button
          className="w-full text-start outline-none"
          onClick={() => {
            setShowUnsupported((prev) => !prev);
          }}>
          <Accordion label={_s(`${baseTranslationKey}.unsupported.title`)} size="sm" expanded={showUnsupported} />
        </button>
      )}
      {type === 'unsupported' && showUnsupported && (
        <div className="p-md">
          <Alert
            leftSlot={<Icon variant="info-circle" color="information-900" />}
            variant="information"
            body={_s(`${baseTranslationKey}.unsupported.alert`)}
          />
        </div>
      )}
      {type === 'unsupported' &&
        showUnsupported &&
        paymentMethods.map((method, key) => {
          return (
            <Fragment key={key}>
              <PaymentMethodsListItem
                onClick={onClick}
                paymentMethod={method}
                type={type}
                useRadioButtons={useRadioButtons}
              />
            </Fragment>
          );
        })}
      {type !== 'unsupported' &&
        visible.map((method, key) => {
          return (
            <Fragment key={key}>
              <PaymentMethodsListItem
                onClick={onClick}
                paymentMethod={method}
                type={type}
                useRadioButtons={useRadioButtons}
                isGiftcardCheckout={isGiftcardCheckout}
              />
            </Fragment>
          );
        })}
      {hidden.length > 0 && (
        <button
          className="w-full text-start outline-none"
          onClick={() => {
            setShowHidden((prev) => isSelectedHidden || !prev);
          }}>
          <Accordion
            label={
              useRadioButtons
                ? _s(`${baseTranslationKey}.showMoreSupported.title`)
                : _s(`${baseTranslationKey}.showMore`, { count: hidden.length })
            }
            size="sm"
            expanded={isSelectedHidden || showHidden}
          />
        </button>
      )}
      {showHidden &&
        hidden.map((method, key) => {
          return (
            <Fragment key={key}>
              <PaymentMethodsListItem
                onClick={onClick}
                paymentMethod={method}
                type={type}
                useRadioButtons={useRadioButtons}
                isGiftcardCheckout={isGiftcardCheckout}
              />
            </Fragment>
          );
        })}
      {(useRadioButtons || showHidden) && !isGiftcardCheckout && (
        <div className="p-md">
          <Alert
            leftSlot={<Icon variant="info-circle" color="information-900" />}
            variant="information"
            body={_s(`${baseTranslationKey}.showMoreSupported.alert`)}
          />
        </div>
      )}
    </div>
  );
};

type PaymentMethodsListItemProps = {
  paymentMethod: PaymentMethodListItem;
  type: PaymentMethodListType;
  onClick: (paymentMethod: PaymentMethodListItem) => void;
  useRadioButtons?: boolean;
  isGiftcardCheckout?: boolean;
};

const PaymentMethodsListItem = ({
  paymentMethod,
  onClick,
  type,
  useRadioButtons = false,
  isGiftcardCheckout = false,
}: PaymentMethodsListItemProps) => {
  const { title = null, subTitle = null, highlighted = false } = paymentMethod || {};
  const { icon, label } = getSelectedPaymentDetails(paymentMethod);
  const isStoredCard = paymentMethod.type === PaymentMethod.STORED_ONLINE_CARD;
  const isBokaPaymentType = type === 'bokaPayment';

  if (useRadioButtons) {
    return (
      <RadioButton
        variant="large"
        iconPosition="left"
        checkPosition="top"
        className="rounded-none border-0 py-0"
        disabled={type === 'unsupported'}
        onClick={() => onClick(paymentMethod)}
        iconType="radio"
        checked={paymentMethod?.isSelected}>
        <div className="ml-12 w-full">
          <div className="flex w-full items-start text-start">
            <Icon
              variant={icon}
              style={{ width: 48, height: 48 }}
              className={classnames('m-md', type === 'unsupported' && 'opacity-40')}
              noFilter
            />
            <ListItem
              underline
              verticalAlign="top"
              leftPadding={false}
              className={classnames('min-h-[72px] items-center', type === 'unsupported' && 'opacity-40')}>
              {highlighted && (
                <div className="mb-2">
                  <Label
                    label={_s(`${baseTranslationKey}.highlighted`)}
                    className="rounded-sm bg-[#1A6437] px-2 align-middle leading-6 text-white"
                  />
                </div>
              )}
              <ListItemContent
                title={isStoredCard ? label : title ?? label}
                {...(!isBokaPaymentType && { subTitle: !isGiftcardCheckout ? subTitle ?? label : undefined })}
              />
            </ListItem>
          </div>
        </div>
      </RadioButton>
    );
  } else {
    return (
      <ListItem
        data-cy={`paymentMethod-${paymentMethod.type}`}
        underline
        className={classnames(type === 'unsupported' && 'opacity-40')}
        leftSlot={<Icon variant={icon} style={{ width: 48, height: 48 }} noFilter />}
        {...(type !== 'unsupported' && {
          rightSlot: <Icon variant="chevron-s-right" />,
          onClick: () => onClick(paymentMethod),
        })}>
        <ListItemContent
          title={isStoredCard ? label : title ?? label}
          {...(!isBokaPaymentType && { subTitle: subTitle ?? label })}
        />
      </ListItem>
    );
  }
};

export default PaymentMethodsList;
