import { User } from '@/types/user';
import { userConstants } from '../constants';
import { isServer, isSistaminuten, setCookie, trackMpAnalyticsEvent } from '../helpers';
import { removeUser, storeUser } from '../helpers/userHelper';
import { userService } from '../services';
import { modalActions } from './modalActions';

export const userActions = {
  logout,
  updateUser,
  profile,
  deleteUser,
};

function profile(token, fromLogin, forceFetch = false) {
  if (isSistaminuten()) {
    return { type: '' };
  }
  return (dispatch) => {
    userService.getProfile(token, forceFetch).then(
      (user) => {
        if (fromLogin) {
          trackMpAnalyticsEvent('login_success', {
            login: 'true',
          });
        }

        dispatch(updateUser(user));
        dispatch(success({ ...user }));
        const { givenName, familyName } = user?.about || {};
        const hasMissingRequiredProfileInfo = !givenName || !familyName;
        dispatch(modalActions.userProfileOnboarding({ show: hasMissingRequiredProfileInfo }));
      },
      (error) => {
        if (fromLogin) {
          trackMpAnalyticsEvent('login_failure', {});
        }
        dispatch(failure(error));
      },
    );
  };

  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function updateUser(user: User, saveToStorage = false) {
  if (isSistaminuten()) {
    return { type: '' };
  }

  return (dispatch) => {
    // Save user in Cache
    if (saveToStorage) {
      storeUser(user);
    }

    if (user && !user.favorites) {
      user.favorites = [];
    }

    if (user?.clientBooker) {
      setCookie('clientBooker', user.clientBooker);
    }

    if (user.clientPlaceBookings) {
      localStorage.setItem('mpClientPlaceBookings', JSON.stringify(user.clientPlaceBookings));
    }

    dispatch(success({ ...user }));
    if (!isServer && window.location.search) {
      const search = window.location.search.substr(1).split('&');
      if (search) {
        search.forEach((item) => {
          const parts = item.split('=');
          if (parts[0] === 'redirect_to') {
            window.location.href = window.location.origin + parts[1];
          }
        });
      }
    }
  };

  function success(user) {
    return { type: userConstants.UPDATE_USER_SUCCESS, user };
  }
}

function deleteUser() {
  if (isSistaminuten()) {
    return;
  }

  return (dispatch) => {
    removeUser();
    dispatch({ type: userConstants.DELETE_USER });
  };
}

function logout() {
  if (isSistaminuten()) {
    return { type: '' };
  }
  userService.logout();
  return { type: userConstants.LOGOUT };
}
