import Rating from '@/components/elements/redesign/Rating/Rating';
import Symbol from '@/components/elements/Symbol';
import Icon from '@/components/icons/Icon';
import { ReadMore } from '@/components/modules/readmore';
import { TooltipPortal } from '@/components/modules/Tooltip';
import AddReviewModal from '@/features/ProfilePages/modules/ReviewModal';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import svStrings from 'react-timeago/lib/language-strings/sv';
import Label from '../Label/Label';

const formatter = buildFormatter(svStrings);

const baseTranslationKey = 'components.elements.Card.ReviewCard';

type ReviewProps = {
  placeId: string;
  companyReply?: { author: string; text: string; createdAt: number };
  review: { author: string; score: number; text: string; employee?: string; createdAt: number; initials: string };
  onEdit?: () => void;
  onDelete?: (id: string) => void;
  reviewId: string;
  tooltipTargetRef: React.RefObject<HTMLDivElement>;
  verified?: boolean;
};

const Review = ({
  placeId,
  companyReply,
  review,
  onEdit,
  onDelete,
  reviewId,
  tooltipTargetRef,
  verified,
}: ReviewProps) => {
  const { text, author, createdAt, score, employee, initials } = review;
  const { isMobileView } = useMobileView();

  const [showDropdown, setShowDropdown] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipTriggerRef = useRef(null);
  const dropdownTriggerRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideDropdown);
    return () => document.removeEventListener('mousedown', handleClickOutsideDropdown);
  }, []);

  const pos = useMemo(() => {
    if (!dropdownTriggerRef?.current) return { top: 0, left: 0 };
    const trigger = dropdownTriggerRef.current.getBoundingClientRect();
    const tooltipTarget = tooltipTargetRef.current.getBoundingClientRect();
    return {
      top: trigger.bottom - tooltipTarget.bottom,
      left: trigger.left - tooltipTarget.left,
    };
  }, [dropdownTriggerRef?.current]);

  const options = [
    { value: 'edit', label: _s(`${baseTranslationKey}.dropdown.edit`) },
    { value: 'delete', label: _s(`${baseTranslationKey}.dropdown.delete`) },
  ];

  const handleCloseReviewModal = () => {
    setShowReviewModal(false);
  };

  const handleClickOutsideDropdown = (e: MouseEvent) => {
    if (!dropdownRef?.current?.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  const handleClickDropdownOption = (option) => {
    if (option === 'edit') {
      setShowReviewModal(true);
    }

    if (option === 'delete' && onDelete) {
      onDelete(reviewId);
    }

    setShowDropdown(false);
  };

  const handleToggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleCloseTooltip = () => {
    setShowTooltip(false);
  };

  const handleClickOutsideTooltip = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setShowTooltip(false);
  };

  const handleMouseEnterLabel: React.MouseEventHandler<HTMLButtonElement> = () => {
    setShowTooltip(true);
  };

  const handleMouseLeaveLabel: React.MouseEventHandler<HTMLButtonElement> = () => {
    setShowTooltip(false);
  };

  const handleClickLabel: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setShowTooltip((prev) => !prev);
  };

  const dropdownComponent = tooltipTargetRef.current
    ? createPortal(
        <div
          ref={dropdownRef}
          style={{ top: pos.top, left: pos.left }}
          className="border-black-300 absolute z-10 w-[150px] rounded-md border bg-white shadow-lg">
          {options.map((option, i) => {
            const firstItem = i === 0;
            const lastItem = i === options.length - 1;

            return (
              <div
                key={option.value}
                className={`hover:bg-black-100 cursor-pointer px-4 py-2 focus:bg-gray-200 ${
                  firstItem ? 'rounded-t-md' : ''
                } ${lastItem ? 'rounded-b-md' : ''}`}
                onClick={() => handleClickDropdownOption(option.value)}>
                {option.label}
              </div>
            );
          })}
        </div>,
        tooltipTargetRef.current,
      )
    : null;

  return (
    <>
      <div className={`bg-bg_secondary ${isMobileView ? 'p-lg' : 'p-xl'} gap-xl flex flex-col rounded-lg`}>
        <div className="flex items-center justify-between">
          <div className="gap-md flex items-center">
            <Symbol children={<span>{initials}</span>} size="md" classNames="bg-bg_secondary_bold" />
            <div className={'gap-2xs flex flex-col'}>
              <span className="text-m">{author || _s('Anonymous')}</span>
              {createdAt && (
                <span className="text-fg_secondary text-xs">{<TimeAgo date={createdAt} formatter={formatter} />}</span>
              )}
              <div className="gap-xs flex items-center">
                <Rating rating={score} size="md" onlyStars={true} />
                {employee && (
                  <span className="text-fg_secondary text-s">
                    {_s('to')}&nbsp;
                    <span>{employee}</span>
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className={`gap-lg relative flex ${isMobileView ? 'flex-col' : ''}`}>
            {onEdit && isMobileView && (
              <div className="relative flex justify-center">
                <button className="outline-none">
                  <Icon variant="more-horizontal" onClick={handleToggleDropdown} />
                </button>

                {showDropdown && (
                  <div
                    ref={dropdownRef}
                    className="border-black-300 absolute right-0 z-10 w-[150px] rounded-md border bg-white shadow-lg">
                    {options.map((option, i) => {
                      const firstItem = i === 0;
                      const lastItem = i === options.length - 1;

                      return (
                        <div
                          key={option.value}
                          className={`hover:bg-black-100 cursor-pointer px-4 py-2 focus:bg-gray-200 ${
                            firstItem ? 'rounded-t-md' : ''
                          } ${lastItem ? 'rounded-b-md' : ''}`}
                          onClick={() => handleClickDropdownOption(option.value)}>
                          {option.label}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}

            {!verified && (
              <button
                ref={tooltipTriggerRef}
                className="focus-visible:outline-black-900 flex items-center justify-center rounded-sm focus:outline-none focus-visible:outline"
                aria-label={_s(`${baseTranslationKey}.tooltip.cta`)}
                aria-expanded={showTooltip}
                {...(!isMobileView
                  ? { onMouseEnter: handleMouseEnterLabel, onMouseLeave: handleMouseLeaveLabel }
                  : { onClick: handleClickLabel })}
                aria-controls="popover">
                <Label className="bg-bg_info text-fg_alwaysDark" label="Ej verifierad" size="lg" />
              </button>
            )}

            {verified && (
              <Label
                icon={{ variant: 'verification', ext: 'svg', color: 'fg_positive_bold' }}
                className="bg-bg_positive text-fg_alwaysDark"
                label="Verifierad"
                size="lg"
              />
            )}

            {showTooltip && (
              <TooltipPortal
                onClose={handleCloseTooltip}
                triggerRef={tooltipTriggerRef}
                offsetTop={isMobileView ? 60 : 46}
                targetRef={tooltipTargetRef}
                onClickOutside={handleClickOutsideTooltip}>
                <div className="p-lg space-y-md">
                  <p className="text-black-300" id="popover-content">
                    {_s(`${baseTranslationKey}.tooltip.body`)}
                  </p>
                </div>
              </TooltipPortal>
            )}

            {onEdit && !isMobileView && (
              <div ref={dropdownTriggerRef} className="relative flex">
                <button className="outline-none">
                  <Icon variant="more-horizontal" onClick={handleToggleDropdown} />
                </button>

                {showDropdown && dropdownComponent}
              </div>
            )}
          </div>
        </div>
        <ReadMore
          maxLines={3}
          text={text}
          className="text-fg_primary text-m"
          {...(text && companyReply?.text
            ? {
                bottomSlot: (
                  <div className="gap-md flex">
                    <div className="ml-md">
                      <svg width="20" height="57" viewBox="0 0 20 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M2 2V39C2 47.8366 9.16344 55 18 55V55"
                          stroke="#B8C3CC"
                          stroke-width="3"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                    <div className="bg-surface_l3 p-xl gap-xs relative flex flex-col rounded-lg">
                      <div className="flex justify-between">
                        <span className={'text-m text-fg_primary font-bold'}>
                          {companyReply.author || _s('Anonymous')}
                        </span>
                        {createdAt && <span className="text-fg_secondary text-s">{createdAt}</span>}
                      </div>
                      <p className="text-m text-fg_primary">{companyReply.text}</p>
                    </div>
                  </div>
                ),
              }
            : {})}
        />
      </div>
      {showReviewModal && (
        <AddReviewModal
          onClose={handleCloseReviewModal}
          _review={{ id: reviewId, content: review.text, score: review.score }}
          placeId={placeId}
          onChangeReviews={onEdit}
        />
      )}
    </>
  );
};

export default Review;
