import { server } from '../helpers';

export const placeService = {
  removeStoredPlaceImpressions,
  storeImpressions,
  storePlaceImpressions,
  storeSerpImpressions,
  topOfSearchClicked,
};

export const PLACE_IMPRESSION = {
  SERP_IMPRESSION: 1,
  PLACE_IMPRESSION: 2,
  SERP_TO_PLACE_CLICK: 3,
  PLACE_SHOW_PHONE_NUMBER_CLICK: 4,
  PLACE_PHONE_NUMBER_CLICK: 5,
  PLACE_EMAIL_ADDRESS_CLICK: 6,
  PLACE_EMAIL_ADDRESS_COPY_CLICK: 7,
  PLACE_ADDRESS_CLICK: 8,
  PLACE_EXTERNAL_BUSINESS_LINK_CLICK: 9,
  PLACE_INSTAGRAM_CLICK: 10,
  PLACE_FACEBOOK_CLICK: 11,
} as const;

function storeImpressions(bidIds: number[]) {
  return server.request.post('/storeImpressions', { bidIds }).then(server.handleSuccess).catch(server.handleError);
}

function topOfSearchClicked(bidId) {
  return server.request
    .get('/top-of-search-clicked/' + bidId)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

/**
 * [Place analytics - sending impressions and clicks related to places]
 */
function storePlaceImpressions(placeIds: number[], type: (typeof PLACE_IMPRESSION)[keyof typeof PLACE_IMPRESSION]) {
  return server.request
    .post('/storePlaceImpressions', { placeIds, type })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

/**
 * [storeSerpImpressions - check if place ids were already sent, to not send them again for current search]
 * @param {number[]} placeIds
 * @param {boolean} forceSend - used for sending what we have in toSendPlaces array to the backend, before clearing data
 */
function storeSerpImpressions(placeIds: number[] = [], forceSend: boolean = false) {
  try {
    let alreadySentPlaces = JSON.parse(sessionStorage.getItem('mpSerpImpressions')) || [];
    let toSendPlaces = JSON.parse(sessionStorage.getItem('mpSerpImpressionsToSend')) || [];

    if (!Array.isArray(alreadySentPlaces)) {
      alreadySentPlaces = [];
    }
    if (!Array.isArray(toSendPlaces)) {
      toSendPlaces = [];
    }
    const notSentYet = placeIds.filter((placeId) => !alreadySentPlaces.includes(placeId));
    if (notSentYet.length > 0 || forceSend) {
      toSendPlaces = Array.from(new Set(toSendPlaces.concat(notSentYet)));
      if (toSendPlaces.length > 0) {
        if (toSendPlaces.length > 9 || forceSend) {
          // Call backend to store place impression
          storePlaceImpressions(toSendPlaces, PLACE_IMPRESSION.SERP_IMPRESSION);
          sessionStorage.removeItem('mpSerpImpressionsToSend');
          sessionStorage.setItem('mpSerpImpressions', JSON.stringify(alreadySentPlaces.concat(toSendPlaces)));
        } else {
          sessionStorage.setItem('mpSerpImpressionsToSend', JSON.stringify(toSendPlaces));
        }
      }
    }
  } catch (e) {}
}

function removeStoredPlaceImpressions() {
  try {
    storeSerpImpressions([], true);
    sessionStorage.removeItem('mpSerpImpressions');
  } catch (e) {}
}
