import Card from '@/components/elements/Card/Card';
import { Fab } from '@/components/elements/forms/buttons';
import Icon from '@/components/icons/Icon';
import { Lightbox } from '@/components/modules/lightbox';
import useMobileView from '@/hooks/useMobileView';
import { __, _s } from '@/locale';
import { Fragment, useRef, useState } from 'react';
import Slider from 'react-slick';

const baseTranslationKey = 'features.ProfilePages.components.PhotoSlider';

const PhotoSlider = ({ images }: { images: string[] }) => {
  const { isMobileView } = useMobileView();
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState<boolean>(false);
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: isMobileView ? 1.35 : 2.35,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    swipe: isMobileView && images.length > 1,
  };

  const openLightbox = () => {
    setCurrentImage(0);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setLightboxIsOpen(false);
  };

  const gotoPrevious = () => {
    setCurrentImage((prev) => Math.max(prev - 1, 0));
  };

  const gotoNext = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const gotoImage = (index: number) => {
    setCurrentImage(index);
  };

  const getLightbox = () => {
    const lightboxPhotos = images.map((url) => ({ src: url }));
    return (
      <Lightbox
        images={lightboxPhotos}
        onClose={closeLightbox}
        backdropClosesModal
        onClickPrev={gotoPrevious}
        onClickNext={gotoNext}
        currentImage={currentImage}
        isOpen={lightboxIsOpen}
        showThumbnails
        onClickThumbnail={gotoImage}
        imageCountSeparator={__('of')}
      />
    );
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const imagePlaceHolders = Array.from({ length: 3 - images.length }, (_, i) => (
    <Fragment key={`placeholder-${i}`}>
      <div className="mr-xs bg-surface_blur flex h-[196px] cursor-pointer overflow-hidden rounded-lg opacity-90"></div>
    </Fragment>
  ));

  return (
    <Card size={isMobileView ? 'lg' : 'xl'} childClassName="pr-0">
      <div className="gap-xl flex flex-col">
        <div className={`${isMobileView ? 'pr-xl' : 'pr-3xl'} flex justify-between`}>
          <div className="gap-md flex items-center">
            <h2 className="text-h-s">{_s(`${baseTranslationKey}.title`)}</h2>
            <Fab
              icon={<Icon variant="expand" ext="svg" color="fg_primary" />}
              onClick={openLightbox}
              size="sm"
              variant="secondary"
            />
          </div>

          {images.length > 2 && (
            <div className="gap-md flex">
              <Fab
                icon={<Icon variant="chevron-left" color="fg_primary" />}
                onClick={prevSlide}
                size="sm"
                variant="secondary"
              />
              <Fab
                icon={<Icon variant="chevron-right" color="fg_primary" />}
                onClick={nextSlide}
                size="sm"
                variant="secondary"
              />
            </div>
          )}
        </div>

        <div className="slider-container h-full">
          <Slider ref={sliderRef} {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                <div className="mr-xs bg-surface_blur flex h-[196px] cursor-pointer overflow-hidden rounded-lg">
                  <img src={image} className="h-full w-full object-cover" alt="" />
                </div>
              </div>
            ))}
            {imagePlaceHolders}
          </Slider>
        </div>
      </div>
      {lightboxIsOpen && getLightbox()}
    </Card>
  );
};

export default PhotoSlider;
