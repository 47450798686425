import { featurePromoConstants } from '@/constants/featurePromoConstants';

export const featurePromoActions = {
  updateFeaturePromoTrigger,
  updateFeaturePromoEvent,
  updateFeaturePromoFinished,
};

function updateFeaturePromoTrigger({ id }: { id: string }) {
  return { type: featurePromoConstants.UPDATE_FEATURE_PROMO, id };
}

function updateFeaturePromoEvent({ id, event }: { id: string; event: string }) {
  return { type: featurePromoConstants.UPDATE_FEATURE_PROMO_EVENT, event, id };
}
function updateFeaturePromoFinished({ id, finished }: { id: string; finished: boolean }) {
  return { type: featurePromoConstants.UPDATE_FEATURE_PROMO_FINISHED, finished, id };
}
