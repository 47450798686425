import Icon from '@/components/icons/Icon';
import { Dropdown } from '@/components/modules/dropdown';
import { _s } from '@/locale';
import moment from 'moment';
import { DATE_FORMAT, getDateTimeFilterLabel } from './DateTimePicker.helpers';
import './DateTimePicker.scss';
import { DateTimePickerInputProps, DateTimePickerManagerOutput } from './DateTimePicker.types';

import { Button } from '@/components/elements/forms/buttons';
import useMobileView from '@/hooks/useMobileView';
import { TimeOfDayOption } from '@/types/state/search';
import { Fragment, useRef } from 'react';
import Calendar from 'react-calendar';
import { twJoin } from 'tailwind-merge';

const TIME_OF_DAY_OPTIONS: { key: TimeOfDayOption; label: string }[] = [
  {
    key: 'morning',
    label: `${_s('timeOfDay_morning')} ☀️`,
  },
  {
    key: 'afternoon',
    label: `${_s('timeOfDay_afternoon')} 🌗`,
  },
  {
    key: 'evening',
    label: `${_s('timeOfDay_evening')} 🌚`,
  },
];

type DateTimePickerProps = DateTimePickerManagerOutput & {
  renderInputComponent: (inputProps: DateTimePickerInputProps) => JSX.Element;
};

type TimeOfDayToggleSelectProps = {
  options: { key: TimeOfDayOption; label: string }[];
  value: string;
  onSelect: (value: string) => void;
};

const TimeOfDayToggleSelect = ({ options, value, onSelect }: TimeOfDayToggleSelectProps) => {
  const handleOnSelect = (event: React.MouseEvent<HTMLButtonElement>, value) => {
    event.preventDefault();
    onSelect(value);
  };
  return (
    <div className="flex flex-wrap gap-2 overflow-x-auto sm:overflow-x-hidden">
      {options.map((option) => {
        const isSelected = value === option.key;
        return (
          <button
            className={`flex w-auto cursor-pointer items-center rounded-full border px-2 py-1 text-sm outline-none ${
              isSelected ? 'border-primary bg-white' : 'border-black-200 bg-black-200'
            }`}
            key={`select_key_${option.key}`}
            onClick={(e) => handleOnSelect(e, option.key)}>
            {isSelected && <Icon variant="check" className={`h-4 w-4`} />}
            {option.label}
          </button>
        );
      })}
    </div>
  );
};

const DateTimePicker = ({
  startDate,
  endDate,
  timeOfDay,
  showCalendar,
  onCloseCalendar,
  onOpenCalendar,
  onChangeDateTime,
  onDateTimeSearch,
  renderInputComponent,
}: DateTimePickerProps) => {
  const calendarRef = useRef(null);
  const { isMobileView } = useMobileView();

  const hasFilterApplied = Boolean(startDate || endDate || timeOfDay);
  const value = startDate && endDate ? [moment.utc(startDate).toDate(), moment.utc(endDate).toDate()] : null;

  const handleChangeDateTime = (values: string[]) => {
    const [startDate, endDate] = values;
    const start = moment(startDate).format(DATE_FORMAT);
    // if no range is chosen default endDate to 3 weeks in advance
    const end = endDate ? moment(endDate).format(DATE_FORMAT) : moment(startDate).add(3, 'weeks').format(DATE_FORMAT);
    onChangeDateTime({ startDate: start, endDate: end, timeOfDay });
  };

  const handleToggleTimeOfDay = (option: TimeOfDayOption) => {
    if (timeOfDay === option) {
      onChangeDateTime({ startDate, endDate, timeOfDay: null });
      return;
    }
    onChangeDateTime({ startDate, endDate, timeOfDay: option });
  };

  const handleOnKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      onOpenCalendar();
    }
  };

  const inputProps: DateTimePickerInputProps = {
    placeholder: _s('when'),
    value: getDateTimeFilterLabel(startDate, endDate, timeOfDay),
    className: twJoin('ml-sm py-sm w-full text-ellipsis bg-transparent', (hasFilterApplied || value) && 'pr-xl'),
    hasFilterApplied,
    readOnly: true,
    onClick: onOpenCalendar,
    onChange: (e) => e.preventDefault(),
    onKeyDown: handleOnKeyDown,
  };

  const renderCalendar = () => {
    return (
      <Fragment>
        <Calendar
          inputRef={calendarRef}
          className="date-time-picker-calendar"
          onChange={handleChangeDateTime}
          minDate={new Date()}
          selectRange={true}
          calendarType="ISO 8601"
          showNeighboringMonth={false}
          showWeekNumbers={true}
          prev2Label={null}
          nextLabel={<Icon className="m-auto" variant="arrow-right" size="md" />}
          prevLabel={<Icon className="m-auto" variant="arrow-left" size="md" />}
          next2Label={null}
          formatShortWeekday={(_, value) => ['Sö', 'Må', 'Ti', 'On', 'To', 'Fr', 'Lö'][value.getDay()]}
          locale="sv-SE"
          {...(value ? { value } : {})}
          allowPartialRange
        />
        <hr className="text-black-100 mt-6 h-px w-full" />
        <div className="py-6">
          <TimeOfDayToggleSelect onSelect={handleToggleTimeOfDay} options={TIME_OF_DAY_OPTIONS} value={timeOfDay} />
        </div>
        <Button size="lg" block onMouseDown={onDateTimeSearch}>
          {_s('search')}
        </Button>
      </Fragment>
    );
  };

  return (
    <div className="relative">
      {renderInputComponent(inputProps)}
      {showCalendar && !isMobileView && (
        <Dropdown className="date-time-dropdown" allowClick onClickOutside={onCloseCalendar}>
          <div className="p-md w-[440px]">{renderCalendar()}</div>
        </Dropdown>
      )}
      {showCalendar && isMobileView && <div className="pt-md pb-2xl">{renderCalendar()}</div>}
    </div>
  );
};

export default DateTimePicker;
