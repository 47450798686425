import { z } from 'zod';

export const standardFormFieldSchema = z.union([
  z.literal('bookingNotes'),
  z.literal('familyName'),
  z.literal('givenName'),
  z.literal('email'),
  z.literal('mobile'),
  z.literal('phone'),
  z.literal('nationalId'),
  z.literal('streetAddress'),
  z.literal('postalCode'),
  z.literal('locality'),
  z.literal('discountCode'),
  z.literal('giftCard'),
]);

const timeRangeSchema = z.object({
  to: z.number(),
  from: z.number(),
});

const weekScheduleSchema = z.object({
  0: z.array(timeRangeSchema).optional().nullable(),
  1: z.array(timeRangeSchema).optional().nullable(),
  2: z.array(timeRangeSchema).optional().nullable(),
  3: z.array(timeRangeSchema).optional().nullable(),
  4: z.array(timeRangeSchema).optional().nullable(),
  5: z.array(timeRangeSchema).optional().nullable(),
  6: z.array(timeRangeSchema).optional().nullable(),
});

export type StandardFormFieldSchema = z.infer<typeof standardFormFieldSchema>;

export const formFieldSchema = z.object({
  displayOrder: z.number(),
  id: z.union([
    z.string(), // <- (for custom fields, ex: "sf_28")
    standardFormFieldSchema,
  ]),
  label: z.string().nullable().optional(),
  required: z.boolean(),
  type: z.union([z.literal('text'), z.literal('dropdown')]),
  values: z.array(z.string()).optional().nullable(),
  visible: z.boolean(),
});

export type FormField = z.infer<typeof formFieldSchema>;

const placeAboutSettingsSchema = z.object({
  terms: z.string().optional().nullable(),
  hasQliro: z.boolean().optional().nullable(),
  listView: z.boolean().optional().nullable(),
  hasKlarna: z.boolean().optional().nullable(),
  sendEmail: z.boolean().optional().nullable(),
  showPrices: z.boolean().optional().nullable(),
  skipEmails: z.boolean().optional().nullable(),
  topOfSearch: z.boolean().optional().nullable(),
  hasCampaigns: z.boolean().optional().nullable(),
  requireLogin: z.boolean().optional().nullable(),
  sortServices: z.number().optional().nullable(),
  allowCustomer: z.boolean().optional().nullable(),
  hideEmployees: z.boolean().optional().nullable(),
  sellsGiftCard: z.boolean().optional().nullable(),
  servicesOrder: z
    .array(
      z.object({
        id: z.number(),
        own: z.boolean(),
        sort: z.number(),
      }),
    )
    .optional()
    .nullable(),
  displayReviews: z.boolean().optional().nullable(),
  hasCertificate: z.boolean().optional().nullable(),
  sellsValueCard: z.boolean().optional().nullable(),
  acceptsGiftCard: z.boolean().optional().nullable(),
  fluentThresolds: z.boolean().optional().nullable(),
  acceptsValueCard: z.boolean().optional().nullable(),
  subscriptionType: z.string().optional().nullable(),
  supportsWaitlist: z.boolean().optional().nullable(),
  customerWebWaitList: z.boolean().optional().nullable(),
  displayCertificates: z.boolean().optional().nullable(),
  onlinePaymentRequired: z.boolean().optional().nullable(),
  openingHoursExtraInformation: z.string().optional().nullable(),
});

export type PlaceAboutSettings = z.infer<typeof placeAboutSettingsSchema>;

export const placeAboutSchema = z.object({
  active: z.boolean(),
  slug: z.string().nullable().optional(),
  name: z.string(),
  description: z.string().optional().nullable(),
  logo: z.string().optional().nullable(),
  profileImage: z.string().optional().nullable(),
  formFields: z.array(formFieldSchema.nullable()).optional().nullable(),
  book: z.object({
    blockCancel: z.boolean().optional(),
    bookInAdvance: z.number().optional().nullable(),
    cancel: z.number().optional().nullable(),
    bookingText: z.string().optional(),
    instantBook: z.boolean().optional(),
    intervals: z.number().optional(),
    showSelectedEmployee: z.boolean(),
    dynamicPricing: z
      .object({
        endHours: z.number(),
        startHours: z.number(),
        applyOnSunday: z.boolean(),
        applyOnSaturday: z.boolean(),
        applyOnBankHoliday: z.boolean(),
      })
      .optional()
      .nullable(),
  }),
  settings: placeAboutSettingsSchema,
  googleAnalytics4Tracking: z.array(z.string()).optional().nullable(),
  googleAnalyticsTracking: z.array(z.string()).optional().nullable(),
  facebookPixelTracking: z.array(z.string()).optional().nullable(),
  category: z
    .object({
      main: z.string().optional().nullable(),
      sub: z.string().optional().nullable(),
    })
    .optional()
    .nullable(),
  images: z.array(z.string()).optional().nullable(),
});

export type PlaceAbout = z.infer<typeof placeAboutSchema>;

const placeContactSchema = z.object({
  address: z
    .object({
      city: z.string().optional().nullable(),
      street: z.string().optional().nullable(),
      zipcode: z.string().optional().nullable(),
    })
    .optional()
    .nullable(),
  position: z
    .object({
      lat: z.number().optional().nullable(),
      lon: z.number().optional().nullable(),
    })
    .optional()
    .nullable(),
  phone: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  website: z.string().optional().nullable(),
  instagram: z.string().optional().nullable(),
  facebook: z.string().optional().nullable(),
});

const placeEmployeeSettingsSchema = z.object({
  lastDay: z.number().optional().nullable(),
  useQliro: z.boolean().optional().nullable(),
  bookAhead: z.number().optional().nullable(),
  hasKlarna: z.boolean().optional().nullable(),
  showPhoto: z.boolean().optional().nullable(),
  bookCancel: z.number().optional().nullable(),
  klarnaText: z.string().optional().nullable(),
  startWorking: z.number().optional().nullable(),
  allowCustomer: z.boolean().optional().nullable(),
  bookInAdvance: z.number().optional().nullable(),
  sellsGiftCard: z.boolean().optional().nullable(),
  useCardOnFile: z.boolean().optional().nullable(),
  useCardOnline: z.boolean().optional().nullable(),
  displayReviews: z.boolean().optional().nullable(),
  klarnaClientId: z.string().optional().nullable(),
  sellsValueCard: z.boolean().optional().nullable(),
  acceptsGiftCard: z.boolean().optional().nullable(),
  acceptsValueCard: z.boolean().optional().nullable(),
  hasOnlinePayment: z.boolean().optional().nullable(),
  giftCardTemplates: z.array(z.unknown()).optional().nullable(),
  offlinePaymentFee: z.number().optional().nullable(),
  blockCancelBooking: z.boolean().optional().nullable(),
  allowCustomerMessage: z.boolean().optional().nullable(),
  alwaysShowPayAtPlace: z.boolean().optional().nullable(),
  onlinePaymentRequired: z.boolean().optional().nullable(),
  useCardOnlinePayLater: z.boolean().optional().nullable(),
  allowBookingsToBeCancelled: z.boolean().optional().nullable(),
  klarnaPaymentDisplayOption: z.number().optional().nullable(),
  cardOnFileReservationEnabled: z.boolean().optional().nullable(),
  forceNonAggressivePaymentFlow: z.boolean().optional().nullable(),
  onlinePaymentRequiredOnlyForNewCustomers: z.boolean().optional().nullable(),
  onlinePaymentForSistaminutenOnly: z.boolean().optional().nullable(),
  useSwishOnline: z.boolean().optional().nullable(),
});

export type PlaceEmployeeSettings = z.infer<typeof placeEmployeeSettingsSchema>;

export const placeEmployeeAboutSchema = z.object({
  avatar: z.string().optional().nullable(),
  inactive: z.boolean().optional().nullable(),
  name: z.string(),
  jobTitle: z.string().optional().nullable(),
  certificates: z
    .array(
      z.object({
        url: z.string().optional().nullable(),
        name: z.string().optional().nullable(),
        title: z.string().optional().nullable(),
        mimeType: z.string().optional().nullable(),
      }),
    )
    .optional()
    .nullable(),
  isBookable: z.boolean().optional(),
  terms: z.string().optional().nullable(),
  info: z.string().optional().nullable(),
  priceListId: z.string().optional().nullable(),
  settings: placeEmployeeSettingsSchema,
  formFields: z.array(formFieldSchema).nullable().optional(),
});

export type PlaceEmployeeAbout = z.infer<typeof placeEmployeeAboutSchema>;

export const placeEmployeeSchema = z.object({
  id: z.number(),
  place: z.number(),
  about: placeEmployeeAboutSchema,
  services: z.array(z.number()).optional().nullable(),
});

export type PlaceEmployee = z.infer<typeof placeEmployeeSchema>;

const placeServiceAboutSettingsSchema = z.object({
  allowMarketing: z.boolean().optional(),
  healthCareCard: z.boolean().optional(),
  hideDuration: z.boolean().optional().nullable(),
  hidePrice: z.boolean().optional().nullable(),
  isAddOn: z.boolean().optional().nullable(),
  isChargeable: z.boolean().optional().nullable(),
  isFree: z.boolean().optional().nullable(),
  onlinePaymentRequired: z.boolean().optional().nullable(),
  onlinePaymentRequiredOnlyForNewCustomers: z.boolean().optional().nullable(),
  showFrom: z.boolean().optional().nullable(),
  useDynamicPricing: z.boolean().optional().nullable(),
});

export type PlaceServiceAboutSettings = z.infer<typeof placeServiceAboutSettingsSchema>;

const placeServiceAboutSchema = z.object({
  displayDuration: z.number().optional().nullable(),
  description: z.string().optional().nullable(),
  product: z.number().optional().nullable(),
  slug: z.string().optional(),
  settings: placeServiceAboutSettingsSchema.optional(),
  formFields: z.array(formFieldSchema).optional().nullable(),
  externalUrl: z.string().optional().nullable(),
});

export type PlaceServiceAbout = z.infer<typeof placeServiceAboutSchema>;

const placeServicePriceTypeSchema = z.object({
  gaps: z.object({}).optional().nullable(),
  range: z.string().optional().nullable(),
  rooms: z.object({}).optional().nullable(),
  prices: z.record(z.number()).optional().nullable(),
  durations: z.record(z.number()).optional().nullable(),
  equipments: z.object({}).optional().nullable(),
  withoutVat: z.record(z.number()).optional().nullable(),
  durationRange: z.string().optional().nullable(),
  rangeWithoutVat: z.string().optional().nullable(),
  displayDurations: z.record(z.number()).optional().nullable(),
});

export type PlaceServicePriceType = z.infer<typeof placeServicePriceTypeSchema>;

/**
 * booServiceSchema is recursive because of addOnServices
 */
const serviceExtraSchema = z.object({
  addOnServices: z.any().optional(),
  external: z.object({
    erpId: z.string().optional().nullable(),
    maxPriceWithoutVat: z.number().optional(),
    oldId: z.number().optional().nullable(),
  }),
  inactive: z.boolean(),
});

export const placeServiceSchema = z.object({
  about: placeServiceAboutSchema.optional().nullable(),
  createdAt: z.string().optional().nullable(),
  duration: z.number().optional().nullable(),
  durationLabel: z.string().optional().nullable(),
  extra: serviceExtraSchema.optional().nullable(),
  id: z.number().optional().nullable(),
  name: z.string().optional().nullable(),
  price: z.string().or(z.number()).optional().nullable(),
  priceLabel: z.string().optional().optional().nullable(),
  priceType: placeServicePriceTypeSchema.optional().nullable(),
  sorting: z.number().optional().nullable(),
});

export type PlaceService = z.infer<typeof placeServiceSchema>;

export const placeServicesSchema = z.array(placeServiceSchema);

export type PlaceServices = z.infer<typeof placeServicesSchema>;

export const placeServiceGroupSchema = z.object({
  id: z.number().optional().nullable(),
  name: z.string().optional().nullable(),
  order: z.number().optional().nullable(),
  own: z.boolean().optional().nullable(),
  services: z.array(placeServiceSchema),
});

export type PlaceServiceGroup = z.infer<typeof placeServiceGroupSchema>;

export const placeCampaignSchema = z.object({
  campaignId: z.string(),
  createdAt: z.string(),
  id: z.number(),
  name: z.string(),
  place: z.number(),
  startDate: z.number().nullable().optional(),
  endDate: z.number().nullable().optional(),
  status: z.number(),
  updatedAt: z.string().optional().nullable(),
  services: z.array(
    z.object({
      id: z.number(),
      discountPercent: z.number().nullable().optional(),
      discountPrice: z.number().nullable().optional(),
    }),
  ),
});

export const sistaminutenCampaignSchema = z.object({
  id: z.literal(-1),
  startDate: z.number().optional().nullable(),
  endDate: z.number().optional().nullable(),
  discountPercent: z.number().optional().nullable(),
});

const placeBundleServiceSchema = z.object({
  mpId: z.number(),
  name: z.string(),
  quantity: z.number(),
});

export const placeBundleSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().optional(),
  validity: z.string().optional(),
  validMonths: z.number(),
  price: z.number(),
  priceLabel: z.string(),
  finalPrice: z.number().optional(),
  finalPriceLabel: z.string().optional(),
  durationLabel: z.string().optional(),
  service: placeBundleServiceSchema,
});

export type PlaceBundle = z.infer<typeof placeBundleSchema>;

const placeReviewSchema = z.object({
  reviewCount: z.number().optional().nullable(),
  stats: z
    .object({
      score: z.number().optional().nullable(),
      count: z.number().optional().nullable(),
    })
    .optional(),
  topReviews: z
    .object({
      items: z
        .array(
          z.object({
            id: z.string().optional().nullable(),
            createdAt: z.string().optional().nullable(),
            author: z.object({ name: z.string().optional().nullable() }),
            review: z.object({ score: z.number(), text: z.string().optional().nullable() }),
            subject: z.object({
              employee: z.object({ id: z.number().optional().nullable(), name: z.string().optional().nullable() }),
            }),
          }),
        )
        .optional()
        .nullable(),
    })
    .optional(),
});

export const placeSchema = z.object({
  id: z.number(),
  about: placeAboutSchema,
  contact: placeContactSchema,
  campaigns: z.array(placeCampaignSchema.or(sistaminutenCampaignSchema)).optional(),
  employees: z.array(placeEmployeeSchema),
  services: z.array(placeServiceGroupSchema),
  bookingBundles: z.array(placeBundleSchema).optional().nullable(),
  source: z.number().optional().nullable(),
  reviews: placeReviewSchema.optional().nullable(),
  lastMinuteDiscount: z.number().optional(),
  isVerifiedExternalPlace: z.boolean().optional(),
  program: weekScheduleSchema.optional().nullable(),
});

export type Place = z.infer<typeof placeSchema>;
