import { isEmpty, secondsToHour } from '@/helpers';
import { __ } from '@/locale';

export const formatPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.length === 10) {
    return {
      fullNumber: `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6, 8)} ${phoneNumber.slice(
        8,
        10,
      )}`,
      hiddenNumber: `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6, 8)} XX`,
    };
  } else if (phoneNumber.length > 2) {
    return {
      fullNumber: phoneNumber,
      hiddenNumber: phoneNumber.slice(0, -2) + 'XX',
    };
  }
  return {
    fullNumber: phoneNumber,
    hiddenNumber: phoneNumber,
  };
};

export const getBreadcrumbItems = (place: any): { title: string; url: string }[] => {
  const mainCategory = place.about?.category?.main;
  const subCategory = place.about?.category?.sub;

  const items = [
    { title: __('Home'), url: '/' },
    ...(mainCategory ? [{ title: mainCategory, url: `/${mainCategory}/var` }] : []),
    ...(subCategory ? [{ title: subCategory, url: `/${subCategory}/var` }] : []),
  ];

  return items;
};

type OpeningHours = Record<string, { from?: number; to?: number }[]>;

export const getOpenHoursStrings = (openingHours: OpeningHours) => {
  const WEEKDAYS = {
    '1': 'monday',
    '2': 'tuesday',
    '3': 'wednesday',
    '4': 'thursday',
    '5': 'friday',
    '6': 'saturday',
    '0': 'sunday',
  };

  const openHours = Object.entries(WEEKDAYS).reduce((acc, [key, value]) => {
    const hours = openingHours?.[key];

    if (!hours || hours.length === 0) {
      return acc;
    }

    acc[value] = `${secondsToHour(hours[0].from)} - ${secondsToHour(hours[0].to)}`;
    return acc;
  }, {});

  if (isEmpty(openHours)) {
    return undefined;
  }
  return openHours;
};
