import Card from '@/components/elements/Card/Card';
import Alert from '@/components/elements/notifications/Alert/Alert';
import Icon from '@/components/icons/Icon';
import { decodeUnicodeString } from '@/helpers';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import * as sanitizeHtml from 'sanitize-html';

const baseTranslationKey = 'features.ProfilePages.components.PlaceInfo';

const PlaceInfo = ({
  place,
  year,
  isVerified,
  category,
}: {
  place: any;
  year: string;
  isVerified: boolean;
  category?: string;
}) => {
  const { isMobileView } = useMobileView();
  const address = place.contact && place.contact.address ? place.contact.address : null;
  const __html = sanitizeHtml(decodeUnicodeString(place.about?.description ?? ''), {
    allowedTags: [],
    allowedAttributes: {},
  });

  return (
    <Card size={isMobileView ? 'lg' : 'xl'}>
      <div className="gap-md flex flex-col">
        <div className="text-fg_primary text-h-s">{_s(`${baseTranslationKey}.title`)}</div>
        <div className="text-fg_secondary text-l">
          {place.about.description && (
            <div id="description" className="whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html }} />
          )}
          {!place.about.description && (
            <span>
              {`${_s(`${baseTranslationKey}.content`, {
                name: place.about.name,
                city: address.city,
              })} ${category ? _s(`${baseTranslationKey}.content2`) + ' ' + category : '.'}`}
            </span>
          )}
          {isVerified && year && !place.about.description && (
            <span>
              &nbsp;
              {_s(`${baseTranslationKey}.verifiedContent`, {
                name: place.about.name,
                year: year,
              })}
            </span>
          )}
        </div>
        <Alert
          leftSlot={
            <div className="gap-sm flex items-center">
              <Icon variant="company" color="fg_primary" />
              <span className="text-m font-semibold">{place.about.name}</span>
            </div>
          }
          variant="neutral"
          asColumn={isMobileView}
          rightSlot={
            <span className="gap-xs flex items-center">
              {isVerified && (
                <>
                  <Icon size="sm" color="fg_positive" ext="svg" variant="verification" />
                  <span className="text-l text-fg_positive flex items-center">{_s(`verified`)}</span>
                </>
              )}
              {!isVerified && (
                <>
                  <Icon color="fg_secondary" variant="close-circle" />
                  <span className="text-l text-fg_secondary flex items-center">{_s(`notVerified`)}</span>
                </>
              )}
            </span>
          }
        />
      </div>
    </Card>
  );
};

export default PlaceInfo;
