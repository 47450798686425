import TabIcon from '@/components/elements/tabs/TabIcon/TabIcon';
import { TabAction } from '..';
import { TabItem as TabItemProps } from '../types';

const classnames = (selected: boolean) =>
  `min-w-[48px] flex h-[50px] flex-col items-center justify-end focus:outline-none focus-visible:outline-1 focus-visible:outline-primary ${
    selected ? 'text-black-900' : 'text-black-600'
  }`;

const Badge = ({ badge }: { badge: TabItemProps['badge'] }) => {
  return (
    <div className="bg-danger-700 text-xxs absolute translate-x-[16px] -top-[6.5px] block h-[16px] min-w-[16px] px-1 text-center rounded-full text-white">
      {badge > 5 ? '5+' : badge}
    </div>
  );
};

const TabItem = ({ label, selected, icon, onClick, to, badge, id }: TabItemProps) => {
  const showBadge = !!Number(badge) && badge > 0;

  return (
    <TabAction id={id} className={classnames(selected)} onClick={onClick} to={to}>
      <span className="relative flex flex-col items-center justify-end">
        <TabIcon icon={icon} selected={selected} />
        <span className="text-xs">{label}</span>
        {showBadge && <Badge badge={badge} />}
      </span>
    </TabAction>
  );
};

export default TabItem;
