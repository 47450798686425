import { Banner, blogBanners } from '@/config/banners';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const bannerIndex = Math.floor(Math.random() * blogBanners.length);

type Props = {
  banner?: Banner;
  className?: string;
};

const DCGiftCardBanner = ({ banner: ssrBanner, className = '' }: Props) => {
  const banner = ssrBanner || blogBanners[bannerIndex];

  const onClick = () => {};

  return (
    <Link to={banner.url} onClick={onClick} className={`${className} block`}>
      <img
        width="1100"
        height="182"
        src={banner.mobile}
        className="block h-auto w-full md:hidden"
        loading="lazy"
        alt=""
      />
      <img
        width="1100"
        height="182"
        src={banner.desktop}
        className="hidden h-auto w-full md:block"
        loading="lazy"
        alt=""
      />
    </Link>
  );
};

const mapStateToProps = (state) => {
  const { homepage: { banner = null } = {} } = state;

  return { banner };
};

const GiftCardBanner = connect(mapStateToProps)(DCGiftCardBanner);
export default GiftCardBanner;
