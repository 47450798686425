export const SCREEN_NAME = {
  SEARCH: 'search_results',
  SEARCH_RESULTS_MAP: 'search_results_map',
  DEALS: 'deals',
  DEALS_MAP: 'deals_map',
} as const;

export const SEARCH_CONTEXT = {
  SEARCH_AUTOCOMPLETE: 'search_autocomplete',
  FREE_SEARCH: 'free_search',
  NAVIGATION: 'navigation',
  HOME_PAGE_CATEGORY: 'home_page_category',
  HOME_PAGE_FREE_SEARCH: 'home_page_free_search',
  HOME_PAGE_SEARCH_AUTOCOMPLETE: 'home_page_search_autocomplete',
  FOOTER_CATEGORY: 'footer_category',
} as const;

export const SEARCH_FILTERS = {
  PAY_LATER: 'pay_later',
  GIFT_CARD: 'gift_card',
  WELLNESS_CARD: 'wellness_card',
  ASSOCIATIONS: 'associations',
  CAMPAIGNS: 'campaigns',
  BUNDLES: 'bundles',
} as const;

export const SORTED_BY = {
  POPULAR: 'popular',
  CLOSEST: 'closest',
  FIRST_AVAILABLE_TIME: 'first_available_time',
  HIGHEST_RATING: 'highest_rating',
  HIGHEST_DISCOUNT: 'highest_discount',
} as const;

export const CAMPAIGNS_SHOWN = {
  RAKETEN: 'raketen',
  SISTAMINUTEN: 'sistaminuten',
  DEALS: 'deals',
} as const;

export const SEARCH_LOCATION_FILTER = {
  GPS_LOCATION: 'gps_location',
  USER_INPUT: 'user_input',
} as const;

export const SEARCH_TIME_FILTER = {
  DEFAULT: 'default',
  USER_INPUT: 'user_input',
} as const;
