import { isServer } from '@/helpers';
import { __ } from '@/locale';
import { useEffect, useState } from 'react';

function FilterServicesInput(props) {
  const [queryString, setQueryString] = useState('');
  const [showFilter, setShowFilter] = useState((!isServer && window.showFilterServices) || false);
  const { setFilterServicesQuery } = props;

  useEffect(() => {
    if (setFilterServicesQuery) {
      setFilterServicesQuery(prepareFilterQuery());
    }
  }, [queryString]);

  const handleChange = (e) => {
    const { value = '' } = e.target;
    setQueryString(value);
  };

  const handleClear = () => {
    setQueryString('');
  };

  const prepareFilterQuery = () => {
    if (queryString && queryString.trim().length > 1) {
      return queryString.trim().toLowerCase();
    }

    return '';
  };

  if (showFilter) {
    return (
      <div className="form-group filter-services">
        <div className="field relative flex w-full md:w-7/12">
          <input
            type="text"
            name="filter-services"
            className="form-control"
            placeholder={__('searchService')}
            value={queryString}
            onChange={handleChange}
          />
          {queryString && (
            <span
              style={{
                backgroundImage: 'url("/images/clear.svg")',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                position: 'absolute',
                verticalAlign: 'middle',
                right: '0px',
                width: '38px',
                height: '100%',
                cursor: 'pointer',
              }}
              onClick={handleClear}></span>
          )}
        </div>
      </div>
    );
  }

  return null;
}

export default FilterServicesInput;
