import * as React from 'react';

export { default as AmexIcon } from './AmexIcon';
export { default as ArrowIcon } from './ArrowIcon';
export { default as ArrowLongIcon } from './ArrowLongIcon';
export { default as ArrowNorthWestIcon } from './ArrowNorthWestIcon';
export { default as AvatarAltIcon } from './AvatarAltIcon';
export { default as AvatarIcon } from './AvatarIcon';
export { default as BeautyLabIcon } from './BeautyLabIcon';
export { default as BeautyLabSmallIcon } from './BeautyLabSmallIcon';
export { default as BriefcaseIcon } from './BriefcaseIcon';
export { default as CalendarAddIcon } from './CalendarAddIcon';
export { default as CalendarIcon } from './CalendarIcon';
export { default as CancelBookingIcon } from './CancelBookingIcon';
export { default as CardGalleryArrowIcon } from './CardGalleryArrowIcon';
export { default as CardIcon } from './CardIcon';
export { default as CashRegisterIcon } from './CashRegisterIcon';
export { default as CheckboxIcon } from './CheckboxIcon';
export { default as CheckIcon } from './CheckIcon';
export { default as ChevronIcon } from './ChevronIcon';
export { default as CloseIcon } from './CloseIcon';
export { default as CloseIconThin } from './CloseIconThin';
export { default as CompanyIcon } from './CompanyIcon';
export { default as CompanyLoginIcon } from './CompanyLoginIcon';
export { default as DropdownIcon } from './DropdownIcon';
export { default as EditIcon } from './EditIcon';
export { default as FacebookIcon } from './FacebookIcon';
export { default as FilterIcon } from './FilterIcon';
export { default as GenericCardIcon } from './GenericCardIcon';
export { default as GiftCardBoxIcon } from './GiftCardBoxIcon';
export { default as GiftCardIcon } from './GiftCardIcon';
export { default as GlobeIcon } from './GlobeIcon';
export { default as GoogleIcon } from './GoogleIcon';
export { default as GooglePayIcon } from './GooglePayIcon';
export { default as HeartAltIcon } from './HeartAltIcon';
export { default as HeartIcon } from './HeartIcon';
export { default as InformationAltIcon } from './InformationAltIcon';
export { default as InformationIcon } from './InformationIcon';
export { default as InvoiceIcon } from './InvoiceIcon';
export { default as KlarnaBadgeIcon } from './KlarnaBadgeIcon';
export { default as KlarnaPayIcon } from './KlarnaPayIcon';
export { default as LangEnFlagIcon } from './LangEnFlagIcon';
export { default as LangSeFlagIcon } from './LangSeFlagIcon';
export { default as ListIcon } from './ListIcon';
export { default as LoadingIcon } from './LoadingIcon';
export { default as LocationIcon } from './LocationIcon';
export { default as LockedIcon } from './LockedIcon';
export { default as LogInIcon } from './LogInIcon';
export { default as LogoIcon } from './LogoIcon';
export { default as LogOutIcon } from './LogOutIcon';
export { default as MailIcon } from './MailIcon';
export { default as MapIcon } from './MapIcon';
export { default as MastercardIcon } from './MastercardIcon';
export { default as MobileLogoIcon } from './MobileLogoIcon';
export { default as MyAccountIcon } from './MyAccountIcon';
export { default as PaymentIcon } from './PaymentIcon';
export { default as PhoneAltIcon } from './PhoneAltIcon';
export { default as PlusAltIcon } from './PlusAltIcon';
export { default as QliroIcon } from './QliroIcon';
export { default as RadioIcon } from './RadioIcon';
export { default as RepeatAltIcon } from './RepeatAltIcon';
export { default as RepeatIcon } from './RepeatIcon';
export { default as RocketIcon } from './RocketIcon';
export { default as SearchIcon } from './SearchIcon';
export { default as SettingsIcon } from './SettingsIcon';
export { default as ShareIcon } from './ShareIcon';
export { default as SistaMinutenLogoIcon } from './SistaMinutenLogoIcon';
export { default as SuccessIcon } from './SuccessIcon';
export { default as SuccessRingIcon } from './SuccessRingIcon';
export { default as SuccessThinIcon } from './SuccessThinIcon';
export { default as SupportIcon } from './SupportIcon';
export { default as TimeIcon } from './TimeIcon';
export { default as TrashIcon } from './TrashIcon';
export { default as UnknownCardIcon } from './UnknownCardIcon';
export { default as VisaIcon } from './VisaIcon';
export { default as WarningIcon } from './WarningIcon';

export type IconProps = React.SVGProps<SVGSVGElement>;

const Icon = ({
  icon,
  ...props
}: {
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
} & React.SVGProps<SVGSVGElement>) => React.createElement(icon, props);

export default Icon;
