import { v4 as uuidv4 } from 'uuid';
import { bookConstants } from '../constants';
import {
  getServiceCampaignIfAny,
  isServer,
  isSistaminuten as isSistaminutenInstance,
  promiseWrapper,
  server,
  startOfDay,
  trackEventTikTok,
} from '../helpers';
import { __ } from '../locale';
import { bookServices } from '../services';
import { loadingActions, userActions } from './';

export const bookActions = {
  selectCapacity,
  restoreBookState,
  addService,
  appendService,
  applyBundle,
  removeBundle,
  popService,
  removeService,
  getAvailability,
  pickEmployee,
  keepEmployee,
  pickDate,
  pickHour,
  removeEmployee,
  clearBook,
  removeHour,
  addPlace,
  bookAgain,
  setFirstDay,
  saveAppointment,
  addUuid,
  addGiftCard,
  removeGiftCard,
  appendExtraService,
  addCampaign,
  removeCampaign,
  setCampaigns,
  clearGiftCardMessages,
  saveTrackingProps,
  clearTrackingProps,
  getTrackingProps,
  fetchAndSetPlace,
  changeBookingTime,
};

function fetchAndSetPlace(placeId, slug, isSistaminuten, abortSignal) {
  return async (dispatch) => {
    const searchQuery = `?slug=${encodeURIComponent(slug)}${isSistaminuten ? '&sistaminuten=1' : ''}`;
    const { data, error: fetchError } = await promiseWrapper(
      server.request.get(`/findPlace/${placeId}/${encodeURIComponent(slug)}${searchQuery}`, {
        abortSignal,
      }),
    );

    if (fetchError) {
      dispatch(error());
      return;
    }

    dispatch(success(data.data.place));
  };

  function success(place) {
    return { type: bookConstants.ADD_PLACE, place };
  }

  function error() {
    return { type: bookConstants.REFETCH_PLACE, loading: false, error: true };
  }
}

function restoreBookState({ employee = null, services, time, campaigns = [] }) {
  const bookState = { employee, services, time, campaigns };
  return { type: bookConstants.RESTORE_BOOK_STATE, bookState };
}

function sendAddToCartEventTikTok(service) {
  trackEventTikTok('AddToCart', {
    contents: [
      {
        content_id: service?.id, // string. ID of the product. Example: '1077218'.
        content_type: 'product', // string. Either product or product_group.
        content_name: service?.name, // string. The name of the page or product. Example: 'shirt'.
      },
    ],
    description: service?.place, // string. The description of the item or page. Example: "light weight cotton".
  });
}

function addPlace(place) {
  if (!isServer) sessionStorage.setItem('bookPlace', JSON.stringify({ id: place.id, slug: place.about.slug }));
  return { type: bookConstants.ADD_PLACE, place };
}

function addService(service, place, date) {
  return (dispatch) => {
    if (!date) {
      date = startOfDay();
    }
    dispatch(add(service));

    if (service?.firstAvailableTime) {
      dispatch(setTime(startOfDay(service.firstAvailableTime)));
    }

    // add campaign if any for the service
    dispatch(setCampaigns([service], place.campaigns, date));

    if (!isServer) {
      const storedServices = JSON.parse(sessionStorage.getItem('service')) || [];
      const ids = storedServices.map((item) => item.id);
      if (ids.indexOf(service.id) === -1) {
        storedServices.push(service);
      }
      try {
        sessionStorage.setItem('service', JSON.stringify(storedServices));
      } catch (error) {
        console.log('error', error);
        if (error instanceof DOMException && error.name === 'QuotaExceededError') {
          console.error('Quota exceeded!');
        }
      }
    }

    sendAddToCartEventTikTok(service?.id ? service : service?.[0]);
  };

  function add(service) {
    return { type: bookConstants.ADD_SERVICE, service };
  }
}

function setTime(time) {
  if (time) {
    const params = {
      time: { timestamp: time, selected: '' },
    };
    return { type: bookConstants.SET_TIME, params };
  }
}

function selectCapacity(capacity) {
  const selectedCapacity = capacity ?? 1;
  return { type: bookConstants.SELECT_CAPACITY, payload: selectedCapacity };
}

function appendExtraService(service, services) {
  // save to session
  if (!isServer) {
    const storedServices = JSON.parse(sessionStorage.getItem('service')) || [];
    const sessionServices = [...storedServices, service];
    sessionStorage.setItem('service', JSON.stringify(sessionServices));
  }

  sendAddToCartEventTikTok(service?.id ? service : service?.[0]);

  return { type: bookConstants.APPEND_SERVICE, services: [...services, service] };
}

function appendService(service, place, date, services, employee, fetchTimes = true) {
  return (dispatch) => {
    if (!date) {
      date = startOfDay();
    }

    if (!services) {
      services = [];
    }

    let toAppend = [];
    if (service?.id) {
      toAppend.push(service);
    } else {
      toAppend = service;
    }

    dispatch(append(toAppend));

    const servicesMap = [...services, ...toAppend].reduce((map, item) => {
      map[item.id] = item;
      return map;
    }, {});
    const newServices = Object.keys(servicesMap).map((id) => servicesMap[id]);
    const isSistaminuten = isSistaminutenInstance() || Boolean(place?.lastMinuteDiscount);

    if (fetchTimes) {
      dispatch(
        getAvailability({
          services: newServices,
          date,
          employee: employee || 0,
          source: 'loadHours',
          isSistaminuten,
        }),
      );
    }

    // add campaigns if any for the services
    dispatch(setCampaigns(newServices, place.campaigns, date));

    if (!isServer) {
      const storedServices = JSON.parse(sessionStorage.getItem('service')) || [];
      const sessionServicesMap = [...storedServices, ...toAppend].reduce((map, item) => {
        map[item.id] = item;
        return map;
      }, {});
      sessionStorage.setItem(
        'service',
        JSON.stringify(Object.keys(sessionServicesMap).map((id) => sessionServicesMap[id])),
      );
    }

    sendAddToCartEventTikTok(service?.id ? service : service?.[0]);
  };

  function append(services) {
    return { type: bookConstants.APPEND_SERVICE, services };
  }
}

function popService(service, date, services, employee, place, skipAvailabilityCall = false) {
  return (dispatch) => {
    if (!date) {
      date = startOfDay();
    }

    if (!services) {
      services = [];
    }
    const mergedServices = services.filter((serv) => serv.id !== service);
    const isSistaminuten = isSistaminutenInstance() || Boolean(place?.lastMinuteDiscount);

    dispatch(pop(service));
    if (!skipAvailabilityCall) {
      dispatch(
        getAvailability({
          services: mergedServices,
          date,
          employee: employee || 0,
          source: 'loadHours',
          isSistaminuten,
        }),
      );
    }
    dispatch(setCampaigns(mergedServices, place.campaigns, date));
  };

  function pop(service) {
    return { type: bookConstants.POP_SERVICE, service };
  }
}

function removeService(service) {
  return { type: bookConstants.REMOVE_SERVICE, service };
}

function pickEmployee(employee, priceId) {
  if (!isServer) sessionStorage.setItem('bookEmployee', JSON.stringify(employee));
  return { type: bookConstants.PICK_EMPLOYEE, employee, priceId };
}
function keepEmployee() {
  return { type: bookConstants.KEEP_EMPLOYEE };
}

function removeEmployee() {
  if (!isServer) sessionStorage.removeItem('bookEmployee');
  return { type: bookConstants.REMOVE_EMPLOYEE };
}

function bookAgain(place, services, employee, date) {
  return (dispatch) => {
    if (!date) {
      date = startOfDay();
    }
    const time = { timestamp: date, selected: '' };

    const params = {
      time: time,
      place: place,
      services: services,
      employee: employee.id,
      priceId: employee.about.priceListId,
    };

    if (!isServer) {
      sessionStorage.removeItem('bookUUID');
      sessionStorage.removeItem('bookCampaigns');
      sessionStorage.removeItem('bookChangeTime');
      sessionStorage.setItem('bookDate', JSON.stringify(time));
      sessionStorage.setItem('service', JSON.stringify(services));
      sessionStorage.setItem('bookEmployee', JSON.stringify(employee.id));
      sessionStorage.setItem('bookPlace', JSON.stringify({ id: place.id, slug: place.about.slug }));
    }

    dispatch(book_again(params));

    // add campaigns
    dispatch(setCampaigns(services, place.campaigns, date));
  };

  function book_again(params) {
    return { type: bookConstants.BOOK_AGAIN, ...params };
  }
}

function changeBookingTime(bookingId) {
  return (dispatch) => {
    if (!isServer) {
      sessionStorage.setItem('bookChangeTime', bookingId ?? undefined);
    }

    dispatch({ type: bookConstants.CHANGE_BOOKING_TIME, bookingId: bookingId ?? undefined });
  };
}

function removeHour() {
  return { type: bookConstants.REMOVE_HOUR };
}
function pickDate(day, weeks, fromErp) {
  const time = { timestamp: day, selected: '' };
  if (!isServer) sessionStorage.setItem('bookDate', JSON.stringify(time));
  return { type: bookConstants.PICK_DATE, time, weeks, fromErp };
}
function setFirstDay(data) {
  return { type: bookConstants.FIRST_DAY, data };
}

function pickHour(timestamp, hour, employees, dynamicPriceListIdKey, resources, capacity, selectedCapacity) {
  //const saved = JSON.parse(sessionStorage.getItem('bookDate'));
  const time = {
    timestamp,
    selected: hour,
    employees,
    dynamicPriceListIdKey,
    resources,
    capacity,
    selectedCapacity,
  };
  if (!isServer) sessionStorage.setItem('bookDate', JSON.stringify(time));
  return { type: bookConstants.PICK_DATE, time };
}

function clearBook() {
  if (!isServer) {
    sessionStorage.removeItem('service');
    sessionStorage.removeItem('bookEmployee');
    sessionStorage.removeItem('bookPlace');
    sessionStorage.removeItem('bookDate');
    sessionStorage.removeItem('bookUUID');
    sessionStorage.removeItem('bookCampaigns');
    sessionStorage.removeItem('bookChangeTime');
  }

  const params = {};
  return { type: bookConstants.CLEAR_BOOK, params };
}

function getAvailability({ services, date, employee, source = null, changeTime = true, bookingId, isSistaminuten }) {
  return (dispatch) => {
    dispatch(loadingActions.show(source));

    if (!date) {
      date = startOfDay();
    }

    bookServices.getAvailability(services, date, employee, bookingId, isSistaminuten).then(
      (program) => {
        dispatch(success(program, services, date));
        return dispatch(loadingActions.hide());
      },
      (error) => {
        dispatch(failure(error));
        return dispatch(loadingActions.hide());
      },
    );
  };

  function success(program, services, date) {
    const time = { timestamp: date, selected: '' };
    if (!isServer && changeTime) sessionStorage.setItem('bookDate', JSON.stringify(time));
    const params = { program, services, time, changeTime, employee };

    return { type: bookConstants.SERVICE_PROGRAM, params };
  }

  function failure(error) {
    return { type: bookConstants.ERROR_SERVICE, error };
  }
}

function generateUuid() {
  return uuidv4();
}

function addUuid(hash) {
  if (isServer) return { type: null };
  let uuidSession = JSON.parse(sessionStorage.getItem('bookUUID'));

  if (!uuidSession || !hash || uuidSession.hash !== hash) {
    uuidSession = { uuid: generateUuid(), hash: hash, giftcards: {} };
  }

  sessionStorage.setItem('bookUUID', JSON.stringify(uuidSession));
  return { type: bookConstants.ADD_UUID, uuidSession };
}

function getGiftCardErrorMessage(error) {
  if (error) {
    const { giftCardUsageError } = error;
    switch (giftCardUsageError) {
      case 'expired':
        return __('giftcard.error.expired');
      case 'expired_at_time_of_service':
        return __('giftcard.error.expired_at_time_of_service');
      case 'no_credit':
        return __('giftcard.error.no_credit');
      case 'associated_to_other_calendar':
        return __('giftcard.error.associated_to_other_calendar');
      case 'calendar_does_not_accept_gift_cards':
        return __('giftcard.error.calendar_does_not_accept_gift_cards');
      case 'already_paid':
        return __('giftcard.error.already_paid');
      case 'not_valid':
        return __('giftcard.error.not_valid');
      case 'already_used_on_booking':
        return __('giftcard.error.already_used_on_booking');
      case 'service_price_not_valid_for_ugc_type':
        return __('giftcard.error.massage_limit');
      case 'service_vat_not_valid_for_ugc_type':
        return __('giftcard.error.service_vat_not_valid_for_ugc_type');
      case 'not_valid_service_category':
        return __('giftcard.error.not_valid_service_category');
      case 'service_is_not_enabled_as_wellness_by_merchant':
        return __('giftcard.error.service_is_not_enabled_as_wellness_by_merchant');
      default:
        return __('genericErrorGiftcard');
    }
  }
  return __('genericErrorGiftcard');
}

function addCampaign(campaign) {
  if (isServer) return { type: null };

  let bookCampaigns = JSON.parse(sessionStorage.getItem('bookCampaigns'));
  if (!bookCampaigns) {
    bookCampaigns = {};
  }

  bookCampaigns[campaign.id] = campaign;
  sessionStorage.setItem('bookCampaigns', JSON.stringify(bookCampaigns));

  const campaigns = Object.keys(bookCampaigns).map((id) => bookCampaigns[id]);
  return { type: bookConstants.SET_CAMPAIGN, campaigns };
}

function removeCampaign(campaignId) {
  if (isServer) return { type: null };

  let bookCampaigns = JSON.parse(sessionStorage.getItem('bookCampaigns'));
  if (!bookCampaigns) {
    bookCampaigns = {};
  }

  delete bookCampaigns[campaignId];
  sessionStorage.setItem('bookCampaigns', JSON.stringify(bookCampaigns));
  const campaigns = Object.keys(bookCampaigns).map((id) => bookCampaigns[id]);
  return { type: bookConstants.SET_CAMPAIGN, campaigns };
}

function setCampaigns(services, existingCampaigns, date) {
  if (isServer) return { type: null };
  const bookCampaigns = {};
  const serviceIds = services.map((i) => i.id);
  services.forEach((item) => {
    const { campaign } = getServiceCampaignIfAny(item, existingCampaigns, serviceIds);

    if (campaign && campaign.id && date >= campaign.startDate * 1000 && date <= campaign.endDate * 1000) {
      bookCampaigns[campaign.id] = campaign;
    } else if (campaign?.id === -1) {
      bookCampaigns[campaign.id] = campaign;
    }
  });

  sessionStorage.setItem('bookCampaigns', JSON.stringify(bookCampaigns));

  const campaigns = Object.keys(bookCampaigns).map((id) => bookCampaigns[id]);
  return { type: bookConstants.SET_CAMPAIGN, campaigns };
}

/**
 *
 * @param {import('@/types/api/services/booking').ValidateGiftCardRequest} params
 * @param {() => void} successCallback
 * @returns
 */
function addGiftCard(params, successCallback = () => null) {
  return (dispatch) => {
    dispatch(loadingActions.show());
    dispatch(loading());
    bookServices.validateGiftCard(params).then(
      (response) => {
        if (response.giftcard) {
          dispatch(success(response.giftcard));
          successCallback();
        } else {
          dispatch(failure(__('genericErrorGiftcard')));
        }
        dispatch(loadingActions.hide());
      },
      (error) => {
        const errorMessage = getGiftCardErrorMessage(error);
        dispatch(failure(errorMessage));
        dispatch(loadingActions.hide());
      },
    );
  };

  function success(giftcard) {
    const uuidSession = JSON.parse(sessionStorage.getItem('bookUUID')) || {};

    if (!uuidSession.uuid) {
      uuidSession.uuid = generateUuid();
      uuidSession.giftcards = {};
    }

    uuidSession.giftcards = Object.assign({}, uuidSession.giftcards, { [giftcard.code]: giftcard });
    sessionStorage.setItem('bookUUID', JSON.stringify(uuidSession));

    return { type: bookConstants.SET_GIFTCARD, payload: { uuidSession, added: true } };
  }

  function failure(error) {
    return { type: bookConstants.ERROR_GIFTCARD, error };
  }

  function loading() {
    return { type: bookConstants.VALIDATE_GIFTCARD };
  }
}

function removeGiftCard(params) {
  return (dispatch) => {
    dispatch(loadingActions.show());
    bookServices.removeGiftCard(params).then(
      (response) => {
        dispatch(loadingActions.hide());
        if (response.removed) {
          dispatch(success(params.code));
        }
      },
      (error) => {
        dispatch(loadingActions.hide());
      },
    );
  };

  function success(code) {
    const uuidSession = JSON.parse(sessionStorage.getItem('bookUUID')) || {};
    delete uuidSession.giftcards[code];
    sessionStorage.setItem('bookUUID', JSON.stringify(uuidSession));
    return { type: bookConstants.SET_GIFTCARD, payload: { uuidSession } };
  }
}

function clearGiftCardMessages() {
  return { type: bookConstants.CLEAR_GIFTCARD_MSG };
}

/**
 * Apply a client bundle to use for booking checkout
 * @param {import('@/types/api/services/users/schema').ClientBundle} bundle
 * @returns
 */
function applyBundle(bundle) {
  if (!isServer) sessionStorage.setItem('bookBundle', JSON.stringify(bundle));
  return { type: bookConstants.APPLY_BUNDLE, payload: bundle };
}
function removeBundle() {
  return { type: bookConstants.REMOVE_BUNDLE };
}

function saveTrackingProps(trackingProps) {
  if (!isServer) sessionStorage.setItem('bookTrk', JSON.stringify(trackingProps));
}

function getTrackingProps() {
  if (isServer) return {};
  return JSON.parse(sessionStorage.getItem('bookTrk')) || {};
}

function clearTrackingProps() {
  if (!isServer) sessionStorage.removeItem('bookTrk');
}

/**
 *  Create booking with pay at place
 * @param {import('@/types/api/services/booking').SaveBookingRequest} params
 */
function saveAppointment(params) {
  return (dispatch) => {
    dispatch(loading(params));
    bookServices.save(params).then(
      (response) => {
        dispatch(saved(response));
        dispatch(loadingActions.hide());

        const user = response.user;

        if (user && !user.activeBookings) {
          user.activeBookings = [];
        }

        if (user) {
          if (params.capacity && params.capacity > 1) {
            for (let i = 0; i < params.capacity; i++) {
              user.activeBookings.push(response.message.start);
            }
          } else {
            user.activeBookings.push(response.message.start);
          }

          dispatch(userActions.updateUser(user));
        }

        dispatch(clearBook());
      },
      (error) => {
        if (error === 'protectedEmail') {
          setTimeout(() => {
            dispatch(validateField('email'));
          }, 500);
        } else {
          dispatch(failure(error || 'serverError'));
        }
        dispatch(loadingActions.hide());
      },
    );
  };

  function loading(params) {
    return { type: bookConstants.SAVING, params };
  }
  function saved(response) {
    return { type: bookConstants.SAVED, response };
  }
  function failure(response) {
    return { type: bookConstants.FAILURE, response };
  }
  function validateField(response) {
    return { type: bookConstants.VALIDATE_FIELD, response };
  }
}
