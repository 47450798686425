import { CalendarIcon, CardIcon, CompanyIcon, GiftCardIcon, InvoiceIcon, MyAccountIcon } from '@/components/icons';

export const BOOK_PATH = '/support/boka-tid';
export const PAYMENT_PATH = '/support/betalning';
export const INVOICE_PATH = '/support/faktura';
export const MY_ACCOUNT_PATH = '/support/mitt-konto';
export const GIFTCARD_PATH = '/support/presentkort';
export const BUSINESS_PATH = '/support/foretag';

export type SupportFormLink = {
  text: string;
  questionId: string;
};

export type Question = {
  id: number;
  question: string;
  answer?: string;
  supportFormLinks?: SupportFormLink[];
  section?: string;
};

export type Section = {
  title: string;
  path?: string;
  icon?: any;
  questions: Question[];
  bgImage?: string;
};

export type FormSection = {
  options: Option[];
  sectionPath: string;
  title?: string;
  icon?: any;
};

export type Option = {
  id: string;
  option: string;
  tag?: number;
  followUpQuestions?: FollowUpQuestion[];
  requiresLogin?: boolean;
  requiresUpload?: boolean;
};

export type ConnectedQuestion = {
  id: string;
  value?: string;
};

export type FollowUpQuestion = {
  id: string;
  question: string;
  type: 'text' | 'checkbox' | 'date';
  values?: Option[];
  validation?: 'phone' | 'email' | 'date' | 'checkbox';
  required?: boolean;
  connected?: ConnectedQuestion;
  placeholder?: string;
};

export const supportFormFaq: FormSection[] = [
  {
    sectionPath: BOOK_PATH,
    options: [
      {
        id: 'missing_booking_confirmation',
        option: 'Jag saknar en bokningsbekräftelse.',
        tag: 7738014,
        followUpQuestions: [
          {
            id: 'company',
            question: 'Vilket företag har du bokat hos?',
            type: 'text',
            placeholder: 'Företag',
          },
          {
            id: 'date',
            question: 'Vilket datum och tid gäller det?',
            type: 'date',
            placeholder: 'Datum (YYYY-MM-DD HH:mm)',
            validation: 'date',
          },
        ],
      },
      {
        id: 'booking_confirmation_not_booked',
        option: 'Jag har fått en bokningsbekräftelse på något som jag inte har bokat.',
        tag: 7738015,
        followUpQuestions: [
          {
            id: 'booking_confirmation_by',
            question: 'Jag fick bokningsbekräftelse via',
            type: 'checkbox',
            validation: 'checkbox',
            values: [
              {
                id: 'email',
                option: 'E-postadress',
              },
              {
                id: 'sms',
                option: 'SMS',
              },
            ],
          },
          {
            id: 'phone',
            question: 'Mitt telefonnummer är',
            type: 'text',
            placeholder: 'Telefonnummer',
            validation: 'phone',
            connected: {
              id: 'booking_confirmation_by',
              value: 'sms',
            },
          },
        ],
      },
      {
        id: 'cannot_cancel_booking',
        option: 'Jag lyckas inte avboka min tid.',
        tag: 7738016,
        followUpQuestions: [
          {
            id: 'company',
            question: 'Vilket företag har du bokat hos?',
            type: 'text',
            placeholder: 'Företag',
          },
          {
            id: 'date',
            question: 'Vilket datum och tid gäller det?',
            type: 'date',
            placeholder: 'Datum (YYYY-MM-DD HH:mm)',
            validation: 'date',
          },
        ],
      },
    ],
  },
  {
    sectionPath: PAYMENT_PATH,
    options: [
      {
        id: 'booking_canceled_refund',
        option: 'Jag har avbokat en tid men inte fått återbetalt för min förskottsbetalning.',
        tag: 7738017,
        requiresLogin: true,
        followUpQuestions: [
          {
            id: 'company',
            question: 'Vilket företag har du bokat hos?',
            type: 'text',
            placeholder: 'Företag',
          },
          {
            id: 'date',
            question: 'Vilket datum och tid gäller det?',
            type: 'date',
            placeholder: 'Datum (YYYY-MM-DD HH:mm)',
            validation: 'date',
          },
          {
            id: 'payment',
            question: 'Hur har du betalat?',
            type: 'text',
            placeholder: 'Betalsätt',
          },
        ],
      },
      {
        id: 'send_booking_receipt_again',
        option: 'Jag behöver mitt kvitto skickat på nytt',
        tag: 10579692,
        followUpQuestions: [
          {
            id: 'company',
            question: 'Vilket företag har du bokat hos?',
            type: 'text',
            placeholder: 'Företag',
          },
          {
            id: 'date',
            question: 'Vilket datum och tid gäller det?',
            type: 'date',
            placeholder: 'Datum (YYYY-MM-DD HH:mm)',
            validation: 'date',
          },
          {
            id: 'payment',
            question: 'Hur har du betalat?',
            type: 'text',
            placeholder: 'Betalsätt',
          },
        ],
      },
    ],
  },
  {
    sectionPath: MY_ACCOUNT_PATH,
    options: [
      {
        id: 'extract_stored_data',
        option: 'Jag vill ha ett utdrag på datan ni har sparat om mitt konto.',
        requiresLogin: true,
        tag: 7738022,
      },
      {
        id: 'help_with_login',
        option: 'Jag behöver hjälp med mitt konto',
        tag: 9120958,
        followUpQuestions: [
          {
            id: 'email',
            question: 'Vilken e-postadress loggar du in med?',
            type: 'text',
            placeholder: 'E-postadress',
            validation: 'email',
          },
        ],
      },
    ],
  },
  {
    sectionPath: GIFTCARD_PATH,
    options: [
      {
        id: 'giftcard_not_found',
        option: 'Jag har köpt ett presentkort men hittar det inte.',
        tag: 7738023,
        followUpQuestions: [
          {
            id: 'email',
            question: 'Vilken e-postadress angavs vid köpet?',
            type: 'text',
            placeholder: 'E-postadress',
            validation: 'email',
          },
          {
            id: 'date',
            question: 'När gjordes köpet?',
            type: 'date',
            placeholder: 'Datum (YYYY-MM-DD HH:mm)',
            validation: 'date',
          },
        ],
      },
      {
        id: 'cancel_purchase',
        option: 'Jag vill ångra ett köp av presentkort/värdekort/friskvårdskort.',
        tag: 7738024,
        followUpQuestions: [
          {
            id: 'email',
            question: 'Vilken e-postadress angavs vid köpet?',
            type: 'text',
            placeholder: 'E-postadress',
            validation: 'email',
          },
          {
            id: 'giftcard_code',
            question: 'Vad är presentkortskoden? (står på presentkortet)',
            type: 'text',
            placeholder: 'Presentkortskoden',
          },
        ],
      },
      {
        id: 'giftcard_not_working',
        option: 'Mitt presentkort fungerar inte.',
        tag: 7738025,
        followUpQuestions: [
          {
            id: 'email',
            question: 'Vilken e-postadress angavs vid köpet?',
            type: 'text',
            placeholder: 'E-postadress',
            validation: 'email',
          },
          {
            id: 'giftcard_code',
            question: 'Vad är presentkortskoden? (står på presentkortet)',
            type: 'text',
            placeholder: 'Presentkortskoden',
          },
        ],
      },
      {
        id: 'send_giftcard_receipt_again',
        option: 'Jag behöver mitt kvitto skickat på nytt',
        tag: 7738018,
        followUpQuestions: [
          {
            id: 'email',
            question: 'Vilken e-postadress angavs vid köpet?',
            type: 'text',
            placeholder: 'E-postadress',
            validation: 'email',
          },
          {
            id: 'date',
            question: 'När gjordes köpet?',
            type: 'date',
            placeholder: 'Datum (YYYY-MM-DD HH:mm)',
            validation: 'date',
          },
        ],
      },
    ],
  },
  {
    sectionPath: INVOICE_PATH,
    options: [
      {
        id: 'send_docs',
        option: 'Jag har varit i kontakt med er och har blivit ombedd att skicka in underlag.',
        requiresUpload: true,
        tag: 7738028,
      },
    ],
  },
  {
    sectionPath: BUSINESS_PATH,
    options: [
      {
        id: 'update_review',
        option: 'Jag vill ändra eller ta bort en recension som jag har lämnat.',
        tag: 7738026,
        requiresLogin: true,
        followUpQuestions: [
          {
            id: 'company',
            question: 'Vilket företag har du recenserat?',
            type: 'text',
            placeholder: 'Företag',
          },
          {
            id: 'date',
            question: 'När lämnades recensionen?',
            type: 'date',
            placeholder: 'Datum (YYYY-MM-DD HH:mm)',
            validation: 'date',
          },
          {
            id: 'why',
            question: 'Varför vill du ta bort recensionen?',
            type: 'text',
            placeholder: 'Varför',
          },
        ],
      },
    ],
  },
];
export const supportFaq: Section[] = [
  {
    title: 'Boka tid',
    icon: CalendarIcon,
    path: BOOK_PATH,
    bgImage: '/images/hero-boka-tid.jpg',
    questions: [
      {
        id: 1,
        question: 'Hur bokar jag?',
        answer: `<p>Använd vår app (<a class="text-link" href="https://bokadirekt.smart.link/cl1p2rx9j">Finns i AppStore och Google Play Butik</a>) eller gå in på Bokadirekt.se och sök tjänsterna som du är intresserad av.
Klicka på ett företag i sökningen så kan du se deras tjänsteutbud, bildgalleri och recensioner.
<br /><br />
Klicka på tjänsten du vill boka och välj tid. Om du är inloggad så behöver du bara bekräfta bokningen. Annars anger du dina uppgifter och bekräftar bokningen.
<br /><br />
Du får en bekräftelse via e-post samt direkt på skärmen.
        </p>`,
      },
      {
        id: 2,
        question: 'Hur avbokar jag?',
        answer: `<p>Genom din inloggning på Bokadirekt så kan du gå till Mina bokningar för att avboka tiden. Vid bokning får du även en bokningsbekräftelse med en avbokningskod. Den kan du ange <a class="text-link" href="https://www.bokadirekt.se/cancel"/>här</a> för att avboka.
<br /><br />
Avbokning kan ske utan kostnad inom reglerna för avbokning.
<br /><br />
Reglerna sätts av varje tjänsteleverantör. Se deras onlinebokning eller din bokningsbekräftelse för att veta vad som gäller för just din bokning.</p>`,
        supportFormLinks: [
          {
            questionId: 'cannot_cancel_booking',
            text: 'Jag lyckas inte avboka min tid.',
          },
        ],
      },
      {
        id: 3,
        question: 'Hur hittar jag min bokningsbekräftelse?',
        answer: `<p>Du får vid slutförd bokning en bekräftelse via e-post samt direkt på skärmen. Saknar du en bokningsbekräftelse så kan du kontakta oss.</p>`,
        supportFormLinks: [
          {
            questionId: 'missing_booking_confirmation',
            text: 'Jag saknar en bokningsbekräftelse.',
          },
        ],
      },
      {
        id: 4,
        question: 'Jag har fått en bekräftelse för något jag inte har bokat.',
        answer: `<p>Kontakta oss gärna via formuläret så ska vi se vad som har hänt.</p>`,
        supportFormLinks: [
          {
            questionId: 'booking_confirmation_not_booked',
            text: 'Jag har fått en bokningsbekräftelse på något som jag inte har bokat.',
          },
        ],
      },
      {
        id: 5,
        question: 'Varför kan jag inte se min bokning som inloggad?',
        answer: `<p>Bokningen kopplas till ditt konto via informationen som är angiven i bokningen. Detta sker per automatik om du är inloggad när du bokar.
<br /><br />
Har du bokat utan att vara inloggad så kan det vara så att din e-postadress som du angav för bokningen inte stämmer mot den på ditt konto.</p>`,
      },
      {
        id: 6,
        question: 'Hur lämnar jag en recension?',
        answer: `<p>Du får ett mail efter den bokade tidens slut där du kan recensera tjänsten.
Det går även att lämna en recension via vår app, genom att gå till tidigare bokningar.</p>`,
      },
    ],
  },
  {
    title: 'Betalning',
    icon: CardIcon,
    path: PAYMENT_PATH,
    bgImage: '/images/hero-betalning.png',
    questions: [
      {
        id: 1,
        question: 'Varför kan jag inte betala min bokning online?',
        answer: `<p>Det är tjänsteutövaren som du bokar hos som väljer vilka betalsätt som erbjuds.
Om tjänsteutövaren erbjuder onlinebetalning och du ändå inte kan betala, så kan det bero på att priset för tjänsten inte visas, eller att den tid du bokar är för långt fram i tiden för att betalas just nu.</p>`,
      },
      {
        id: 2,
        question: 'Jag har betalat min bokning online och avbokat, hur får jag tillbaka mina pengar?',
        answer: `<p>Vid avbokning så avbryts din betalning per automatik.
<br /><br />
Återbetalningstiden beror på betalsätt.
<br /><br />
Vanligtvis tar det 2-3 bankdagar.
<br /><br />
Har du valt en direktbetalning och inte har fått en återbetalning inom 7 bankdagar, vänliga kontakta oss.</p>`,
        supportFormLinks: [
          {
            questionId: 'booking_canceled_refund',
            text: 'Jag har avbokat en tid men inte fått återbetalt för min förskottsbetalning.',
          },
        ],
      },
      {
        id: 3,
        question: 'Kan jag i efterhand betala en bokning som redan är skapad?',
        answer: `<p>Om du loggar in via vår app så kan du gå till dina bokningar och betala dem så länge tjänsteleverantören erbjuder det.</p>`,
      },
      {
        id: 4,
        question: 'Hur hittar jag mitt kvitto?',
        answer: `<p>Vid onlinebetalning så skickas per automatik ett kvitto till dig.
<br /><br />
Om du har betalat direkt till tjänsteleverantören så får du ett kvitto av dem när du betalar.
<br /><br />
Saknar du ett kvitto för en betalning gjord online så kan du kontakta oss.</p>`,
        supportFormLinks: [
          {
            questionId: 'send_booking_receipt_again',
            text: 'Jag behöver mitt kvitto skickat på nytt',
          },
        ],
      },
    ],
  },
  {
    title: 'Mitt konto',
    icon: MyAccountIcon,
    path: MY_ACCOUNT_PATH,
    bgImage: '/images/hero-mitt-konto.png',
    questions: [
      {
        id: 1,
        question: 'Hur återställer jag mitt lösenord?',
        answer: `<p>Numera krävs inget lösenord för att logga in på Bokadirekt. <br />Istället får du ett mail med en verifieringskod när du loggar in, som du sen använder för att komma in på ditt konto.
Får du inget mail så kolla även din skräppostmapp. Du kan även försöka lägga till noreply@bokadirekt.se som en betrodd mailadress/kontakt.
</p>`,
        supportFormLinks: [
          {
            questionId: 'help_with_login',
            text: 'Jag behöver hjälp med mitt konto',
          },
        ],
      },
      {
        id: 2,
        question: 'Hur byter jag e-postadress på min inloggning?',
        answer: `<p>Det går inte att ändra e-postadress för ett befintligt konto.
<br /><br />
Du kan däremot skapa ett nytt konto med din nya e-postadress och fortsätta använda det nya kontot istället. Det gamla kontot kan du ta bort genom att logga in och klicka på Kontoinställningar i menyn.<br />
Klicka sedan på Radera konto.</p>`,
      },
      {
        id: 3,
        question: 'Hur tar jag bort mitt konto?',
        answer: `<p>Du kan ta bort kontot genom att logga in och klicka på <a class="text-link" href="https://www.bokadirekt.se/account-settings">Kontoinställningar</a> i menyn. <br />Klicka sedan på Radera konto.</p>`,
      },
      {
        id: 4,
        question: 'Hur hanteras mina uppgifter?',
        answer: `<p>När en kund bokar en tid hos en tjänsteleverantör via Bokadirekt så samlas de personuppgifter tjänsteleverantören anser vara nödvändiga in. Uppgifterna behandlas vidare av Bokadirekt samt tjänsteleverantören under varsitt åtskilt ansvar.
<br /><br />
Du kan läsa mer om vår hantering av personuppgifter:
<a class="text-link" href="https://www.bokadirekt.se/articles/terms-booking-consumer">här</a></p>`,
      },
      {
        id: 5,
        question: 'Jag vill ha ett utdrag på datan ni har sparat om mitt konto.',
        answer: `<p></p>`,
        supportFormLinks: [
          {
            questionId: 'extract_stored_data',
            text: 'Jag vill ha ett utdrag på datan',
          },
        ],
      },
    ],
  },
  {
    title: 'Presentkort & Friskvårdskort',
    icon: GiftCardIcon,
    path: GIFTCARD_PATH,
    bgImage: '/images/hero-presentkort.png',
    questions: [
      {
        id: 1,
        question: 'Hur hittar jag mitt köpta kort?',
        answer: `<p>Vid köp av kort så får du det via e-post direkt vid köpet.
<br /><br />
Vid köp av fysiskt presentkort så postar vi det till den adress som angavs vid köpet.
<br /><br />
Om du inte hittar eller inte har fått ditt presentkort så kan du kontakta oss.</p>`,
        supportFormLinks: [
          {
            questionId: 'giftcard_not_found',
            text: 'Jag har köpt ett presentkort men hittar det inte.',
          },
        ],
      },
      {
        id: 2,
        question: 'Hur bokar jag med mitt köpta kort?',
        answer: `<p>Före du slutför din bokning så finns ett fält där du kan ange presentkortets kod, så länge tjänsteleverantören tar emot presentkort köpta på Bokadirekt.
<br /><br />
Observera att presentkort köpta direkt hos tjänsteleverantören inte går att ange vid bokning, dessa tas istället med till besöket.</p>`,
        supportFormLinks: [
          {
            questionId: 'giftcard_not_working',
            text: 'Mitt presentkort fungerar inte.',
          },
        ],
      },
      {
        id: 3,
        question: 'Mitt presentkorts giltighetstid är passerad',
        answer: `<p>Presentkort köpta på Bokadirekt har 12 månaders giltighetstid från köptillfället.
Du kan se giltighetstid genom att ange koden <a class="text-information-700 underline" href="https://www.bokadirekt.se/giftcards">här</a>
<br /><br />
Giltighetstiden står även angiven på presentkortet.
<br /><br />
Obeservera att Friskvårdskort har en giltighetstid som beror på datumet det köptes. Giltighetstid för friskvårdskort står vid köp och på kvittot.
</p>`,
      },
      {
        id: 4,
        question: 'Hur ser jag saldo för mitt presentkort?',
        answer: `<p>Du kan se ditt presentkorts aktuella saldo genom att ange presentkortskoden (står på presentkortet) <a class="text-information-700 underline" href="https://www.bokadirekt.se/giftcards">här</a></p>`,
      },
      {
        id: 5,
        question: 'Hur hittar jag mitt kvitto?',
        answer: `<p>Kvittot skickas via e-post i samband med köpet. Saknar du ett kvitto så kan du kontakta oss.</p>`,
        supportFormLinks: [
          {
            questionId: 'send_giftcard_receipt_again',
            text: 'Jag behöver mitt kvitto skickat på nytt',
          },
        ],
      },
    ],
  },
  {
    title: 'Faktura',
    icon: InvoiceIcon,
    path: INVOICE_PATH,
    bgImage: '/images/hero-faktura.jpg',
    questions: [
      {
        id: 1,
        question: 'Jag anser att fakturan jag fått från Klarna är felaktig',
        answer: `<p>Du kan bestrida fakturan från Klarna via deras hemsida.
<br /><br />
Läs mer:
<a class="text-link" target="_blank" rel="nofollow external noopener noreferrer" href="https://www.klarna.com/se/beskrivning-av-klarnas-koparskydd/">här</a></p>`,
      },
      {
        id: 2,
        question: 'Jag anser att fakturan jag fått från Qliro är felaktig',
        answer: `<p>Du kan bestrida fakturan från Qliro via deras hemsida.
<br /><br />
Läs mer:
<a class="text-link" target="_blank" rel="nofollow external noopener noreferrer" href="https://se-support.qliro.com/">här</a></p>`,
      },
      {
        id: 3,
        question: 'Jag anser att jag har fått en felaktig no show-faktura',
        answer: `<p>Om du har fått en e-postfaktura från Bokadirekt genom Billogram så kan du kontakta oss genom att öppna fakturan och skriva i meddelandefältet.
<br /><br />
Om du har fått en faktura skickad direkt ifrån tjänsteutövaren så behöver du kontakta dem.
Kontaktuppgifter finns vanligtvis på deras onlinebokning.
<br /><br />
Om du har varit i kontakt med oss om en faktura och har blivit ombedd att skicka in underlag, kontakta oss via formuläret här.</p>`,
        supportFormLinks: [
          {
            questionId: 'send_docs',
            text: 'Jag har varit i kontakt med er och har blivit ombedd att skicka in underlag.',
          },
        ],
      },
    ],
  },
  {
    title: 'Tjänsteutövare',
    icon: CompanyIcon,
    path: BUSINESS_PATH,
    bgImage: '/images/hero-foretag.png',
    questions: [
      {
        id: 1,
        question: 'Jag vill rapportera ett företag anslutet till Bokadirekt.se',
        answer: `<p>Vi är givetvis måna om att du får en bra upplevelse när du bokar via oss.
        Kontakta oss via länken <a class="text-link" href="https://form.typeform.com/to/cFOxh0Uy">här</a> om du vill rapportera ett företag som du har bokat hos.
<br /><br />
Tänk dock på att du vid bokning ingår avtal direkt med den som utför tjänsten. Bokadirekt tar således inget ansvar för felaktiga eller uteblivna behandlingar.</p>`,
      },
      {
        id: 2,
        question: 'Hur lämnar jag en recension?',
        answer: `<p>Du får ett mail efter den bokade tidens slut där du kan recensera tjänsten.
Det går även att lämna en recension via vår app, genom att gå till tidigare bokningar.</p>`,
      },
      {
        id: 3,
        question: 'Jag vill ändra eller ta bort en recension som jag har lämnat.',
        supportFormLinks: [
          {
            questionId: 'update_review',
            text: 'Jag vill ändra eller ta bort en recension som jag har lämnat.',
          },
        ],
      },
    ],
  },
];

export const highlightedFaq = {
  title: 'Vanliga frågor',
  questions: supportFaq
    .reduce((acc, section) => {
      section.questions.forEach((q) => {
        q.section = section.path;
        acc.push({ ...q });
      });
      return [...acc];
    }, [])
    .map((item) => ({ item, sort: Math.random() })) // randomize questions
    .sort((a, b) => a.sort - b.sort) // randomize questions
    .map(({ item }, i) => {
      item.id = i + 1;
      return item;
    })
    .splice(0, 5),
};

export const faq: Section[] = [
  {
    title: 'Vanliga frågor Akupunktur',
    path: '/faq-vanliga-fragor/akupunktur',
    questions: [
      {
        id: 1,
        question: 'Vad är akupunktur?',
        answer: `<p>Akupunktur är en alternativmedicinsk behandlingsform som har sitt ursprung i traditionell kinesisk medicin. Den kinesiska medicinen har en holistisk syn på människan och människokroppen och behandlingarna används för att återställa kroppens egna balans. Idag används akupunktur som behandlingsform även i stora delar av västvärlden. Traditionellt ses akupunktur påverka kroppens meridianer, vilka utgör grunden i den traditionella kinesiska läkekonsten. Vid akupunkturbehandling sticks tunna nålar in i hudens akupunkturpunker och genom att stimulera dessa upplöses blockeringar och obalanser i kroppens nervsystem. Behandlingen upplevs som lugnande och smärtlindrande.
          <br /> <br />
          Under behandlingen placeras tunna nålar i huden i och runt omkring området där patienten upplever smärta. Vanligtvis får nålarna sitta i mellan 20-30 minuter innan de tas bort. I vissa fall kan nålarna även ge stimulering genom att kopplas till en elektrisk apparat, detta kallas elektroakupunktur. Klassisk akupunktur används vid en rad olika medicinska besvär så som exempelvis stress, spänningshuvudvärk, utbrändhet, ångest, muskelsmärta och PMS.
        </p>`,
      },
      {
        id: 2,
        question: 'Varför ska du boka en tid för akupunktur?',
        answer: `<p>
          <ul>
            <li>Akupunkturbehandling kan ge smärtlindring vid spänningshuvudvärk, ischias, förlossningssmärtor och PMS.</li>
            <li>Behandlingen kan även ha en lugnande effekt vid symptom så som stress, ångest och mildare depression.</li>
          </ul>
        </p>`,
      },
    ],
  },
  {
    title: 'Vanliga frågor Fillers',
    path: '/faq-vanliga-fragor/fillers',
    questions: [
      {
        id: 1,
        question: 'Varför gör man en injektionsbehandling?',
        answer: `<p>Man kanske tycker sig ha förlorat volym eller bara vill förbättra någon rynka. Det viktigaste är att det är du själv som vill göra behandlingen, och inte för att någon annan sagt det åt dig.</p>`,
      },
      {
        id: 2,
        question: 'Vilka är de vanligaste missuppfattningarna med botox/fillers/injektioner?',
        answer: `<p>En vanlig missuppfattning är att behandlingen kommer förändra dig till den grad att ingen känner igen dig. Det är sällan syftet. Väldigt många gör tex fillers i läpparna bara för att få tillbaka lite volym, jämna ut läpparna eller för att tillföra läpparna fukt.</p>`,
      },
      {
        id: 3,
        question: 'Vad ska man tänka på innan man gör fillers?',
        answer: `<p>Välj en behandlare som är minst legitimerad sjuksköterska och som är utbildad av estetiska injektionsrådet. Gärna med flera års erfarenhet.
          <br /><br />
          Gå på konsultation innan behandlingen och få en uppfattning om kliniken är seriös.
          <br /><br />
          Ca två veckor innan behandlingen ska du heller inte använda läkemedel som ökar blödningsrisken.
        </p>`,
      },
      {
        id: 4,
        question: 'Vad ska man tänka på efter en behandling?',
        answer: `<p>Träna eller sola inte några dagar efter behandlingen. Undvik också att använda smink samma dag som behandlingen gjorts, och rör så lite som möjligt på det behandlade området.</p>`,
      },
      {
        id: 5,
        question: 'Hur länge “håller” en behandling?',
        answer: `<p>Hur länge en behandling håller är individuellt och beror även på vilket område som behandlats och vilket preparat som använts. Generellt håller fillers ca sex till tio månader. För Botox är hållbarheten cirka tre till sex månader.</p>`,
      },
      {
        id: 6,
        question: 'Finns det några tydliga trender i branschen just nu?',
        answer: `<p>Vi ser en tydlig trend i efterfrågan av naturliga resultat,  det ska inte se sprutat ut.</p>`,
      },
      {
        id: 7,
        question: 'Det finns olika varumärke - vad är skillnaden?',
        answer: `<p>Man ska fråga vad som sprutas in i kroppen och vara noga med sina val. Mest känt och säkrast är Restylane som är en svensk kvalitetsprodukt. Det innehåller väldigt ren hyaluronsyra som inte är blandad med en massa tillsatser.</p>`,
      },
      {
        id: 8,
        question: 'Är det farligt med behandling? Gör det ont? Får man bedövning',
        answer: `<p>Innan man gör en fillerbehandling så informeras man om risker såsom blödning, infektion eller nekros (vävnadsdöd). Nekros är dock väldigt ovanligt bland rutinerade behandlare.
          <br /><br />
          Behandlingen kan i stort sett vara helt smärtfri. Ofta får du bedövningssalva innan en behandling, men alla använder inte det.
        </p>`,
      },
    ],
  },
  {
    title: 'Vanliga frågor kring Fransar',
    path: '/faq-vanliga-fragor/kring-fransar',
    questions: [
      {
        id: 1,
        question: 'Vad skiljer volym- och singelfransar?',
        answer: `<p>Volymfransar
          <br /><br />
          Volymfransar är tekniken för dig som vill se ett tydligt resultat och mer volym i fransarna. Enkelt uttryckt sätter man in ett helt knippe med fransar som ger ett fylligare intryck. Denna tekniken kan ge mer än fyrahundra fransar per öga utöver dina naturliga fransar.
          <br /><br />
          Singelfransar
          <br /><br />
          Medan volymfransar ger mer volym så ger singelfransar en naturligare och längre kontur. Antalet fransar är färre än vid applicering av volymfransar; man brukar räkna med femtio till hundra per öga. Singelfransarnas längd, grovhet och form anpassas efter ditt öga.
        </p>`,
      },
      {
        id: 2,
        question: 'Vad ska man tänka på innan man kommer på en fransförlängning?',
        answer: `<p>Kom i god tid till din fransförlängning och helst osminkad. Är det första gången? Fundera på vad du är ute efter för resultat, du kan t.ex. ta med en bild som illustrerar vad du är ute efter - om det är något väldigt dramatiskt eller en mer naturlig look.</p>`,
      },
      {
        id: 3,
        question: 'Vad ska man tänka på efter man gjort en behandling?',
        answer: `<p>För bästa underhåll så är det jätteviktigt att regelbundet rengöra fransarna med ett fransschampo. Annars så samlas lätt talg, smuts, och bakterier längs med franskanten vilket gör att man kan tappa fransar i förtid och i värsta fall få en ögoninfektion. Du bör inte duscha eller tvätta ögonen/ansiktet tolv timmar efter en förlängning eller påfyllnad pga limmets härdningstid.
          <br /><br />
          Försök att inte “sova på ansiktet” använd inte olja i närheten av fransarna.
        </p>`,
      },
      {
        id: 4,
        question: 'Hur länge “håller” en behandling?',
        answer: `<p>Hållbarheten efter en behandling varierar beroende på hur man tar hand om sina fransar. Men det vanligaste är att man kommer för påfyllning var tredje vecka. Fransförlängningen påverkas även negativt av hormoner, graviditet och stress.</p>`,
      },
      {
        id: 5,
        question: 'Kan man göra något för att underhålla fransarna efter en behandling?',
        answer: `<p>Gör rent fransarna dagligen med ett anpassat fransschampo och borste</p>`,
      },
      {
        id: 6,
        question: 'Kan man sminka sig som vanligt efter?',
        answer: `<p>Man kan absolut sminka sig som vanligt efter en behandling men använd inte mascara på fransförlängningen.</p>`,
      },
      {
        id: 7,
        question: 'Vad är trendigt i branschen just nu?',
        answer: `<p>Trenderna i branschen varierar i olika städer men fler vill ha en mer naturlig look än tidigare. Mix och lätt volym går bäst. Att jobba med olika längder och olika böj i ett sett är också extra trendigt.</p>`,
      },
    ],
  },
  {
    title: 'Vanliga frågor kring Hudvård',
    path: '/faq-vanliga-fragor/kring-hudvard',
    questions: [
      {
        id: 1,
        question: 'Varför ska du boka en tid för hudvård/ansiktsbehandling?',
        answer: `<p>
          <ul>
            <li>På sikt minskar vår cellförnyelse och produktion av kollagen och elastin, samtidigt bryts vår naturliga hyaluronsyra i huden ned. För att bibehålla hudens spänst och elasticitet är god hudvård viktigt.</li>
            <li>Ansiktsbehandlingar kan anpassas till en mängd olika hudtyper, behov och problemområden och hjälper dig till en frisk och välmående hud.</li>
            <li>Regelbundna ansiktsbehandlingar med rätt produkter och näringsämnen kan hjälpa dig till de bästa resultaten för just din hud.</li>
          </ul>
        </p>`,
      },
      {
        id: 2,
        question: 'Vad menas med en klassisk ansiktsbehandling?',
        answer: `<p>Den klassiska ansiktsbehandlingen är en omfattande behandling som skräddarsys efter dina behov. Behandlingen innefattar djuprengöring, ånga, portömning och peeling följt av ansiktsmask och ansiktsmassage. Vid behandlingen används produkter anpassade utifrån dina önskemål och din hudtyp. Problemområden att arbeta med kan vara exempelvis torr/fet hud, acne eller rosasea.</p>`,
      },
      {
        id: 3,
        question: 'Vad är ansiktsbehandling express?',
        answer: `<p>För dig som har ont om tid men vill uppnå snabbt resultat och känslan av en rengjord hud med ny lyster, perfekt inför helgen eller kommande festligheter! Behandlingen innefattar lättare rengöring med ånga, kortare ansiktsmassage samt ansiktsmask och produkter anpassade för just din hudtyp. </p>`,
      },
      {
        id: 4,
        question: 'Vad är anti age?',
        answer: `<p>En behandling framtagen specifikt för mogen hy. När vi åldras förlorar vår hud sin spänst och elasticitet, cellförnyelsen går långsammare och vårt kollagen bryts ned. Vid en anti age-behandling används produkter framtagna för den åldrade huden, produkterna innehåller ämnen som stimulerar cellbildningen såsom hyaluronsyra som återfuktar och ökar huden elasticitet samt probiotika som bekämpar åldersprocesser. Behandlingen avslutas med en anti age mask och fuktgivande serum för lyster och fuktgivande effekt.</p>`,
      },
      {
        id: 5,
        question: 'Vad är dermapen nålbehandling?',
        answer: `<p>Dermapen/Micro-needling är en ny och revolutionerande nålbehandling som stimulerar hudens produktion av kollagen. Genom att använda sig av nålbehandling kan näringsrika produkter med aktiva ingredienser nå djupare in i huden för att uppnå en föryngrande effekt. Behandlingen används även på problemområden såsom rynkor, stora porer, solskadad hud eller på synliga ärr i ansiktet. Behandlingen kan även göras tillsammans med en s.k. skinbooster med hyaluronsyra som ger huden en riktig vitamininjektion och ny lyster!</p>`,
      },
      {
        id: 6,
        question: 'Vad är kemisk peeling?',
        answer: `<p>Kemisk peeling är ett samlingsnamn på behandlingar där en blandning av syror används för att uppnå önskat resultat, t.ex. Aha eller Tca syror. Denna behandlingsform är effektiv för att arbeta med problemområden såsom ärr, grov hud, finnar eller pigmenteringar, men kan även användas som enstaka behandling för extra uppfräschning. För extra lyster avslutas behandlingar ofta med en ansiktsmask innehållande såväl vitaminer som antioxidanter.</p>`,
      },
    ],
  },
  {
    title: 'Vanliga frågor kring Klippning',
    path: '/faq-vanliga-fragor/kring-klippning',
    questions: [
      {
        id: 1,
        question: 'Vilka typer av behandlingar är vanligast?',
        answer: `<p>Det går alltid starka trender inom frisyrer. Vilken frisyr passar just dig? Det är alltid bra att förbereda ditt besök hos frisören med att titta på inspirationsbilder på nätet eller i tidningar för att sedan visa för frisören. Då är det enklare för dig att beskriva hur du vill ha det och enklare för frisören att förstå hur just du vill ha din frisyr.</p>`,
      },
      {
        id: 2,
        question: 'Klippning kort hår',
        answer: `<p>Klippning av kort hår den vanligaste tjänsten för män. Vilken teknik frisören använder sig av är väldigt olika. Vissa klipper enbart med sax medan andra använder sig av maskin. Prata med din frisör innan besöket om vilken metod just du vill ha.</p>`,
      },
      {
        id: 3,
        question: 'Klippning långt hår',
        answer: `<p>Det är mer tidskrävande att klippa långt hår och oftast kombineras det med en annan behandling såsom toning eller slingor. I år är trenden att klippa så att du inte ska behöva styla så mycket själv. Vi ser också att trenden går mot ett mer androgynt mode.</p>`,
      },
      {
        id: 4,
        question: 'Tvätta håret hos frisören',
        answer: `<p>Även om du redan har tvättat ditt hår hemma eller på gymmet så finns det inget skönare än att få håret tvättat hos frisören. Många frisörer erbjuder även en hårbottenmassage, vilket är helt underbart.</p>`,
      },
      {
        id: 5,
        question: 'Hur ofta ska jag klippa mig?',
        answer: `<p>Hur ofta du ska klippa dig beror på din håkvalitét och frisyr. En kort frisyr behöver i regel klippas ca var 3-8 vecka för att bibehålla önskad längd och form. Ett långt och friskt hår räcker att klippa var tredje månad medan ett torrt och slitet hår behöver klippas oftare. Rådgör med din frisör vad som passar just din hårtyp och frisyr.</p>`,
      },
      {
        id: 6,
        question: 'Hur ska jag välja frisör?',
        answer: `<p>De flesta av oss söker en frisör som förstår hur man vill ha sin frisyr, som man trivs bra med och som finns i närheten av ens bostad eller arbete. På bokadirekt.se kan du både hitta- och boka frisörer i närheten av dig och ta del av betyg och recensioner från tidigare kunder innan ditt besök.</p>`,
      },
      {
        id: 7,
        question: 'Hur ska jag behandla håret mellan besöken till frisören?',
        answer: `<p>För att bibehålla ett friskt hår mellan frisörbesöken gäller det att ge håret rätt förutsättningar. Använd ett schampo och balsam anpassat för din hårtyp, lägg en hårinpackning ett par gånger i månaden för extra fukt och använd skonsamma stylingverktyg. Prata med din frisör om du är osäker på vilka produkter som passar just dig.</p>`,
      },
      {
        id: 8,
        question: 'Vilka trender är hetast just nu?',
        answer: `<p>I linje med pandemiåret 2020 har hårtrenderna kantats av lättskötta frisyrer och lösa uppsättningar. Nu spås istället många nya spännande frisyrer bli populära. Lockar, lugg i olika former, vågade hårfärger och androgyna herrfrisyrer kommer vi få se mer av 2021. </p>`,
      },
    ],
  },
  {
    title: 'Vanliga frågor Kiropraktik',
    path: '/faq-vanliga-fragor/kiropraktik',
    questions: [
      {
        id: 1,
        question: 'Vad är Kiropraktik?',
        answer: `<p>Kiropraktisk behandling är en justerande behandlingsform som i första hand används för att behandla och förebygga problem kopplade till ryggraden. Problem kopplade till ryggen kan ge symptom som bl.a. spänningar/låsningar i axelpartiet, snedbelastning, ryggskott, ischias, nacksmärtor och ryggvärk. En kiropraktor arbetar för att återställa en normal funktion i patientens leder och muskler.
          <br /><br />
          Vid behandlingen undersöks ryggradens leder och muskler för att kunna diagnosticera spänningar och eventuella skador. Den klassiska behandlingen genomförs manuellt med händerna men även medicinsk akupunktur och stötvågsbehandling kan användas som komplement.
          <br /><br />
          Efter behandling ges oftast en anpassad rehabiliteringsplan för att patienten själv ska kunna arbeta förebyggande och långsiktigt med sina besvär. En kiropraktor kan även ge råd om kost, kosttillskott och vardagsmotion som kan ge ytterligare hjälp till bättre välmående.
        </p>`,
      },
      {
        id: 2,
        question: 'Används kiropraktisk behandling vid idrottsskador?',
        answer: `<p>Ja, kiropraktisk behandling kan även användas vid idrottsskador, både vid akuta skador såsom ryggskott eller vid mer långvariga belastningsskador som uppkommit av t.ex. en snedhet i ryggen. Kiropraktisk behandling kan även användas för att förebygga skador och höja därmed höja din prestationsnivå. Exempel på skador och problem som ofta behandlas med kiropraktik är tennisarmbåge, löparknä, gubbvad och muskelbristningar. </p>`,
      },
    ],
  },
  {
    title: 'Vanliga frågor kring massagebehandlingar',
    path: '/faq-vanliga-fragor/kring-massagebehandlingar',
    questions: [
      {
        id: 1,
        question: 'Varför ska du boka en massagetid?',
        answer: `<p>Det finns en rad fördelar med massage.
          <ul>
            <li>En väl genomförd massage lugnar ner nervsystemet och har en avslappnande effekt på kroppen. </li>
            <li>Massage minskar muskelspänningar och oro vilket i förlängningen till och med kan bidra till att motverka depressioner.</li>
            <li>Massage ökar kroppens blodcirkulation och syresätter kroppens celler</li>
          </ul>
        </p>`,
      },
      {
        id: 2,
        question: 'Vad är klassisk svensk massage?',
        answer: `<p>Den vanligaste massage formen i Sverige är den traditionella “Klassisk svensk massage”. Tekniken bygger på knådning av strykande av hud och muskler. Ibland används apparater i tekniken för att med vibrationer stimulera musklerna. Utöver de generella välgörande egenskaperna från massage så används Klassisk svensk massage i syfte att mjuka upp muskler, mot träningsvärk, för att motverka muskelknutor och för att öka lymfflödet.</p>`,
      },
      {
        id: 3,
        question: 'Vad är hot stone massage?',
        answer: `<p>Hot stone massage är en form av massage där massören använder varma stenar och aromaoljor som verktyg. Just värmen från lavastenarna bidrar till en avslappnande effekt och metoden lämpar sig bäst för avslappning och stressreducering. Mineraler från stenarna som t.ex. järn tas också upp av kroppen med välgörande effekt.</p>`,
      },
      {
        id: 4,
        question: 'Vad är taktil stimulering?',
        answer: `<p>Taktil massage är en varsammare typ av massageterapi där kroppens receptorer berörs med mindre kraft och lättare beröring. Metoden lämpar sig speciellt väl för stressdämpning och för att motverka stress.</p>`,
      },
      {
        id: 5,
        question: 'Vad är bindvävsmassage?',
        answer: `<p>Bindvävsmassage är en en metod för djupgående behandling av muskler. Det är en intensiv terapi som går på djupet för att bryta upp tidigare ärrvävnad  och frigöra bindväv. Rörelserna i bindvävsmassagen är långa och görs tradidionelt utan massageolja eller liknande i syfte att skapa mer friktion och värma upp musklerna.
          <br />
          Tekniken används normalt mer för att motverka muskelproblem och kan vara något mer smärtsam och mindre avslappnande än andra tekniker som mer syftar till avslappning.
        </p>`,
      },
      {
        id: 6,
        question: 'Vad är idrottsmassage?',
        answer: `<p>Idrottsmassage är en behandlande teknik som har sitt ursprung i idrottens värld. Behandlingsformen är utvecklad för att hjälpa idrottares kroppar att återhämta sig. Många tekniker i idrottsmassagen är hämtade från den klassiska massagen men är mer behandlande mot t.ex. tennisarmbåge, benhinneinflamation, sträckningar, spänningshuvudvärk, musarm etc. och är inte av avslappnande karaktär.</p>`,
      },
      {
        id: 7,
        question: 'Vad är thaimassage?',
        answer: `<p>Denna massage, som föga förvånande, har sitt ursprung i Thailand är en uråldrig teknik som kombinerat delar av den klassiska akupressuren och yogan till en behandling. Behandlingsformen är därför bra för att förbättra kroppens rörlighet och även hållning. Traditionellt utförs tekniken på golvet på en matta och omfattar vanligtvis hela kroppen. Massören använder inte bara sina händer utan använder även armbågar, knän etc.
          <br /><br />
          Tekniken är både avslappnande och delvis terapeutisk. Många känner sig både avslappnade och piggare efter en behandling.
        </p>`,
      },
      {
        id: 8,
        question: 'Vad är akupressur?',
        answer: `<p>Akupressuren härstammar från den orientaliska medicinen och är av avslappnande karaktär. Ofta behåller patienten sina kläder under behandlingen och avstressande behandling genom tryck mot känsliga punkter runt nacken, huvudet och axlarna.</p>`,
      },
      {
        id: 9,
        question: 'Vad är gravidmassage?',
        answer: `<p>Gravidmassagen är framtagen för att lindra vanliga smärtor för den gravida kvinnan som problem med rygg, ländrygg och axlar. Behandlingen är både avslappnande och terapeutisk och görs på en bänk med hål för magen eller en för magen speciellt framtagen dyna. Man använder ofta också för gravidmassage anpassad massageolja fri från ämnen som foster kan vara känsliga mot.</p>`,
      },
      {
        id: 10,
        question: 'Vilka trender finns inom massage?',
        answer: `<p><strong>LPG eller Lipo-massage</strong>
          <br /><br />
          LPG massagen är en typ av massage som utförs med en LPG-maskin. Tekniken reducerar fettansamlingar i kroppen och ses därför som ett alternativ till fettsugning och andra kirurgiska ingrepp. Tekniken har också likheter med bindvävsmassagen då den är djupgående och den har även återfuktande egenskaper som gör huden mer elastisk. Under behandlingen tar patienten normalt på sig en typ av kroppsstrumpa som gör att LPG-maskinen glider bättre. Även om det kanske inte låter så så är behandlingen behaglig och lika avslappnande som en klassisk massage.
        </p>`,
      },
    ],
  },
  {
    title: 'Vanliga frågor kring olika behandling för naglar',
    path: '/faq-vanliga-fragor/kring-olika-behandling-for-naglar',
    questions: [
      {
        id: 1,
        question: 'Varför ska du boka en tid för nagelbehandling?',
        answer: `<p>
          <ul>
            <li>Välmående naglar ger vackra händer och ett välvårdat intryck.</li>
            <li>Låt någon ta hand om dina trötta händer och fötter och unna dig själv en avkopplande stund i vardagen. </li>
            <li>Oändliga valmöjligheter, material, form, färg och design, det finns en nagelbehandling för alla!</li>
          </ul>
        </p>`,
      },
      {
        id: 2,
        question: 'Vad är nagelförlängning?',
        answer: `<p>Med en nagelförlängning får dina naglar ett helt nytt utseende. Passar dig med svaga och sköra naglar som vill ha både längre och starkare naglar. Naglarna byggs upp med en tipp som sedan förstärks med material i form av akryl eller gelé. Akryl, som är det starkaste materialet appliceras med pulverteknik där akrylmaterialet härdar av syret i luften. Vid användning av gelé appliceras materialet på nageln och får sedan härda under en UV-lampa. Vid både teknikerna formas naglar till valfri form och målas sedan i valfri färg och design. En nagelförlängning ger ett bestående resultat i upp till 6 veckor.</p>`,
      },
      {
        id: 3,
        question: 'Vad är gellack/shellack?',
        answer: `<p>Gellack/Shellack är en blandning av nagellack och gelé och är en permanent lackning som passar dig med naturligt starka naglar. Lacket som finns i en mängd olika färger och nyanser appliceras på dina egna naglar och stelnar under en UV-ljuslampa. Behandlingen är skonsam och ger ett skyddande och hållbart resultat i upp till 4 veckor. </p>`,
      },
      {
        id: 4,
        question: 'Vad är pedikyr?',
        answer: `<p>En pedikyr innefattar såväl fotbad som en avslappnande underbensmassage och är den perfekta behandlingen för att pigga upp trötta fötter. Förhårdnader på fötterna avlägsnas och hälarna filas, naglarna klipps och filas till valfri längd och form. Pedikyren avslutas med lackning där ett permanent shellack kan användas för ett bestående resultat i upp till 6 veckor. Pedikyren kan även göras som en snabbare express behandling, perfekt för för dig på språng! </p>`,
      },
    ],
  },
];
