import Button, { ButtonProps } from '@/components/elements/forms/buttons/Button/Button';
import { classnames } from '@/helpers';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type AlertVariant = 'default' | 'success' | 'warning' | 'cation' | 'information' | 'neutral';

type AlertAction = Pick<ButtonProps, 'onClick' | 'title' | 'children'>;

type AlertProps = {
  leftSlot?: ReactNode;
  rightSlot?: ReactNode;
  middleSlot?: ReactNode;
  title?: string;
  body?: ReactNode | string;
  floating?: boolean;
  variant?: AlertVariant;
  action?: AlertAction;
  verticalAlign?: 'top' | 'center';
  asColumn?: boolean;
  className?: string;
};

const variantClasses = {
  default: 'bg-white text-black-900 shadow-md',
  success: 'bg-success-50 text-success-900',
  warning: 'bg-danger-50 text-danger-900',
  cation: 'bg-bg_notice text-fg_alwaysDark',
  information: 'bg-information-50 text-information-900',
  neutral: 'bg-bg_secondary_neutral text-fg_primary',
} as const;

const Alert = ({
  variant = 'default',
  floating = false,
  title,
  body,
  action,
  leftSlot,
  rightSlot,
  className,
  middleSlot,
  asColumn,
  verticalAlign = 'center',
}: AlertProps) => {
  return (
    <div
      className={classnames(
        twMerge('p-lg w-full rounded-sm', variantClasses[variant], floating && 'shadow-md', className),
      )}>
      <div
        className={classnames(
          'flex',
          verticalAlign === 'top' || asColumn ? 'items-start' : 'items-center',
          asColumn ? 'space-y-sm flex-col' : 'space-x-md',
        )}>
        {leftSlot && <div className="min-w-[24px]">{leftSlot}</div>}
        {!middleSlot && (
          <div className="space-y-xxs flex flex-1 flex-col">
            {title && <p className="text-m font-semibold">{title}</p>}
            {body && <p className="text-m">{body}</p>}
          </div>
        )}
        {middleSlot && middleSlot}
        {rightSlot && <div className="min-w-[24px]">{rightSlot}</div>}
      </div>
      {action && (
        <div className="mt-xs flex justify-end">
          <Button {...action} variant="link" className="!text-current" />
        </div>
      )}
    </div>
  );
};

export default Alert;
