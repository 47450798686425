import AddCardForm from '@/components/templates/adyen/AddCardForm';
import useAddCard from '@/hooks/adyen/useAddCard';
import { AdyenCheckoutConfiguration } from '@/types/adyen';
import '@adyen/adyen-web/dist/adyen.css';

type CardCheckoutProps = Omit<AdyenCheckoutConfiguration, 'type'> & {
  detachedSubmit?: boolean;
  inModal?: boolean;
  submitLabel?: string;
  disclaimerLabel?: string;
};

const AddCard = ({
  onAuthorize,
  onError,
  onMounted,
  onChange,
  inModal = false,
  submitLabel = '',
  disclaimerLabel,
  detachedSubmit = false,
  amount,
  guestId,
  isGiftcardCheckout = false,
}: CardCheckoutProps) => {
  const { checkout, paymentContainer, error, handleSubmit, sessionState, loading } = useAddCard({
    onAuthorize,
    onChange,
    onMounted,
    onError,
    amount,
    guestId,
    isGiftcardCheckout,
  });

  return (
    <AddCardForm
      checkout={checkout}
      detachedSubmit={detachedSubmit}
      inModal={inModal}
      submitLabel={submitLabel}
      disclaimerLabel={disclaimerLabel}
      handleSubmit={handleSubmit}
      error={error}
      loading={loading}
      sessionState={sessionState}
      paymentContainer={paymentContainer}
    />
  );
};

export default AddCard;
