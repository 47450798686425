import { blog, exportIdSlug, promiseWrapper } from '@/helpers';
import { useEffect, useState } from 'react';

/**
 * Check that slug of ssr loaded page matches the current slug
 */
const checkSlug = (ssrSlug: string, currentSlug: string) => {
  return ssrSlug === currentSlug;
};

const useBlogPost = (ssrPost, slugId) => {
  const { id, slug } = exportIdSlug(slugId);
  const ssrLoadedPost = ssrPost && checkSlug(ssrPost?.data?.slug, slug) ? ssrPost : null;

  const [post, setPost] = useState(ssrLoadedPost?.data ?? null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (ssrLoadedPost || ssrPost?.error) return;

    (async () => {
      const { data, error } = await promiseWrapper(blog.getPost(id, slug));
      if (error) {
        setError(true);
        return;
      }
      setPost(data);
    })();
  }, []);

  if (ssrLoadedPost || ssrPost?.error) {
    return { post, error: ssrPost?.error ?? false };
  }

  return { post, error };
};

export default useBlogPost;
