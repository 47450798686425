import { useReducer } from 'react';
import { DateTimePickerAction, DateTimePickerManagerProps, DateTimePickerState } from './DateTimePicker.types';

function dateTimePickerReducer(state: DateTimePickerState, action: DateTimePickerAction): DateTimePickerState {
  switch (action.type) {
    case 'ON_INPUT_CHANGE': {
      const { startDate, endDate, timeOfDay } = action.payload;
      return { ...state, startDate, endDate, timeOfDay };
    }
    case 'ON_OPEN_CALENDAR': {
      return { ...state, showCalendar: true };
    }
    case 'ON_CLOSE_CALENDAR': {
      return { ...state, showCalendar: false };
    }
    case 'ON_CLEAR_DATE_TIME': {
      return { ...state, startDate: undefined, endDate: undefined, timeOfDay: undefined };
    }
    default:
      return state;
  }
}

const useDateTimePickerManager = (props: DateTimePickerManagerProps) => {
  const [state, dispatch] = useReducer(dateTimePickerReducer, props.initialState);

  const handleOnCloseCalendar = () => dispatch({ type: 'ON_CLOSE_CALENDAR' });
  const handleOpenCalendar = () => dispatch({ type: 'ON_OPEN_CALENDAR' });

  const handleOnChangeDateTime = (values: Pick<DateTimePickerState, 'startDate' | 'endDate' | 'timeOfDay'>) => {
    dispatch({ type: 'ON_INPUT_CHANGE', payload: values });
  };

  const handleOnClearDateTime = () => {
    dispatch({ type: 'ON_CLEAR_DATE_TIME' });
    props.onClearDateTime();
  };

  const handleOnDateTimeSearch = () => {
    props.onUpdateDateTime({ startDate: state.startDate, endDate: state.endDate, timeOfDay: state.timeOfDay });
  };

  return {
    startDate: state.startDate,
    endDate: state.endDate,
    timeOfDay: state.timeOfDay,
    showCalendar: state.showCalendar,
    onCloseCalendar: handleOnCloseCalendar,
    onOpenCalendar: handleOpenCalendar,
    onClearDateTime: handleOnClearDateTime,
    onChangeDateTime: handleOnChangeDateTime,
    onDateTimeSearch: handleOnDateTimeSearch,
  };
};

export default useDateTimePickerManager;
