import Chip from '@/components/elements/Chip';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { getGeoLocation } from '@/helpers';
import { SearchManagerOutput } from '@/hooks/search/useSearch';
import { DEFAULT_MY_LOCATION_KEYWORD } from '@/hooks/search/useSearch.constants';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { SortOption } from '@/types/state/search';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Filter from '../components/Filter/Filter';
import { getSelectedFilters } from '../components/Filter/Filter.helpers';
import useFilterManager from '../components/Filter/Filter.hooks';
import { FilterOption } from '../components/Filter/Filter.types';
import Sorting from '../components/Sorting/Sorting';
import useSortingManager from '../components/Sorting/Sorting.hooks';

type SearchControlProps = SearchManagerOutput & {};

const baseTranslationKey = 'features.searchV2.modules.SearchControls';

const SearchControls = (props: SearchControlProps) => {
  const { isMobileView } = useMobileView();
  const selectionOptions = getSelectedFilters(props.prefs);
  const [isLoadingGeoLocation, setIsLoadingGeoLocation] = useState<boolean>(false);

  const filterManager = useFilterManager({
    initialState: { prefs: props.prefs, showOptions: false },
    urlPrefs: props.prefs,
    applyOnToggle: !isMobileView,
    onChangeAppliedFilters: handleOnChangeAppliedFilters,
  });

  const sortingManager = useSortingManager({
    initialState: { sort: props.sort, showOptions: false },
    urlSorting: props.sort,
    onChangeSorting: handleOnChangeSorting,
    onClearSorting: handleClearSorting,
  });

  function handleClearSorting() {
    /**
     * If user clears sorting by distance (closest), we need to clear the lat and lon
     * in all cases where the user is not doing a "near me" location search
     */
    if (props.location !== DEFAULT_MY_LOCATION_KEYWORD) {
      props.performSearch({ sort: null, lat: undefined, lon: undefined });
      return;
    }

    props.performSearch({ sort: null });
  }

  function handleOnChangeSorting(sort: SortOption) {
    /**
     *  if we are sorting by closest, we need to clear the location since we are not using it
     *  instead we want to sort by the closest location to the user position
     */
    if (sort === 'closest') {
      getGeoLocation(
        // on success handler
        (lat: string, lon: string) => {
          props.performSearch({ sort, lat: +lat, lon: +lon });
          setIsLoadingGeoLocation(false);
        },
        () => {},
        // on loading handler
        () => {
          setIsLoadingGeoLocation(true);
        },
        // on error handler
        (error) => {
          setIsLoadingGeoLocation(false);
          toast(
            ({ closeToast }) => (
              <Snackbar onClose={closeToast} label={_s(`${baseTranslationKey}.snackbar.error.userLocationDenied`)} />
            ),
            { autoClose: false },
          );
        },
      );
    } else {
      props.performSearch({ sort, lat: undefined, lon: undefined });
    }
  }

  function handleOnChangeAppliedFilters(prefs: string) {
    props.performSearch({ prefs });
  }

  function handleOnRemoveSingleFilter(filterId: FilterOption['id']) {
    return (event) => {
      event?.stopPropagation();
      event?.preventDefault();
      const selectedOptions = getSelectedFilters(props.prefs);
      const newOptions = selectedOptions.filter((option) => option.id !== filterId).map((option) => option.id);
      props.performSearch({ prefs: newOptions.join(',') });
    };
  }

  return (
    <ul className="space-x-sm !relative z-10 flex">
      <li>
        <Sorting {...sortingManager} loading={isLoadingGeoLocation} />
      </li>
      <li>
        <Filter {...filterManager} />
      </li>
      {!isMobileView && selectionOptions.length > 0 && (
        <li>
          <ul className="gap-sm no-scrollbar flex overflow-x-scroll rounded-sm">
            {selectionOptions.map((selectedOption) => (
              <li key={selectedOption.id} className="flex-shrink-0">
                <Chip label={selectedOption.label} onClose={handleOnRemoveSingleFilter(selectedOption.id)} />
              </li>
            ))}
          </ul>
        </li>
      )}
    </ul>
  );
};

export default SearchControls;
