import { Button } from '@/components/elements/forms/buttons';
import Alert from '@/components/elements/notifications/Alert/Alert';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import TextArea from '@/components/elements/TextArea';
import Icon from '@/components/icons/Icon';
import Modal from '@/components/modules/modals/redesign/Modal/Modal';
import { promiseWrapper } from '@/helpers';
import { useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import { __, _s } from '@/locale';
import { reviewsServices } from '@/services';
import { handleLoginClick } from '@/services/navigationServices';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const baseTranslationKey = 'features.ProfilePages.modules.ReviewModal';

const ReviewModal = ({
  onClose,
  onChangeReviews,
  _review,
  placeId,
}: {
  onClose: () => void;
  onChangeReviews?: () => void;
  placeId: string;
  _review?: { id: string; content?: string; score: number };
}) => {
  const { isMobileView } = useMobileView();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [hoveredStar, setHoveredStar] = useState<number | null>(null);
  const [selectedStars, setSelectedStars] = useState<number | null>(_review?.score || null);
  const [review, setReview] = useState<string>(_review?.content || '');
  const user = useAppSelector((state) => state.users?.user);
  const isChangingExistingReview = Boolean(_review?.id);
  const dispatch = useDispatch();

  const handleMouseEnter = (index: number) => {
    setHoveredStar(index);
  };

  const handleMouseLeave = () => {
    setHoveredStar(null);
  };

  const handleDeleteReview = async () => {
    setDeleteLoading(true);
    const { error } = await promiseWrapper(reviewsServices.deleteExternal(_review?.id));

    if (!error) {
      onClose();
      toast(({ closeToast }) => (
        <Snackbar type="success" label={_s(`${baseTranslationKey}.alert.deleted.success`)} onClose={closeToast} />
      ));
      onChangeReviews && onChangeReviews();
    } else {
      toast(({ closeToast }) => (
        <Snackbar type="danger" label={_s(`${baseTranslationKey}.alert.deleted.error`)} onClose={closeToast} />
      ));
    }

    setDeleteLoading(false);
  };

  const handleAddReview = async () => {
    setLoading(true);
    const { error } = await promiseWrapper(
      reviewsServices.saveExternal({
        placeId: parseInt(placeId),
        stars: selectedStars,
        message: review,
      }),
    );

    if (!error) {
      onClose();
      toast(({ closeToast }) => (
        <Snackbar type="success" label={_s(`${baseTranslationKey}.alert.saved.success`)} onClose={closeToast} />
      ));
      onChangeReviews && onChangeReviews();
    } else {
      toast(({ closeToast }) => (
        <Snackbar
          type="danger"
          label={
            error.message === 'max_allowed_reviews'
              ? _s(`${baseTranslationKey}.alert.saved.maxAllowedError`)
              : _s(`${baseTranslationKey}.alert.saved.success`)
          }
          onClose={closeToast}
        />
      ));
    }

    setLoading(false);
  };

  const handleChangeReview = async () => {
    setLoading(true);
    const { error } = await promiseWrapper(
      reviewsServices.updateExternal({
        placeId: parseInt(placeId),
        stars: selectedStars,
        message: review,
        reviewId: parseInt(_review?.id),
      }),
    );

    if (!error) {
      onClose();
      toast(({ closeToast }) => (
        <Snackbar type="success" label={_s(`${baseTranslationKey}.alert.edit.success`)} onClose={closeToast} />
      ));
      onChangeReviews && onChangeReviews();
    } else {
      toast(({ closeToast }) => (
        <Snackbar type="danger" label={_s(`${baseTranslationKey}.alert.edit.error`)} onClose={closeToast} />
      ));
    }

    setLoading(false);
  };

  const unHappyPlaceholder = __(`${baseTranslationKey}.placeholder.unhappy`);
  const happyPlaceholder = __(`${baseTranslationKey}.placeholder.happy`);
  const neutralPlaceHolder = __(`${baseTranslationKey}.placeholder.neutral`);

  const getPlaceHolder = () => {
    switch (selectedStars) {
      case 1:
      case 2:
        return unHappyPlaceholder;
      case 3:
        return neutralPlaceHolder;
      case 4:
      case 5:
        return happyPlaceholder;
      default:
        return neutralPlaceHolder;
    }
  };

  return (
    <Modal className={`${isMobileView ? 'h-screen' : 'md:max-w-[560px]'}`} isOpen onRequestClose={onClose}>
      <div className={`${isMobileView ? 'flex h-full flex-col' : ''}`}>
        <Modal.NewHeader
          onClose={onClose}
          floating={!isMobileView}
          title={
            isChangingExistingReview ? __(`${baseTranslationKey}.title.edit`) : __(`${baseTranslationKey}.title.add`)
          }
        />
        <div className={`p-xl gap-xl bg-surface_l4 flex flex-col ${isMobileView ? 'flex-1' : ''}`}>
          <div className="gap-xs flex flex-col">
            <h2 className="text-h-s">{_s(`${baseTranslationKey}.subTitle`)}</h2>
            <p className="text-m text-fg_secondary">{_s(`${baseTranslationKey}.body`)}</p>
          </div>
          <div className="flex items-center justify-between">
            <h3 className="text-l">{_s(`${baseTranslationKey}.label`)}</h3>
            <div className="gap-xs flex">
              {[1, 2, 3, 4, 5].map((index) => (
                <button className="outline-none" type="button" key={index} onClick={() => setSelectedStars(index)}>
                  <Icon
                    key={index}
                    variant="star-filled"
                    color={
                      (hoveredStar && index <= hoveredStar) || (index <= selectedStars && !hoveredStar)
                        ? 'bg_accent_5'
                        : 'fg_disabled'
                    }
                    size="md"
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  />
                </button>
              ))}
            </div>
          </div>
          <TextArea
            onChange={(e) => setReview(e.target.value)}
            disabled={!Boolean(selectedStars)}
            placeholder={getPlaceHolder()}
            value={review}
          />
          {Boolean(user?.id) ? (
            <Alert
              variant="information"
              leftSlot={<Icon color="fg_info_bold" variant="info-circle" />}
              body={_s(`${baseTranslationKey}.alert.info`)}
            />
          ) : (
            <Alert
              variant="cation"
              leftSlot={<Icon color="fg_notice_bold" variant="login" />}
              middleSlot={
                <div className="text-m text-fg_alwaysDark">
                  <span>{_s(`${baseTranslationKey}.alert.login.1`)}</span>{' '}
                  <button onClick={() => handleLoginClick(dispatch)} className="text-fg_link underline outline-none">
                    {_s(`${baseTranslationKey}.alert.login.cta`)}
                  </button>{' '}
                  <span>{_s(`${baseTranslationKey}.alert.login.2`)}</span>
                </div>
              }
            />
          )}
        </div>
        <div
          className={`bg-surface_l4 p-md border-border_regular flex justify-between border-t ${
            isMobileView ? '' : 'rounded-b-lg'
          }`}>
          <Button label={__(`${baseTranslationKey}.ctaClose`)} variant="link" size="md" onClick={onClose} />

          <div className="gap-md flex">
            {isChangingExistingReview && (
              <Button
                label={__(`${baseTranslationKey}.ctaDelete`)}
                size="md"
                disabled={loading}
                loading={deleteLoading}
                variant="destructiveBold"
                leftIcon={<Icon variant="trash" color="fg_alwaysLight" style={{ width: '20px', height: '20px' }} />}
                onClick={handleDeleteReview}
              />
            )}
            <Button
              label={
                isChangingExistingReview ? __(`${baseTranslationKey}.ctaEdit`) : __(`${baseTranslationKey}.ctaAdd`)
              }
              size="md"
              loading={loading}
              leftIcon={
                !isChangingExistingReview &&
                Boolean(selectedStars) && (
                  <Icon variant="check" color="fg_primary_inverse" style={{ width: '20px', height: '20px' }} />
                )
              }
              disabled={!Boolean(selectedStars) || !Boolean(user?.id) || deleteLoading}
              onClick={() => (isChangingExistingReview ? handleChangeReview() : handleAddReview())}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReviewModal;
