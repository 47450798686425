import { SortOption } from '@/types/state/search';
import { useState } from 'react';
import { SortingManagerProps } from './Sorting.types';

const useSortingManager = ({ initialState, urlSorting, onChangeSorting, onClearSorting }: SortingManagerProps) => {
  const [sort, setSort] = useState<SortOption>(initialState.sort);
  const [showOptions, setShowOptions] = useState<boolean>(initialState.showOptions);

  const handleShowOptions = (show: boolean) => {
    if (show) {
      setSort(urlSorting);
    }
    setShowOptions(show);
  };

  const handleOnChangeSorting = (option: SortOption) => {
    setSort(option);
    onChangeSorting(option);
    handleShowOptions(false);
  };

  return {
    sort,
    urlSorting: urlSorting,
    showOptions,
    onClearSorting,
    onShowOptions: handleShowOptions,
    onChangeSorting: handleOnChangeSorting,
  };
};

export default useSortingManager;
