import { customTwMerge } from '@/helpers/theme';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import Label, { LabelProps } from '../../Label/Label';

export type ListItemContentProps = {
  titleClassName?: string;
  subTitleClassName?: string;
  contentClassName?: string;
  label?: LabelProps;
  badge?: ReactNode;
} & (
  | { title: string | ReactNode; subTitle?: string | ReactNode; content?: string | ReactNode }
  | { title?: string | ReactNode; subTitle: string | ReactNode; content?: string | ReactNode }
  | { title?: string | ReactNode; subTitle?: string | ReactNode; content: string | ReactNode }
);

const ListItemContent = ({
  title,
  subTitle,
  content,
  badge,
  label,
  titleClassName,
  subTitleClassName,
  contentClassName,
}: ListItemContentProps) => {
  return (
    <div className="space-x-sm flex w-full items-center justify-between">
      <div className="gap-xxs flex flex-col">
        {title && <p className={twMerge('text-fg_primary text-md', titleClassName)}>{title}</p>}
        {subTitle && <p className={twMerge('text-fg_secondary text-sm', subTitleClassName)}>{subTitle}</p>}
        {content && <p className={customTwMerge('text-fg_secondary text-m', contentClassName)}>{content}</p>}
      </div>
      {(badge || label) && (
        <span>
          {badge && badge}
          {label && <Label {...label} />}
        </span>
      )}
    </div>
  );
};

export default ListItemContent;
