import Header from '@/components/elements/Header/Header';
import { LoadingIcon } from '@/components/icons';
import PickPaymentMethod from '@/components/modules/checkout/PickPaymentMethod/PickPaymentMethod';
import { COUPON_PAYMENT_METHOD } from '@/constants/checkout';
import { _s } from '@/locale';
import { TriggerSource } from '@/types/analytics';
import { CheckoutSummary, CouponMethod, SelectedPaymentMethod } from '@/types/checkout';
import { UseCheckoutModalManagerResult } from '../CheckoutModal/CheckoutModal.hooks';

export type PromotedPaymentMethodsProps = {
  summary: CheckoutSummary;
  selectedPaymentMethod: SelectedPaymentMethod;
  onChangePaymentMethod: (method: SelectedPaymentMethod | CouponMethod) => void;
  useHighlighted?: boolean;
  error?: boolean;
  loading?: boolean;
} & Omit<UseCheckoutModalManagerResult, 'onModalClose'> & { triggerSource: TriggerSource };

const PromotedPaymentMethods = ({
  summary,
  selectedPaymentMethod,
  triggerSource,
  onChangePaymentMethod,
  onModalShow,
  useHighlighted = false,
  error = false,
  loading = false,
}: PromotedPaymentMethodsProps) => {
  const handleChangePaymentMethod = (method: SelectedPaymentMethod | CouponMethod) => {
    if (method.type === COUPON_PAYMENT_METHOD.GIFTCARD) {
      onModalShow('applyGiftcard');
      return;
    }
    if (method.type === COUPON_PAYMENT_METHOD.VALUECARD) {
      onModalShow('applyValuecard');
      return;
    }
    if (method.type === COUPON_PAYMENT_METHOD.WELLNESSCARD) {
      onModalShow('applyWellnesscard');
      return;
    }

    onChangePaymentMethod(method);
  };

  return (
    <div>
      <div className="pl-lg py-md">
        <Header size="md" label={_s('choosePaymentMethod')} />
      </div>
      {error && (
        <p className="text-danger-500 ml-lg py-xxs text-xxs border-danger-500 border-t">
          {_s(`components.templates.checkout.SelectPaymentOptions.error`)}
        </p>
      )}
      {loading ? (
        <LoadingIcon style={{ margin: 'auto', width: 40 }} />
      ) : (
        <PickPaymentMethod
          triggerSource={triggerSource}
          onPaymentSelected={handleChangePaymentMethod}
          summary={summary}
          selectedPaymentMethod={selectedPaymentMethod}
          useRadioButtons={true}
          methodsVisible={3}
          useHighlighted={useHighlighted}
        />
      )}
    </div>
  );
};

export default PromotedPaymentMethods;
