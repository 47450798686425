import Icon from '@/components/icons/Icon';
import { classnames } from '@/helpers';
import { customTwMerge } from '@/helpers/theme';
import { _s } from '@/locale';
import { getSvgPath } from 'figma-squircle';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

type CardSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type CardProps = {
  children?: React.ReactNode;
  bottom?: boolean;
  closable?: boolean;
  className?: string;
  onClickOutside?: () => void;
  childClassName?: string;
  size?: CardSize;
};

const getCardStyle = ({ width, height, bottom }: { width: number; height: number; bottom: boolean }) => {
  const path = getSvgPath({
    width,
    height,
    ...(bottom ? { topLeftCornerRadius: 16, topRightCornerRadius: 16 } : { cornerRadius: 16 }),
    cornerSmoothing: 1,
  });
  return { width, height, clipPath: `path('${path}')` };
};

const getVariant = (size: CardSize, closable: boolean, bottom: boolean) => {
  const variant = {
    xs: `px-xs ${!bottom ? 'pt-xs' : ''} ${closable ? 'pb-xs' : 'py-xs'}`,
    sm: `px-md ${!bottom ? 'pt-md' : ''} ${closable ? 'pb-md' : 'py-md'}`,
    md: `px-lg ${!bottom ? 'pt-lg' : ''} ${closable ? 'pb-lg' : 'py-lg'}`,
    lg: `px-xl ${!bottom ? 'pt-xl' : ''} ${closable ? 'pb-xl' : 'py-xl'}`,
    xl: `px-3xl ${!bottom ? 'pt-3xl' : ''} ${closable ? 'pb-3xl' : 'py-3xl'}`,
  };

  return variant[size];
};

const Card = ({
  children,
  bottom,
  className,
  closable = true,
  onClickOutside,
  size = 'md',
  childClassName,
}: CardProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [minimized, setMinimized] = useState(false);
  const contentId = useRef<string>(uuidv4());

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClickOutside = (e: any) => {
    if (!cardRef?.current?.contains(e?.target)) {
      if (onClickOutside) {
        onClickOutside();
      }
    }
  };

  const handleClick = () => {
    setMinimized(!minimized);
  };

  return (
    <div className={classnames('w-full overflow-hidden drop-shadow-sm', className ?? '')}>
      <div
        ref={cardRef}
        style={{ ...(minimized ? { height: 60 } : { height: 'auto' }) }}
        className={`bg-surface_l2 ${bottom ? 'rounded-t-md' : 'rounded-md'}`}>
        {bottom && closable && (
          <button
            onClick={handleClick}
            className="p-lg m-auto flex w-full justify-center"
            aria-expanded={!minimized}
            aria-controls={contentId.current}
            aria-label={_s('close')}>
            {!minimized && <Icon size="sm" variant="chevron-down"></Icon>}
            {minimized && <Icon size="sm" variant="chevron-up"></Icon>}
          </button>
        )}
        <div
          id={contentId.current}
          hidden={minimized}
          className={customTwMerge(
            `flex w-full flex-col justify-center`,
            getVariant(size, closable, bottom),
            childClassName,
          )}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Card;
