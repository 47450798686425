import { ChevronIcon } from '@/components/icons';
import { isMobile, isServiceBookable, scrollAccordionContentInView, startOfDay } from '@/helpers';
import { themed } from '@/helpers/theme';
import { __ } from '@/locale';
import React from 'react';
import Campaign from './Campaign';

export default class Campaigns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.isOpen ?? true,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ expanded: this.props.isOpen });
    }
  }

  getBookableServices(campaign, place) {
    if (!(place && place.services)) {
      return null;
    }

    if (!(campaign && campaign.services && campaign.services.length > 0)) {
      return null;
    }

    const services = {};
    const allCategories = place.services;

    if (campaign.services) {
      campaign.services.forEach((campaignService) => {
        for (let i = 0; i < allCategories.length; i++) {
          const categoryServices = allCategories[i].services;
          let found = false;
          for (let j = 0; j < categoryServices.length; j++) {
            const item = categoryServices[j];
            if (item.id === campaignService.id) {
              if (isServiceBookable(item, place)) {
                services[item.id] = item;
              }
              found = true;
              break;
            }
          }

          if (found) break;
        }
      });
    }

    if (campaign.type === 6 || Object.keys(services).length === campaign.services.length) {
      return services;
    }

    return null;
  }

  handleCollapse = (e) => {
    if (!this.state.expanded) {
      this.setState({ expanded: !this.state.expanded }, () => scrollAccordionContentInView(e.target));
    } else {
      this.setState({ expanded: !this.state.expanded });
    }
  };

  render() {
    const { place, selectedServices = [], fromEmployee, source, append } = this.props;
    let { date } = this.props;

    if (!(place && place.campaigns && place.campaigns.length)) {
      return null;
    }

    if (!date) {
      date = startOfDay();
    }

    const campaigns = [];
    for (let i = 0; i < place.campaigns.length; i++) {
      const campaign = place.campaigns[i];
      const campaignServices = this.getBookableServices(campaign, place);

      if (!campaignServices) continue;

      const campaignServicesIds = Object.keys(campaignServices);
      const selectedServicesIds = (selectedServices || []).map((service) => service.id);

      // add campaign if the campaign is valid for the selected date
      if (date >= campaign.startDate * 1000 && date <= campaign.endDate * 1000) {
        // show only discount campaigns for now
        if (campaign.type === 6) {
          campaignServicesIds.forEach((id) => {
            const serviceId = parseInt(id);
            // add campaign if the service is not already selected and
            // if there is no employee selected or
            // the selected employee performs the service
            if (
              selectedServicesIds.indexOf(serviceId) === -1 &&
              (!fromEmployee ||
                (fromEmployee && fromEmployee.services && fromEmployee.services.indexOf(serviceId) >= 0))
            ) {
              const campaignService = {};
              campaignService[id] = campaignServices[id];

              campaigns.push(
                <Campaign
                  place={place}
                  campaign={campaign}
                  campaignServices={campaignService}
                  fromEmployee={fromEmployee}
                  append={append}
                  key={i + '_' + id}
                  source={source || 'company_details'}
                  hidePopupCallback={this.props.hidePopupCallback}
                />,
              );
            }
          });
        } else {
          // package campaigns here
          // campaignServicesIds.every(id => fromEmployee.services.indexOf(parseInt(id)) >= 0)
        }
      }
    }

    if (!campaigns.length) {
      return null;
    }
    const { expanded } = this.state;
    const collapseClass = expanded ? 'block' : 'hidden';

    return (
      <div className={`item ${expanded && !isMobile() ? 'mb-4' : ''}`}>
        <div
          onClick={this.handleCollapse}
          className={`mb-[2px] flex cursor-pointer items-center justify-between p-3 ${themed('bg-black-50', '')}`}>
          <h3 className="font-semibold">{__('campaigns')}</h3>
          <span
            className={`flex items-center justify-center rounded-full p-2 ${
              expanded ? 'bg-[#ADB4C4]' : themed('bg-primary', 'bg-sm_primary')
            }`}>
            <ChevronIcon className={`h-[8px] w-[8px] text-white ${expanded ? 'rotate-180' : ''}`} />
          </span>
        </div>

        <div className={'accordion-content ' + collapseClass} role="tabpanel">
          <ul>{campaigns}</ul>
        </div>
      </div>
    );
  }
}
