import CountBadge from '@/components/elements/CountBadge/CountBadge';
import { CloseIconThin, DropdownIcon } from '@/components/icons';
import { classnames } from '@/helpers';
import { HTMLAttributes } from 'react';

type GenericButtonProps = {
  variant?: 'primary' | 'secondary' | 'tertiary';
  large?: boolean;
  block?: boolean;
  label?: string;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
};

type DropdownProps = HTMLAttributes<HTMLButtonElement> &
  GenericButtonProps & {
    toggleOpen: () => void;
    close?: () => void;
    isActive: boolean;
    isToggleOpen?: boolean;
    newDesign?: boolean;
    activeBadge?: number;
  };

export const DropdownButton = ({
  className = '',
  children = null,
  icon = null,
  isActive = false,
  toggleOpen = null,
  close = null,
  newDesign = false,
  isToggleOpen = false,
  activeBadge = 0,
  ...props
}: DropdownProps) => {
  const classNameString = !newDesign
    ? classnames(`
    ${className} bg-black-100 relative flex h-[30px] items-center justify-center rounded-lg px-4 text-m outline-none
    ${isActive ? '!bg-primary-50 shadow-sm' : ''}
    ${close ? 'pr-8' : ''}
  `)
    : classnames(`
    ${className} relative flex h-[30px] items-center justify-start px-4 text-sm outline-none
    border-[1px] pr-8
    ${isToggleOpen ? 'border-black-300 rounded-t-lg w-[20rem]' : 'rounded-lg'}
  `);

  return (
    <button {...(classNameString ? { className: classNameString } : {})} {...props}>
      <div onClick={toggleOpen} className="absolute inset-0"></div>
      {icon && (
        <span className={`flex flex-shrink-0 items-center justify-center ${children ? 'mr-2' : ''} h-5 w-5`}>
          {icon}
        </span>
      )}
      {children}
      {toggleOpen && (close || newDesign || activeBadge) && (
        <>
          {isActive && close ? (
            <CloseIconThin onClick={close} className="absolute right-1 h-6 w-6 p-2" />
          ) : isActive && activeBadge ? (
            <span className="absolute right-3 top-1/2 -translate-y-1/2">
              <CountBadge count={activeBadge} handleClick={toggleOpen} />
            </span>
          ) : (
            <DropdownIcon onClick={toggleOpen} className="absolute right-3 top-1/2 h-2 w-2 -translate-y-1/2" />
          )}
        </>
      )}
    </button>
  );
};
