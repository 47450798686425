import Card from '@/components/elements/Card/Card';
import { Button, Fab, LinkButton } from '@/components/elements/forms/buttons';
import Alert from '@/components/elements/notifications/Alert/Alert';
import Rating from '@/components/elements/redesign/Rating/Rating';
import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import Breadcrumbs from '@/components/modules/breadcrumbs/Breadcrumbs';
import EmptyState from '@/features/ProfilePages/components/EmptyState';
import { getDetailsBreadcrumbs, hasFeatureSetting, limitString, promiseWrapper, server, url } from '@/helpers';
import { getBreadcrumbsSchema, getLocalBusinessSchema } from '@/helpers/jsonld';
import { useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import { MAPTILER_FEATURE_FLAG } from '@/constants/experimentConstants';
import { useGetAmplitudeExperimentVariant } from '@/hooks/useAmplitudeExperiment';
import { __, _s } from '@/locale';
import { PLACE_IMPRESSION, placeService } from '@/services';
import { getPopularAndSimilar } from '@/services/searchService';
import { Place } from '@/types/state/shared';
import * as Sentry from '@sentry/react';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import Address from './components/Address';
import Companies from './components/Companies';
import Contact from './components/Contact';
import Links from './components/Links';
import OpeningHours from './components/OpeningHours';
import Photos from './components/Photos';
import PhotoSlider from './components/PhotoSlider';
import PlaceDetails from './components/PlaceDetails';
import PlaceInfo from './components/PlaceInfo';
import Services from './components/Services';
import VerifyCompany from './components/VerifyCompany';
import { formatPhoneNumber, getBreadcrumbItems, getOpenHoursStrings } from './helpers/company';
import { default as UseReviews } from './hooks/UseReviews';

const baseTranslationKey = 'features.ProfilePages';

type ProfilePageProps = {
  place: Place;
};

type OtherPlaces = {
  id: number;
  name: string;
  address: { street?: string; city?: string };
  rating: number;
};

type PlaceService = {
  categoryName: string;
  services: {
    name: string;
    description?: string;
    length?: number;
    price?: number;
    externalUrl?: string;
    discount?: number;
  }[];
};

const seo = (place) => {
  if (!place) return null;
  const serviceNames = place.services?.length
    ? place.services.flatMap((category) => category.services?.map((s) => s.name) || [])
    : [];
  const uniqueServiceNames = serviceNames.filter((item, pos) => item !== false && serviceNames.indexOf(item) === pos);
  const title = limitString(
    `${place.about.name}${place.contact?.address?.city ? ', ' + place.contact.address.city : ''}${
      place.contact?.address?.district ? ', ' + place.contact.address.district : ''
    } - Bokadirekt`,
    60,
  );

  const description = limitString(place.about.description, 155);
  const keywords = `${place.about.name}, ${place.contact.address.city}, ${uniqueServiceNames.join(', ')}`;
  const placeUrl = `${url.getBaseUrl()}profile-places/${place.about.slug}-${place.id}`;
  const image = place.about?.profileImage ? <meta property="og:image" content={place.about.profileImage} /> : null;
  const images = place.about?.images?.length
    ? place.about.images.map((imageUrl, i) => <meta key={i} property="og:image" content={imageUrl} />)
    : null;
  const streetAddress = `${place.contact.address.street || ''}, ${place.contact.address.city || ''}`;
  const isNoIndex = hasFeatureSetting(place, 'seo_noindex') || hasFeatureSetting(place, 'seo_noindex_portal');

  const breadcrumbs = getDetailsBreadcrumbs(place).map((item) => ({
    ...item,
    url: `${url.getBaseUrl()}${(item.url ?? '').slice(1)}`,
  }));

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={placeUrl} />
      <meta property="og:url" content={placeUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Bokadirekt" />
      <meta property="og:locale" content="sv_SE" />
      {image}
      {images}
      <meta name="og:type" content="business.business" />
      <meta name="business:contact_data:street_address" content={streetAddress} />
      <meta name="business:contact_data:postal_code" content={place.contact.address.zipcode || ''} />
      <meta name="business:contact_data:locality" content={place.contact.address.city || ''} />
      <meta name="business:contact_data:country_name" content="Sverige" />
      <meta name="place:location:latitude" content={place.contact.position.lat || ''} />
      <meta name="place:location:longitude" content={place.contact.position.lon || ''} />
      {place.contact.phone && <meta name="business:contact_data:phone_number" content={place.contact.phone || ''} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@bokadirekt" />
      <meta name="robots" content={isNoIndex ? 'noindex, follow' : 'index, follow'} />
      <script type="application/ld+json">{getLocalBusinessSchema(place)}</script>
      <script type="application/ld+json">{getBreadcrumbsSchema(breadcrumbs)}</script>
    </Helmet>
  );
};

const ProfilePage = ({ place }: ProfilePageProps) => {
  const { isMobileView } = useMobileView();
  const history = useHistory();
  const location = useLocation();
  const tooltipTargetRef = useRef(null);
  const user = useAppSelector((state) => state.users?.user);
  const {
    render: ReviewsComponent,
    getTotalReviews,
    rating,
  } = UseReviews({ placeId: place.id.toString(), tooltipTargetRef: tooltipTargetRef });
  const [showFullPhoneNumber, setShowFullPhoneNumber] = useState(false);
  const reviewRef = useRef(null);
  const serviesRef = useRef(null);
  const serviceCategory = place.about?.category?.main ?? place.about?.category?.sub;
  const companyPhoneNumber = place.contact?.phone ? formatPhoneNumber(place.contact.phone) : undefined;
  const isVerified = place.isVerifiedExternalPlace;
  const PLACE_YEAR = undefined; // TODO change to correct year when implemented in M3
  const companyEmail = place.contact?.email;
  const isPremium = place.about?.settings?.subscriptionType === 'profilepagepremium';
  const isBasic = false; // TODO change to correct type when implemented in M3
  const [lastVisitedPlaces, setLastVisitedPlaces] = useState<
    {
      id: number;
      name: string;
      address: { street?: string; city?: string };
      rating: number;
    }[]
  >([]);

  // experiment with maptiler
  const useMapTilerExperiment =
    useGetAmplitudeExperimentVariant()(MAPTILER_FEATURE_FLAG)?.value === 'on';

  const [popularPlaces, setPopularPlaces] = useState<OtherPlaces[]>([]);
  const [similarPlaces, setSimilarPlaces] = useState<OtherPlaces[]>([]);
  const profilePageServices: PlaceService[] = place.services
    ? place.services.map((service) => {
        return {
          categoryName: service.name,
          services: service.services.map((service) => {
            const price = isNaN(Number(service.price)) ? undefined : Number(service.price);
            return {
              name: service.name,
              description: service.about.description,
              length: service.duration ? service.duration / 60 : undefined,
              price,
              externalUrl: service.about.externalUrl,
              discount: 0,
            };
          }),
        };
      })
    : [];

  useEffect(() => {
    const getPopularAndSimilarPlaces = async () => {
      const { data, error } = await promiseWrapper(getPopularAndSimilar(place.id.toString()));

      if (data?.popular?.length > 0) {
        setPopularPlaces(
          data.popular.map((place) => {
            return {
              id: place.id,
              name: place.name,
              address: { street: place.address.street, city: place.address.city },
              rating: place.rating.score,
            };
          }),
        );
      }

      if (data?.similar?.length > 0) {
        setSimilarPlaces(
          data.similar.map((place) => {
            return {
              id: place.id,
              name: place.name,
              address: { street: place.address.street, city: place.address.city },
              rating: place.rating.score,
            };
          }),
        );
      }

      if (error) {
        Sentry.captureException(error);
      }
    };

    if (!isVerified) {
      getPopularAndSimilarPlaces();
    }

    placeService.storePlaceImpressions([place.id], PLACE_IMPRESSION.PLACE_IMPRESSION);
  }, []);

  useEffect(() => {
    const fetchRecentVisistedUserPlaces = async () => {
      const { data, error } = await promiseWrapper(server.request.get('/places/recent'));

      if (error) {
        Sentry.captureException(error);
      }

      setLastVisitedPlaces(
        data.data.map((place) => ({
          id: place.id,
          name: place.about?.name,
          rating: place.rating?.score,
          address: { street: place.contact?.address?.street, city: place.contact?.address?.city },
        })),
      );
    };

    const fetchRecentVisistedPlaces = async () => {
      const lastVisitedPlaces = JSON.parse(localStorage.getItem('mpLastVisitedPlaces') || '[]');

      const { data, error } = await promiseWrapper(
        server.request.get('/places', {
          params: { ids: lastVisitedPlaces.join(',') },
        }),
      );

      if (error) {
        Sentry.captureException(error);
      }

      setLastVisitedPlaces(
        data.data.map((place) => ({
          id: place.id,
          name: place.about?.name,
          rating: place.rating?.score,
          address: { street: place.contact?.address?.street, city: place.contact?.address?.city },
        })),
      );
    };

    if (user) {
      fetchRecentVisistedUserPlaces();
    } else {
      fetchRecentVisistedPlaces();
    }
  }, [user]);

  const getCompanyWebsiteWithProcotol = () => {
    let url = (place.contact?.website || '').trim();
    if (url && url.indexOf(' ') === -1 && url.indexOf('http') === -1) {
      url = 'https://' + url;
    }
    return url;
  };

  const companySocials = {
    instagram: place.contact?.instagram,
    facebook: place.contact?.facebook,
    website: getCompanyWebsiteWithProcotol(),
  };
  const openingHours = place.program ? getOpenHoursStrings(place.program) : undefined;

  const goBack = () => {
    if (location.state?.fromList) {
      history.goBack();
    } else {
      history.replace('/vad/var');
    }
  };

  const handleShowFullPhoneNumber = () => {
    placeService.storePlaceImpressions([place.id], PLACE_IMPRESSION.PLACE_SHOW_PHONE_NUMBER_CLICK);
    setShowFullPhoneNumber(true);
  };

  const handlePhoneNumberClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    placeService.storePlaceImpressions([place.id], PLACE_IMPRESSION.PLACE_PHONE_NUMBER_CLICK);
    setTimeout(() => {
      window.location.href = `tel:${companyPhoneNumber.fullNumber}`;
    }, 200);
  };

  const handleOnReviewsClick = () => {
    if (reviewRef.current) {
      reviewRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleOnShowServicesClick = () => {
    if (serviesRef.current) {
      serviesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleExternalWebsiteClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    placeService.storePlaceImpressions([place.id], PLACE_IMPRESSION.PLACE_EXTERNAL_BUSINESS_LINK_CLICK);
  };

  const breadcrumbs =
    place.about?.category?.main || place.about?.category?.sub
      ? getBreadcrumbItems(place)
      : getDetailsBreadcrumbs(place);
  const { about = {} } = place;
  const { images = [] } = about;
  const address = place.contact && place.contact.address ? place.contact.address : null;

  const HAS_MULTIPLE_IMAGES = images.length > 1;

  return (
    <PageViewLayout
      back
      hideBottomNavBar
      title={place.about.name}
      type={'mainView'}
      onBackButtonClick={goBack}
      wrapperClass="bg-surface_mp">
      <div className="text-fg_primary flex justify-center">
        <div
          className={`px-md pt-md pb-3xl flex w-full max-w-[1040px] flex-col ${isMobileView ? 'gap-2xl' : 'gap-4xl'}`}>
          <Breadcrumbs items={breadcrumbs} className="w-full text-sm" />
          <div className={`flex ${isMobileView ? 'gap-xl flex-col' : 'justify-between'}`}>
            <div className={`flex flex-col ${isMobileView ? 'gap-xs' : 'gap-md'}`}>
              <h1 className={`text-fg_primary ${isMobileView ? 'text-h-l' : 'text-h-xl'} font-bold`}>
                {place.about.name}
              </h1>
              <div className={`gap-xs flex ${isMobileView && isBasic ? 'flex-col' : 'items-center'}`}>
                {(address?.zipcode || address?.city) && (
                  <div className="text-fg_secondary text-m border-border_regular pr-2xs flex border-r">
                    {address?.street && isVerified && (!isMobileView || isBasic) && (
                      <span>{address.street.trim() + ','}&nbsp;</span>
                    )}
                    {address?.zipcode && <span>{address.zipcode.trim()}</span>}
                    {address?.city && <span>&nbsp;{address.city.trim()}</span>}
                  </div>
                )}

                <Rating
                  count={getTotalReviews ?? 0}
                  rating={rating}
                  size={'sm'}
                  link={!isMobileView || isBasic}
                  onReviewsClick={handleOnReviewsClick}
                />
              </div>
            </div>
            <div>
              {!isVerified && (
                <Button
                  size="lg"
                  block={isMobileView}
                  iconNoFilter
                  rightIcon={<Icon variant="payment-method-bank-id" ext="svg" color="fg_primary_inverse" />}
                  label={__(`${baseTranslationKey}.cta`)}
                />
              )}

              {isVerified && !isBasic && !isPremium && !showFullPhoneNumber && companyPhoneNumber && (
                <Button size="lg" block={isMobileView} onClick={handleShowFullPhoneNumber} className="flex flex-col">
                  <span className="text-xl">{companyPhoneNumber.hiddenNumber}</span>
                  <span className="text-s">{_s(`${baseTranslationKey}.ctaVerified`)}</span>
                </Button>
              )}

              {isVerified && !isBasic && !isPremium && showFullPhoneNumber && companyPhoneNumber && (
                <LinkButton
                  size="lg"
                  block={isMobileView}
                  variant="primary"
                  onClick={handlePhoneNumberClick}
                  to={`tel:${companyPhoneNumber.fullNumber}`}
                  className="flex flex-col">
                  <span className="text-xl">{companyPhoneNumber.fullNumber}</span>
                  <span className="text-s">{_s(`${baseTranslationKey}.ctaVerified`)}</span>
                </LinkButton>
              )}

              {(isBasic || isPremium) && (
                <div className="gap-xl flex items-center">
                  {!isMobileView && companyPhoneNumber && (
                    <Fab
                      size="md"
                      variant="secondary"
                      icon={<Icon variant="phone" color="fg_primary" />}
                      to={`tel:${companyPhoneNumber.fullNumber}`}></Fab>
                  )}

                  {(!isPremium || !Boolean(profilePageServices.length)) && (
                    <LinkButton
                      size="lg"
                      variant="primary"
                      to={companySocials.website}
                      onClick={handleExternalWebsiteClick}
                      className={`${isMobileView ? '' : 'min-w-[240px]'}`}
                      block={isMobileView}
                      iconNoFilter
                      rightIcon={<Icon variant="external-link" color="fg_primary_inverse" />}>
                      <span className="flex flex-col">
                        <span className="text-xl">{_s(`${baseTranslationKey}.book.cta`)}</span>
                        <span className="text-s">{_s(`${baseTranslationKey}.book.description`)}</span>
                      </span>
                    </LinkButton>
                  )}

                  {isPremium && profilePageServices.length > 0 && (
                    <Button
                      size="lg"
                      onClick={handleOnShowServicesClick}
                      block={isMobileView}
                      iconNoFilter
                      label="Visa tjänster"></Button>
                  )}

                  {isMobileView && (
                    <div>
                      <Fab size="md" variant="secondary" icon={<Icon variant="phone" color="fg_primary" />}></Fab>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {images.length > 4 && <Photos photos={images} place={place} />}

          <div className={`flex ${isMobileView ? 'gap-2xl flex-col' : 'gap-md justify-between'}`}>
            <div className={`flex min-w-0 flex-1 flex-col ${isMobileView ? 'gap-2xl' : 'gap-4xl'}`}>
              {(isPremium || isBasic) && (
                <>
                  {isMobileView && !HAS_MULTIPLE_IMAGES && !isPremium && (
                    <Alert
                      variant="cation"
                      leftSlot={<Icon variant="warning" color="fg_notice_bold" />}
                      body={_s(`${baseTranslationKey}.book.alert`)}
                    />
                  )}
                  {images.length <= 4 && images.length > 0 && <Photos photos={images} place={place} />}
                  {(!isMobileView || (HAS_MULTIPLE_IMAGES && isMobileView)) && !isPremium && (
                    <Alert
                      variant="cation"
                      leftSlot={<Icon variant="warning" color="fg_notice_bold" />}
                      body={_s(`${baseTranslationKey}.book.alert`)}
                    />
                  )}
                </>
              )}

              {isPremium && Boolean(profilePageServices.length) && (
                <div className="gap-md flex flex-col">
                  <div className="flex justify-between">
                    <h2 className="text-h-m">{_s(`${baseTranslationKey}.book.cta`)}</h2>
                  </div>

                  <Alert
                    variant="cation"
                    leftSlot={<Icon variant="warning" color="fg_notice_bold" />}
                    body={_s(`${baseTranslationKey}.book.alert`)}
                  />

                  <div ref={serviesRef} className="scroll-mt-16">
                    {profilePageServices.map((service, index) => (
                      <Services placeId={place.id} {...service} key={index} />
                    ))}
                  </div>
                </div>
              )}

              {isMobileView && isPremium && (
                <div>
                  <div className="relative" ref={tooltipTargetRef}></div>
                  <div ref={reviewRef} className="scroll-mt-16">
                    {ReviewsComponent}
                  </div>
                </div>
              )}

              {isVerified && isMobileView && (
                <>
                  <PlaceDetails place={place} isVerified={isVerified} isBasic={isBasic} isPremium={isPremium} />
                  {isBasic && (
                    <div>
                      <div className="relative" ref={tooltipTargetRef}></div>
                      <div className={`flex flex-col ${isMobileView ? 'gap-2xl' : 'gap-4xl'}`}>
                        <div ref={reviewRef} className="scroll-mt-16">
                          {ReviewsComponent}
                        </div>
                        <Card size={isMobileView ? 'lg' : 'xl'}>
                          <div className="gap-2xl flex flex-col">
                            <div className="flex items-center justify-between">
                              <h2 className="text-h-s">{_s(`${baseTranslationKey}.servicesCard.title`)}</h2>
                              {!isMobileView && (
                                <Button
                                  size="md"
                                  label={_s(`${baseTranslationKey}.servicesCard.cta`)}
                                  iconNoFilter
                                  rightIcon={<Icon variant="plus" color="fg_primary_inverse" />}
                                />
                              )}
                            </div>
                            <EmptyState
                              body={_s(`${baseTranslationKey}.servicesCard.emptyState.body`)}
                              title={_s(`${baseTranslationKey}.servicesCard.emptyState.title`)}
                              icon={<img src="/images/illustrations/empty.svg" alt="" />}
                            />
                            {isMobileView && (
                              <Button
                                size="md"
                                label={_s(`${baseTranslationKey}.servicesCard.cta`)}
                                iconNoFilter
                                block
                                rightIcon={<Icon variant="plus" color="fg_primary_inverse" />}
                              />
                            )}
                          </div>
                        </Card>
                      </div>
                    </div>
                  )}
                  {(companyEmail || companyPhoneNumber) && (
                    <Contact
                      placeId={place.id}
                      email={companyEmail}
                      isVerified={isVerified}
                      onShowFullPhoneNumber={handleShowFullPhoneNumber}
                      onPhoneNumberClick={handlePhoneNumberClick}
                      phone={
                        companyPhoneNumber
                          ? showFullPhoneNumber
                            ? companyPhoneNumber.fullNumber
                            : companyPhoneNumber.hiddenNumber
                          : undefined
                      }
                      fullNumber={showFullPhoneNumber}
                    />
                  )}
                  {isVerified && (isPremium || isBasic) && (
                    <Links placeId={place.id} onWebsiteClick={handleExternalWebsiteClick} {...companySocials} />
                  )}
                  <Address isVerified={isVerified} place={place} useMapTiler={useMapTilerExperiment} />
                  {((isVerified && !(isPremium || isBasic)) || (openingHours && (isPremium || isBasic))) && (
                    <OpeningHours openingHours={isBasic || isPremium ? openingHours : undefined} />
                  )}
                </>
              )}

              {!isPremium && !isBasic && (
                <PlaceInfo place={place} category={serviceCategory} isVerified={isVerified} year={PLACE_YEAR} />
              )}

              {(!isMobileView || (!isBasic && !isPremium)) && (
                <div>
                  <div className="relative" ref={tooltipTargetRef}></div>
                  <div ref={reviewRef} className="scroll-mt-16">
                    {ReviewsComponent}
                  </div>
                </div>
              )}

              {!isVerified && <VerifyCompany />}
              {(!isMobileView || !isBasic) && !isPremium && (
                <Card size={isMobileView ? 'lg' : 'xl'}>
                  <div className="gap-2xl flex flex-col">
                    <div className="flex items-center justify-between">
                      <h2 className="text-h-s">{_s(`${baseTranslationKey}.servicesCard.title`)}</h2>
                      {!isMobileView && (
                        <Button
                          size="md"
                          label={_s(`${baseTranslationKey}.servicesCard.cta`)}
                          iconNoFilter
                          rightIcon={<Icon variant="plus" color="fg_primary_inverse" />}
                        />
                      )}
                    </div>
                    <EmptyState
                      body={_s(`${baseTranslationKey}.servicesCard.emptyState.body`)}
                      title={_s(`${baseTranslationKey}.servicesCard.emptyState.title`)}
                      icon={<img src="/images/illustrations/empty.svg" alt="" />}
                    />
                    {isMobileView && (
                      <Button
                        size="md"
                        label={_s(`${baseTranslationKey}.servicesCard.cta`)}
                        iconNoFilter
                        block
                        rightIcon={<Icon variant="plus" color="fg_primary_inverse" />}
                      />
                    )}
                  </div>
                </Card>
              )}

              {(isPremium || isBasic) && (
                <>
                  <PlaceInfo place={place} category={serviceCategory} isVerified={isVerified} year={PLACE_YEAR} />
                  {images?.length > 0 && <PhotoSlider images={images} />}
                </>
              )}

              {!isMobileView && !isBasic && !isPremium && (
                <>
                  <Companies
                    companies={similarPlaces}
                    title={_s(`${baseTranslationKey}.similarCompanyCard`)}
                    city={address.city}
                  />
                  <Companies
                    companies={popularPlaces}
                    title={_s(`${baseTranslationKey}.popularCard`)}
                    city={address.city}
                  />
                  {lastVisitedPlaces.length > 0 && (
                    <Companies companies={lastVisitedPlaces} title={_s(`${baseTranslationKey}.recentlyViewedCard`)} />
                  )}
                </>
              )}
            </div>

            <div className={`flex flex-col ${isMobileView ? 'gap-2xl' : 'gap-md max-w-[336px]'}`}>
              {(!isVerified || !isMobileView) && (
                <>
                  <PlaceDetails place={place} isVerified={isVerified} isBasic={isBasic} isPremium={isPremium} />
                  {(companyEmail || companyPhoneNumber) && (
                    <Contact
                      placeId={place.id}
                      email={companyEmail}
                      isVerified={isVerified}
                      onShowFullPhoneNumber={handleShowFullPhoneNumber}
                      onPhoneNumberClick={handlePhoneNumberClick}
                      phone={
                        companyPhoneNumber
                          ? showFullPhoneNumber
                            ? companyPhoneNumber.fullNumber
                            : companyPhoneNumber.hiddenNumber
                          : undefined
                      }
                      fullNumber={showFullPhoneNumber}
                    />
                  )}
                  {isVerified &&
                    (isPremium || isBasic) &&
                    (companySocials.facebook || companySocials.instagram || companySocials.website) && (
                      <Links placeId={place.id} onWebsiteClick={handleExternalWebsiteClick} {...companySocials} />
                    )}
                  <Address isVerified={isVerified} place={place} useMapTiler={useMapTilerExperiment} />
                  {((isVerified && !(isPremium || isBasic)) || (openingHours && (isPremium || isBasic))) && (
                    <OpeningHours openingHours={isBasic || isPremium ? openingHours : undefined} />
                  )}
                </>
              )}

              {isMobileView && !isBasic && !isPremium && (
                <>
                  <Companies
                    companies={similarPlaces}
                    title={_s(`${baseTranslationKey}.similarCompanyCard`)}
                    city={address.city}
                  />
                  <Companies
                    companies={popularPlaces}
                    title={_s(`${baseTranslationKey}.popularCard`)}
                    city={address.city}
                  />
                  {lastVisitedPlaces.length > 0 && (
                    <Companies companies={lastVisitedPlaces} title={_s(`${baseTranslationKey}.recentlyViewedCard`)} />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {seo(place)}
    </PageViewLayout>
  );
};

export default ProfilePage;
