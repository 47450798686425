import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { exportIdSlug, isAndroidPhone, isEmpty, isIPhone, promiseWrapper } from '@/helpers';
import { createSession, mapApplePayLineItem } from '@/helpers/applepay';
import {
  buildGiftcardCheckoutTrackingProps,
  getGiftcardCheckoutSummary,
  getGiftcardRequestData,
  getRedirectPreselectedPaymentMethod,
  guestCheckoutManager,
} from '@/helpers/checkout';
import { trackGiftcardPurchase } from '@/helpers/giftcards';
import {
  buildBrowserInfo,
  clientInstance,
  getGoogleIsReadyToPayRequest,
  getGooglePayPaymentDataRequest,
} from '@/helpers/googlepay';
import { useAppSelector } from '@/hooks';
import { GiftcardFormData, useGiftcardCheckoutFormData } from '@/hooks/useGiftcardCheckoutFormData';
import { usePlaceGiftCardData, useValueCardData } from '@/hooks/usePlaceData';
import useWalletPayment from '@/hooks/useWalletPayment';
import { _s } from '@/locale';
import { giftCardServices, swishServices } from '@/services';
import { CofPaymentData } from '@/types/adyen';
import { CheckoutTracking } from '@/types/analytics';
import {
  CreatePaymentAcceptedResponse as AdyenCreatePaymentAccepted,
  CreatePaymentActionRequiredResponse,
  createPaymentAcceptedResponseSchema,
  createPaymentActionRequiredResponseSchema,
  createPaymentRefusedResponseSchema,
} from '@/types/api/services/adyen';
import { cofPaymentResponseThreeDSSchema } from '@/types/api/services/booking';
import { Employee, EmployeeValueCard } from '@/types/api/services/giftcard';
import { ErrorResponse, errorResponseSchema } from '@/types/api/services/schema';
import { SwishPaymentResponse, swishPaymentResponseSchema } from '@/types/api/services/swish';
import {
  CheckoutAPI,
  CheckoutAction,
  CheckoutMissingAction,
  CheckoutSummary,
  SelectedPaymentMethod,
  baseCheckoutStateSchema,
  giftcardCheckoutSummarySchema,
  selectedPaymentMethodSchema,
} from '@/types/checkout';
import * as Sentry from '@sentry/react';
import { Dispatch, Reducer, createContext, useContext, useEffect, useReducer } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import { saveSwishCheckoutStateToLocalStorage } from '../validate-swish-payment-redirect/ValidateSwishPaymentRedirect.hooks';
import { baseTranslationKey } from './GiftcardCheckout';

import { QLIRO_GC_CHECKOUT_FEATURE_FLAG, SWISH_GC_CHECKOUT_FEATURE_FLAG } from '@/constants/experimentConstants';
import { APP_ROUTES } from '@/constants/pages';
import { useGetAmplitudeExperimentVariant } from '@/hooks/useAmplitudeExperiment';
import {
  CreatePaymentResponse as QliroPaymentResponse,
  createPaymentResponseSchema as createQliroPaymentResponseSchema,
} from '@/types/api/services/qliro';
import { PaymentCard } from '@/types/paymentcards';
import { saveCofCheckoutStateToLocalStorage } from '../validate-cof-payment-redirect/ValidateCofPaymentRedirect.hooks';
import { getGiftcardCheckoutProductType } from './GiftcardCheckout.helpers';
import { ProductType } from './GiftcardCheckout.types';

const getRedirectUrl = (productType: ProductType, placeId: string) => {
  switch (productType) {
    case 'giftcard':
      return '/validate-cof-payment-redirect/giftcard/<placeholder>';
    case 'wellnesscard':
      return '/validate-cof-payment-redirect/wellnesscard/<placeholder>';
    case 'placecard':
      return `/validate-cof-payment-redirect/placecard/<placeholder>/${placeId}`;
    case 'valuecard':
      return `/validate-cof-payment-redirect/valuecard/<placeholder>/${placeId}`;
  }
};

export const PHYSICAL_FEE = 45;

const swishStateSchema = z.object({
  payment: swishPaymentResponseSchema.nullable(),
  notificationChannel: z.any().optional(),
  showQrCode: z.boolean().optional(),
});

type SwishState = z.infer<typeof swishStateSchema>;

export const giftcardCheckoutStateSchema = baseCheckoutStateSchema.merge(
  z.object({
    summary: giftcardCheckoutSummarySchema,
    cofThreeDS: cofPaymentResponseThreeDSSchema.nullable().optional(),
    swish: swishStateSchema.nullable().optional(),
    loading: z.boolean().optional(),
    employee: z.string().optional(),
    guestId: z.string().optional(),
  }),
);

type SubmitGiftcardPaymentContext = {
  successCallback: ({ paymentMethod, responseData, trackingProps }: SuccessRedirectData) => void;
  errorCallback: (error: ErrorResponse) => void;
  trackingProps?: CheckoutTracking.Giftcard;
  formData: GiftcardFormData;
  productType: ProductType;
  employee?: Employee;
  slugId?: string;
  valueCard?: EmployeeValueCard;
  placeId?: number;
};

type SubmitCofGiftcardPaymentContext = SubmitGiftcardPaymentContext & {
  selectedPaymentMethod: SelectedPaymentMethod;
  guestId?: string;
};

export type SubmitThreeDSContext = Omit<
  SubmitCofGiftcardPaymentContext,
  'formData' | 'productType' | 'selectedPaymentMethod' | 'employees'
> &
  Partial<Pick<SubmitCofGiftcardPaymentContext, 'selectedPaymentMethod'>> & {
    cofThreeDS: CreatePaymentActionRequiredResponse;
    paymentMethod?:
      | typeof CHECKOUT_PAYMENT_METHOD.STORED_COF
      | typeof CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY
      | typeof CHECKOUT_PAYMENT_METHOD.APPLE_PAY;
  };

type CompleteWalletPaymentContext = SubmitCofGiftcardPaymentContext & {
  type: 'googlepay' | 'applepay';
  paymentData: CofPaymentData;
};

type SubmitSwishGiftcardPaymentContext = SubmitGiftcardPaymentContext & { loggedIn: boolean };

export type CofPaymentSuccessRedirectData = {
  paymentMethod:
    | typeof CHECKOUT_PAYMENT_METHOD.STORED_COF
    | typeof CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY
    | typeof CHECKOUT_PAYMENT_METHOD.APPLE_PAY;
  responseData: AdyenCreatePaymentAccepted;
  trackingProps: CheckoutTracking.Giftcard;
};

type SwishPaymentSuccessRedirectData = {
  paymentMethod: typeof CHECKOUT_PAYMENT_METHOD.SWISH;
  responseData: SwishPaymentResponse;
  trackingProps: CheckoutTracking.Giftcard;
};

type QliroPaymentSuccessRedirectData = {
  paymentMethod: typeof CHECKOUT_PAYMENT_METHOD.QLIRO;
  responseData: QliroPaymentResponse;
  trackingProps: CheckoutTracking.Giftcard;
};

type SuccessRedirectData =
  | CofPaymentSuccessRedirectData
  | SwishPaymentSuccessRedirectData
  | QliroPaymentSuccessRedirectData;

export type GiftcardCheckoutState = z.infer<typeof giftcardCheckoutStateSchema>;

export type UseGiftcardCheckoutManagerResult = ReturnType<typeof useGiftcardCheckoutManager>;

const initialState: GiftcardCheckoutState = {
  summary: {
    acceptsGiftcard: false,
    acceptsValuecard: false,
    acceptsWellnesscard: false,
    availablePaymentMethods: [],
    isOnlinePaymentRequired: true,
    preSelectedPaymentMethod: {},
    canPayOnline: true,
  },
  selectedPaymentMethod: { type: CHECKOUT_PAYMENT_METHOD.NONE },
  missingActions: [],
  submitting: false,
  loading: true,
};

type GiftcardCheckoutAction =
  | CheckoutAction<CheckoutSummary>
  | { type: 'LOADING'; payload: boolean }
  | {
      type: 'SET_GUEST_ID';
    }
  | {
      type: 'ONHOLD_COF';
      payload: {
        cofThreeDS: CreatePaymentActionRequiredResponse;
        selectedPaymentMethod: SelectedPaymentMethod;
        trackingProps: CheckoutTracking.Giftcard;
      };
    }
  | {
      type: 'ONHOLD_SWISH';
      payload: {
        swish: SwishState;
        trackingProps: CheckoutTracking.Giftcard;
      };
    }
  | {
      type: 'CLOSE_SWISH_QRCODE_MODAL';
    };

const giftcardCheckoutReducer: Reducer<GiftcardCheckoutState, GiftcardCheckoutAction> = (state, action) => {
  switch (action.type) {
    case 'SUBMITTING': {
      return { ...state, submitting: action.payload };
    }
    case 'SET_PAYMENT_METHOD':
      const hasNotSelectedPaymentMethod = action.payload.type === CHECKOUT_PAYMENT_METHOD.NONE;
      return {
        ...state,
        selectedPaymentMethod: action.payload,
        missingActions: state.missingActions.filter((a) => hasNotSelectedPaymentMethod || a !== 'payment-method'),
      };
    case 'SET_MISSING_ACTION': {
      const missingActions = Array.from(new Set([...state.missingActions, action.payload]));
      return {
        ...state,
        missingActions,
      };
    }
    case 'ONHOLD_COF': {
      if (JSON.parse(action.payload?.cofThreeDS?.adyenActionJson || '{}')?.type === 'redirect') {
        saveCofCheckoutStateToLocalStorage(action.payload);
      }

      return {
        ...state,
        cofThreeDS: action.payload.cofThreeDS,
        submitting: false,
      };
    }
    case 'ONHOLD_SWISH': {
      return {
        ...state,
        submitting: false,
        swish: action.payload.swish,
      };
    }
    case 'CLOSE_SWISH_QRCODE_MODAL': {
      return {
        ...state,
        submitting: false,
        swish: { ...state.swish, showQrCode: false },
      };
    }
    case 'SET_GUEST_ID': {
      return {
        ...state,
        guestId: uuidv4(),
      };
    }
    case 'ERROR': {
      return {
        ...state,
        submitting: false,
        cofThreeDS: null,
        swish: null,
        error: action.payload,
      };
    }
    case 'UPDATE_SUMMARY':
      return {
        ...state,
        summary: action.payload,
      };
    case 'LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default:
      return state;
  }
};

async function handleInitQliroPayment(
  context: SubmitGiftcardPaymentContext,
  dispatch: Dispatch<GiftcardCheckoutAction>,
) {
  dispatch({ type: 'SUBMITTING', payload: true });
  const { formData, productType, employee, placeId, slugId } = context;

  const successPath = (() => {
    switch (productType) {
      case 'giftcard':
      case 'wellnesscard':
        return APP_ROUTES.GIFTCARD_CONFIRMATION;
      case 'placecard':
        return `/places/${slugId}/placecard/confirmation`;
      case 'valuecard':
        return `/places/${slugId}/valuecard/confirmation`;
    }
  })();

  const { data, error } = await promiseWrapper(
    giftCardServices.createGiftcardQliroOrder({
      ...getGiftcardRequestData({ formData, productType, employee, mpPlaceId: placeId }),
      successPath,
    }),
  );

  const validateResponse = createQliroPaymentResponseSchema.safeParse(data);

  if (!validateResponse.success || error) {
    context.errorCallback(error);
    return;
  }

  context.successCallback({
    paymentMethod: CHECKOUT_PAYMENT_METHOD.QLIRO,
    responseData: validateResponse.data,
    trackingProps: context.trackingProps,
  });
}

function handleSubmitGooglePay(context: SubmitCofGiftcardPaymentContext, dispatch: Dispatch<GiftcardCheckoutAction>) {
  return async (final: number) => {
    try {
      const googlePaymentsClient = clientInstance('gc');
      const isReadyToPay = googlePaymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest('gc'));

      if (!isReadyToPay) throw new Error('Google pay is not ready to pay');

      dispatch({ type: 'SUBMITTING', payload: true });

      const response = await googlePaymentsClient.loadPaymentData(
        getGooglePayPaymentDataRequest(
          {
            totalPrice: `${final.toFixed(2)}`,
            totalPriceStatus: 'FINAL',
            totalPriceLabel: _s('googlepay.totalPriceLabel.paynow'),
            currencyCode: 'SEK',
            countryCode: 'SE',
            displayItems: [
              ...((): google.payments.api.DisplayItem[] => {
                return [
                  {
                    label: _s('googlepay.totalPriceLabel.paynow'),
                    type: 'SUBTOTAL',
                    price: `${final.toFixed(2)}`,
                    status: 'PENDING',
                  },
                ];
              })(),
            ],
          },
          'gc',
        ),
      );

      await completeWalletBooking(
        {
          ...context,
          paymentData: {
            paymentMethod: {
              type: 'googlepay',
              googlePayToken: response.paymentMethodData.tokenizationData.token,
              googlePayCardNetwork: response.paymentMethodData.info.cardNetwork,
            },
            browserInfo: buildBrowserInfo(),
            redirectInfo: { returnPath: getRedirectUrl(context.productType, context.slugId) },
          },
          type: 'googlepay',
        },
        dispatch,
      );
    } catch (error) {
      // Google pay throws "CANCELED statusCode when user closes the payments sheet"
      if (error.statusCode !== 'CANCELED') {
        context.errorCallback(error);
      }
      dispatch({ type: 'ERROR', payload: true });
    }
  };
}

function handleSubmitApplePay(context: SubmitCofGiftcardPaymentContext, dispatch: Dispatch<GiftcardCheckoutAction>) {
  return async (final: number) => {
    const applePaySession = createSession(
      {
        total: mapApplePayLineItem({ amount: `${final}`, label: 'Bokadirekt', payLater: false }),
      },
      'gc',
    );

    applePaySession.begin();

    applePaySession.onpaymentauthorized = async (payment) => {
      try {
        await completeWalletBooking(
          {
            ...context,
            paymentData: {
              paymentMethod: {
                type: 'applepay',
                applePayToken: payment.payment.token.paymentData,
              },
              redirectInfo: { returnPath: getRedirectUrl(context.productType, context.slugId) },
            },
            type: 'applepay',
          },
          dispatch,
          applePaySession,
        );
      } catch (error) {
        context.errorCallback(error);

        applePaySession.completePayment({
          status: ApplePaySession.STATUS_FAILURE,
          errors: [],
        });
        dispatch({ type: 'ERROR', payload: true });
      }
    };
  };
}

async function completeWalletBooking(
  context: CompleteWalletPaymentContext,
  dispatch: Dispatch<GiftcardCheckoutAction>,
  applePaySession?: ApplePaySession,
) {
  const { type, paymentData, formData, guestId, productType, selectedPaymentMethod, employee, placeId } = context;
  const paymentMethod = type === 'googlepay' ? CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY : CHECKOUT_PAYMENT_METHOD.APPLE_PAY;

  const { data, error } = await promiseWrapper(
    giftCardServices.createGiftcardAdyenOrder({
      ...getGiftcardRequestData({ formData, productType, employee, mpPlaceId: placeId }),
      guestId,
      paymentMethod,
      paymentData: {
        ...(() => {
          if (paymentData.paymentMethod.type === 'googlepay') {
            return {
              paymentMethod: {
                type: 'googlepay',
                googlePayToken: paymentData.paymentMethod.googlePayToken,
                googlePayCardNetwork: paymentData.paymentMethod.googlePayCardNetwork,
              },
              browserInfo: paymentData.browserInfo,
              redirectInfo: paymentData.redirectInfo,
            };
          }

          if (paymentData.paymentMethod.type === 'applepay') {
            return {
              paymentMethod: { type: 'applepay', applePayToken: paymentData.paymentMethod.applePayToken },
              browserInfo: paymentData.browserInfo,
              redirectInfo: paymentData.redirectInfo,
            };
          }
        })(),
      },
    }),
  );

  const paymentAccepted = createPaymentAcceptedResponseSchema.safeParse(data);

  if (paymentAccepted.success) {
    applePaySession?.completePayment?.({ status: ApplePaySession.STATUS_SUCCESS });
    context.successCallback({
      paymentMethod,
      responseData: paymentAccepted.data,
      trackingProps: context.trackingProps,
    });
    return;
  }

  const paymentActionRequired = createPaymentActionRequiredResponseSchema.safeParse(data);

  if (paymentActionRequired.success) {
    dispatch?.({
      type: 'ONHOLD_COF',
      payload: {
        cofThreeDS: paymentActionRequired.data,
        selectedPaymentMethod,
        trackingProps: context.trackingProps,
      },
    });

    return;
  }

  const paymentRefused = createPaymentRefusedResponseSchema.safeParse(data);

  context.errorCallback(paymentRefused.success ? { clientError: paymentRefused.data.userError } : error);
  applePaySession?.completePayment?.({ status: ApplePaySession.STATUS_FAILURE });
}

function handleSubmitGuestCoF(context: SubmitCofGiftcardPaymentContext, dispatch: Dispatch<GiftcardCheckoutAction>) {
  return async (paymentData: CofPaymentData) => {
    const { formData, guestId, productType, selectedPaymentMethod, employee, slugId, placeId } = context;

    const paymentMethod = (() => {
      if (paymentData?.paymentMethod?.type === 'googlepay') return CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY;
      if (paymentData?.paymentMethod?.type === 'applepay') return CHECKOUT_PAYMENT_METHOD.APPLE_PAY;
      return CHECKOUT_PAYMENT_METHOD.STORED_COF;
    })();

    dispatch?.({ type: 'SUBMITTING', payload: true });

    const { data, error } = await promiseWrapper(
      giftCardServices.createGiftcardAdyenOrder({
        ...getGiftcardRequestData({ formData, productType, employee, mpPlaceId: placeId }),
        paymentData: {
          ...paymentData,
          redirectInfo: { returnPath: getRedirectUrl(productType, slugId) },
        },
        guestId,
        paymentMethod,
      }),
    );

    const paymentAccepted = createPaymentAcceptedResponseSchema.safeParse(data);

    if (paymentAccepted.success) {
      context.successCallback({
        paymentMethod,
        responseData: paymentAccepted.data,
        trackingProps: context.trackingProps,
      });
      return;
    }
    const threeDSData = createPaymentActionRequiredResponseSchema.safeParse(data);
    if (threeDSData.success) {
      dispatch?.({
        type: 'ONHOLD_COF',
        payload: {
          cofThreeDS: threeDSData.data,
          selectedPaymentMethod,
          trackingProps: context.trackingProps,
        },
      });
      return;
    }

    context.errorCallback(error);
  };
}

function handleSubmitCoF(context: SubmitCofGiftcardPaymentContext, dispatch: Dispatch<GiftcardCheckoutAction>) {
  return async (storedPaymentMethodId: string) => {
    const { formData, guestId, productType, selectedPaymentMethod, employee, slugId, placeId } = context;

    dispatch?.({ type: 'SUBMITTING', payload: true });

    const { data, error } = await promiseWrapper(
      giftCardServices.createGiftcardAdyenOrder({
        ...getGiftcardRequestData({ formData, productType, employee, mpPlaceId: placeId }),
        paymentData: {
          paymentMethod: {
            type: 'storedCard',
            storedPaymentMethodId,
          },
          browserInfo: buildBrowserInfo(),
          redirectInfo: { returnPath: getRedirectUrl(productType, slugId) },
        },
        guestId,
        paymentMethod: CHECKOUT_PAYMENT_METHOD.STORED_COF,
      }),
    );

    const paymentAccepted = createPaymentAcceptedResponseSchema.safeParse(data);

    if (paymentAccepted.success) {
      context.successCallback({
        paymentMethod: CHECKOUT_PAYMENT_METHOD.STORED_COF,
        responseData: paymentAccepted.data,
        trackingProps: context.trackingProps,
      });
      return;
    }

    const paymentActionRequired = createPaymentActionRequiredResponseSchema.safeParse(data);

    if (paymentActionRequired.success) {
      dispatch?.({
        type: 'ONHOLD_COF',
        payload: {
          cofThreeDS: paymentActionRequired.data,
          selectedPaymentMethod,
          trackingProps: context.trackingProps,
        },
      });

      return;
    }

    const paymentRefused = createPaymentRefusedResponseSchema.safeParse(data);

    context.errorCallback(paymentRefused.success ? { clientError: paymentRefused.data.userError } : error);
  };
}

export function handleSubmitThreeDSPayment(context: SubmitThreeDSContext, dispatch?: Dispatch<GiftcardCheckoutAction>) {
  return async (_state: any) => {
    const { paymentMethod, selectedPaymentMethod, cofThreeDS, errorCallback, successCallback } = context;
    dispatch?.({ type: 'SUBMITTING', payload: true });

    const { data, error } = await promiseWrapper(
      giftCardServices.submitGiftcardAdyenPaymentDetails({
        details: _state.data.details,
        paymentData: cofThreeDS.adyenPaymentData,
        paymentMethod,
      }),
    );

    const paymentAccepted = createPaymentAcceptedResponseSchema.safeParse(data);

    if (paymentAccepted.success) {
      successCallback({
        paymentMethod: CHECKOUT_PAYMENT_METHOD.STORED_COF,
        responseData: paymentAccepted.data,
        trackingProps: context.trackingProps,
      });
      return;
    }

    const paymentActionRequired = createPaymentActionRequiredResponseSchema.safeParse(data);

    if (paymentActionRequired.success) {
      dispatch?.({
        type: 'ONHOLD_COF',
        payload: {
          cofThreeDS: paymentActionRequired.data,
          selectedPaymentMethod,
          trackingProps: context.trackingProps,
        },
      });
      return;
    }

    const paymentRefused = createPaymentRefusedResponseSchema.safeParse(data);

    errorCallback(paymentRefused.success ? { clientError: paymentRefused.data.userError } : error);
  };
}

export async function handleSubmitSwishPayment(
  context: SubmitSwishGiftcardPaymentContext,
  dispatch: Dispatch<GiftcardCheckoutAction>,
) {
  dispatch({ type: 'SUBMITTING', payload: true });
  const { formData, productType, employee, slugId, placeId: mpPlaceId } = context;

  const { data, error } = await promiseWrapper(
    giftCardServices.createGiftcardSwishOrder({
      ...getGiftcardRequestData({ formData, productType, employee, mpPlaceId }),
      placeSlug: slugId,
    }),
  );
  const validateResponse = swishPaymentResponseSchema.safeParse(data);

  if (!validateResponse.success || error) {
    context.errorCallback(error);
    return;
  }

  const { qrCode } = validateResponse.data;

  if (!qrCode && !isIPhone() && !isAndroidPhone()) {
    context.errorCallback({
      clientError: _s(`${baseTranslationKey}.swishModal.snackbar.message`),
      message: 'Qr-code is missing',
      status: 500,
    });
    return;
  }
  /**
   * When user is not redirected (desktop flow) we hook up to the swish sse endpoint
   * and wait for a response
   *
   * We still run this code on mobile devices so that if they return
   * to previous tab that tab will also be redirected successfully if
   * the payment was successful
   */

  const eventSource = swishServices.waitForSwishNotification(validateResponse.data.channel);

  dispatch({
    type: 'ONHOLD_SWISH',
    payload: {
      trackingProps: {},
      swish: {
        payment: validateResponse.data,
        notificationChannel: eventSource,
        showQrCode: !isIPhone() && !isAndroidPhone(),
      },
    },
  });

  /**
   * When the user needs to be redirected (on mobile device) we need save
   * some data to local storage so we can restore the state
   * when the user is redirected back to our swish redirect hook
   */
  if (isIPhone() || isAndroidPhone()) {
    saveSwishCheckoutStateToLocalStorage({
      payment: validateResponse.data,
      trackingProps: context.trackingProps,
      ...(context.valueCard && {
        fallbackLocationState: {
          valueCard: context.valueCard,
          employeeId: context.employee.id,
        },
      }),
    });

    window.location.href = validateResponse.data.url;
  }

  if (eventSource) {
    eventSource.onmessage = (e) => {
      const swishNotification = JSON.parse(e.data);

      if (swishNotification.success) {
        context.successCallback({
          paymentMethod: CHECKOUT_PAYMENT_METHOD.SWISH,
          responseData: { ...validateResponse.data, id: swishNotification.id },
          trackingProps: context.trackingProps,
        });
        eventSource.close();
      } else {
        if (swishNotification.keepAlive) {
          return;
        }

        eventSource.close();

        dispatch({ type: 'ERROR', payload: true });

        toast(
          ({ closeToast }) => (
            <Snackbar
              type="danger"
              label={
                swishNotification.clientError
                  ? swishNotification.clientError
                  : _s(`${baseTranslationKey}.swishModal.snackbar.message`)
              }
              action={<button onClick={closeToast}>{_s(`${baseTranslationKey}.swishModal.snackbar.cta`)}</button>}
            />
          ),
          {
            autoClose: 3000,
          },
        );
      }
    };

    eventSource.onerror = () => {
      dispatch({ type: 'ERROR', payload: true });
      context.errorCallback({ message: 'Could not subscribe to swish notification events', status: 500 });
    };
  }
}

const useGiftcardCheckoutManager = (cards: PaymentCard[]) => {
  const { setValue, getValues, formdata: formData } = useGiftcardCheckoutFormData();
  const location = useLocation();
  const history = useHistory();
  const user = useAppSelector((state) => state.users.user);
  const match = useRouteMatch();
  const { id, slug } = exportIdSlug(match?.params?.slugId);
  const {
    hasActiveGooglePayPaymentMethod,
    hasActiveApplePayPaymentMethod,
    isLoading: isLoadingWalletMethods,
  } = useWalletPayment();
  const canPayWithSwish = useGetAmplitudeExperimentVariant()(SWISH_GC_CHECKOUT_FEATURE_FLAG).value === 'on';
  const canPayWithQliro = useGetAmplitudeExperimentVariant()(QLIRO_GC_CHECKOUT_FEATURE_FLAG).value === 'on';

  const productType = getGiftcardCheckoutProductType(match.params, location.pathname);
  const { loading: loadingPlace, place } = (() => {
    switch (productType) {
      case 'placecard': {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return usePlaceGiftCardData(undefined, parseInt(id), slug);
      }
      case 'valuecard': {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useValueCardData(undefined, parseInt(id), slug);
      }
      default:
        return { loading: false, place: null };
    }
  })();

  const slugId = match?.params?.slugId;
  const campaign = match?.params?.campaign;
  const employees = place && !isEmpty(place) && place.employees;
  const placeName = (place && !isEmpty(place) && place.name) || '...';
  const placeSellsGiftcard = Boolean(place && !isEmpty(place) && place.sellsGiftCard);
  const giftcardValue = formData.customAmount || formData.amount;
  const validSelectedPaymentMethod = selectedPaymentMethodSchema.safeParse(location.state?.selectedPaymentMethod);
  const savedSelectedPaymentMethod = validSelectedPaymentMethod.success ? validSelectedPaymentMethod.data : null;

  const [state, dispatch] = useReducer(giftcardCheckoutReducer, initialState);

  useEffect(() => {
    if (!place?.employees?.length) return;
    place.redirect && (window.location.href = `${place.redirect}/giftcard/checkout`);

    if (productType === 'placecard') {
      setValue('employee', place.employees[0].id);
    }

    if (productType === 'valuecard') {
      const pickedEmployee = place.employees.find((e) => e.id === location.state.employeeId);
      const pickedValueCard = pickedEmployee?.valueCards.find((vc) => vc.id === location.state.valueCard.id);
      setValue('employee', pickedEmployee.id);
      setValue('amount', pickedValueCard.price / 100);
    }

    // setValue('amount', )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place]);

  useEffect(() => {
    let loading = true;

    if (productType === 'placecard') {
      loading = loadingPlace || place?.dataType !== 'giftCard';
    }

    if (productType === 'valuecard') {
      loading = loadingPlace || place?.dataType !== 'valueCard';
    }

    dispatch({ type: 'LOADING', payload: loading });
  }, [loadingPlace, place?.dataType]);

  useEffect(() => {
    if (!user) {
      guestCheckoutManager().setOptions({ useGuestCheckout: true });
      dispatch({ type: 'SET_GUEST_ID' });
    }
  }, []);

  useEffect(() => {
    const updateSummary = async () => {
      const orderTotal = giftcardValue * formData.quantity + (formData.type === 'physical' ? PHYSICAL_FEE : 0);
      const { data } = formData.discountCode
        ? await promiseWrapper(giftCardServices.checkDiscountCode(formData.discountCode, orderTotal))
        : { data: null };

      const summary = getGiftcardCheckoutSummary({
        cards,
        orderTotal,
        canPayWithGooglePay: hasActiveGooglePayPaymentMethod,
        canPayWithApplePay: hasActiveApplePayPaymentMethod,
        canPayWithSwish,
        canPayWithQliro,
        discountAmount: data?.discount,
      });

      const selectedPaymentMethod =
        state.selectedPaymentMethod.type !== -1
          ? state.selectedPaymentMethod
          : getRedirectPreselectedPaymentMethod({
              cards,
              summary,
              selectedPaymentMethod: savedSelectedPaymentMethod,
            }) ||
            summary.preSelectedPaymentMethod ||
            summary.availablePaymentMethods.find((method) => method.type === CHECKOUT_PAYMENT_METHOD.NONE);

      dispatch({ type: 'UPDATE_SUMMARY', payload: summary });
      dispatch({ type: 'SET_PAYMENT_METHOD', payload: selectedPaymentMethod });
    };
    updateSummary();
  }, [
    cards,
    formData.type,
    formData.amount,
    formData.discountCode,
    formData.customAmount,
    formData.quantity,
    location.state?.selectedPaymentMethod,
  ]);

  /**
   * Currently we want google pay to be the default payment method if it's available
   * since it might not be loaded initially we want to update the summary and
   * set the payment method as selected when it's ready
   */
  useEffect(() => {
    const hasGooglePayInSummary = state.summary.availablePaymentMethods.some(
      (method) => method.type === CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY,
    );
    const hasApplePayInSummary = state.summary.availablePaymentMethods.some(
      (method) => method.type === CHECKOUT_PAYMENT_METHOD.APPLE_PAY,
    );
    const hasSwishInSummary = state.summary.availablePaymentMethods.some(
      (method) => method.type === CHECKOUT_PAYMENT_METHOD.SWISH,
    );

    const updateGooglePayInSummary = !(hasGooglePayInSummary || !hasActiveGooglePayPaymentMethod);
    const updateApplePayInSummary = !(hasApplePayInSummary || !hasActiveApplePayPaymentMethod);
    const updateSwishInSummary = !(hasSwishInSummary || !canPayWithSwish);

    async function updateSummary() {
      const orderTotal = giftcardValue * formData.quantity + (formData.type === 'physical' ? PHYSICAL_FEE : 0);
      const { data } = formData.discountCode
        ? await promiseWrapper(giftCardServices.checkDiscountCode(formData.discountCode, orderTotal))
        : { data: null };

      const summary = getGiftcardCheckoutSummary({
        cards,
        orderTotal,
        canPayWithGooglePay: hasActiveGooglePayPaymentMethod,
        canPayWithApplePay: hasActiveApplePayPaymentMethod,
        canPayWithSwish,
        canPayWithQliro,
        discountAmount: data?.discount,
      });

      dispatch({ type: 'UPDATE_SUMMARY', payload: summary });
      dispatch({
        type: 'SET_PAYMENT_METHOD',
        payload:
          summary.preSelectedPaymentMethod ||
          summary.availablePaymentMethods.find((method) => method.type === CHECKOUT_PAYMENT_METHOD.NONE),
      });
    }

    if (updateGooglePayInSummary || updateApplePayInSummary || updateSwishInSummary) {
      updateSummary();
    }
  }, [hasActiveGooglePayPaymentMethod, hasActiveApplePayPaymentMethod, canPayWithSwish]);

  const handleChangePaymentMethod = (method: SelectedPaymentMethod) => {
    dispatch({ type: 'SET_PAYMENT_METHOD', payload: method });
  };

  const handleUseGuestCheckout = () => {
    guestCheckoutManager().setOptions({ useGuestCheckout: true });
    dispatch({ type: 'SET_GUEST_ID' });
  };

  const handleOnCheckoutMissingAction = (action: CheckoutMissingAction) => {
    dispatch({ type: 'SET_MISSING_ACTION', payload: action });
  };

  const handleDismissSwishQRCode = () => {
    dispatch({ type: 'CLOSE_SWISH_QRCODE_MODAL' });
  };

  const handleError = (error: unknown) => {
    const errorData = errorResponseSchema.safeParse(error);
    dispatch({ type: 'ERROR', payload: true });

    const displayClientError = (errorMessage?: string) => {
      toast(
        ({ closeToast }) => (
          <Snackbar
            label={errorMessage ?? _s('serverError')}
            type="danger"
            onClose={() => {
              dispatch({ type: 'ERROR', payload: false });
              closeToast();
            }}
          />
        ),
        {
          autoClose: 5000,
        },
      );
    };

    if (!errorData.success) {
      displayClientError();
      Sentry.captureException(error);
      return;
    }

    const { clientError } = errorData.data;

    displayClientError(clientError);
    Sentry.captureException(error);
  };

  const giftcardCheckoutAPI = (): CheckoutAPI => {
    const employeeId = productType === 'valuecard' ? location.state.employeeId : formData.employee;
    const employee = employees && formData.employee ? employees.find((e) => e.id === employeeId) : undefined;
    // filter out all value cards that are not the selected
    if (productType === 'valuecard') {
      employee['valueCards'] = employee?.valueCards?.filter((vc) => vc.id === location.state.valueCard.id);
    }

    const placeId = !isNaN(parseInt(id)) ? parseInt(id) : undefined;

    const context: SubmitGiftcardPaymentContext = {
      errorCallback: handleError,
      successCallback: ({ paymentMethod, responseData, trackingProps }) => {
        if (trackingProps) {
          trackingProps['giftcard_id'] = +responseData.id;
        }

        let selectedPaymentMethod: SelectedPaymentMethod | undefined;

        switch (paymentMethod) {
          case CHECKOUT_PAYMENT_METHOD.STORED_COF:
            if (state.selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.STORED_COF) {
              selectedPaymentMethod = {
                type: state.selectedPaymentMethod.type,
                id: state.selectedPaymentMethod.id,
                brand: state.selectedPaymentMethod.brand,
                lastFour: state.selectedPaymentMethod.lastFour,
              };
            }
          default: {
            selectedPaymentMethod = state.selectedPaymentMethod;
            break;
          }
        }

        /**
         * Qliro has another step to complete the purchase by redirecting the user
         * to a page where we display the qliro html snippet where the client
         * completes the payment
         */
        if (selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.QLIRO) {
          const redirect = ((): { pathname: string; state: object } => {
            let pathname: string;
            switch (productType) {
              case 'giftcard':
                pathname = APP_ROUTES.GIFTCARD_CHECKOUT + '/qliro';
                break;
              case 'wellnesscard':
                pathname = APP_ROUTES.WELLNESSCARD_CHECKOUT + '/qliro';
                break;
              case 'placecard':
                pathname = `/places/${slugId}/giftcard/checkout/qliro`;
                break;
              case 'valuecard':
                pathname = `/places/${slugId}/valuecard/checkout/qliro`;
                break;
              default:
                const never: never = productType;
                throw new Error(`Unhandled productType: ${never}`);
            }
            return {
              pathname,
              state: {
                from: location.pathname,
                qliroOrder: responseData,
                savedCheckoutState: state,
                savedFormDataState: formData,
                ...(!user && { guestEmail: formData.email }),
                /**
                 * These two fields are optional
                 */
                valueCard: location.state?.valueCard,
                employeeId: location.state?.employeeId,
              },
            };
          })();
          history.push(redirect);
          return;
        }

        /**
         * set the selected payment method to the state that will be passed to the confirmation page
         */
        const locationState = {
          ...(selectedPaymentMethod && { selectedPaymentMethod }),
          ...(!user && { guestEmail: formData.email }),
        };

        switch (productType) {
          case 'giftcard':
          case 'wellnesscard':
            trackGiftcardPurchase(trackingProps);
            history.push({ pathname: `${APP_ROUTES.GIFTCARD_CONFIRMATION}/${responseData.id}`, state: locationState });
            return;
          case 'placecard':
            trackGiftcardPurchase(trackingProps);
            history.push({
              pathname: `/places/${slugId}/placecard/confirmation/${responseData.id}`,
              state: locationState,
            });
            return;
          case 'valuecard':
            trackGiftcardPurchase(trackingProps);
            history.push({
              pathname: `/places/${slugId}/valuecard/confirmation/${responseData.id}`,
              state: locationState,
            });
            return;
          default:
            const never: never = productType;
            throw new Error(`Unhandled productType: ${never}`);
        }
      },
      formData,
      productType,
      employee,
      placeId,
      slugId: slugId,
      valueCard: location.state?.valueCard,
      trackingProps: buildGiftcardCheckoutTrackingProps({
        formdata: getValues(),
        summary: state.summary,
        selectedPaymentMethod: state.selectedPaymentMethod,
        placeId,
        productType,
        campaign,
      }),
    };

    const adyenContext = {
      guestId: state.guestId,
      selectedPaymentMethod: state.selectedPaymentMethod,
    };

    return {
      submitCoF: handleSubmitCoF({ ...context, ...adyenContext }, dispatch),
      submitGuestCoF: handleSubmitGuestCoF({ ...context, ...adyenContext }, dispatch),
      submitGooglePay: handleSubmitGooglePay({ ...context, ...adyenContext }, dispatch),
      submitApplePay: handleSubmitApplePay({ ...context, ...adyenContext }, dispatch),
      submitThreeDS: handleSubmitThreeDSPayment(
        { ...context, ...adyenContext, cofThreeDS: state.cofThreeDS },
        dispatch,
      ),
      initQliro: () => handleInitQliroPayment(context, dispatch),
      submitSwish: () => handleSubmitSwishPayment({ ...context, loggedIn: Boolean(user) }, dispatch),
    };
  };

  return {
    ...state,
    placeSellsGiftcard,
    placeName,
    employees,
    isLoadingPaymentMethods: isLoadingWalletMethods,
    onUseGuestCheckout: handleUseGuestCheckout,
    onChangePaymentMethod: handleChangePaymentMethod,
    onDismissSwishQRCode: handleDismissSwishQRCode,
    onCheckoutMissingAction: handleOnCheckoutMissingAction,
    giftcardCheckoutAPI,
  };
};

export default useGiftcardCheckoutManager;

const GiftcardCheckoutContext = createContext<UseGiftcardCheckoutManagerResult>({
  submitting: false,
  placeSellsGiftcard: false,
  placeName: '',
  loading: true,
  cofThreeDS: null,
  employees: [],
  isLoadingPaymentMethods: false,
  selectedPaymentMethod: { type: CHECKOUT_PAYMENT_METHOD.NONE },
  onChangePaymentMethod: () => {},
  onCheckoutMissingAction: () => {},
  onUseGuestCheckout: () => {},
  onDismissSwishQRCode: () => {},
  giftcardCheckoutAPI: () => ({
    submitSwish: async () => {},
    submitCoF: async () => {},
    submitGooglePay: async () => {},
    submitGuestCoF: async () => {},
    submitApplePay: async () => {},
    submitThreeDS: async () => {},
    initQliro: async () => {},
  }),
});

export const useGiftcardCheckout = () => {
  const context = useContext(GiftcardCheckoutContext);

  if (!context) {
    throw new Error('useGiftcardCheckout must be used within GiftcardCheckoutContext');
  }

  return context;
};

export const GiftcardCheckoutProvider = ({ children, cards }: { children: React.ReactNode; cards: PaymentCard[] }) => {
  return (
    <GiftcardCheckoutContext.Provider value={useGiftcardCheckoutManager(cards)}>
      {children}
    </GiftcardCheckoutContext.Provider>
  );
};
