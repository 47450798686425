import { RefObject, useEffect, useRef } from 'react';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useIsInView = (targetRef: RefObject<HTMLElement>) => {
  const isInView = useIntersectionObserver(targetRef, { root: null, threshold: 0.3 });
  const wasInView = usePrevious(isInView);

  return !wasInView && isInView;
};
