import Card from '@/components/elements/Card/Card';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import { Location } from '@/components/modules/salon';
import { hasFeatureSetting } from '@/helpers';
import { _s } from '@/locale';
import { PLACE_IMPRESSION, placeService } from '@/services';

const baseTranslationKey = 'features.ProfilePages.components.Address';

type AddressProps = {
  place: any;
  isVerified: boolean;
  useMapTiler: boolean;
};

const Address = ({ place, isVerified, useMapTiler = false }: AddressProps) => {
  const salesTrigger = ''; // @TODO add sales trigger link to button when adding support for unverified places and basic subscriptions
  const address = place.contact && place.contact.address ? place.contact.address : null;
  const addressString = address?.street?.split(' ').join('+');

  const handleAddressClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    placeService.storePlaceImpressions([place.id], PLACE_IMPRESSION.PLACE_ADDRESS_CLICK);
  };

  return (
    <Card size="lg">
      <div className="gap-xs flex flex-col">
        <h2 className="text-h-s">{_s(`${baseTranslationKey}.title`)}</h2>
        <ul>
          {!isVerified && !address?.street && (
            <ListItem
              leftPadding={false}
              underline
              className="gap-sm"
              leftSlot={<Icon variant="shop" color="fg_secondary" />}>
              <a className="text-l text-fg_link underline" href={salesTrigger}>
                {_s(`${baseTranslationKey}.cta`)}
              </a>
            </ListItem>
          )}
          {isVerified && address?.street && (
            <ListItem
              className="gap-sm"
              leftPadding={false}
              underline
              leftSlot={<Icon variant="shop" color="fg_secondary" />}>
              <a
                className="text-l text-fg_link underline"
                target="_blank"
                rel="noreferrer"
                href={`https://www.google.com/maps/search/?api=1&query=${addressString}`}
                onClick={handleAddressClick}>
                {address.street}
              </a>
            </ListItem>
          )}
          <ListItem className="gap-sm" leftPadding={false} leftSlot={<Icon variant="location" color="fg_secondary" />}>
            <ListItemContent
              subTitle={`${address.zipcode ? address.zipcode : ''}${address.zipcode && address.city ? ', ' : ''}${
                address.city ? address.city : ''
              }`}
            />
          </ListItem>
        </ul>
        <div className="flex justify-center">
          <Location
            placeId={place.id}
            contact={place.contact}
            showOnlyDirections={hasFeatureSetting(place, 'hide_location_image')}
            showAddress={false}
            showOnlyMap={true}
            useMapTiler={useMapTiler}
          />
        </div>
      </div>
    </Card>
  );
};

export default Address;
