import { decodeUnicodeString } from '@/helpers';
import { themed } from '@/helpers/theme';
import { _s } from '@/locale';
import { useEffect, useRef, useState } from 'react';
import * as sanitizeHtml from 'sanitize-html';

type Props = {
  text?: string;
  style?: string;
};

const WelcomeText = ({ text = '', style = '' }: Props) => {
  const [height, setHeight] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current.scrollHeight);
  }, []);

  const handleToggle = () => {
    setExpanded((prev) => !prev);
  };

  const styles =
    `text-ellipsis text-sm ${
      !expanded
        ? `${height > 60 ? ' line-clamp-3 whitespace-pre-wrap' : ''} max-h-[60px] overflow-hidden `
        : ' whitespace-pre-wrap '
    }` + style;

  const __html = sanitizeHtml(decodeUnicodeString(text.trim()), {
    allowedTags: [],
    allowedAttributes: {},
  });

  return (
    <>
      <div ref={ref} id="welcomeText" className={styles} dangerouslySetInnerHTML={{ __html }}></div>
      {!expanded && height > 60 && (
        <span
          className={`mt-2 block cursor-pointer text-sm hover:underline ${themed(
            'text-primary',
            'text-black-800 underline',
          )} ${style}`}
          onClick={handleToggle}>
          {_s('showMore')}
        </span>
      )}
      {expanded && (
        <span
          className={`mt-2 block cursor-pointer text-sm hover:underline ${themed(
            'text-primary',
            'text-black-800 underline',
          )} ${style}`}
          onClick={handleToggle}>
          {_s('showLess')}
        </span>
      )}
    </>
  );
};

export default WelcomeText;
