import { promiseWrapper } from '@/helpers';
import { DEFAULT_MY_LOCATION_KEYWORD } from '@/hooks/search/useSearch.constants';
import { autocompleteService } from '@/services';
import Fuse from 'fuse.js';
import { AutoSuggestionSection } from '../shared/types';
import { LocationSuggestion } from './LocationAutoSuggest.types';

export async function getSuggestionsFromSearchValue(value: string): Promise<{
  hasHistory: boolean;
  suggestions: AutoSuggestionSection<LocationSuggestion>[];
  storedSuggestions: AutoSuggestionSection<LocationSuggestion>[];
}> {
  const { data, error } = await promiseWrapper(autocompleteService.getLocationSuggestions(value));

  if (error) {
    console.error('Error while fetching location suggestions: ', error);
    return { suggestions: [], storedSuggestions: [], hasHistory: false };
  }

  const suggestions = data?.results || [];
  if (suggestions[0]) {
    suggestions[0].highlight = true;
  }

  const history = (data?.history || []).filter((item) => item && item.label);
  const popular = (data?.popular || []).filter((item) => item && item.label);

  suggestions.unshift({ type: 'geolocation', label: DEFAULT_MY_LOCATION_KEYWORD });

  const suggestionsArray = [
    {
      type: 'results',
      title: '',
      suggestions: suggestions,
    },
  ];

  const storedSuggestions = [
    {
      type: 'results',
      title: '',
      suggestions: suggestions,
    },
  ];

  /**
   * Fuzzy search (fuse.js https://www.fusejs.io/api/options.html) for history suggestions based on the search value
   * If the search value is empty, return all history suggestions
   */
  let historySuggestions = [];
  if (!value.trim()) {
    historySuggestions = history;
  } else {
    const results = new Fuse(history, { keys: ['label'], threshold: 0.2, includeScore: true }).search(value);
    historySuggestions = results.sort((a, b) => a.score - b.score).map((result) => result.item);
  }

  const hasHistory = !!historySuggestions.length;

  if (hasHistory) {
    suggestionsArray.push({
      type: 'history',
      title: 'history',
      suggestions: historySuggestions.slice(0, 3),
    });

    storedSuggestions.push({
      type: 'history',
      title: 'history',
      suggestions: historySuggestions,
    });
  }

  suggestionsArray.push({
    type: 'popular',
    title: 'popular',
    suggestions: popular,
  });

  storedSuggestions.push({
    type: 'popular',
    title: 'popular',
    suggestions: popular,
  });

  return { suggestions: suggestionsArray, storedSuggestions, hasHistory };
}

export async function getLocationSuggestionFromSearchHistory(): Promise<LocationSuggestion> {
  const { data, error } = await promiseWrapper(autocompleteService.getLocationSuggestions(''));

  if (error) {
    console.error('Error while fetching location suggestions: ', error);
    return;
  }

  const [lastLocationSearch] = data?.history;
  return lastLocationSearch;
}
