import Icon from '@/components/icons/Icon';
import { classnames, roundRating } from '@/helpers';
import { customTwMerge } from '@/helpers/theme';
import { _s } from '@/locale';
import { Button } from '../../forms/buttons';

const baseTranslationKey = 'components.elements.Rating';

type Props = {
  rating: number;
  count?: number;
  showCount?: boolean;
  size: 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  link?: boolean;
  onlyStars?: boolean;
  onReviewsClick?: () => void;
};

const getStars = (rating: number) => {
  let empty = false;
  return [1, 2, 3, 4, 5].map((i, key) => {
    let fill = 100;
    if (i > rating) {
      fill = !empty ? (rating % 1) * 100 : 0;
      empty = true;
    }
    return (
      <div key={key} className="relative w-4">
        <Icon variant="star-filled" color="black-300" style={{ width: 16, height: 16 }} />
        <Icon
          className="absolute inset-0"
          variant="star-filled"
          color="warning-500"
          style={{ width: 16, height: 16, clipPath: `inset(0 ${100 - fill}% 0 0)` }}
        />
      </div>
    );
  });
};

const Rating = ({ rating, count, showCount, size = 'sm', className = '', link, onlyStars, onReviewsClick }: Props) => {
  return (
    <div
      className={customTwMerge(
        `gap-xs inline-flex flex-row flex-wrap items-center justify-start ${size === 'sm' ? 'text-s' : 'text-l'}`,
        className,
      )}>
      <div className={`${size === 'lg' ? 'gap-sm' : 'gap-2xs'} flex select-none items-center`}>
        {size === 'lg' && (
          <>
            <span className="text-fg_primary flex items-center font-bold">{roundRating(rating, 1).toFixed(1)}</span>
            <span className="gap-2xs flex flex-col">
              <span className="gap-2xs flex">{getStars(rating)}</span>
              <span className="text-m text-fg_secondary font-normal">
                {count > 0 ? `${count} ${_s(`${baseTranslationKey}.reviews`)}` : _s(`${baseTranslationKey}.noReviews`)}
              </span>
            </span>
          </>
        )}

        {size === 'md' && (
          <>
            {!onlyStars && (
              <span className={`text-fg_primary text-l flex items-center font-bold`}>
                {roundRating(rating, 1).toFixed(1)}
              </span>
            )}
            <span className="gap-2xs flex flex-col">
              <span className="gap-2xs flex">{getStars(rating)}</span>
            </span>
          </>
        )}

        {size === 'sm' && (
          <>
            {!link && <Icon variant="star-filled" color="warning-500" style={{ width: 16, height: 16 }} />}
            <span className={`text-fg_primary flex items-center font-bold ${!link ? 'text-s' : 'text-l'}`}>
              {roundRating(rating, 1).toFixed(1)}
            </span>
            {link && <Icon variant="star-filled" color="warning-500" style={{ width: 16, height: 16 }} />}
          </>
        )}
      </div>
      {(size === 'sm' || (size === 'md' && showCount)) && (
        <span
          className={classnames(
            /** dot styles */
            'before:w-xxs before:h-xxs before:mr-xs before:bg-fg_tertiary before:inline-block before:rounded-full before:content-[""]',
            /** rest */
            `text-xxs text-fg_secondary flex items-center`,
          )}>
          {link && (
            <Button onClick={onReviewsClick} variant="link" className={`text-fg_link text-l !p-0 underline`}>
              {count > 0 ? `${count} ${_s(`${baseTranslationKey}.reviews`)}` : _s(`${baseTranslationKey}.noReviews`)}
            </Button>
          )}
          {!link && <span>{`${count} ${_s(`${baseTranslationKey}.reviews`)}`}</span>}
        </span>
      )}
    </div>
  );
};

export default Rating;
